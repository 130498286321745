import BackButton from '../components/BackButton'
import { useSelector, useDispatch } from 'react-redux'
import toast from 'react-hot-toast'
import { useEffect, useState } from 'react'
import Spinner from '../components/Spinner'
import { getOtherBusinesses, reset } from '../features/partners/partnersSlice'
import Pagination from '../components/Pagination'
import { useDebounce } from '../components/useDebounce'
import { motion } from 'framer-motion'
import { FiSearch, FiCalendar, FiUser, FiMail, FiBriefcase, FiGrid, FiInfo } from 'react-icons/fi'

const OtherBusinesses = () => {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')

  const {
    otherBusinesses: { otherBusinesses, totalPages },
    isError,
    isSuccess,
    isLoading,
    message,
  } = useSelector((state) => state.partners)

  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  useEffect(() => {
    if (isError) {
      toast.error(message)
      dispatch(reset())
    }
    if (isSuccess) {
      dispatch(reset())
    }
  }, [isSuccess, dispatch, isError, message])

  useEffect(() => {
    const filterData = {
      page: currentPage,
      limit: 15,
      searchTerm: debouncedSearchTerm,
    }
    dispatch(getOtherBusinesses(filterData))

    return () => {
      dispatch(reset())
    }
  }, [dispatch, currentPage, debouncedSearchTerm])

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
    setCurrentPage(1)
  }

  const formatDate = (isoString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }
    const date = new Date(isoString)
    return date.toLocaleDateString('en-GB', options)
  }

  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3,
      },
    },
  }

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    show: { y: 0, opacity: 1 },
  }

  return (
    <div className='min-h-screen bg-[#1a1f2b]'>
      {/* Hero Section */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className='relative overflow-hidden bg-gradient-to-r from-blue-600 to-blue-900 py-16 mb-4'>
        <div className='relative z-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
          <div className='text-center'>
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className='text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl'>
              Other Businesses
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              className='mt-6 text-xl text-gray-100'>
              Review and manage other business registrations
            </motion.p>
          </div>
        </div>

        {/* Decorative blob */}
        <div className='absolute top-0 right-0 -translate-y-12 translate-x-12 transform opacity-20'>
          <svg width='400' height='400' viewBox='0 0 184 184' xmlns='http://www.w3.org/2000/svg'>
            <path
              fill='white'
              d='M92 0C92 0 92 0 92 0C142.8 0 184 41.2 184 92C184 142.8 142.8 184 92 184C41.2 184 0 142.8 0 92C0 41.2 41.2 0 92 0Z'
            />
          </svg>
        </div>
      </motion.div>

      <div className='mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 pb-20'>
        <BackButton className='text-white hover:text-gray-300 mb-6' />

        {/* Search Controls */}
        <motion.div
          variants={containerVariants}
          initial='hidden'
          animate='show'
          className='bg-[#252b38] rounded-2xl shadow-xl p-6 mb-8 border border-gray-700'>
          <div className='flex items-center gap-4'>
            <div className='relative flex-1'>
              <FiSearch className='absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400' />
              <input
                type='text'
                placeholder='Search by name, email, postcode or business category'
                value={searchTerm}
                onChange={handleSearchChange}
                className='w-full pl-12 pr-4 py-3 bg-[#1a1f2b] border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-20 transition-colors'
              />
            </div>
          </div>
        </motion.div>

        {/* Other Businesses Table */}
        <motion.div
          variants={containerVariants}
          initial='hidden'
          animate='show'
          className='bg-[#252b38] rounded-2xl shadow-xl p-6 border border-gray-700'>
          <div className='flex items-center gap-3 text-2xl font-semibold text-white mb-6'>
            <FiBriefcase className='text-blue-500' />
            <h2>Business Registrations</h2>
          </div>

          <div className='overflow-x-auto'>
            <table className='w-full'>
              <thead className='bg-[#1a1f2b]'>
                <tr>
                  <th className='px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>
                    <div className='flex items-center gap-2'>
                      <FiCalendar className='text-blue-500' />
                      Date
                    </div>
                  </th>
                  <th className='px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>
                    <div className='flex items-center gap-2'>
                      <FiUser className='text-blue-500' />
                      Customer Name
                    </div>
                  </th>
                  <th className='px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>
                    <div className='flex items-center gap-2'>
                      <FiBriefcase className='text-blue-500' />
                      Business Name
                    </div>
                  </th>
                  <th className='px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>
                    <div className='flex items-center gap-2'>
                      <FiMail className='text-blue-500' />
                      Email
                    </div>
                  </th>
                  <th className='px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>
                    <div className='flex items-center gap-2'>
                      <FiGrid className='text-blue-500' />
                      Business Category
                    </div>
                  </th>
                  <th className='px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>
                    <div className='flex items-center gap-2'>
                      <FiInfo className='text-blue-500' />
                      Other Details
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-700'>
                {isLoading ? (
                  <tr>
                    <td colSpan='6' className='px-6 py-12 text-center'>
                      <Spinner />
                    </td>
                  </tr>
                ) : !Array.isArray(otherBusinesses) || otherBusinesses.length === 0 ? (
                  <tr>
                    <td colSpan='6' className='px-6 py-12 text-center text-gray-400 text-lg'>
                      No other businesses found
                    </td>
                  </tr>
                ) : (
                  otherBusinesses.map((business) => (
                    <tr key={business._id} className='bg-[#252b38] hover:bg-[#2a3040] transition-colors'>
                      <td className='px-6 py-4 text-sm text-gray-200'>{formatDate(business.createdAt)}</td>
                      <td className='px-6 py-4 text-sm text-gray-200'>{business.name}</td>
                      <td className='px-6 py-4 text-sm text-gray-200'>{business.businessName}</td>
                      <td className='px-6 py-4 text-sm text-gray-200'>{business.email}</td>
                      <td className='px-6 py-4'>
                        <span className='px-2 py-1 text-xs font-semibold rounded-full bg-blue-500/10 text-blue-500'>{business.businessCategory}</span>
                      </td>
                      <td className='px-6 py-4 text-sm text-gray-200'>{business.otherBusinessType}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </motion.div>

        {/* Pagination */}
        {totalPages > 1 && (
          <motion.div variants={containerVariants} initial='hidden' animate='show' className='flex justify-center mt-8'>
            <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
          </motion.div>
        )}
      </div>
    </div>
  )
}

export default OtherBusinesses
