import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import authService from './authService'

// Get user from localstorage

const user = JSON.parse(localStorage.getItem('user'))

// Ensure the user object from localStorage has a wallet property
if (user && !user.wallet) {
  user.wallet = {
    userAccount: {},
    tedsBalance: 0,
    madTedsBalance: 0,
    transactionHistory: [],
  }
}

// Ensure the user object from localStorage has an agentType property
if (user && !user.agentType) {
  user.agentType = 'basicAgent' // Default for existing users
}

const initialState = {
  user: user ? user : null,
  isAuthenticated: false,
  userDetails: '',
  agentDetails: '',
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
  passwordResetEmail: false,
  isVerifying: false,
  isVerified: false,
  verifyError: '',
  amlRecords: [],
  amlSearchLoading: false,
  amlSearchError: null,
  amlSearchSuccess: false,
}

//* Check if user is authenticated */
export const authCheck = createAsyncThunk('auth/authCheck', async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await authService.authCheck(token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *register new client
export const register = createAsyncThunk('auth/register', async (user, thunkAPI) => {
  console.log(user, 'user')
  try {
    return await authService.register(user)
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString()

    return thunkAPI.rejectWithValue(message)
  }
})

// login user
export const login = createAsyncThunk('auth/login', async (user, thunkAPI) => {
  try {
    return await authService.login(user)
  } catch (error) {
    // More specific error handling
    const message =
      (error.response && error.response.data && error.response.data.message) || // Try to get specific message
      (error.response && error.response.data) || // Fallback to response data
      error.message || // Fallback to error message
      'Invalid credentials' // Default message

    return thunkAPI.rejectWithValue(message)
  }
})

// logout user
export const logout = createAsyncThunk('auth/logout', async () => {
  authService.logout()
})

//Reset password
export const resetPassword = createAsyncThunk('auth/resetpassword', async (emailAddress, thunkAPI) => {
  console.log('🚀 ~ file: authSlice.js:129 ~ resetPassword ~ emailAddress:', emailAddress)
  try {
    return await authService.resetPassword(emailAddress)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

    return thunkAPI.rejectWithValue(message)
  }
})

// Search AML records by email
export const searchAMLRecordsByEmail = createAsyncThunk('auth/searchAMLRecordsByEmail', async (email, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await authService.searchAMLRecordsByEmail(email, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// * verify token

export const verifyToken = createAsyncThunk('auth/verifyToken', async (verificationToken, thunkAPI) => {
  try {
    thunkAPI.dispatch(resetVerificationState())
    return await authService.verifyToken(verificationToken)
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString()

    return thunkAPI.rejectWithValue(message)
  }
})

// * resend verification email

export const resendVerificationEmail = createAsyncThunk('subscription/resendEmail', async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await authService.resendVerificationEmail(token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

    return thunkAPI.rejectWithValue(message)
  }
})

// *get Agent
export const getAgentDetails = createAsyncThunk('auth/getAgentDetails', async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await authService.getAgentDetails(token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Update client details
export const updateAgentDetails = createAsyncThunk('auth/updateAgentDetails', async (agentData, thunkAPI) => {
  console.log(agentData, 'user data')
  try {
    const token = thunkAPI.getState().auth.user.token
    console.log('🚀 ~ file: authSlice.js:54 ~ updateUserDetails ~ token:', token)
    return await authService.updateAgentDetails(agentData, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isError = false
      state.isSuccess = false
      state.message = ''
    },
    updateWallet: (state, action) => {
      // This assumes the payload is the new wallet balance
      state.user.wallet = action.payload
      // Sync with local storage
      localStorage.setItem('user', JSON.stringify(state.user))
    },
    resetVerificationState: (state) => {
      state.isVerifying = false
      state.isVerified = false
      state.verifyError = ''
    },
    resetResendEmailState: (state) => {
      state.isResending = false
      state.isResendSuccessful = false
      state.resendError = ''
    },
    resetEmailSent: (state) => {
      state.passwordResetEmail = ''
    },
    resetAmlSearchState: (state) => {
      state.amlRecords = []
      state.amlSearchLoading = false
      state.amlSearchError = null
      state.amlSearchSuccess = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(register.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(register.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      console.log(action.payload)
      state.user = action.payload
    })
    builder.addCase(register.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(login.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(login.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.user = {
        ...action.payload,
        agentType: action.payload.agentType, // Ensure agentType is stored
      }
    })
    builder.addCase(login.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder
      .addCase(logout.fulfilled, (state) => {
        state.user = null
        state.userDetails = null
      })

      .addCase(resetPassword.pending, (state) => {
        state.isLoading = true
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.passwordResetEmail = action.payload
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
    builder.addCase(verifyToken.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(verifyToken.fulfilled, (state) => {
      state.isLoading = false
      state.isSuccess = true
      state.isError = false
    })
    builder.addCase(verifyToken.rejected, (state, action) => {
      state.isError = true
      state.message = action.payload
      state.isLoading = false
    })

    builder.addCase(resendVerificationEmail.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(resendVerificationEmail.fulfilled, (state) => {
      state.isLoading = false
      state.emailResent = true
    })
    builder.addCase(resendVerificationEmail.rejected, (state, action) => {
      state.isError = true
      state.emailResent = false
      state.message = action.payload
    })
    builder.addCase(updateAgentDetails.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(updateAgentDetails.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.agentDetails = action.payload
    })
    builder.addCase(updateAgentDetails.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(getAgentDetails.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getAgentDetails.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.agentDetails = action.payload
    })
    builder.addCase(getAgentDetails.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(authCheck.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(authCheck.fulfilled, (state) => {
      state.isLoading = false
      state.isAuthenticated = true
    })
    builder.addCase(authCheck.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
      state.isAuthenticated = false
    })

    // Handle AML email search
    builder.addCase(searchAMLRecordsByEmail.pending, (state) => {
      state.amlSearchLoading = true
      state.amlSearchError = null
      state.amlSearchSuccess = false
    })
    builder.addCase(searchAMLRecordsByEmail.fulfilled, (state, action) => {
      state.amlSearchLoading = false
      state.amlRecords = action.payload
      state.amlSearchSuccess = true
    })
    builder.addCase(searchAMLRecordsByEmail.rejected, (state, action) => {
      state.amlSearchLoading = false
      state.amlSearchError = action.payload
      state.amlSearchSuccess = false
    })
  },
})

export const {
  reset,
  resetResendEmailState,
  resetVerificationStatus,
  updateServerCount,
  resetEmailSent,
  resetVerificationState,
  updateWallet,
  resetAmlSearchState,
} = authSlice.actions

export default authSlice.reducer
