import { Link } from 'react-router-dom'
import BackButton from '../components/BackButton'
import { useSelector, useDispatch } from 'react-redux'
import toast from 'react-hot-toast'
import { useEffect, useState } from 'react'
import Spinner from '../components/Spinner'
import { getDailyTEDSReport, reset } from '../features/partners/partnersSlice'
import Pagination from '../components/Pagination'
import { motion } from 'framer-motion'
import { FiUsers, FiUserCheck, FiUserPlus, FiClock, FiDollarSign, FiActivity } from 'react-icons/fi'

const TEDSDailyUpdate = () => {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)

  const {
    dailyTEDSReport: { dailyTEDS, totalPages, today },
    isError,
    isSuccess,
    isLoading,
    message,
  } = useSelector((state) => state.partners)

  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3,
      },
    },
  }

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    show: { y: 0, opacity: 1 },
  }

  useEffect(() => {
    if (isError) {
      toast.error(message)
      dispatch(reset())
    }
    if (isSuccess) {
      dispatch(reset())
    }
  }, [isSuccess, dispatch, isError, message])

  useEffect(() => {
    const filterData = {
      page: currentPage,
      limit: 15,
    }
    dispatch(getDailyTEDSReport(filterData))

    return () => {
      dispatch(reset())
    }
  }, [dispatch, currentPage])

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const formatDate = (isoString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' }
    const date = new Date(isoString)
    return date.toLocaleDateString('en-GB', options)
  }

  return (
    <div className='min-h-screen w-full bg-gradient-to-b from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800 pb-20'>
      {/* Hero Section */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className='w-full relative overflow-hidden bg-gradient-to-r from-purple-600 to-indigo-900 py-16 mb-8'>
        <div className='relative z-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
          <div className='text-center'>
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className='text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl'>
              TEDS Daily Update
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              className='mt-6 text-xl text-gray-100'>
              Monitor daily TEDS distribution across all user types
            </motion.p>
          </div>
        </div>

        {/* Decorative blob */}
        <div className='absolute top-0 right-0 -translate-y-12 translate-x-12 transform opacity-20'>
          <svg width='400' height='400' viewBox='0 0 184 184' xmlns='http://www.w3.org/2000/svg'>
            <path
              fill='white'
              d='M92 0C92 0 92 0 92 0C142.8 0 184 41.2 184 92C184 142.8 142.8 184 92 184C41.2 184 0 142.8 0 92C0 41.2 41.2 0 92 0Z'
            />
          </svg>
        </div>
      </motion.div>

      <div className='mx-auto max-w-8xl px-4 sm:px-6 lg:px-8'>
        <BackButton className='text-white hover:text-gray-300 mb-6' />

        {/* Today's Stats */}
        <motion.div variants={containerVariants} initial='hidden' animate='show' className='mb-8'>
          <h2 className='text-2xl font-bold text-gray-900 dark:text-white mb-6'>Today's Current Count</h2>
          <div className='grid grid-cols-1 md:grid-cols-3 gap-6'>
            <motion.div variants={itemVariants} className='bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-6 border border-gray-700'>
              <div className='flex items-center gap-3 mb-4'>
                <FiUsers className='text-2xl text-purple-500' />
                <h3 className='text-xl font-semibold text-gray-900 dark:text-white'>Partners</h3>
              </div>
              <div className='space-y-2'>
                <p className='text-gray-500 dark:text-gray-400'>
                  Total TEDS: <span className='text-purple-500 font-semibold'>{today?.ClientUser?.totalTEDS}</span>
                </p>
                <p className='text-gray-500 dark:text-gray-400'>
                  Total MADTEDS: <span className='text-purple-500 font-semibold'>{today?.ClientUser?.totalMADTEDS}</span>
                </p>
              </div>
            </motion.div>

            <motion.div variants={itemVariants} className='bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-6 border border-gray-700'>
              <div className='flex items-center gap-3 mb-4'>
                <FiUserCheck className='text-2xl text-purple-500' />
                <h3 className='text-xl font-semibold text-gray-900 dark:text-white'>Customers</h3>
              </div>
              <div className='space-y-2'>
                <p className='text-gray-500 dark:text-gray-400'>
                  Total TEDS: <span className='text-purple-500 font-semibold'>{today?.Customer?.totalTEDS}</span>
                </p>
                <p className='text-gray-500 dark:text-gray-400'>
                  Total MADTEDS: <span className='text-purple-500 font-semibold'>{today?.Customer?.totalMADTEDS}</span>
                </p>
              </div>
            </motion.div>

            <motion.div variants={itemVariants} className='bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-6 border border-gray-700'>
              <div className='flex items-center gap-3 mb-4'>
                <FiUserPlus className='text-2xl text-purple-500' />
                <h3 className='text-xl font-semibold text-gray-900 dark:text-white'>Loyalty Club PLC</h3>
              </div>
              <div className='space-y-2'>
                <p className='text-gray-500 dark:text-gray-400'>
                  Total TEDS: <span className='text-purple-500 font-semibold'>{today?.AdminUser?.totalTEDS}</span>
                </p>
                <p className='text-gray-500 dark:text-gray-400'>
                  Total MADTEDS: <span className='text-purple-500 font-semibold'>{today?.AdminUser?.totalMADTEDS}</span>
                </p>
              </div>
            </motion.div>
          </div>
        </motion.div>

        {/* Historical Data Table */}
        <motion.div
          variants={containerVariants}
          initial='hidden'
          animate='show'
          className='bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-6 border border-gray-700'>
          <div className='overflow-x-auto'>
            <table className='w-full'>
              <thead className='bg-[#1a1f2b]'>
                <tr>
                  <th className='px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>
                    <div className='flex items-center gap-2'>
                      <FiClock className='text-purple-500' />
                      Date
                    </div>
                  </th>
                  <th className='px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Partners TEDS</th>
                  <th className='px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Partners MADS</th>
                  <th className='px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Customers TEDS</th>
                  <th className='px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Customers MADS</th>
                  <th className='px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Loyalty Club PLC TEDS</th>
                  <th className='px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Loyalty Club PLC MADS</th>
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-700'>
                {isLoading ? (
                  <tr>
                    <td colSpan='7' className='px-6 py-12 text-center'>
                      <Spinner />
                    </td>
                  </tr>
                ) : !Array.isArray(dailyTEDS) || dailyTEDS.length === 0 ? (
                  <tr>
                    <td colSpan='7' className='px-6 py-12 text-center text-gray-400 text-lg'>
                      No historical data found.
                    </td>
                  </tr>
                ) : (
                  dailyTEDS.map((day) => (
                    <tr key={day.id} className='bg-[#252b38] hover:bg-[#2a3040] transition-colors'>
                      <td className='px-6 py-4 text-sm text-gray-200'>{formatDate(day.date)}</td>
                      <td className='px-6 py-4 text-sm text-purple-500'>{day.partners.totalTEDS}</td>
                      <td className='px-6 py-4 text-sm text-purple-500'>{day.partners.totalMADTEDS}</td>
                      <td className='px-6 py-4 text-sm text-purple-500'>{day.customers.totalTEDS}</td>
                      <td className='px-6 py-4 text-sm text-purple-500'>{day.customers.totalMADTEDS}</td>
                      <td className='px-6 py-4 text-sm text-purple-500'>{day.admin.totalTEDS}</td>
                      <td className='px-6 py-4 text-sm text-purple-500'>{day.admin.totalMADTEDS}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </motion.div>

        {/* Pagination */}
        {totalPages > 1 && (
          <motion.div variants={containerVariants} initial='hidden' animate='show' className='flex justify-center mt-8'>
            <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
          </motion.div>
        )}
      </div>
    </div>
  )
}

export default TEDSDailyUpdate
