import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PrivateRoute from './components/PrivateRoute'
import Login from './pages/Login'
import Register from './pages/Register'
import Partners from './pages/Partners'
import Members from './pages/Members'
import PartnerOverview from './pages/PartnerOverview'
import MemberOverview from './pages/MemberOverview'
import NewAgents from './pages/NewAgents'
import Agents from './pages/Agents'
import AgentOverview from './pages/AgentOverview'
import MyReferrals from './pages/MyReferrals'
import ReferredPartners from './pages/ReferredPartners'
import AgentInfo from './pages/AgentInfo'
import AllSales from './pages/AllSales'
import TEDSDailyUpdate from './pages/TEDSDailyUpdate'
import DailyWalletActivity from './pages/DailyWalletActivity'
import AgentSales from './pages/AgentSales'
import FailedRegistrations from './pages/FailedRegistrations'
import OtherBusinesses from './pages/OtherBusinesses'
import AgentSignup from './pages/AgentSignup'
import StrategicPartners from './pages/StrategicPartners.jsx'
import AddStrategicPartner from './pages/AddStrategicPartner'
import ViewStrategicPartner from './pages/ViewStrategicPartner'
import EditStrategicPartner from './pages/EditStrategicPartner'
import AddCharity from './pages/AddCharity'
import ViewCharity from './pages/ViewCharity'
import EditCharity from './pages/EditCharity'
import Charities from './pages/Charities'
import TEDSWallet from './pages/TEDSWallet.jsx'
import TEDSTransactions from './pages/TEDSTransactions.jsx'
import SoldTEDSTransactions from './pages/SoldTEDSTransactions.jsx'
import AgentSettings from './pages/AgentSettings'
import PasswordReset from './pages/PasswordReset'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import Header from './components/Header'
import toast, { Toaster } from 'react-hot-toast'
import VerifyEmail from './pages/VerifyEmail'
import VerifyPrompt from './pages/VerifyPrompt'
import MemberVerifyEmail from './pages/MemberVerifyEmail'
import BottomNav from './components/BottomNav'
import SideNav from './components/SideNav'
import Home from './pages/Home'
import TEDSHome from './pages/TEDSHome'
import CountryManagerRegistration from './pages/CountryManagerRegistration'
import SalesManagerRegistration from './pages/SalesManagerRegistration'
import CampaignManagerRegistration from './pages/CampaignManagerRegistration'
import AMLSearch from './pages/AMLSearch'
import InfluencerRegistration from './pages/InfluencerRegistration'
import CommissionEarnings from './pages/CommissionEarnings'
import Influencers from './pages/Influencers'
import './index.css'
import { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import ScrollToTop from './components/ScrollToTop'

// Override toast functions globally to prevent blank messages
const originalToast = toast.success
const originalError = toast.error

toast.success = (message) => {
  if (message && typeof message === 'string' && message.trim()) {
    originalToast(message)
  }
}

toast.error = (message) => {
  if (message && typeof message === 'string' && message.trim()) {
    originalError(message)
  }
}

const PageLayout = ({ children, isSidebarExpanded, isMobile }) => {
  const location = useLocation()
  const fullWidthPages = [
    '/',
    '/agent-overview',
    '/agent-overview/',
    '/partners',
    '/partner-overview',
    '/partner-referrals',
    '/strategic-partners',
    '/members',
    '/member-overview',
    '/member-overview/',
    '/agents',
    '/all-sales',
    '/new-agents',
    '/charities',
    '/charity/add',
    '/charities/',
    '/failed-registrations',
    '/other-businesses',
    '/teds',
    '/country-manager-registration',
    '/sales-manager-registration',
    '/campaign-manager-registration',
    '/influencer-registration',
    '/commissions',
    '/agent-sales',
    '/settings',
    '/agent-info',
    '/aml-search',
  ]
  const isFullWidth =
    fullWidthPages.includes(location.pathname) ||
    location.pathname.startsWith('/agent-overview/') ||
    location.pathname.startsWith('/partner-overview/') ||
    location.pathname.startsWith('/strategic-partners/') ||
    location.pathname.startsWith('/member-overview/') ||
    location.pathname.startsWith('/members/') ||
    location.pathname.startsWith('/agents/') ||
    location.pathname.startsWith('/all-sales/') ||
    location.pathname.startsWith('/charity/') ||
    location.pathname.startsWith('/teds/') ||
    location.pathname.startsWith('/resetpassword') ||
    location.pathname.startsWith('/influencers') ||
    location.pathname.startsWith('/agent-sign-up') ||
    location.pathname.startsWith('/agent-info/')

  return <div className={`flex-1 ${!isFullWidth ? 'container mt-24' : ''} ${isMobile ? 'pb-20' : 'pb-0'}`}>{children}</div>
}

function App() {
  const { user } = useSelector((state) => state.auth)
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)
  const location = useLocation()
  const isLoginPage = location.pathname === '/login' || location.pathname === '/register' || location.pathname === '/resetpassword'

  // Add resize listener
  useEffect(() => {
    // Initial check on component mount
    setIsMobile(window.innerWidth < 768)

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768)
      // Collapse sidebar on mobile automatically
      if (window.innerWidth < 768) {
        setIsSidebarExpanded(false)
      }
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const toggleSidebar = () => {
    setIsSidebarExpanded(!isSidebarExpanded)
  }

  return (
    <div className='app min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800'>
      <Toaster
        position='top-right'
        toastOptions={{
          duration: 5000,
          style: {
            padding: '16px',
            borderRadius: '8px',
            fontSize: '14px',
            fontWeight: '500',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          },
          success: {
            style: {
              background: '#059669', // Emerald-600
              color: 'white',
            },
            iconTheme: {
              primary: 'white',
              secondary: '#059669',
            },
          },
          error: {
            style: {
              background: '#DC2626', // Red-600
              color: 'white',
            },
            iconTheme: {
              primary: 'white',
              secondary: '#DC2626',
            },
          },
        }}
      />
      <ScrollToTop />
      {!isLoginPage && <SideNav isExpanded={isSidebarExpanded} onToggle={toggleSidebar} />}

      <div
        className={`min-h-screen flex flex-col ${
          isLoginPage ? '' : !isMobile && (isSidebarExpanded ? 'md:pl-[240px]' : 'md:pl-[72px]')
        } transition-all duration-200`}>
        <Header onMenuClick={toggleSidebar} isExpanded={isSidebarExpanded} />

        <main className='pt-24 flex-grow'>
          <HelmetProvider>
            <PageLayout isSidebarExpanded={isSidebarExpanded} isMobile={isMobile}>
              <Helmet>
                <meta charSet='utf-8' />
                <title>OLC - Agent Portal</title>
                <link rel='canonical' href='http://mysite.com/example' />
              </Helmet>
              <Routes>
                <Route path='/login' element={<Login />} />
                <Route path='/register' element={<Register />} />
                <Route path='/agent-sign-up' element={<AgentSignup />} />
                <Route path='/strategic-partners/add' element={<PrivateRoute roles={['AdminUser']} />}>
                  <Route path='/strategic-partners/add' element={<AddStrategicPartner />} />
                </Route>
                <Route path='/strategic-partners' element={<PrivateRoute roles={['AdminUser']} />}>
                  <Route path='/strategic-partners' element={<StrategicPartners />} />
                </Route>
                <Route path='/charities' element={<PrivateRoute roles={['AdminUser']} />}>
                  <Route path='/charities' element={<Charities />} />
                </Route>
                <Route path='/charities/:charityId/edit' element={<PrivateRoute roles={['AdminUser']} />}>
                  <Route path='/charities/:charityId/edit' element={<EditCharity />} />
                </Route>
                <Route path='/strategic-partners/:strategicPartnerId/edit' element={<PrivateRoute roles={['AdminUser']} />}>
                  <Route path='/strategic-partners/:strategicPartnerId/edit' element={<EditStrategicPartner />} />
                </Route>

                <Route path='/charity/add' element={<PrivateRoute roles={['AdminUser']} />}>
                  <Route path='/charity/add' element={<AddCharity />} />
                </Route>
                <Route path='/charity/:charityId/view' element={<PrivateRoute roles={['AdminUser']} />}>
                  <Route path='/charity/:charityId/view' element={<ViewCharity />} />
                </Route>
                <Route path='/country-manager-registration' element={<PrivateRoute roles={['AdminUser']} />}>
                  <Route path='/country-manager-registration' element={<CountryManagerRegistration />} />
                </Route>
                <Route path='/strategic-partner/:strategicPartnerId/view' element={<PrivateRoute roles={['AdminUser']} />}>
                  <Route path='/strategic-partner/:strategicPartnerId/view' element={<ViewStrategicPartner />} />
                </Route>
                <Route path='/resetpassword' element={<PasswordReset />} />
                <Route
                  path='/'
                  element={
                    <PrivateRoute
                      roles={['AdminUser', 'AgentUser']}
                      allowedAgentTypes={['AdminUser', 'basicAgent', 'countryManager', 'salesManager', 'campaignManager', 'influencer']}
                    />
                  }>
                  <Route path='/' element={<Home />} />
                </Route>
                <Route path='/verify-email/*' element={<VerifyEmail />} />
                <Route path='/verification-required' element={<VerifyPrompt />} />
                <Route path='/member-verification' element={<MemberVerifyEmail />} />
                <Route
                  path='/partners'
                  element={<PrivateRoute roles={['AdminUser', 'AgentUser']} allowedAgentTypes={['countryManager', 'salesManager']} />}>
                  <Route path='/partners' element={<Partners />} />
                </Route>
                <Route
                  path='/partner-referrals'
                  element={
                    <PrivateRoute
                      roles={['AdminUser', 'AgentUser']}
                      allowedAgentTypes={['basicAgent', 'countryManager', 'salesManager', 'campaignManager']}
                    />
                  }>
                  <Route path='/partner-referrals' element={<ReferredPartners />} />
                </Route>
                <Route path='/members' element={<PrivateRoute roles={['AdminUser']} />}>
                  <Route path='/members' element={<Members />} />
                </Route>
                <Route path='' element={<PrivateRoute roles={['AdminUser', 'AgentUser']} allowedAgentTypes={['basicAgent']} />}>
                  <Route path='/member-overview/:memberId/:name' element={<MemberOverview />} />
                </Route>
                <Route path='' element={<PrivateRoute roles={['AdminUser', 'AgentUser']} allowedAgentTypes={['basicAgent']} />}>
                  <Route path='/partner-overview/:partnerId?' element={<PartnerOverview />} />
                </Route>
                <Route path='/all-sales' element={<PrivateRoute roles={['AdminUser', 'AgentUser']} allowedAgentTypes={['countryManager']} />}>
                  <Route path='/all-sales' element={<AllSales />} />
                </Route>
                <Route path='/failed-registrations' element={<PrivateRoute roles={['AdminUser']} />}>
                  <Route path='/failed-registrations' element={<FailedRegistrations />} />
                </Route>
                <Route path='/other-businesses' element={<PrivateRoute roles={['AdminUser']} />}>
                  <Route path='/other-businesses' element={<OtherBusinesses />} />
                </Route>
                <Route
                  path='/agent-sales'
                  element={
                    <PrivateRoute
                      roles={['AgentUser']}
                      allowedAgentTypes={['basicAgent', 'countryManager', 'salesManager', 'campaignManager', 'influencer']}
                    />
                  }>
                  <Route path='/agent-sales' element={<AgentSales />} />
                </Route>
                <Route path='/teds' element={<PrivateRoute roles={['AdminUser']} />}>
                  <Route path='/teds' element={<TEDSHome />} />
                </Route>
                <Route path='/aml-search' element={<PrivateRoute roles={['AdminUser']} />}>
                  <Route path='/aml-search' element={<AMLSearch />} />
                </Route>
                <Route path='/teds/daily-update' element={<PrivateRoute roles={['AdminUser']} />}>
                  <Route path='/teds/daily-update' element={<TEDSDailyUpdate />} />
                </Route>
                <Route
                  path='/teds/wallet'
                  element={
                    <PrivateRoute
                      roles={['AdminUser', 'AgentUser']}
                      allowedAgentTypes={['basicAgent', 'countryManager', 'salesManager', 'campaignManager', 'influencer']}
                    />
                  }>
                  <Route path='/teds/wallet' element={<TEDSWallet />} />
                </Route>
                <Route
                  path='/teds/transactions'
                  element={
                    <PrivateRoute
                      roles={['AdminUser']}
                      allowedAgentTypes={['basicAgent', 'countryManager', 'salesManager', 'campaignManager', 'influencer']}
                    />
                  }>
                  <Route path='/teds/transactions' element={<TEDSTransactions />} />
                </Route>
                <Route path='/teds/daily-wallet-activity' element={<PrivateRoute roles={['AdminUser']} />}>
                  <Route path='/teds/daily-wallet-activity' element={<DailyWalletActivity />} />
                </Route>
                <Route
                  path='/settings'
                  element={
                    <PrivateRoute
                      roles={['AgentUser']}
                      allowedAgentTypes={['basicAgent', 'countryManager', 'salesManager', 'campaignManager', 'influencer']}
                    />
                  }>
                  <Route path='/settings' element={<AgentSettings />} />
                </Route>
                <Route path='/my-referrals' element={<PrivateRoute roles={['AgentUser', 'AdminUser']} allowedAgentTypes={['basicAgent']} />}>
                  <Route path='/my-referrals' element={<MyReferrals />} />
                </Route>
                <Route path='/agent-info/:agentId/:agentReferralCode' element={<PrivateRoute roles={['AdminUser']} />}>
                  <Route path='/agent-info/:agentId/:agentReferralCode' element={<AgentInfo />} />
                </Route>
                <Route
                  path='/new-agents'
                  element={
                    <PrivateRoute roles={['AdminUser', 'AgentUser']} allowedAgentTypes={['salesManager', 'countryManager', 'campaignManager']} />
                  }>
                  <Route path='/new-agents' element={<NewAgents />} />
                </Route>
                <Route
                  path='/agents'
                  element={
                    <PrivateRoute roles={['AdminUser', 'AgentUser']} allowedAgentTypes={['salesManager', 'countryManager', 'campaignManager']} />
                  }>
                  <Route path='/agents' element={<Agents />} />
                </Route>
                <Route path='/teds/sold-teds-transactions' element={<PrivateRoute roles={['AdminUser']} />}>
                  <Route path='/teds/sold-teds-transactions' element={<SoldTEDSTransactions />} />
                </Route>
                <Route
                  path=''
                  element={
                    <PrivateRoute
                      roles={['AdminUser', 'AgentUser']}
                      allowedAgentTypes={['basicAgent', 'salesManager', 'countryManager', 'campaignManager', 'influencer']}
                    />
                  }>
                  <Route path='/agent-overview/:agentId?' element={<AgentOverview />} />
                </Route>
                <Route
                  path='/sales-manager-registration'
                  element={<PrivateRoute roles={['AgentUser', 'AdminUser']} allowedAgentTypes={['countryManager']} />}>
                  <Route path='/sales-manager-registration' element={<SalesManagerRegistration />} />
                </Route>
                <Route
                  path='/campaign-manager-registration'
                  element={<PrivateRoute roles={['AdminUser', 'AgentUser']} allowedAgentTypes={['countryManager']} />}>
                  <Route path='/campaign-manager-registration' element={<CampaignManagerRegistration />} />
                </Route>
                <Route
                  path='/influencer-registration'
                  element={<PrivateRoute roles={['AdminUser', 'AgentUser']} allowedAgentTypes={['countryManager', 'salesManager']} />}>
                  <Route path='/influencer-registration' element={<InfluencerRegistration />} />
                </Route>
                <Route
                  path='/commissions'
                  element={
                    <PrivateRoute
                      roles={['AgentUser', 'AdminUser']}
                      allowedAgentTypes={['basicAgent', 'countryManager', 'salesManager', 'campaignManager', 'influencer']}
                    />
                  }>
                  <Route path='/commissions' element={<CommissionEarnings />} />
                </Route>
                <Route
                  path='/influencers'
                  element={<PrivateRoute roles={['AdminUser', 'AgentUser']} allowedAgentTypes={['salesManager', 'countryManager']} />}>
                  <Route path='/influencers' element={<Influencers />} />
                </Route>
              </Routes>
            </PageLayout>
          </HelmetProvider>
          {!isLoginPage && <BottomNav />}
        </main>
      </div>
    </div>
  )
}

export default App
