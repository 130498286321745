import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import toast from 'react-hot-toast'
import BackButton from '../components/BackButton'
import Spinner from '../components/Spinner'
import { getTEDSTransactions, reset } from '../features/teds/tedsSlice'
import Pagination from '../components/Pagination'
import { useDebounce } from '../components/useDebounce'
import { motion } from 'framer-motion'
import { FiSearch, FiFilter, FiClock, FiUser, FiDollarSign, FiActivity } from 'react-icons/fi'
import { TableSkeleton } from '../components/Skeleton'

const TEDSTransactions = () => {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const [displayCommissions, setDisplayCommissions] = useState(false)

  const {
    tedsTransactions: { transactions, totalPages },
    isError,
    isSuccess,
    isLoading,
    message,
  } = useSelector((state) => state.teds)

  const { user } = useSelector((state) => state.auth)

  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3,
      },
    },
  }

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    show: { y: 0, opacity: 1 },
  }

  useEffect(() => {
    if (isError) {
      toast.error(message)
      dispatch(reset())
    }
    if (isSuccess) {
      dispatch(reset())
    }
  }, [isSuccess, dispatch, isError, message])

  useEffect(() => {
    const filterData = {
      page: currentPage,
      limit: 15,
      searchTerm: debouncedSearchTerm,
      displayCommissions,
    }
    dispatch(getTEDSTransactions(filterData))

    return () => {
      dispatch(reset())
    }
  }, [dispatch, currentPage, debouncedSearchTerm, displayCommissions])

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
    setCurrentPage(1)
  }

  const formatDate = (isoString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }
    const date = new Date(isoString)
    return date.toLocaleDateString('en-GB', options)
  }

  function formatTransactionType(transactionType) {
    const words = transactionType.split('-')
    return words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
  }

  return (
    <div className='min-h-screen w-full bg-gradient-to-b from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800 pb-20'>
      {/* Hero Section */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className='w-full relative overflow-hidden bg-gradient-to-r from-purple-600 to-indigo-900 py-16 mb-8'>
        <div className='relative z-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
          <div className='text-center'>
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className='text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl'>
              TEDS Transactions
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              className='mt-6 text-xl text-gray-100'>
              View and manage your TEDS transaction history
            </motion.p>
          </div>
        </div>

        {/* Decorative blob */}
        <div className='absolute top-0 right-0 -translate-y-12 translate-x-12 transform opacity-20'>
          <svg width='400' height='400' viewBox='0 0 184 184' xmlns='http://www.w3.org/2000/svg'>
            <path
              fill='white'
              d='M92 0C92 0 92 0 92 0C142.8 0 184 41.2 184 92C184 142.8 142.8 184 92 184C41.2 184 0 142.8 0 92C0 41.2 41.2 0 92 0Z'
            />
          </svg>
        </div>
      </motion.div>

      <div className='mx-auto max-w-8xl px-4 sm:px-6 lg:px-8'>
        <BackButton className='text-white hover:text-gray-300 mb-6' />

        {/* Search and Filter Controls */}
        <motion.div
          variants={containerVariants}
          initial='hidden'
          animate='show'
          className='bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-6 mb-8 border border-gray-700'>
          <div className='flex flex-col md:flex-row items-center justify-between gap-4'>
            <div className='relative flex-1 w-full'>
              <FiSearch className='absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400' />
              <input
                type='text'
                placeholder='Search for a transaction by Business Name'
                value={searchTerm}
                onChange={handleSearchChange}
                className='w-full pl-12 pr-4 py-3 bg-[#1a1f2b] border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:border-purple-500 focus:ring-2 focus:ring-purple-500 focus:ring-opacity-20 transition-colors'
              />
            </div>
            <div className='flex items-center gap-2'>
              <FiFilter className='text-gray-400' />
              <label className='flex items-center gap-3 text-gray-200'>
                <input
                  type='checkbox'
                  checked={displayCommissions}
                  onChange={(e) => setDisplayCommissions(e.target.checked)}
                  className='checkbox checkbox-primary'
                />
                <span>Display Our Fee</span>
              </label>
            </div>
          </div>
        </motion.div>

        {/* Transactions Table */}
        <motion.div
          variants={containerVariants}
          initial='hidden'
          animate='show'
          className='bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-6 border border-gray-700'>
          <div className='overflow-x-auto'>
            <table className='w-full'>
              <thead className='bg-[#1a1f2b]'>
                <tr>
                  <th className='px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>
                    <div className='flex items-center gap-2'>
                      <FiClock className='text-purple-500' />
                      Date
                    </div>
                  </th>
                  <th className='px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>
                    <div className='flex items-center gap-2'>
                      <FiUser className='text-purple-500' />
                      From
                    </div>
                  </th>
                  <th className='px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>
                    <div className='flex items-center gap-2'>
                      <FiUser className='text-purple-500' />
                      To
                    </div>
                  </th>
                  <th className='px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>
                    <div className='flex items-center gap-2'>
                      <FiDollarSign className='text-purple-500' />
                      Cost
                    </div>
                  </th>
                  <th className='px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>
                    <div className='flex items-center gap-2'>
                      <FiDollarSign className='text-purple-500' />
                      Our Fee
                    </div>
                  </th>
                  <th className='px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>
                    <div className='flex items-center gap-2'>
                      <FiActivity className='text-purple-500' />
                      TEDS Amount
                    </div>
                  </th>
                  <th className='px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Status</th>
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-700'>
                {isLoading ? (
                  <tr>
                    <td colSpan='7' className='px-6 py-4'>
                      <div className='w-full px-2'>
                        <TableSkeleton columns={7} rows={10} />
                      </div>
                    </td>
                  </tr>
                ) : !Array.isArray(transactions) || transactions.length === 0 ? (
                  <tr>
                    <td colSpan='7' className='px-6 py-12 text-center text-gray-400 text-lg'>
                      No TEDS Transactions found.
                    </td>
                  </tr>
                ) : (
                  transactions.map((transaction) => (
                    <tr key={transaction.id} className='bg-[#252b38] hover:bg-[#2a3040] transition-colors'>
                      <td className='px-6 py-4 text-sm text-gray-200'>{formatDate(transaction.createdAt)}</td>
                      <td className='px-6 py-4 text-sm text-gray-200'>{transaction.fromUser.userName}</td>
                      <td className='px-6 py-4 text-sm text-gray-200'>{transaction.toUser.userName}</td>
                      <td className='px-6 py-4 text-sm'>
                        {isNaN(transaction.cost) || transaction.cost == null ? (
                          <span className='text-gray-200'>N/A</span>
                        ) : (
                          <span className='text-green-500'>{`£${transaction.cost.toFixed(2)}`}</span>
                        )}
                      </td>
                      <td className='px-6 py-4 text-sm text-green-500'>{transaction.transactionFee}</td>
                      <td className='px-6 py-4 text-sm text-gray-200'>
                        {transaction.transactionType === 'stamp-exchange' || transaction.transactionType === 'teds-sale' ? (
                          <span className='text-red-500'>-{transaction.tedsAmount + transaction.transactionFee}</span>
                        ) : transaction.transactionType === 'teds-purchase' ? (
                          <span className='text-green-500'>+{transaction.tedsAmount}</span>
                        ) : (
                          transaction.tedsAmount
                        )}
                      </td>
                      <td className='px-6 py-4 text-sm'>
                        <span className='px-2 py-1 text-xs font-semibold rounded-full bg-purple-500/10 text-purple-500'>
                          {formatTransactionType(transaction.transactionType)}
                        </span>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </motion.div>

        {/* Pagination */}
        {totalPages > 1 && (
          <motion.div variants={containerVariants} initial='hidden' animate='show' className='flex justify-center mt-8'>
            <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
          </motion.div>
        )}
      </div>
    </div>
  )
}

export default TEDSTransactions
