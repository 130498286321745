import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { getManagerAgents } from '../features/agents/agentsSlice'
import { Helmet } from 'react-helmet-async'
import Pagination from '../components/Pagination'
import { motion, AnimatePresence } from 'framer-motion'
import { FaEye, FaEdit, FaSearch, FaTwitter, FaInstagram, FaYoutube, FaTiktok, FaFacebook } from 'react-icons/fa'
import { FiUsers, FiUserPlus, FiFilter, FiRefreshCw, FiArrowRight } from 'react-icons/fi'

function Influencers() {
  const dispatch = useDispatch()
  const { agents, isLoading, isError, message, currentPage, totalPages } = useSelector((state) => state.agents)
  const [searchTerm, setSearchTerm] = useState('')
  const [currentSearchTerm, setCurrentSearchTerm] = useState('')
  const [isSearchFocused, setIsSearchFocused] = useState(false)

  useEffect(() => {
    // Only get influencers (filter by agent type)
    dispatch(getManagerAgents({ isActive: true, page: 1, limit: 10, agentType: 'influencer' }))
  }, [dispatch])

  const handleSearch = (e) => {
    e.preventDefault()
    setCurrentSearchTerm(searchTerm)
    dispatch(getManagerAgents({ isActive: true, page: 1, limit: 10, searchTerm, agentType: 'influencer' }))
  }

  const handlePageChange = (page) => {
    dispatch(getManagerAgents({ isActive: true, page, limit: 10, searchTerm: currentSearchTerm, agentType: 'influencer' }))
  }

  // Social media platform icons mapper
  const getPlatformIcon = (platform) => {
    if (!platform) return <FaTwitter className='text-gray-400' />

    const platformLower = platform.toLowerCase()
    if (platformLower.includes('instagram')) return <FaInstagram className='text-pink-500' />
    if (platformLower.includes('youtube')) return <FaYoutube className='text-red-500' />
    if (platformLower.includes('tiktok')) return <FaTiktok className='text-black dark:text-white' />
    if (platformLower.includes('facebook')) return <FaFacebook className='text-blue-600' />
    if (platformLower.includes('twitter')) return <FaTwitter className='text-blue-400' />

    return <FaTwitter className='text-gray-400' />
  }

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  }

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: { y: 0, opacity: 1 },
  }

  // Correctly access the agents array from the agents object
  const agentsList = agents?.agents || []
  const hasAgents = Array.isArray(agentsList) && agentsList.length > 0

  return (
    <div className='min-h-screen w-full bg-gradient-to-b from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800'>
      <Helmet>
        <title>Manage Influencers | Loyalty Club PLC</title>
      </Helmet>

      {/* Hero Section - Full width */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className='w-full relative overflow-hidden bg-gradient-to-r from-purple-600 to-indigo-700 py-12'>
        <div className='relative z-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
          <div className='md:flex md:items-center md:justify-between'>
            <div>
              <motion.h1
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
                className='text-3xl font-bold tracking-tight text-white sm:text-4xl'>
                Influencer Management
              </motion.h1>
              <motion.p
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4 }}
                className='mt-2 text-lg text-purple-100'>
                View and manage your social media influencer partners
              </motion.p>
            </div>

            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: 0.5 }}
              className='mt-4 flex md:ml-4 md:mt-0'>
              <Link
                to='/influencer-registration'
                className='flex items-center px-4 py-2 rounded-lg bg-white text-purple-700 font-medium shadow-md hover:bg-purple-50 transition-colors'>
                <FiUserPlus className='mr-2' />
                Register New Influencer
              </Link>
            </motion.div>
          </div>
        </div>

        {/* Decorative blob */}
        <div className='absolute top-0 right-0 -translate-y-12 translate-x-12 transform opacity-20'>
          <svg width='400' height='400' viewBox='0 0 184 184' xmlns='http://www.w3.org/2000/svg'>
            <path
              fill='white'
              d='M92 0C92 0 92 0 92 0C142.8 0 184 41.2 184 92C184 142.8 142.8 184 92 184C41.2 184 0 142.8 0 92C0 41.2 41.2 0 92 0Z'
            />
          </svg>
        </div>
      </motion.div>

      {/* Content Container */}
      <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 -mt-8 pb-12'>
        <motion.div
          variants={containerVariants}
          initial='hidden'
          animate='visible'
          className='bg-white dark:bg-gray-800 rounded-xl shadow-xl p-6 overflow-hidden relative'>
          {/* Search and filters section */}
          <motion.div variants={itemVariants} className='mb-6'>
            <form onSubmit={handleSearch} className='flex flex-col md:flex-row gap-4'>
              <div className='relative flex-grow'>
                <div
                  className={`absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none 
                  transition-all duration-200 ${isSearchFocused ? 'text-primary' : 'text-gray-400'}`}>
                  <FaSearch />
                </div>
                <input
                  type='text'
                  className='pl-10 w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent transition-all'
                  placeholder='Search by name, email, location or social handles...'
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onFocus={() => setIsSearchFocused(true)}
                  onBlur={() => setIsSearchFocused(false)}
                />
              </div>
              <div className='flex space-x-2'>
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  type='submit'
                  className='px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary-dark transition-colors'>
                  Search
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  type='button'
                  onClick={() => {
                    setSearchTerm('')
                    setCurrentSearchTerm('')
                    dispatch(getManagerAgents({ isActive: true, page: 1, limit: 10, agentType: 'influencer' }))
                  }}
                  className='px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-600 dark:text-gray-200 rounded-lg hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors flex items-center'>
                  <FiRefreshCw className='mr-2' /> Reset
                </motion.button>
              </div>
            </form>
          </motion.div>

          {/* Loading state */}
          {isLoading && (
            <div className='flex justify-center items-center p-8'>
              <motion.div
                animate={{ rotate: 360 }}
                transition={{ duration: 1, repeat: Infinity, ease: 'linear' }}
                className='w-12 h-12 border-4 border-primary border-t-transparent rounded-full'
              />
            </div>
          )}

          {/* Error state */}
          {isError && (
            <motion.div
              variants={itemVariants}
              className='alert bg-red-500/10 border border-red-500/20 text-red-700 dark:text-red-300 p-4 rounded-lg'>
              {message}
            </motion.div>
          )}

          {/* Data display */}
          {!isLoading && !isError && (
            <AnimatePresence>
              {hasAgents ? (
                <motion.div variants={itemVariants} className='overflow-x-auto'>
                  <table className='w-full border-collapse'>
                    <thead>
                      <tr className='bg-gray-50 dark:bg-gray-700/50 text-left'>
                        <th className='px-4 py-3 text-gray-500 dark:text-gray-300 font-medium rounded-tl-lg'>Name</th>
                        <th className='px-4 py-3 text-gray-500 dark:text-gray-300 font-medium'>Email</th>
                        <th className='px-4 py-3 text-gray-500 dark:text-gray-300 font-medium'>Location</th>
                        <th className='px-4 py-3 text-gray-500 dark:text-gray-300 font-medium'>Platform</th>
                        <th className='px-4 py-3 text-gray-500 dark:text-gray-300 font-medium'>Followers</th>
                        <th className='px-4 py-3 text-gray-500 dark:text-gray-300 font-medium rounded-tr-lg'>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {agentsList.map((agent, index) => (
                        <motion.tr
                          key={agent.id}
                          variants={itemVariants}
                          className={`
                            border-b border-gray-100 dark:border-gray-700 
                            ${index % 2 === 0 ? 'bg-white dark:bg-gray-800' : 'bg-gray-50 dark:bg-gray-800/50'}
                            hover:bg-gray-100 dark:hover:bg-gray-700/50 transition-colors
                          `}>
                          <td className='px-4 py-4 font-medium text-gray-900 dark:text-white'>{agent.name}</td>
                          <td className='px-4 py-4 text-gray-600 dark:text-gray-300'>{agent.email}</td>
                          <td className='px-4 py-4 text-gray-600 dark:text-gray-300'>
                            {agent.city && agent.country ? (
                              <>
                                {agent.city}, {agent.country}
                              </>
                            ) : (
                              <span className='text-gray-400 dark:text-gray-500'>Not specified</span>
                            )}
                          </td>
                          <td className='px-4 py-4'>
                            <div className='flex items-center'>
                              <div className='mr-3'>{getPlatformIcon(agent.businessDetails?.socialMedia?.platform)}</div>
                              <div>
                                <div className='text-gray-900 dark:text-white'>{agent.businessDetails?.socialMedia?.platform || 'Not specified'}</div>
                                <div className='text-sm text-gray-500 dark:text-gray-400'>
                                  {agent.businessDetails?.socialMedia?.handle || 'No handle'}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className='px-4 py-4'>
                            {agent.businessDetails?.socialMedia?.followerCount ? (
                              <div className='flex items-center'>
                                <FiUsers className='mr-2 text-purple-500' />
                                <span className='font-medium text-gray-900 dark:text-white'>
                                  {new Intl.NumberFormat().format(agent.businessDetails.socialMedia.followerCount)}
                                </span>
                              </div>
                            ) : (
                              <span className='text-gray-400 dark:text-gray-500'>Not specified</span>
                            )}
                          </td>
                          <td className='px-4 py-4'>
                            <div className='flex space-x-2'>
                              <motion.button
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                onClick={() => (window.location.href = `/agent-overview/${agent.id}`)}
                                className='p-2 bg-blue-50 dark:bg-blue-900/20 text-blue-600 dark:text-blue-400 rounded hover:bg-blue-100 dark:hover:bg-blue-900/40 transition-colors'>
                                <FaEye />
                              </motion.button>
                              <motion.button
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                onClick={() => (window.location.href = `/influencer/edit/${agent.id}`)}
                                className='p-2 bg-amber-50 dark:bg-amber-900/20 text-amber-600 dark:text-amber-400 rounded hover:bg-amber-100 dark:hover:bg-amber-900/40 transition-colors'>
                                <FaEdit />
                              </motion.button>
                            </div>
                          </td>
                        </motion.tr>
                      ))}
                    </tbody>
                  </table>
                </motion.div>
              ) : (
                <motion.div variants={itemVariants} className='text-center py-12 bg-gray-50 dark:bg-gray-800/50 rounded-lg'>
                  <FiUsers className='mx-auto h-12 w-12 text-gray-400 dark:text-gray-500' />
                  <h3 className='mt-2 text-lg font-medium text-gray-900 dark:text-white'>No influencers found</h3>
                  <p className='mt-1 text-gray-500 dark:text-gray-400'>Try adjusting your search or add a new influencer.</p>
                  <div className='mt-6'>
                    <Link
                      to='/influencer-registration'
                      className='inline-flex items-center px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary-dark transition-colors'>
                      <FiUserPlus className='mr-2' />
                      Register New Influencer
                    </Link>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          )}

          {/* Pagination */}
          {!isLoading && !isError && hasAgents && (agents?.totalPages > 1 || totalPages > 1) && (
            <motion.div variants={itemVariants} className='mt-6 flex justify-center'>
              <Pagination
                currentPage={agents?.currentPage || currentPage}
                totalPages={agents?.totalPages || totalPages}
                onPageChange={handlePageChange}
              />
            </motion.div>
          )}
        </motion.div>
      </div>
    </div>
  )
}

export default Influencers
