import React, { useEffect } from 'react'
import { motion } from 'framer-motion'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { FiSearch } from 'react-icons/fi'
import { resetAmlSearchState } from '../features/auth/authSlice'
import AMLEmailSearch from '../components/AMLEmailSearch'
import BackButton from '../components/BackButton'

function AMLSearch() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)

  // Redirect non-admin users
  useEffect(() => {
    if (user && user.role !== 'AdminUser') {
      navigate('/')
    }
  }, [user, navigate])
  
  // Clear AML search state when component unmounts
  useEffect(() => {
    return () => {
      dispatch(resetAmlSearchState())
    }
  }, [dispatch])
  


  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3,
      },
    },
  }

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    show: { y: 0, opacity: 1 },
  }

  return (
    <div className="min-h-screen w-full bg-gradient-to-b from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800">
      {/* Hero Section */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="relative overflow-hidden bg-gradient-to-r from-primary to-primary-dark py-16 mb-4"
      >
        <div className="relative z-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl"
            >
              Deleted Account Recovery
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              className="mt-6 text-xl text-gray-100"
            >
              Locate deleted user IDs by email to view their TEDS transactions
            </motion.p>
          </div>
        </div>

        {/* Decorative blob */}
        <div className="absolute top-0 right-0 -translate-y-12 translate-x-12 transform opacity-20">
          <svg width="400" height="400" viewBox="0 0 184 184" xmlns="http://www.w3.org/2000/svg">
            <path
              fill="white"
              d="M92 0C92 0 92 0 92 0C142.8 0 184 41.2 184 92C184 142.8 142.8 184 92 184C41.2 184 0 142.8 0 92C0 41.2 41.2 0 92 0Z"
            />
          </svg>
        </div>

        {/* No shield icon - removed */}
      </motion.div>

      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <BackButton />
        
        {/* Main Content */}
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="show"
          className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6 mb-8"
        >
          <div className="max-w-4xl mx-auto">
            <motion.div variants={itemVariants} className="mb-8">
              <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-white flex items-center">
                <FiSearch className="mr-2 text-primary" /> Deleted Account Lookup Tool
              </h2>
              <p className="text-gray-600 dark:text-gray-300">
                This secure tool helps admins locate customer IDs for deleted accounts using their email address. 
                Once retrieved, these IDs can be used to view historical TEDS transactions for compliance and support purposes.
                All searches are logged for audit compliance.
              </p>
            </motion.div>
            
            <motion.div variants={itemVariants}>
              <AMLEmailSearch />
            </motion.div>
          </div>
        </motion.div>
        
        {/* Additional Information Card */}
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="show"
          className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6 mb-8 border-l-4 border-amber-500"
        >
          <motion.div variants={itemVariants}>
            <h3 className="text-xl font-semibold mb-3 text-gray-900 dark:text-white">Important Information</h3>
            <p className="text-gray-600 dark:text-gray-300">
              This recovery tool maintains customer data access according to data retention policies. Once you retrieve a customer ID, 
              you can use it to access their historical TEDS transaction records even after account deletion. 
              This ensures continuity for financial audits and customer support inquiries while maintaining privacy compliance.
            </p>
          </motion.div>
        </motion.div>
      </div>
    </div>
  )
}

export default AMLSearch
