const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const pageNeighbours = 2 // Number of page numbers to show around the current page
  const totalNumbers = pageNeighbours * 2 + 3 // Total page numbers to display
  const totalBlocks = totalNumbers + 2 // Total blocks including next and previous

  const goPrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1)
    }
  }

  const goNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1)
    }
  }

  const goToPage = (page) => {
    const pageNumber = Math.max(0, Math.min(page, totalPages))
    onPageChange(pageNumber)
  }

  const fetchPageNumbers = () => {
    const startPage = Math.max(2, currentPage - pageNeighbours)
    const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours)

    let pages = Array.from({ length: endPage - startPage + 1 }, (_, idx) => startPage + idx)

    if (startPage > 2) {
      pages = ['...', ...pages]
    }

    if (endPage < totalPages - 1) {
      pages = [...pages, '...']
    }

    pages = [1, ...pages, totalPages]

    return pages.map((page, index) => (
      <li
        className={`page__numbers cursor-pointer ${
          currentPage === page
            ? 'active bg-primary text-white'
            : 'text-gray-300 hover:bg-gray-700 dark:hover:bg-gray-600 hover:text-white transition-colors'
        } mx-1 flex justify-center items-center rounded-full w-10 h-10`}
        key={index}
        onClick={() => (page === '...' ? null : goToPage(page))}>
        {page}
      </li>
    ))
  }

  if (totalPages <= 1) {
    return null
  }

  const paginationNumbers = fetchPageNumbers()

  return (
    <div id='app' className='container flex justify-center items-center'>
      <ul className='page flex justify-center items-center h-16 mx-12 rounded-md bg-gray-800 dark:bg-gray-700 shadow-lg'>
        {currentPage > 1 && (
          <li
            className='page__btn mx-1 cursor-pointer flex justify-center items-center rounded-full w-10 h-10 text-gray-300 hover:bg-gray-700 dark:hover:bg-gray-600 hover:text-white transition-colors'
            onClick={goPrevious}>
            &lt;
          </li>
        )}
        {paginationNumbers}
        {currentPage < totalPages && (
          <li
            className='page__btn mx-1 cursor-pointer flex justify-center items-center rounded-full w-10 h-10 text-gray-300 hover:bg-gray-700 dark:hover:bg-gray-600 hover:text-white transition-colors'
            onClick={goNext}>
            &gt;
          </li>
        )}
      </ul>
    </div>
  )
}

export default Pagination
