import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import BackButton from '../components/BackButton'
import toast from 'react-hot-toast'
import { getReferredPartners, reset, markAsContacted } from '../features/partners/partnersSlice'
import Pagination from '../components/Pagination'
import { useDebounce } from '../components/useDebounce'
import { motion } from 'framer-motion'
import { FiSearch, FiUserPlus, FiCheck, FiX, FiClock } from 'react-icons/fi'

const ReferredPartners = () => {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const [isSearchFocused, setIsSearchFocused] = useState(false)

  const { referredPartners, isError, isSuccess, isLoading, message } = useSelector((state) => state.partners)

  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  useEffect(() => {
    if (isError) {
      toast.error(message)
      dispatch(reset())
    }
    if (isSuccess) {
      dispatch(reset())
    }
  }, [isSuccess, dispatch, isError, message])

  useEffect(() => {
    const filterData = {
      page: currentPage,
      limit: 20,
      searchTerm: debouncedSearchTerm,
    }
    dispatch(getReferredPartners(filterData))
  }, [dispatch, currentPage, debouncedSearchTerm])

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
    setCurrentPage(1)
  }

  const handleMarkAsContacted = (partnerId) => {
    dispatch(markAsContacted(partnerId))
  }

  const getStatusConfig = (status) => {
    switch (status.toLowerCase()) {
      case 'pending':
        return {
          icon: FiClock,
          bgColor: 'bg-yellow-100 text-yellow-800 dark:bg-yellow-800 dark:text-yellow-100',
        }
      case 'contacted':
        return {
          icon: FiUserPlus,
          bgColor: 'bg-blue-100 text-blue-800 dark:bg-blue-800 dark:text-blue-100',
        }
      case 'joined':
        return {
          icon: FiCheck,
          bgColor: 'bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100',
        }
      case 'declined':
        return {
          icon: FiX,
          bgColor: 'bg-red-100 text-red-800 dark:bg-red-800 dark:text-red-100',
        }
      default:
        return {
          icon: FiClock,
          bgColor: 'bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-gray-100',
        }
    }
  }

  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3,
      },
    },
  }

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    show: { y: 0, opacity: 1 },
  }

  return (
    <div className='min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800'>
      {/* Hero Section */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className='relative overflow-hidden bg-gradient-to-r from-primary to-primary-dark py-16 mb-4'>
        <div className='relative z-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
          <div className='text-center'>
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className='text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl'>
              Referred Partners
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              className='mt-6 text-xl text-gray-100'>
              Manage and track partner referrals
            </motion.p>
          </div>
        </div>

        {/* Decorative blob */}
        <div className='absolute top-0 right-0 -translate-y-12 translate-x-12 transform opacity-20'>
          <svg width='400' height='400' viewBox='0 0 184 184' xmlns='http://www.w3.org/2000/svg'>
            <path
              fill='white'
              d='M92 0C92 0 92 0 92 0C142.8 0 184 41.2 184 92C184 142.8 142.8 184 92 184C41.2 184 0 142.8 0 92C0 41.2 41.2 0 92 0Z'
            />
          </svg>
        </div>
      </motion.div>

      <div className='mx-auto max-w-8xl px-4 sm:px-6 lg:px-8'>
        <BackButton />

        {/* Search Controls */}
        <motion.div variants={containerVariants} initial='hidden' animate='show' className='bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6 mb-8'>
          <div className='flex flex-col md:flex-row items-center justify-between gap-4'>
            <div className='relative flex-grow'>
              <div
                className={`absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none 
                transition-all duration-200 ${isSearchFocused ? 'text-primary' : 'text-gray-400'}`}>
                <FiSearch />
              </div>
              <input
                type='text'
                placeholder='Search by name or email'
                value={searchTerm}
                onChange={handleSearchChange}
                onFocus={() => setIsSearchFocused(true)}
                onBlur={() => setIsSearchFocused(false)}
                className='pl-10 w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent transition-all'
              />
            </div>
          </div>
        </motion.div>

        {/* Partners Table */}
        <motion.div
          variants={containerVariants}
          initial='hidden'
          animate='show'
          className='bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6 mb-8 overflow-hidden'>
          <h2 className='text-2xl font-bold text-gray-900 dark:text-white mb-6'>Referred Partners List</h2>
          <div className='overflow-x-auto'>
            <table className='w-full table-auto bg-[#1a1f2b]'>
              <thead className='bg-[#1a1f2b]'>
                <tr>
                  <th className='px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Date</th>
                  <th className='px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Business Name</th>
                  <th className='px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Address</th>
                  <th className='px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Phone</th>
                  <th className='px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Email</th>
                  <th className='px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Referred By</th>
                  <th className='px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Referral Code</th>
                  <th className='px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Status</th>
                  <th className='px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Action</th>
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-700'>
                {!Array.isArray(referredPartners.partners) || referredPartners.partners.length === 0 ? (
                  <tr>
                    <td colSpan='9' className='text-center py-8 text-gray-400'>
                      No referred partners found.
                    </td>
                  </tr>
                ) : (
                  referredPartners.partners.map((partner) => {
                    const statusConfig = getStatusConfig(partner.status)
                    const StatusIcon = statusConfig.icon
                    return (
                      <tr key={partner._id} className='group bg-[#1a1f2b] hover:bg-[#252b38]'>
                        <td className='px-6 py-4 text-sm text-gray-300'>{new Date(partner.createdAt).toLocaleDateString()}</td>
                        <td className='px-6 py-4 text-sm text-gray-300'>{partner.businessName}</td>
                        <td className='px-6 py-4 text-sm text-gray-300'>{partner.businessAddress}</td>
                        <td className='px-6 py-4 text-sm text-gray-300'>{partner.businessPhone}</td>
                        <td className='px-6 py-4 text-sm text-gray-300'>{partner.businessEmail}</td>
                        <td className='px-6 py-4 text-sm text-gray-300'>{partner.referredBy?.name || 'No referrer'}</td>
                        <td className='px-6 py-4 font-medium text-white'>{partner.referralCode}</td>
                        <td className='px-6 py-4'>
                          <span className={`inline-flex items-center px-2 py-1 rounded-full text-xs font-medium ${statusConfig.bgColor}`}>
                            <StatusIcon className='w-3 h-3 mr-1' />
                            {partner.status}
                          </span>
                        </td>
                        <td className='px-6 py-4'>
                          {partner.status === 'pending' && (
                            <button
                              onClick={() => handleMarkAsContacted(partner._id)}
                              className='inline-flex items-center px-3 py-1 rounded-md bg-primary text-white hover:bg-primary-dark transition-colors'>
                              <FiCheck className='mr-2' /> Mark as Contacted
                            </button>
                          )}
                        </td>
                      </tr>
                    )
                  })
                )}
              </tbody>
            </table>
          </div>
        </motion.div>

        {/* Pagination */}
        {referredPartners.totalPages > 1 && (
          <motion.div variants={containerVariants} initial='hidden' animate='show' className='flex justify-center mb-20'>
            <Pagination totalPages={referredPartners.totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
          </motion.div>
        )}
      </div>
    </div>
  )
}

export default ReferredPartners
