import BackButton from '../components/BackButton'
import { useSelector, useDispatch } from 'react-redux'
import toast from 'react-hot-toast'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Spinner from '../components/Spinner'
import { getPartners, reset } from '../features/partners/partnersSlice'
import { transferAgent, reset as agentsReset } from '../features/agents/agentsSlice'
import Pagination from '../components/Pagination'
import { useDebounce } from '../components/useDebounce'
import { motion } from 'framer-motion'
import { FiSearch, FiFilter, FiEye, FiEyeOff } from 'react-icons/fi'
import { TableSkeleton } from '../components/Skeleton'
import ButtonLoader from '../components/ButtonLoader'

const Partners = () => {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const [agentID, setAgentID] = useState('')
  const [selectedPartner, setSelectedPartner] = useState(null)
  const [categoryVisibility, setCategoryVisibility] = useState({
    dateStatus: true,
    customerMetrics: true,
    financial: true,
    teds: true,
    other: true,
  })
  const [isSearchFocused, setIsSearchFocused] = useState(false)

  const { partners, isError, isSuccess, isLoading, message } = useSelector((state) => state.partners)
  const {
    isSuccess: agentsIsSuccess,
    isLoading: agentsIsLoading,
    message: agentsMessage,
    isError: agentsIsError,
  } = useSelector((state) => state.agents)

  const columnCategories = {
    dateStatus: ['businessName', 'dateJoined', 'isActive', 'status'],
    customerMetrics: [
      'totalCustomers',
      'totalCustomersIntroduced',
      'totalPartnersIntroduced',
      'stampsAdministered',
      'campaigns',
      'rewardsAdministered',
    ],
    financial: ['monthlyPaymentsMade', 'yearlyPaymentsMade', 'totalSpent', 'lastPaymentDate', 'plan'],
    teds: [
      'tedsBalance',
      'madTedsBalance',
      'transactionCount',
      'numberOfStampsSoldForTeds',
      'numberOfStampsBoughtForTeds',
      'numberOfRewardsBoughtForTeds',
    ],
    other: ['messagesSent', 'assignAgent', 'servers', 'referralType'],
  }

  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  const columnConfig = [
    { id: 'businessName', label: 'Business Name', isVisible: true },
    { id: 'dateJoined', label: 'Date Joined', isVisible: true },
    { id: 'isActive', label: 'isActive', isVisible: true },
    { id: 'totalCustomers', label: 'Total Customers', isVisible: true },
    { id: 'totalCustomersIntroduced', label: 'Customers Introduced', isVisible: true },
    { id: 'totalPartnersIntroduced', label: 'Partners Introduced', isVisible: true },
    { id: 'referralType', label: 'Point of Origin', isVisible: true },
    { id: 'stampsAdministered', label: 'Stamps Administered', isVisible: true },
    { id: 'campaigns', label: 'Campaigns', isVisible: true },
    { id: 'rewardsAdministered', label: 'Rewards Administered', isVisible: true },
    { id: 'servers', label: 'Servers', isVisible: true },
    { id: 'messagesSent', label: 'Messages Sent', isVisible: true },
    { id: 'monthlyPaymentsMade', label: 'Monthly Payments Made', isVisible: true },
    { id: 'yearlyPaymentsMade', label: 'Yearly Payments Made', isVisible: true },
    { id: 'totalSpent', label: 'Total Spent', isVisible: true },
    { id: 'lastPaymentDate', label: 'Last Payment Date', isVisible: true },
    { id: 'plan', label: 'Plan', isVisible: true },
    { id: 'tedsBalance', label: 'TEDS Balance', isVisible: true },
    { id: 'madTedsBalance', label: 'Mad TEDS Balance', isVisible: true },
    { id: 'transactionCount', label: 'Transaction Count', isVisible: true },
    { id: 'numberOfStampsSoldForTeds', label: 'Stamps Sold For TEDS', isVisible: true },
    { id: 'numberOfStampsBoughtForTeds', label: 'Stamps Bought For TEDS', isVisible: true },
    { id: 'numberOfRewardsBoughtForTeds', label: 'Rewards Bought For TEDS', isVisible: true },
    { id: 'assignAgent', label: 'Assign Agent', isVisible: true },
  ]

  const [columns, setColumns] = useState(columnConfig)

  const handleColumnVisibilityChange = (columnId) => {
    setColumns(columns.map((col) => (col.id === columnId ? { ...col, isVisible: !col.isVisible } : col)))
  }

  const handleCategoryVisibilityChange = (category) => {
    const newVisibility = !categoryVisibility[category]
    setCategoryVisibility({ ...categoryVisibility, [category]: newVisibility })
    setColumns(columns.map((col) => (columnCategories[category].includes(col.id) ? { ...col, isVisible: newVisibility } : col)))
  }

  useEffect(() => {
    if (isError || agentsIsError) {
      console.log(message)
      toast.error(message)
      dispatch(reset())
    }
    if (agentsIsSuccess && agentsMessage.success === true) {
      toast.success(agentsMessage.message)
      document.getElementById('assignAgentModal').checked = false
      dispatch(agentsReset())
      const filterData = {
        page: currentPage,
        limit: 15,
        searchTerm: debouncedSearchTerm,
      }
      dispatch(getPartners(filterData))
    } else if (isSuccess) {
      dispatch(reset())
    }
  }, [isSuccess, dispatch, isError, message, agentsIsSuccess, agentsMessage, agentsIsError, currentPage, debouncedSearchTerm])

  useEffect(() => {
    const filterData = {
      page: currentPage,
      limit: 15,
      searchTerm: debouncedSearchTerm,
    }
    dispatch(getPartners(filterData))
  }, [dispatch, currentPage, debouncedSearchTerm])

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
    setCurrentPage(1)
  }

  const assignAgentToPartner = (agentReferralCode, clientID) => {
    console.log(agentReferralCode, clientID)
    dispatch(transferAgent({ agentReferralCode, clientID }))
  }

  const tedsToPounds = (teds) => (teds / 10).toFixed(2)

  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3,
      },
    },
  }

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    show: { y: 0, opacity: 1 },
  }

  return (
    <div className='bg-gradient-to-b from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800'>
      {/* Hero Section */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className='relative overflow-hidden bg-gradient-to-r from-primary to-primary-dark py-16 mb-4'>
        <div className='relative z-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
          <div className='text-center'>
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className='text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl'>
              Partner Management
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              className='mt-6 text-xl text-gray-100'>
              Manage and monitor all partner activities
            </motion.p>
          </div>
        </div>

        {/* Decorative blob */}
        <div className='absolute top-0 right-0 -translate-y-12 translate-x-12 transform opacity-20'>
          <svg width='400' height='400' viewBox='0 0 184 184' xmlns='http://www.w3.org/2000/svg'>
            <path
              fill='white'
              d='M92 0C92 0 92 0 92 0C142.8 0 184 41.2 184 92C184 142.8 142.8 184 92 184C41.2 184 0 142.8 0 92C0 41.2 41.2 0 92 0Z'
            />
          </svg>
        </div>
      </motion.div>

      <div className='mx-auto max-w-8xl px-4 sm:px-6 lg:px-8'>
        <BackButton />

        {/* Column Visibility Controls */}
        <motion.div variants={containerVariants} initial='hidden' animate='show' className='bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6 mb-8'>
          <div className='flex items-center justify-between mb-4'>
            <h2 className='text-2xl font-bold text-gray-900 dark:text-white'>Column Visibility</h2>
            <button
              onClick={() => Object.keys(categoryVisibility).forEach((category) => handleCategoryVisibilityChange(category))}
              className='btn btn-ghost btn-sm'>
              <FiEye className='mr-2' /> Toggle All
            </button>
          </div>

          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'>
            {Object.entries(columnCategories).map(([category, columnIds]) => (
              <motion.div variants={itemVariants} key={category} className='bg-gray-50 dark:bg-gray-700 rounded-xl p-4'>
                <div className='flex items-center justify-between mb-4'>
                  <h3 className='font-semibold text-lg text-gray-900 dark:text-white capitalize'>{category.replace(/([A-Z])/g, ' $1').trim()}</h3>
                  <label className='cursor-pointer'>
                    <input
                      type='checkbox'
                      checked={categoryVisibility[category]}
                      onChange={() => handleCategoryVisibilityChange(category)}
                      className='checkbox checkbox-primary'
                    />
                  </label>
                </div>
                <div className='space-y-2'>
                  {columns
                    .filter((col) => columnIds.includes(col.id))
                    .map((col) => (
                      <label key={col.id} className='flex items-center justify-between p-2 hover:bg-gray-100 dark:hover:bg-gray-600 rounded'>
                        <span className='text-gray-700 dark:text-gray-300'>{col.label}</span>
                        <input
                          type='checkbox'
                          checked={col.isVisible}
                          onChange={() => handleColumnVisibilityChange(col.id)}
                          className='checkbox checkbox-sm checkbox-primary'
                        />
                      </label>
                    ))}
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>

        {/* Search and Controls */}
        <motion.div variants={containerVariants} initial='hidden' animate='show' className='bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6 mb-8'>
          <div className='flex flex-col md:flex-row items-center justify-between gap-4'>
            <div className='relative flex-grow'>
              <div
                className={`absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none 
                transition-all duration-200 ${isSearchFocused ? 'text-primary' : 'text-gray-400'}`}>
                <FiSearch />
              </div>
              <input
                type='text'
                placeholder='Search by name, email, partner code, or agent code'
                value={searchTerm}
                onChange={handleSearchChange}
                onFocus={() => setIsSearchFocused(true)}
                onBlur={() => setIsSearchFocused(false)}
                className='pl-10 w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent transition-all'
              />
            </div>
          </div>
        </motion.div>

        {/* Partners Table */}
        <motion.div
          variants={containerVariants}
          initial='hidden'
          animate='show'
          className='bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6 mb-8 overflow-hidden'>
          <h2 className='text-2xl font-bold text-gray-900 dark:text-white mb-6'>Partners List</h2>
          <div className='overflow-x-auto'>
            <table className='w-full table-auto'>
              <thead className='bg-gray-50 dark:bg-gray-700'>
                <tr>
                  {columns
                    .filter((col) => col.isVisible)
                    .map((col) => (
                      <th key={col.id} className='px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>
                        {col.label}
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200 dark:divide-gray-600'>
                {isLoading ? (
                  <tr>
                    <td colSpan={columns.filter((col) => col.isVisible).length} className='text-center py-8'>
                      <TableSkeleton columns={columns.filter((col) => col.isVisible).length} rows={10} />
                    </td>
                  </tr>
                ) : !Array.isArray(partners.partners) || partners.partners.length === 0 ? (
                  <tr>
                    <td colSpan={columns.filter((col) => col.isVisible).length} className='text-center py-8 text-gray-500 dark:text-gray-400'>
                      No partners found.
                    </td>
                  </tr>
                ) : (
                  partners.partners.map((partner, index) => (
                    <tr key={partner._id} className='hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors'>
                      {columns
                        .filter((col) => col.isVisible)
                        .map((col) => {
                          let content
                          switch (col.id) {
                            case 'businessName':
                              content = (
                                <div className='px-6 py-4'>
                                  <Link to={`/partner-overview/${partner._id}`} className='hover:text-primary transition-colors'>
                                    <p className='font-bold text-gray-900 dark:text-white'>{partner.businessName}</p>
                                    <p className='text-gray-500 dark:text-gray-400 text-xs'>{partner.email}</p>
                                  </Link>
                                </div>
                              )
                              break
                            case 'dateJoined':
                              content = (
                                <div className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400'>
                                  {new Date(partner.createdAt).toLocaleDateString()}
                                </div>
                              )
                              break
                            case 'isActive':
                              content = (
                                <div className='px-6 py-4 whitespace-nowrap'>
                                  <span
                                    className={`px-2 py-1 text-xs font-medium rounded-full ${
                                      partner.subscription?.isActive
                                        ? 'bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100'
                                        : 'bg-red-100 text-red-800 dark:bg-red-800 dark:text-red-100'
                                    }`}>
                                    {partner.subscription?.isActive ? 'Active' : 'Inactive'}
                                  </span>
                                </div>
                              )
                              break
                            case 'totalCustomers':
                              content = (
                                <div className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400'>
                                  {partner.keyMetrics?.totalCustomers || 0}
                                </div>
                              )
                              break
                            case 'totalCustomersIntroduced':
                              content = (
                                <div className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400'>
                                  {partner.keyMetrics?.totalCustomersIntroduced || 0}
                                </div>
                              )
                              break
                            case 'totalPartnersIntroduced':
                              content = (
                                <div className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400'>
                                  {partner.keyMetrics?.totalPartnersIntroduced || 0}
                                </div>
                              )
                              break
                            case 'stampsAdministered':
                              content = (
                                <div className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400'>
                                  {partner.keyMetrics?.numberOfStampsAdministered || 0}
                                </div>
                              )
                              break
                            case 'campaigns':
                              content = (
                                <div className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400'>
                                  {partner.keyMetrics?.numberOfCampaigns || 0}
                                </div>
                              )
                              break
                            case 'rewardsAdministered':
                              content = (
                                <div className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400'>
                                  {partner.keyMetrics?.numberOfRewardsAdministered || 0}
                                </div>
                              )
                              break
                            case 'servers':
                              content = (
                                <div className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400'>
                                  {partner.keyMetrics?.numberOfServers || 0}
                                </div>
                              )
                              break
                            case 'messagesSent':
                              content = (
                                <div className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400'>
                                  {partner.keyMetrics?.numberOfMessagesSent || 0}
                                </div>
                              )
                              break
                            case 'monthlyPaymentsMade':
                              content = (
                                <div className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400'>
                                  {partner.subscription?.monthlyPaymentsMade || 0}
                                </div>
                              )
                              break
                            case 'yearlyPaymentsMade':
                              content = (
                                <div className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400'>
                                  {partner.subscription?.yearlyPaymentsMade || 0}
                                </div>
                              )
                              break
                            case 'totalSpent':
                              content = (
                                <div className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400'>
                                  £{((partner.subscription?.totalSpent || 0) / 100).toFixed(2)}
                                </div>
                              )
                              break
                            case 'lastPaymentDate':
                              content = (
                                <div className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400'>
                                  {partner.subscription?.lastPaymentDate
                                    ? new Date(partner.subscription.lastPaymentDate).toLocaleDateString()
                                    : 'N/A'}
                                </div>
                              )
                              break
                            case 'plan':
                              content = (
                                <div className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400'>
                                  {partner.subscription?.plan || 'N/A'}
                                </div>
                              )
                              break
                            case 'tedsBalance':
                            case 'madTedsBalance':
                              const balance = col.id === 'tedsBalance' ? partner?.wallet?.tedsBalance : partner?.wallet?.madTedsBalance
                              content = (
                                <div className='px-6 py-4'>
                                  <div className='text-sm text-gray-900 dark:text-white font-medium'>{balance || 0}</div>
                                  <div className='text-xs text-gray-500 dark:text-gray-400'>£{tedsToPounds(balance || 0)}</div>
                                </div>
                              )
                              break
                            case 'transactionCount':
                              content = (
                                <div className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400'>
                                  {partner?.wallet?.transactionCount || 0}
                                </div>
                              )
                              break
                            case 'numberOfStampsSoldForTeds':
                              content = (
                                <div className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400'>
                                  {partner.keyMetrics?.numberOfStampsSoldForTeds || 0}
                                </div>
                              )
                              break
                            case 'numberOfStampsBoughtForTeds':
                              content = (
                                <div className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400'>
                                  {partner.keyMetrics?.numberOfStampsBoughtForTeds || 0}
                                </div>
                              )
                              break
                            case 'numberOfRewardsBoughtForTeds':
                              content = (
                                <div className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400'>
                                  {partner.keyMetrics?.numberOfRewardsBoughtForTeds || 0}
                                </div>
                              )
                              break
                            case 'referralType':
                              content = (
                                <div className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400'>
                                  {partner.introducedBy?.referralType || 'N/A'}
                                </div>
                              )
                              break
                            case 'assignAgent':
                              content = (
                                <div className='px-6 py-4'>
                                  <div className='flex flex-col gap-2'>
                                    {partner.referredByPartnerCode && (
                                      <div className='mb-2'>
                                        <p className='text-sm font-medium text-gray-700 dark:text-gray-300'>Referred by Partner</p>
                                        <p className='text-gray-600 dark:text-gray-400'>Code: {partner.referredByPartnerCode}</p>
                                      </div>
                                    )}
                                    {partner.agentReferralCode ? (
                                      <>
                                        <Link
                                          to={`/agent-overview/${partner.subscription?.referringAgentID}`}
                                          className='inline-flex items-center px-3 py-1 rounded-md bg-primary text-white hover:bg-primary-dark transition-colors'>
                                          {partner.subscription?.agentName || 'View Agent'}
                                        </Link>
                                        <p className='text-gray-600 dark:text-gray-400 text-sm'>Agent Code: {partner.agentReferralCode}</p>
                                      </>
                                    ) : (
                                      <label
                                        onClick={() => setSelectedPartner(partner)}
                                        htmlFor='assignAgentModal'
                                        className='inline-flex items-center px-3 py-1 rounded-md bg-primary text-white hover:bg-primary-dark transition-colors cursor-pointer'>
                                        Assign Agent
                                      </label>
                                    )}
                                  </div>
                                </div>
                              )
                              break
                            default:
                              content = <div className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400'>N/A</div>
                          }
                          return <td key={`${partner._id}-${col.id}`}>{content}</td>
                        })}
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </motion.div>

        {/* Pagination */}
        <motion.div variants={containerVariants} initial='hidden' animate='show' className='flex justify-center mb-6'>
          <Pagination totalPages={partners.totalPages} currentPage={currentPage} onPageChange={handlePageChange}>
            <button
              className='btn btn-primary'
              onClick={() => {
                setCurrentPage(currentPage - 1)
                handlePageChange(currentPage - 1)
              }}
              disabled={currentPage === 1}>
              Previous
            </button>
            <span className='mx-2'>
              Page {currentPage} of {partners.totalPages}
            </span>
            <button
              className='btn btn-primary'
              onClick={() => {
                setCurrentPage(currentPage + 1)
                handlePageChange(currentPage + 1)
              }}
              disabled={currentPage === partners.totalPages}>
              Next
            </button>
          </Pagination>
        </motion.div>

        {/* Modal */}
        <input type='checkbox' id='assignAgentModal' className='modal-toggle' />
        <div className='modal'>
          <div className='modal-box bg-white dark:bg-gray-800 rounded-2xl'>
            <h3 className='font-bold text-2xl mb-4 text-gray-900 dark:text-white'>Assign Agent</h3>
            <div className='py-4'>
              <input
                type='text'
                placeholder='Enter Agent Code To Assign To Partner'
                value={agentID}
                onChange={(e) => setAgentID(e.target.value)}
                className='input input-bordered w-full'
              />
            </div>
            <div className='modal-action'>
              <ButtonLoader
                className='btn btn-primary'
                onClick={() => assignAgentToPartner(agentID, selectedPartner._id)}
                isLoading={agentsIsLoading}>
                Submit
              </ButtonLoader>
              <label htmlFor='assignAgentModal' className='btn'>
                Close
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Partners
