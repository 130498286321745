import React from 'react'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getAgent, reset } from '../features/agents/agentsSlice'
import BackButton from '../components/BackButton'
import { motion } from 'framer-motion'
import { FiUser, FiMail, FiPhone, FiMapPin, FiBriefcase, FiHash } from 'react-icons/fi'

const AgentOverview = () => {
  const dispatch = useDispatch()

  const { agentId } = useParams()
  const { agent, isError, isSuccess, isLoading, message } = useSelector((state) => state.agents)
  const { user } = useSelector((state) => state.auth)

  const {
    name,
    email,
    address: { addressLine1: addressLine1, addressLine2: addressLine2, city: city, region: region, country: country, postcode: postcode } = {}, // Defaulting to an empty object in case `address` is not provided
    businessAddress: {
      addressLine1: businessAddressLine1,
      addressLine2: businessAddressLine2,
      city: businessCity,
      region: businessRegion,
      country: businessCountry,
      postcode: businessPostcode,
    } = {},
    phone: { landline: landline, mobile: mobile } = {},
  } = agent.businessDetails || {}

  useEffect(() => {
    dispatch(getAgent(agentId))
  }, [dispatch, agentId])

  useEffect(() => {
    if (isError) {
      toast.error(message)
    }
    if (isSuccess) {
      dispatch(reset())
    }
  }, [isSuccess, dispatch, isError, message])

  // Assuming agent has properties like dailySales, weeklySales, and monthlySales
  const { dailySales = [], weeklySales = [], monthlySales = [], yearlySales = [] } = agent || {}

  const generateLastNDays = (n) => {
    const dates = []
    const today = new Date()
    for (let i = 0; i < n; i++) {
      const date = new Date(today)
      date.setDate(today.getDate() - i)
      dates.push(date.toISOString().split('T')[0])
    }
    return dates
  }

  const generateLastNWeeks = (n) => {
    const weeks = []
    const today = new Date()
    for (let i = 0; i < n; i++) {
      const weekStart = new Date(today)
      weekStart.setDate(today.getDate() - 7 * i)
      const weekNumber = Math.ceil(((weekStart - new Date(weekStart.getFullYear(), 0, 1)) / 86400000 + 1) / 7)
      weeks.push(`${weekStart.getFullYear()}-Week${weekNumber}`)
    }
    return weeks
  }

  const generateAllYears = () => {
    const currentYear = new Date().getFullYear()
    // Assuming yearlySales is sorted in descending order by year
    const startYear = yearlySales.length > 0 && yearlySales[0].yearLabel ? parseInt(yearlySales[0].yearLabel) : currentYear

    const years = []
    for (let i = startYear; i <= currentYear; i++) {
      years.push(String(i))
    }
    return years
  }

  // Find the latest month from monthlySales
  const latestMonthInSales =
    monthlySales.length > 0 && monthlySales[0].monthLabel ? monthlySales[0].monthLabel : new Date().toISOString().split('T')[0].slice(0, 7)

  // Modify generateLastNMonths to accept a start date
  const generateLastNMonths = (n) => {
    const months = []
    let today = new Date()
    let year = today.getFullYear()
    let month = today.getMonth() + 1 // JavaScript months are 0-11, so add 1

    for (let i = 0; i < n; i++) {
      months.push(`${year}-${String(month).padStart(2, '0')}`)
      month--
      if (month === 0) {
        month = 12
        year--
      }
    }
    return months
  }
  const getDayName = (dateStr) => {
    const date = new Date(dateStr)
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    const formattedDate = date.toLocaleDateString('en-GB') // This will format as DD/MM/YYYY
    return `${days[date.getDay()]}, ${formattedDate}`
  }

  // Generate last 7 days
  const last7Days = generateLastNDays(7)

  // Generate last 5 weeks
  const last5Weeks = generateLastNWeeks(5)

  const last12Months = generateLastNMonths(12, latestMonthInSales)

  const allYears = generateAllYears()

  function getMonthNameFromLabel(monthLabel) {
    const [year, month] = monthLabel.split('-')
    const date = new Date(year, month - 1) // month - 1 because months in JavaScript are 0-indexed
    const monthName = date.toLocaleString('en-GB', { month: 'long' })
    return `${monthName} ${year}`
  }

  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3,
      },
    },
  }

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    show: { y: 0, opacity: 1 },
  }

  return (
    <div className='min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800'>
      {/* Hero Section */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className='relative overflow-hidden bg-gradient-to-r from-primary to-primary-dark py-16 mb-8'>
        <div className='relative z-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
          <div className='text-center'>
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className='text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl'>
              Agent Overview
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              className='mt-6 text-xl text-gray-100'>
              Detailed performance metrics and business information
            </motion.p>
          </div>
        </div>

        {/* Decorative blob */}
        <div className='absolute top-0 right-0 -translate-y-12 translate-x-12 transform opacity-20'>
          <svg width='400' height='400' viewBox='0 0 184 184' xmlns='http://www.w3.org/2000/svg'>
            <path
              fill='white'
              d='M92 0C92 0 92 0 92 0C142.8 0 184 41.2 184 92C184 142.8 142.8 184 92 184C41.2 184 0 142.8 0 92C0 41.2 41.2 0 92 0Z'
            />
          </svg>
        </div>
      </motion.div>

      <div className='mx-auto max-w-8xl px-4 sm:px-6 lg:px-8'>
        <BackButton />

        {/* Business Details Card */}
        <motion.div variants={containerVariants} initial='hidden' animate='show' className='my-6 rounded-2xl bg-white dark:bg-gray-800 p-8 shadow-lg'>
          <div className='flex justify-between items-start mb-6'>
            <h2 className='font-bold text-2xl text-gray-900 dark:text-white'>Business Details</h2>
            {user.name === agent.name && (
              <div className='bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-100 rounded-full px-4 py-2'>
                <span className='font-semibold'>
                  {user.agentType
                    ? user.agentType
                        .replace(/([A-Z])/g, ' $1')
                        .replace(/^./, (str) => str.toUpperCase())
                        .trim()
                    : 'N/A'}
                </span>
              </div>
            )}
          </div>

          <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
            <motion.div variants={itemVariants} className='flex items-start space-x-4'>
              <FiUser className='w-6 h-6 text-primary mt-1' />
              <div>
                <h3 className='font-semibold text-gray-700 dark:text-gray-300'>Name</h3>
                <p className='text-gray-600 dark:text-gray-400'>{agent.name || 'N/A'}</p>
              </div>
            </motion.div>

            <motion.div variants={itemVariants} className='flex items-start space-x-4'>
              <FiMail className='w-6 h-6 text-primary mt-1' />
              <div>
                <h3 className='font-semibold text-gray-700 dark:text-gray-300'>Email</h3>
                <p className='text-gray-600 dark:text-gray-400'>{agent.email || 'N/A'}</p>
              </div>
            </motion.div>

            <motion.div variants={itemVariants} className='flex items-start space-x-4'>
              <FiPhone className='w-6 h-6 text-primary mt-1' />
              <div>
                <h3 className='font-semibold text-gray-700 dark:text-gray-300'>Contact</h3>
                <p className='text-gray-600 dark:text-gray-400'>Landline: {landline || 'N/A'}</p>
                <p className='text-gray-600 dark:text-gray-400'>Mobile: {mobile || 'N/A'}</p>
              </div>
            </motion.div>

            <motion.div variants={itemVariants} className='flex items-start space-x-4'>
              <FiMapPin className='w-6 h-6 text-primary mt-1' />
              <div>
                <h3 className='font-semibold text-gray-700 dark:text-gray-300'>Address</h3>
                <p className='text-gray-600 dark:text-gray-400'>{`${addressLine1 || ''} ${addressLine2 || ''}`.trim() || 'N/A'}</p>
                <p className='text-gray-600 dark:text-gray-400'>{[city, region].filter(Boolean).join(', ') || 'N/A'}</p>
                <p className='text-gray-600 dark:text-gray-400'>{[country, postcode].filter(Boolean).join(' - ') || 'N/A'}</p>
              </div>
            </motion.div>

            <motion.div variants={itemVariants} className='flex items-start space-x-4'>
              <FiBriefcase className='w-6 h-6 text-primary mt-1' />
              <div>
                <h3 className='font-semibold text-gray-700 dark:text-gray-300'>Business Address</h3>
                <p className='text-gray-600 dark:text-gray-400'>{`${businessAddressLine1 || ''} ${businessAddressLine2 || ''}`.trim() || 'N/A'}</p>
                <p className='text-gray-600 dark:text-gray-400'>{[businessCity, businessRegion].filter(Boolean).join(', ') || 'N/A'}</p>
                <p className='text-gray-600 dark:text-gray-400'>{[businessCountry, businessPostcode].filter(Boolean).join(' - ') || 'N/A'}</p>
              </div>
            </motion.div>

            <motion.div variants={itemVariants} className='flex items-start space-x-4'>
              <FiHash className='w-6 h-6 text-primary mt-1' />
              <div>
                <h3 className='font-semibold text-gray-700 dark:text-gray-300'>Referral Code</h3>
                <p className='text-gray-600 dark:text-gray-400'>{agent.agentReferralCode || 'N/A'}</p>
              </div>
            </motion.div>
          </div>
        </motion.div>

        {/* Sales Tables */}
        <motion.div variants={containerVariants} initial='hidden' animate='show' className='space-y-12 mb-20'>
          {/* Daily Sales */}
          <motion.div variants={itemVariants} className='bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6'>
            <h2 className='text-2xl font-bold text-gray-900 dark:text-white mb-6'>Daily Sales (Last 7 Days)</h2>
            <div className='overflow-x-auto'>
              <table className='w-full table-auto'>
                <thead className='bg-gray-50 dark:bg-gray-700'>
                  <tr>
                    <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>Date</th>
                    <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>
                      Yearly Renewals
                    </th>
                    <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>
                      Monthly Renewals
                    </th>
                    <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>Yearly New</th>
                    <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>Monthly New</th>
                    <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>Commission</th>
                    {user.role === 'AdminUser' && (
                      <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>Amount</th>
                    )}
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-200 dark:divide-gray-600'>
                  {last7Days.map((day, index) => {
                    const sale = dailySales.find((s) => s.dayLabel === day) || {}
                    return (
                      <tr key={index} className='hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors'>
                        <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-300'>{getDayName(day)}</td>
                        <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400'>
                          {sale.yearlySubscriptionRenewal || 0}
                        </td>
                        <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400'>
                          {sale.monthlySubscriptionRenewal || 0}
                        </td>
                        <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400'>{sale.yearlyNewSubscription || 0}</td>
                        <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400'>{sale.monthlyNewSubscription || 0}</td>
                        <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400'>
                          {sale.totalCommission
                            ? (sale.totalCommission / 100).toLocaleString('en-GB', { style: 'currency', currency: 'GBP' })
                            : '£0.00'}
                        </td>
                        {user.role === 'AdminUser' && (
                          <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400'>
                            {sale.amount ? (sale.amount / 100).toLocaleString('en-GB', { style: 'currency', currency: 'GBP' }) : '£0.00'}
                          </td>
                        )}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </motion.div>

          {/* Weekly Sales */}
          <motion.div variants={itemVariants} className='bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6'>
            <h2 className='text-2xl font-bold text-gray-900 dark:text-white mb-6'>Weekly Sales</h2>
            <div className='overflow-x-auto'>
              <table className='w-full table-auto'>
                <thead className='bg-gray-50 dark:bg-gray-700'>
                  <tr>
                    <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>Week</th>
                    <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>
                      Yearly Renewals
                    </th>
                    <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>
                      Monthly Renewals
                    </th>
                    <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>Yearly New</th>
                    <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>Monthly New</th>
                    <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>Commission</th>
                    {user.role === 'AdminUser' && (
                      <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>Amount</th>
                    )}
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-200 dark:divide-gray-600'>
                  {last5Weeks.map((weekStart, index) => {
                    const sale = weeklySales.find((s) => s.weekLabel.startsWith(weekStart)) || {}
                    return (
                      <tr key={index} className='hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors'>
                        <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-300'>{weekStart}</td>
                        <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400'>
                          {sale.yearlySubscriptionRenewal || 0}
                        </td>
                        <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400'>
                          {sale.monthlySubscriptionRenewal || 0}
                        </td>
                        <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400'>{sale.yearlyNewSubscription || 0}</td>
                        <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400'>{sale.monthlyNewSubscription || 0}</td>
                        <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400'>
                          {sale.totalCommission
                            ? (sale.totalCommission / 100).toLocaleString('en-GB', { style: 'currency', currency: 'GBP' })
                            : '£0.00'}
                        </td>
                        {user.role === 'AdminUser' && (
                          <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400'>
                            {sale.amount ? (sale.amount / 100).toLocaleString('en-GB', { style: 'currency', currency: 'GBP' }) : '£0.00'}
                          </td>
                        )}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </motion.div>

          {/* Monthly Sales */}
          <motion.div variants={itemVariants} className='bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6'>
            <h2 className='text-2xl font-bold text-gray-900 dark:text-white mb-6'>Monthly Sales</h2>
            <div className='overflow-x-auto'>
              <table className='w-full table-auto'>
                <thead className='bg-gray-50 dark:bg-gray-700'>
                  <tr>
                    <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>Month</th>
                    <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>
                      Yearly Renewals
                    </th>
                    <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>
                      Monthly Renewals
                    </th>
                    <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>Yearly New</th>
                    <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>Monthly New</th>
                    <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>Commission</th>
                    {user.role === 'AdminUser' && (
                      <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>Amount</th>
                    )}
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-200 dark:divide-gray-600'>
                  {last12Months.map((month, index) => {
                    const sale = monthlySales.find((s) => s.monthLabel === month) || {}
                    return (
                      <tr key={index} className='hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors'>
                        <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-300'>{getMonthNameFromLabel(month)}</td>
                        <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400'>
                          {sale.yearlySubscriptionRenewal || 0}
                        </td>
                        <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400'>
                          {sale.monthlySubscriptionRenewal || 0}
                        </td>
                        <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400'>{sale.yearlyNewSubscription || 0}</td>
                        <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400'>{sale.monthlyNewSubscription || 0}</td>
                        <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400'>
                          {sale.totalCommission
                            ? (sale.totalCommission / 100).toLocaleString('en-GB', { style: 'currency', currency: 'GBP' })
                            : '£0.00'}
                        </td>
                        {user.role === 'AdminUser' && (
                          <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400'>
                            {sale.amount ? (sale.amount / 100).toLocaleString('en-GB', { style: 'currency', currency: 'GBP' }) : '£0.00'}
                          </td>
                        )}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </motion.div>

          {/* Yearly Sales */}
          <motion.div variants={itemVariants} className='bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6'>
            <h2 className='text-2xl font-bold text-gray-900 dark:text-white mb-6'>Yearly Sales</h2>
            <div className='overflow-x-auto'>
              <table className='w-full table-auto'>
                <thead className='bg-gray-50 dark:bg-gray-700'>
                  <tr>
                    <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>Year</th>
                    <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>
                      Yearly Renewals
                    </th>
                    <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>
                      Monthly Renewals
                    </th>
                    <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>Yearly New</th>
                    <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>Monthly New</th>
                    <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>Commission</th>
                    {user.role === 'AdminUser' && (
                      <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>Amount</th>
                    )}
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-200 dark:divide-gray-600'>
                  {allYears.map((year, index) => {
                    const sale = yearlySales.find((s) => s.yearLabel === year) || {}
                    return (
                      <tr key={index} className='hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors'>
                        <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-300'>{year}</td>
                        <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400'>
                          {sale.yearlySubscriptionRenewal || 0}
                        </td>
                        <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400'>
                          {sale.monthlySubscriptionRenewal || 0}
                        </td>
                        <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400'>{sale.yearlyNewSubscription || 0}</td>
                        <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400'>{sale.monthlyNewSubscription || 0}</td>
                        <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400'>
                          {sale.totalCommission
                            ? (sale.totalCommission / 100).toLocaleString('en-GB', { style: 'currency', currency: 'GBP' })
                            : '£0.00'}
                        </td>
                        {user.role === 'AdminUser' && (
                          <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400'>
                            {sale.amount ? (sale.amount / 100).toLocaleString('en-GB', { style: 'currency', currency: 'GBP' }) : '£0.00'}
                          </td>
                        )}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </motion.div>
        </motion.div>
      </div>
    </div>
  )
}

export default AgentOverview
