import React from 'react'

// Generic skeleton loading component with configurable type
const Skeleton = ({ type = 'default', count = 1, className = '' }) => {
  // Basic styling for all skeleton items
  const baseStyle = 'animate-pulse bg-slate-200 dark:bg-slate-700 rounded'

  // Generate multiple skeleton items if count > 1
  const renderMultiple = (renderFn) => {
    return Array(count)
      .fill(0)
      .map((_, index) => (
        <div key={index} className='mb-2'>
          {renderFn()}
        </div>
      ))
  }

  // Different skeleton types
  const renderByType = () => {
    switch (type) {
      case 'text':
        return renderMultiple(() => <div className={`h-4 ${baseStyle} ${className}`}></div>)

      case 'title':
        return renderMultiple(() => <div className={`h-7 w-1/3 ${baseStyle} ${className}`}></div>)

      case 'avatar':
        return renderMultiple(() => <div className={`h-12 w-12 rounded-full ${baseStyle} ${className}`}></div>)

      case 'thumbnail':
        return renderMultiple(() => <div className={`h-24 w-24 ${baseStyle} ${className}`}></div>)

      case 'button':
        return renderMultiple(() => <div className={`h-10 w-24 ${baseStyle} ${className}`}></div>)

      case 'card':
        return renderMultiple(() => <div className={`h-40 ${baseStyle} ${className}`}></div>)

      case 'table-row':
        return renderMultiple(() => (
          <div className='flex w-full'>
            <div className={`h-6 w-1/6 mr-2 ${baseStyle}`}></div>
            <div className={`h-6 w-2/6 mr-2 ${baseStyle}`}></div>
            <div className={`h-6 w-1/6 mr-2 ${baseStyle}`}></div>
            <div className={`h-6 w-2/6 ${baseStyle}`}></div>
          </div>
        ))

      case 'stat':
        return renderMultiple(() => (
          <div className='p-4 border border-gray-200 dark:border-gray-800 rounded-lg'>
            <div className={`h-8 w-16 mb-2 ${baseStyle}`}></div>
            <div className={`h-4 w-full ${baseStyle}`}></div>
          </div>
        ))

      // Default for generic blocks
      default:
        return renderMultiple(() => <div className={`h-6 ${baseStyle} ${className}`}></div>)
    }
  }

  return renderByType()
}

export default Skeleton

// Table skeleton specifically for data tables
export const TableSkeleton = ({ rows = 5, columns = 4 }) => {
  return (
    <div className='w-full space-y-3'>
      {/* Data rows */}
      {Array(rows)
        .fill(0)
        .map((_, rowIndex) => (
          <div key={`row-${rowIndex}`} className='flex w-full space-x-3'>
            {Array(columns)
              .fill(0)
              .map((_, colIndex) => (
                <div key={`cell-${rowIndex}-${colIndex}`} className='h-6 flex-1 animate-pulse bg-slate-200 dark:bg-slate-700 rounded'></div>
              ))}
          </div>
        ))}
    </div>
  )
}

// Dashboard card skeleton
export const DashboardSkeleton = () => {
  return (
    <div className='grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4'>
      {Array(4)
        .fill(0)
        .map((_, index) => (
          <div key={`stat-${index}`} className='relative overflow-hidden rounded-2xl bg-white dark:bg-gray-800 p-6 shadow-lg'>
            <div className='h-8 w-8 animate-pulse bg-slate-200 dark:bg-slate-700 rounded mb-4'></div>
            <div className='h-8 w-24 animate-pulse bg-slate-200 dark:bg-slate-700 rounded mb-2'></div>
            <div className='h-4 w-32 animate-pulse bg-slate-200 dark:bg-slate-700 rounded'></div>
          </div>
        ))}
    </div>
  )
}

// Page skeleton with header and content
export const PageSkeleton = () => {
  return (
    <div className='w-full p-4'>
      <div className='h-12 w-1/3 animate-pulse bg-slate-200 dark:bg-slate-700 rounded mb-6'></div>
      <div className='h-4 w-2/3 animate-pulse bg-slate-200 dark:bg-slate-700 rounded mb-8'></div>

      <DashboardSkeleton />

      <div className='mt-8 mb-4 h-8 w-48 animate-pulse bg-slate-200 dark:bg-slate-700 rounded'></div>
      <TableSkeleton rows={5} columns={4} />
    </div>
  )
}
