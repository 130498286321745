import { useState, useEffect } from 'react'
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'
import { agentSignup, reset } from '../features/agents/agentsSlice'
import SubmissionMessage from '../components/SubmissionMessage'
import { useSelector, useDispatch } from 'react-redux'
import toast from 'react-hot-toast'
import { motion } from 'framer-motion'
import { FiCopy, FiUser, FiMail, FiHome, FiMapPin, FiPhone, FiGlobe, FiBriefcase, FiSave, FiTrendingUp } from 'react-icons/fi'
import BackButton from '../components/BackButton'

const AgentSignUp = () => {
  const { message, isLoading, isSuccess, isError, submissionSuccess } = useSelector((state) => state.agents || {})
  const { user } = useSelector((state) => state.auth)
  const [generatedLink, setGeneratedLink] = useState('')

  const [selectedCountry, setSelectedCountry] = useState('GB')
  const [selectedRegion, setSelectedRegion] = useState('')
  const [selectedCountryBusinessAddress, setSelectedCountryBusinessAddress] = useState('GB')
  const [selectedRegionBusinessAddress, setSelectedRegionBusinessAddress] = useState('')
  const [emailError, setEmailError] = useState('')
  const [formErrors, setFormErrors] = useState({})
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isValidPostCode, setIsValidPostCode] = useState(true)

  const dispatch = useDispatch()

  const [formData, setFormData] = useState({
    email: '',
    name: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    postcode: '',
    country: selectedCountry,
    region: selectedRegion,
    addressLine1BusinessAddress: '',
    addressLine2BusinessAddress: '',
    cityBusinessAddress: '',
    postcodeBusinessAddress: '',
    countryBusinessAddress: selectedCountryBusinessAddress,
    regionBusinessAddress: selectedRegionBusinessAddress,
    website: '',
    landline: '',
    mobile: '',
    vatRegistered: '',
    vatRegNumber: '',
    tradingStyle: '',
    tradingName: '',
    currentTradingActivity: '',
    companyRegNum: '',
    januarySales: '',
    februarySales: '',
    marchSales: '',
    aprilSales: '',
    maySales: '',
    juneSales: '',
    julySales: '',
    augustSales: '',
    septemberSales: '',
    octoberSales: '',
    novemberSales: '',
    decemberSales: '',
    parentDna: user ? user._id : '',
  })

  // Update parentDna when user changes
  useEffect(() => {
    if (user) {
      setFormData((prev) => ({ ...prev, parentDna: user._id }))
    }
  }, [user])

  console.log(formData)

  useEffect(() => {
    if (isError) {
      console.log(message)
      toast.error(message || 'Something went wrong')
      dispatch(reset())
    }
    if (isSuccess) {
      dispatch(reset())
    }
  }, [isSuccess, dispatch, isError, message])

  // Add this cleanup effect
  useEffect(() => {
    return () => {
      dispatch(reset()) // Reset state when component unmounts
      setIsSubmitted(false) // Reset local submission state
    }
  }, [dispatch])

  const validateForm = () => {
    const requiredFields = [
      'name',
      'email',
      'addressLine1',
      'city',
      'postcode',
      'country',
      'region',
      'vatRegistered',
      'tradingStyle',
      'tradingName',
      'currentTradingActivity',
      'addressLine1BusinessAddress',
      'cityBusinessAddress',
      'postcodeBusinessAddress',
      'countryBusinessAddress',
      'regionBusinessAddress',
    ]

    if (formData.vatRegistered === 'true') {
      requiredFields.push('vatRegNumber')
    }

    if (formData.tradingStyle === 'Ltd company') {
      requiredFields.push('companyRegNum')
    }

    const errors = {}

    requiredFields.forEach((field) => {
      if (formData[field] === undefined || formData[field] === null || formData[field] === '') {
        // Check if the field is 'logo' and show a different error message
        if (field === 'companyLogo') {
          errors[field] = 'Please include a company logo'
        } else {
          errors[field] = 'This field is required'
        }
      }
    })

    setFormErrors(errors)
    return Object.keys(errors).length === 0
  }

  const handleCountryChange = (val) => {
    setSelectedCountry(val)
    setFormData((prevState) => ({
      ...prevState,
      country: val,
    }))
  }

  const handleRegionChange = (val) => {
    setSelectedRegion(val)
    setFormData((prevState) => ({
      ...prevState,
      region: val,
    }))
  }

  const handleCountryChangeBusinessAddress = (val) => {
    setSelectedCountryBusinessAddress(val)
    setFormData((prevState) => ({
      ...prevState,
      countryBusinessAddress: val,
    }))
  }

  const handleRegionChangeBusinessAddress = (val) => {
    setSelectedRegionBusinessAddress(val)
    setFormData((prevState) => ({
      ...prevState,
      regionBusinessAddress: val,
    }))
  }

  const validatePostCode = (postcode) => {
    const regex = new RegExp(/^(GIR 0AA|[A-PR-UWYZ]([0-9]{1,2}|([A-HK-Y][0-9]([0-9ABEHMNPRV-Y])?)|[0-9][A-HJKS-UW]) ?[0-9][ABD-HJLNP-UW-Z]{2})$/i)
    return regex.test(postcode)
  }

  function formatPostcode(p) {
    var postcodeRegEx = /(^[A-Z]{1,2}[0-9]{1,2})([0-9][A-Z]{2}$)/i
    return p.replace(postcodeRegEx, function (match, part1, part2) {
      return part1.toUpperCase() + ' ' + part2.toUpperCase()
    })
  }

  const onSubmit = (e) => {
    e.preventDefault()
    setIsSubmitted(true)
    if (!validateForm() || emailError) {
      console.log('Form is invalid')
      return
    }
    const userData = {
      ...formData,
    }
    console.log(userData, 'userdata')

    dispatch(agentSignup(userData))
  }

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  const onChange = (e) => {
    let value = e.target.value
    if (e.target.name === 'email') {
      if (!validateEmail(e.target.value)) {
        setEmailError('Invalid email')
      } else {
        setEmailError('')
      }
    } else if (e.target.name === 'postcode') {
      if (e.target.value.length > 6) {
        value = formatPostcode(value)
        setIsValidPostCode(validatePostCode(value))
      }
    }

    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const generateReferralLink = () => {
    const baseUrl = `${window.location.origin}/agent-sign-up`
    const link = `${baseUrl}?parentDna=${user._id}`
    setGeneratedLink(link)
    navigator.clipboard.writeText(link)
    toast.success('Referral link copied to clipboard!', {
      duration: 4000,
      position: 'top-right',
    })
  }

  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3,
      },
    },
  }

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    show: { y: 0, opacity: 1 },
  }

  return (
    <>
      {submissionSuccess ? (
        <SubmissionMessage userName={formData.name} />
      ) : (
        <div className='min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800'>
          {/* Hero Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className='relative overflow-hidden bg-gradient-to-r from-primary to-primary-dark py-16 mb-4'>
            <div className='relative z-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
              <div className='text-center'>
                <motion.h1
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.2 }}
                  className='text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl'>
                  Agent Application
                </motion.h1>
                <motion.p
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.4 }}
                  className='mt-6 text-xl text-gray-100'>
                  Join our team and become a Loyalty Club PLC Agent
                </motion.p>
              </div>
            </div>

            {/* Decorative blob */}
            <div className='absolute top-0 right-0 -translate-y-12 translate-x-12 transform opacity-20'>
              <svg width='400' height='400' viewBox='0 0 184 184' xmlns='http://www.w3.org/2000/svg'>
                <path
                  fill='white'
                  d='M92 0C92 0 92 0 92 0C142.8 0 184 41.2 184 92C184 142.8 142.8 184 92 184C41.2 184 0 142.8 0 92C0 41.2 41.2 0 92 0Z'
                />
              </svg>
            </div>
          </motion.div>

          <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pb-20'>
            <div className='flex justify-start w-full'>
              <BackButton />
            </div>

            <motion.div variants={containerVariants} initial='hidden' animate='show'>
              {/* Only show referral section if user exists */}
              {user && (
                <motion.div variants={itemVariants} className='bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8 mb-8'>
                  <div className='flex items-center gap-3 text-2xl font-semibold text-gray-900 dark:text-white border-b border-gray-200 dark:border-gray-700 pb-4'>
                    <FiCopy className='text-primary' />
                    <h2>Generate Your Referral Link</h2>
                  </div>
                  <div className='flex flex-col gap-4 items-center mt-6'>
                    <button
                      onClick={generateReferralLink}
                      className='px-6 py-3 bg-primary hover:bg-primary-dark text-white font-medium rounded-lg transition-colors flex items-center gap-2'>
                      <FiCopy /> Generate & Copy Link
                    </button>
                    {generatedLink && (
                      <div className='w-full'>
                        <input
                          type='text'
                          readOnly
                          value={generatedLink}
                          className='p-4 border dark:border-gray-600 rounded-lg w-full text-center bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white'
                          onClick={() => navigator.clipboard.writeText(generatedLink)}
                        />
                        <p className='mt-2 text-sm text-gray-600 dark:text-gray-400 text-center'>
                          Share this link with potential agents to track your referrals
                        </p>
                      </div>
                    )}
                  </div>
                </motion.div>
              )}

              <motion.div variants={itemVariants} className='bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8 mb-8'>
                <div className='flex items-center gap-3 text-2xl font-semibold text-gray-900 dark:text-white border-b border-gray-200 dark:border-gray-700 pb-4'>
                  <FiUser className='text-primary' />
                  <h2>Agent Application</h2>
                </div>

                <div className='mt-6 text-gray-700 dark:text-gray-300'>
                  <p className='mb-4'>
                    Hello Future Partners! We're thrilled you're considering becoming an Agent with us. At Loyalty Club PLC, we believe in creating
                    win-win relationships, and our Agent Program is designed to do just that. By joining us, you're not just another member of the
                    team; you're family. If you're accepted, you'll join an elite team focused on transforming the loyalty software landscape.
                  </p>

                  <h3 className='text-xl font-semibold mb-2 text-gray-900 dark:text-white'>Why Become an Agent?</h3>
                  <ul className='list-disc list-inside mb-4 space-y-2'>
                    <li>
                      📈 <strong>Financial Growth</strong>: Earn attractive commissions for every client you bring on board.
                    </li>
                    <li>
                      🤝 <strong>Networking</strong>: Build your professional network while working with a diverse range of clients.
                    </li>
                    <li>
                      💡 <strong>Skill Enhancement</strong>: Receive comprehensive training to equip you with the skills to succeed.
                    </li>
                    <li>
                      🎯 <strong>Exclusive Access</strong>: Be the first to know about our new features and offerings.
                    </li>
                  </ul>

                  <h3 className='text-xl font-semibold mb-2 text-gray-900 dark:text-white'>Who Are We Looking For?</h3>
                  <p className='mb-2'>We're seeking individuals who are:</p>
                  <ul className='list-disc list-inside mb-4 space-y-1'>
                    <li>🌟 Highly Motivated</li>
                    <li>🗣 Excellent Communicators</li>
                    <li>🤝 Relationship Builders</li>
                    <li>📊 Results-Driven</li>
                    <li>🔄 Adaptable to Change</li>
                  </ul>
                </div>
              </motion.div>

              <motion.form variants={containerVariants} onSubmit={onSubmit} className='space-y-8'>
                {/* Personal Information */}
                <motion.div variants={itemVariants} className='bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8 space-y-6'>
                  <div className='flex items-center gap-3 text-2xl font-semibold text-gray-900 dark:text-white border-b border-gray-200 dark:border-gray-700 pb-4'>
                    <FiUser className='text-primary' />
                    <h2>Personal Information</h2>
                  </div>

                  <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
                    <div>
                      <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Full Name</label>
                      <div className='relative'>
                        <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                          <FiUser className='text-gray-400' />
                        </div>
                        <input
                          type='text'
                          name='name'
                          value={formData.name}
                          onChange={onChange}
                          className='w-full pl-10 px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                          placeholder='Your full name'
                        />
                      </div>
                      <p className='text-red-500 text-sm mt-1'>{formErrors.name && formErrors.name}</p>
                    </div>

                    <div>
                      <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Email Address</label>
                      <div className='relative'>
                        <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                          <FiMail className='text-gray-400' />
                        </div>
                        <input
                          type='email'
                          name='email'
                          value={formData.email}
                          onChange={onChange}
                          className='w-full pl-10 px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                          placeholder='your@email.com'
                        />
                      </div>
                      <p className='text-red-500 text-sm mt-1'>{emailError || (formErrors.email && formErrors.email)}</p>
                    </div>

                    <div>
                      <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Phone Number</label>
                      <div className='relative'>
                        <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                          <FiPhone className='text-gray-400' />
                        </div>
                        <input
                          type='tel'
                          name='phone'
                          value={formData.phone}
                          onChange={onChange}
                          className='w-full pl-10 px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                          placeholder='Your phone number'
                        />
                      </div>
                      <p className='text-red-500 text-sm mt-1'>{formErrors.phone && formErrors.phone}</p>
                    </div>

                    <div>
                      <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Website (if any)</label>
                      <div className='relative'>
                        <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                          <FiGlobe className='text-gray-400' />
                        </div>
                        <input
                          type='url'
                          name='website'
                          value={formData.website}
                          onChange={onChange}
                          className='w-full pl-10 px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                          placeholder='https://yourwebsite.com'
                        />
                      </div>
                      <p className='text-red-500 text-sm mt-1'>{formErrors.website && formErrors.website}</p>
                    </div>
                  </div>
                </motion.div>

                {/* Personal Address */}
                <motion.div variants={itemVariants} className='bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8 space-y-6'>
                  <div className='flex items-center gap-3 text-2xl font-semibold text-gray-900 dark:text-white border-b border-gray-200 dark:border-gray-700 pb-4'>
                    <FiHome className='text-primary' />
                    <h2>Personal Address</h2>
                  </div>

                  <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
                    <div>
                      <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Address Line 1</label>
                      <input
                        type='text'
                        name='addressLine1'
                        value={formData.addressLine1}
                        onChange={onChange}
                        className='w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                        placeholder='Street address'
                      />
                      <p className='text-red-500 text-sm mt-1'>{formErrors.addressLine1 && formErrors.addressLine1}</p>
                    </div>

                    <div>
                      <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Address Line 2</label>
                      <input
                        type='text'
                        name='addressLine2'
                        value={formData.addressLine2}
                        onChange={onChange}
                        className='w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                        placeholder='Apartment, suite, etc. (optional)'
                      />
                    </div>

                    <div>
                      <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>City</label>
                      <input
                        type='text'
                        name='city'
                        value={formData.city}
                        onChange={onChange}
                        className='w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                        placeholder='City'
                      />
                      <p className='text-red-500 text-sm mt-1'>{formErrors.city && formErrors.city}</p>
                    </div>

                    <div>
                      <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Postal Code</label>
                      <input
                        type='text'
                        name='postcode'
                        value={formData.postcode}
                        onChange={onChange}
                        className='w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                        placeholder='Postal code'
                      />
                      <p className='text-red-500 text-sm mt-1'>{formErrors.postcode && formErrors.postcode}</p>
                    </div>

                    <div>
                      <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Country</label>
                      <CountryDropdown
                        value={selectedCountry}
                        onChange={handleCountryChange}
                        classes='w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                      />
                      <p className='text-red-500 text-sm mt-1'>{formErrors.country && formErrors.country}</p>
                    </div>

                    <div>
                      <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Region/State</label>
                      <RegionDropdown
                        country={selectedCountry}
                        value={selectedRegion}
                        onChange={handleRegionChange}
                        classes='w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                      />
                      <p className='text-red-500 text-sm mt-1'>{formErrors.region && formErrors.region}</p>
                    </div>
                  </div>
                </motion.div>

                {/* Business Address */}
                <motion.div variants={itemVariants} className='bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8 space-y-6'>
                  <div className='flex items-center gap-3 text-2xl font-semibold text-gray-900 dark:text-white border-b border-gray-200 dark:border-gray-700 pb-4'>
                    <FiBriefcase className='text-primary' />
                    <h2>Business Address</h2>
                  </div>

                  <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
                    <div>
                      <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Address Line 1</label>
                      <input
                        type='text'
                        name='addressLine1BusinessAddress'
                        value={formData.addressLine1BusinessAddress}
                        onChange={onChange}
                        className='w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                        placeholder='Business street address'
                      />
                      <p className='text-red-500 text-sm mt-1'>{formErrors.addressLine1BusinessAddress && formErrors.addressLine1BusinessAddress}</p>
                    </div>

                    <div>
                      <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Address Line 2</label>
                      <input
                        type='text'
                        name='addressLine2BusinessAddress'
                        value={formData.addressLine2BusinessAddress}
                        onChange={onChange}
                        className='w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                        placeholder='Suite, unit, etc. (optional)'
                      />
                    </div>

                    <div>
                      <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>City</label>
                      <input
                        type='text'
                        name='cityBusinessAddress'
                        value={formData.cityBusinessAddress}
                        onChange={onChange}
                        className='w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                        placeholder='Business city'
                      />
                      <p className='text-red-500 text-sm mt-1'>{formErrors.cityBusinessAddress && formErrors.cityBusinessAddress}</p>
                    </div>

                    <div>
                      <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Postal Code</label>
                      <input
                        type='text'
                        name='postcodeBusinessAddress'
                        value={formData.postcodeBusinessAddress}
                        onChange={onChange}
                        className='w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                        placeholder='Business postal code'
                        maxLength='7'
                      />
                      <p className='text-red-500 text-sm mt-1'>{formErrors.postcodeBusinessAddress && formErrors.postcodeBusinessAddress}</p>
                    </div>

                    <div>
                      <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Country</label>
                      <CountryDropdown
                        value={selectedCountryBusinessAddress}
                        onChange={handleCountryChangeBusinessAddress}
                        classes='w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                        priorityOptions={['GB', 'US', 'CA']}
                        showDefaultOption={false}
                        valueType='short'
                      />
                      <p className='text-red-500 text-sm mt-1'>{formErrors.countryBusinessAddress && formErrors.countryBusinessAddress}</p>
                    </div>

                    <div>
                      <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Region/State</label>
                      <RegionDropdown
                        country={selectedCountryBusinessAddress}
                        value={selectedRegionBusinessAddress}
                        onChange={handleRegionChangeBusinessAddress}
                        classes='w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                        countryValueType='short'
                        blankOptionLabel='Select region'
                        showDefaultOption={true}
                      />
                      <p className='text-red-500 text-sm mt-1'>{formErrors.regionBusinessAddress && formErrors.regionBusinessAddress}</p>
                    </div>
                  </div>
                </motion.div>

                {/* Contact Information */}
                <motion.div variants={itemVariants} className='bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8 space-y-6'>
                  <div className='flex items-center gap-3 text-2xl font-semibold text-gray-900 dark:text-white border-b border-gray-200 dark:border-gray-700 pb-4'>
                    <FiPhone className='text-primary' />
                    <h2>Contact Information</h2>
                  </div>

                  <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
                    <div>
                      <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Landline</label>
                      <div className='relative'>
                        <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                          <FiPhone className='text-gray-400' />
                        </div>
                        <input
                          type='tel'
                          name='landline'
                          value={formData.landline}
                          onChange={onChange}
                          className='w-full pl-10 px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                          placeholder='Landline number'
                        />
                      </div>
                    </div>

                    <div>
                      <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Mobile</label>
                      <div className='relative'>
                        <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                          <FiPhone className='text-gray-400' />
                        </div>
                        <input
                          type='tel'
                          name='mobile'
                          value={formData.mobile}
                          onChange={onChange}
                          className='w-full pl-10 px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                          placeholder='Mobile number'
                        />
                      </div>
                    </div>
                  </div>
                </motion.div>

                {/* Business Information */}
                <motion.div variants={itemVariants} className='bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8 space-y-6'>
                  <div className='flex items-center gap-3 text-2xl font-semibold text-gray-900 dark:text-white border-b border-gray-200 dark:border-gray-700 pb-4'>
                    <FiBriefcase className='text-primary' />
                    <h2>Business Information</h2>
                  </div>

                  <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
                    <div>
                      <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Trading Style</label>
                      <select
                        name='tradingStyle'
                        value={formData.tradingStyle}
                        onChange={onChange}
                        className='w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'>
                        <option value=''>Select a trading style</option>
                        <option value='Trader'>Trader</option>
                        <option value='Partnership'>Partnership</option>
                        <option value='Ltd company'>Ltd Company</option>
                      </select>
                      <p className='text-red-500 text-sm mt-1'>{formErrors.tradingStyle && formErrors.tradingStyle}</p>
                    </div>

                    {formData.tradingStyle === 'Ltd company' && (
                      <div>
                        <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Company Registration Number</label>
                        <input
                          type='number'
                          name='companyRegNum'
                          value={formData.companyRegNum}
                          onChange={onChange}
                          className='w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                          placeholder='Enter registration number'
                        />
                        <p className='text-red-500 text-sm mt-1'>{formErrors.companyRegNum && formErrors.companyRegNum}</p>
                      </div>
                    )}

                    <div className='md:col-span-2'>
                      <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Current Trading Activity</label>
                      <textarea
                        name='currentTradingActivity'
                        value={formData.currentTradingActivity}
                        onChange={onChange}
                        className='w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                        placeholder='Describe your current trading activity'
                        rows='4'></textarea>
                      <p className='text-red-500 text-sm mt-1'>{formErrors.currentTradingActivity && formErrors.currentTradingActivity}</p>
                    </div>

                    <div>
                      <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Trading Name</label>
                      <input
                        type='text'
                        name='tradingName'
                        value={formData.tradingName}
                        onChange={onChange}
                        className='w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                        placeholder='Enter trading name'
                      />
                      <p className='text-red-500 text-sm mt-1'>{formErrors.tradingName && formErrors.tradingName}</p>
                    </div>

                    <div>
                      <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>VAT Registered?</label>
                      <select
                        name='vatRegistered'
                        value={formData.vatRegistered}
                        onChange={onChange}
                        className='w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'>
                        <option value=''>Select an option</option>
                        <option value='true'>Yes</option>
                        <option value='false'>No</option>
                      </select>
                      <p className='text-red-500 text-sm mt-1'>{formErrors.vatRegistered && formErrors.vatRegistered}</p>
                    </div>

                    {formData.vatRegistered === 'true' && (
                      <div>
                        <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>VAT Registration Number</label>
                        <input
                          type='number'
                          name='vatRegNumber'
                          value={formData.vatRegNumber}
                          onChange={onChange}
                          className='w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                          placeholder='Enter VAT number'
                        />
                        <p className='text-red-500 text-sm mt-1'>{formErrors.vatRegNumber && formErrors.vatRegNumber}</p>
                      </div>
                    )}
                  </div>
                </motion.div>

                {/* Monthly Sales Projections */}
                <motion.div variants={itemVariants} className='bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8 space-y-6'>
                  <div className='flex items-center gap-3 text-2xl font-semibold text-gray-900 dark:text-white border-b border-gray-200 dark:border-gray-700 pb-4'>
                    <FiTrendingUp className='text-primary' />
                    <h2>Monthly Sales Projections</h2>
                  </div>

                  <div className='grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4'>
                    {['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].map(
                      (month, index) => (
                        <div key={index} className='space-y-2'>
                          <label className='block font-medium text-gray-700 dark:text-gray-300'>{month}</label>
                          <select
                            name={`${month.toLowerCase()}Sales`}
                            value={formData[`${month.toLowerCase()}Sales`]}
                            onChange={onChange}
                            required
                            className='w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'>
                            <option value='' disabled>
                              Select Range
                            </option>
                            <option value='0-5'>0-5</option>
                            <option value='5-10'>5-10</option>
                            <option value='10-15'>10-15</option>
                            <option value='15-20'>15-20</option>
                            <option value='20-30'>20-30</option>
                            <option value='30-40'>30-40</option>
                            <option value='40-50'>40-50</option>
                            <option value='50-70'>50-70</option>
                            <option value='70-100'>70-100</option>
                            <option value='100-150'>100-150</option>
                            <option value='150-200'>150-200</option>
                            <option value='200+'>200+</option>
                          </select>
                        </div>
                      )
                    )}
                  </div>
                </motion.div>

                <motion.div variants={itemVariants} className='flex justify-center mt-8'>
                  <button
                    type='submit'
                    className='px-8 py-4 bg-primary hover:bg-primary-dark text-white font-medium rounded-lg transition-colors flex items-center gap-2 text-lg'>
                    <FiSave className='text-xl' /> Submit Application
                  </button>
                </motion.div>

                {isSubmitted && Object.keys(formErrors).length > 0 && (
                  <motion.p variants={itemVariants} className='text-red-500 text-center text-lg font-bold mt-4'>
                    Please fill in all required fields
                  </motion.p>
                )}

                {isLoading && (
                  <motion.div variants={itemVariants} className='flex justify-center mt-4'>
                    <img src='/images/loadingSpinner.svg' alt='Loading...' className='w-8 h-8 mr-2' />
                    <span className='text-gray-600 dark:text-gray-400'>Saving your application...</span>
                  </motion.div>
                )}
              </motion.form>
            </motion.div>
          </div>
        </div>
      )}
    </>
  )
}

export default AgentSignUp
