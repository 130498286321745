import React from 'react'

const ButtonLoader = ({ isLoading, children, className = '', ...props }) => {
  return (
    <button className={`relative ${className}`} disabled={isLoading} {...props}>
      {isLoading ? (
        <div className='flex items-center justify-center'>
          <div className='w-5 h-5 mr-2 border-t-2 border-b-2 border-white dark:border-gray-800 rounded-full animate-spin'></div>
          <span>Loading...</span>
        </div>
      ) : (
        children
      )}
    </button>
  )
}

export default ButtonLoader
