import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import BackButton from '../components/BackButton'
import { getTEDSTransactions, fetchTedsWallet } from '../features/teds/tedsSlice'
import { motion } from 'framer-motion'
import { FiArrowRight } from 'react-icons/fi'

const TEDSWallet = () => {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)

  // Calculate the total TEDS balance
  const totalTedsBalance = user.wallet.madTedsBalance + user.wallet.tedsBalance

  const {
    tedsTransactions: { transactions },
  } = useSelector((state) => state.teds)

  const tedsToPounds = (teds) => (teds / 100).toFixed(2) // Convert TEDS to pounds

  useEffect(() => {
    dispatch(getTEDSTransactions({ limit: 5 }))
    dispatch(fetchTedsWallet())
  }, [dispatch])

  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3,
      },
    },
  }

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    show: { y: 0, opacity: 1 },
  }

  // Define a single combined animation
  const combinedAnimation = {
    rotate: [0, -15, 360, 360, 360, 360, 360, 360, 360, 360], // Hold at 360 for multiple steps
    y: [0, -10, 0],
    opacity: [0.8, 1, 0.8],
    transition: {
      duration: 10,
      times: [0, 0.05, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.8, 1], // Spread out the rotation
      ease: 'easeInOut',
      repeat: Infinity,
    },
  }

  return (
    <div className='min-h-screen w-full bg-gradient-to-b from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800'>
      {/* Hero Section */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className='w-full relative overflow-hidden bg-gradient-to-r from-purple-600 to-indigo-900 py-16 mb-8'>
        <div className='relative z-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
          <div className='text-center'>
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className='text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl'>
              TEDS Wallet
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              className='mt-6 text-xl text-gray-100'>
              Manage your TEDS balance and transactions
            </motion.p>
          </div>
        </div>

        {/* Decorative blob */}
        <div className='absolute top-0 right-0 -translate-y-12 translate-x-12 transform opacity-20'>
          <svg width='400' height='400' viewBox='0 0 184 184' xmlns='http://www.w3.org/2000/svg'>
            <path
              fill='white'
              d='M92 0C92 0 92 0 92 0C142.8 0 184 41.2 184 92C184 142.8 142.8 184 92 184C41.2 184 0 142.8 0 92C0 41.2 41.2 0 92 0Z'
            />
          </svg>
        </div>
      </motion.div>

      <div className='mx-auto max-w-4xl px-4 sm:px-6 lg:px-8'>
        <BackButton className='text-white hover:text-gray-300' />

        <motion.div variants={containerVariants} initial='hidden' animate='show' className='bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8 mb-8'>
          {/* TEDS Logo */}
          <div className='flex justify-center mb-8'>
            <motion.img
              src='/images/TEDSLogo-sm.png'
              alt='TEDS Logo'
              className='w-32 h-32 object-contain drop-shadow-lg'
              animate={combinedAnimation}
              style={{
                borderRadius: '50%',
              }}
              whileHover={{
                scale: 1.05,
                filter: 'drop-shadow(0 0 25px rgba(255, 140, 0, 0.8)) brightness(1.1)',
                animationPlayState: 'paused',
              }}
            />
          </div>

          {/* Balance Section */}
          <div className='flex flex-col md:flex-row justify-between items-center mb-8'>
            <motion.div variants={itemVariants} className='flex-1 text-center'>
              <p className='text-sm text-gray-500 dark:text-gray-400'>Available Balance</p>
              <p className='text-4xl font-bold text-gray-900 dark:text-white mt-2'>{totalTedsBalance.toFixed(1)} TEDS</p>
              <p className='text-sm text-gray-500 dark:text-gray-400 mt-4'>
                Approx. <span className='font-semibold'>£{tedsToPounds(totalTedsBalance)}</span>
              </p>
            </motion.div>
          </div>

          {/* Transactions Section */}
          <motion.div variants={itemVariants} className='mt-8'>
            <div className='flex justify-between items-center mb-6'>
              <h3 className='text-xl font-bold text-gray-900 dark:text-white'>Recent Transactions</h3>
              <Link to='/teds/transactions' className='flex items-center gap-2 text-purple-600 hover:text-purple-700 transition-colors'>
                View All <FiArrowRight />
              </Link>
            </div>
            <div className='overflow-x-auto'>
              <table className='w-full'>
                <thead className='bg-gray-50 dark:bg-gray-700'>
                  <tr>
                    <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>From</th>
                    <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>To</th>
                    <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>TEDS</th>
                    <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>Cost</th>
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-200 dark:divide-gray-600'>
                  {transactions && transactions.length > 0 ? (
                    transactions.map((transaction) => (
                      <tr key={transaction._id} className='hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors'>
                        <td className='px-6 py-4 text-sm text-gray-900 dark:text-gray-200'>{transaction.fromUser.userName}</td>
                        <td className='px-6 py-4 text-sm text-gray-900 dark:text-gray-200'>{transaction.toUser.userName}</td>
                        <td className='px-6 py-4 text-sm text-gray-900 dark:text-gray-200'>{transaction.tedsAmount}</td>
                        <td className='px-6 py-4 text-sm text-gray-900 dark:text-gray-200'>
                          {isNaN(transaction.cost) || transaction.cost == null ? 'N/A' : `£${transaction.cost.toFixed(2)}`}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan='4' className='px-6 py-4 text-center text-gray-500 dark:text-gray-400'>
                        No recent transactions found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </motion.div>
        </motion.div>
      </div>
    </div>
  )
}

export default TEDSWallet
