import { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import toast from 'react-hot-toast'
import { useSelector, useDispatch } from 'react-redux'
import { registerCharity, reset } from '../features/charity/charitySlice'
import Spinner from '../components/Spinner'
import BackButton from '../components/BackButton'
import { motion } from 'framer-motion'
import { FiHeart, FiInfo, FiMapPin, FiMail, FiPhone, FiGlobe, FiUser, FiSave } from 'react-icons/fi'

function AddCharity() {
  const { isLoading, isSuccess, isError, message } = useSelector((state) => state.charities)

  const [formData, setFormData] = useState({
    charityName: '',
    registrationNumber: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    region: '',
    country: '',
    postcode: '',
    phone: '',
    email: '',
    website: '',
    contactPersonName: '',
    contactPersonPosition: '',
    contactPersonEmail: '',
    contactPersonPhone: '',
    charityType: '',
    charityCategory: '',
    missionStatement: '',
    donationMethods: [],
    notes: '',
  })

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (isError) {
      toast.error(message)
    }
    if (isSuccess) {
      toast.success('Charity Added Successfully')
      navigate('/charities')
    }
    dispatch(reset())
  }, [isError, isSuccess, message, navigate, dispatch])

  const onChange = (e) => {
    if (e.target.name === 'donationMethods') {
      const selectedMethods = Array.from(e.target.selectedOptions, (option) => option.value)
      setFormData((prevState) => ({
        ...prevState,
        donationMethods: selectedMethods,
      }))
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }))
    }
  }

  const onSubmit = (e) => {
    e.preventDefault()

    const charityData = {
      charityName: formData.charityName,
      registrationNumber: formData.registrationNumber,
      address: {
        addressLine1: formData.addressLine1,
        addressLine2: formData.addressLine2,
        city: formData.city,
        region: formData.region,
        country: formData.country,
        postcode: formData.postcode,
      },
      phone: formData.phone,
      email: formData.email,
      website: formData.website,
      contactPerson: {
        name: formData.contactPersonName,
        position: formData.contactPersonPosition,
        email: formData.contactPersonEmail,
        phone: formData.contactPersonPhone,
      },
      charityType: formData.charityType,
      charityCategory: formData.charityCategory,
      missionStatement: formData.missionStatement,
      donationMethods: formData.donationMethods,
      notes: formData.notes,
    }

    dispatch(registerCharity(charityData))
  }

  const containerVariants = {
    hidden: { opacity: 0 },
    show: { opacity: 1, transition: { staggerChildren: 0.1, delayChildren: 0.3 } },
  }

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    show: { y: 0, opacity: 1 },
  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <div className='min-h-screen bg-[#1a1f2b]'>
      {/* Hero Section */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className='relative overflow-hidden bg-gradient-to-r from-[#ff6b00] to-[#ee0979] py-16 mb-8'>
        <div className='relative z-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
          <div className='text-center'>
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className='text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl'>
              Add New Charity
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              className='mt-6 text-xl text-gray-100'>
              Register a new charity in the system
            </motion.p>
          </div>
        </div>
        {/* Decorative blob */}
        <div className='absolute top-0 right-0 -translate-y-12 translate-x-12 transform opacity-20'>
          <svg width='400' height='400' viewBox='0 0 184 184' xmlns='http://www.w3.org/2000/svg'>
            <path
              fill='white'
              d='M92 0C92 0 92 0 92 0C142.8 0 184 41.2 184 92C184 142.8 142.8 184 92 184C41.2 184 0 142.8 0 92C0 41.2 41.2 0 92 0Z'
            />
          </svg>
        </div>
      </motion.div>

      <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pb-20'>
        <BackButton url='/charities' className='text-white hover:text-gray-300' />
        <Link to='/' className='ml-4 text-white hover:text-gray-300 underline'>
          Home
        </Link>

        <motion.form variants={containerVariants} initial='hidden' animate='show' onSubmit={onSubmit} className='space-y-8'>
          {/* Charity Information */}
          <motion.div variants={itemVariants} className='bg-[#252b38] rounded-2xl shadow-xl p-8 space-y-6 border border-gray-700'>
            <div className='flex items-center gap-3 text-2xl font-semibold text-white border-b border-gray-700 pb-4'>
              <FiHeart className='text-[#ff6b00]' />
              <h2>Charity Information</h2>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
              {/* Charity Name */}
              <div>
                <label className='block text-sm font-medium text-gray-200 mb-2'>Charity Name</label>
                <div className='relative'>
                  <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                    <FiHeart className='text-gray-400' />
                  </div>
                  <input
                    type='text'
                    name='charityName'
                    value={formData.charityName}
                    onChange={onChange}
                    required
                    className='w-full pl-10 px-4 py-3 bg-[#1a1f2b] border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:border-[#ff6b00] focus:ring-2 focus:ring-[#ff6b00] focus:ring-opacity-20 transition-colors'
                    placeholder='Enter charity name'
                  />
                </div>
              </div>
              {/* Registration Number */}
              <div>
                <label className='block text-sm font-medium text-gray-200 mb-2'>Registration Number</label>
                <div className='relative'>
                  <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                    <FiInfo className='text-gray-400' />
                  </div>
                  <input
                    type='text'
                    name='registrationNumber'
                    value={formData.registrationNumber}
                    onChange={onChange}
                    required
                    className='w-full pl-10 px-4 py-3 bg-[#1a1f2b] border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:border-[#ff6b00] focus:ring-2 focus:ring-[#ff6b00] focus:ring-opacity-20 transition-colors'
                    placeholder='Enter registration number'
                  />
                </div>
              </div>
            </div>
          </motion.div>

          {/* Address Information */}
          <motion.div variants={itemVariants} className='bg-[#252b38] rounded-2xl shadow-xl p-8 space-y-6 border border-gray-700'>
            <div className='flex items-center gap-3 text-2xl font-semibold text-white border-b border-gray-700 pb-4'>
              <FiMapPin className='text-[#ff6b00]' />
              <h2>Address Information</h2>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
              {/* Address Line 1 */}
              <div>
                <label className='block text-sm font-medium text-gray-200 mb-2'>Address Line 1</label>
                <div className='relative'>
                  <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                    <FiMapPin className='text-gray-400' />
                  </div>
                  <input
                    type='text'
                    name='addressLine1'
                    value={formData.addressLine1}
                    onChange={onChange}
                    required
                    className='w-full pl-10 px-4 py-3 bg-[#1a1f2b] border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:border-[#ff6b00] focus:ring-2 focus:ring-[#ff6b00] focus:ring-opacity-20 transition-colors'
                    placeholder='Enter address line 1'
                  />
                </div>
              </div>
              {/* Address Line 2 */}
              <div>
                <label className='block text-sm font-medium text-gray-200 mb-2'>Address Line 2</label>
                <div className='relative'>
                  <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                    <FiMapPin className='text-gray-400' />
                  </div>
                  <input
                    type='text'
                    name='addressLine2'
                    value={formData.addressLine2}
                    onChange={onChange}
                    className='w-full pl-10 px-4 py-3 bg-[#1a1f2b] border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:border-[#ff6b00] focus:ring-2 focus:ring-[#ff6b00] focus:ring-opacity-20 transition-colors'
                    placeholder='Enter address line 2'
                  />
                </div>
              </div>
              {/* City */}
              <div>
                <label className='block text-sm font-medium text-gray-200 mb-2'>City</label>
                <div className='relative'>
                  <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                    <FiGlobe className='text-gray-400' />
                  </div>
                  <input
                    type='text'
                    name='city'
                    value={formData.city}
                    onChange={onChange}
                    required
                    className='w-full pl-10 px-4 py-3 bg-[#1a1f2b] border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:border-[#ff6b00] focus:ring-2 focus:ring-[#ff6b00] focus:ring-opacity-20 transition-colors'
                    placeholder='Enter city'
                  />
                </div>
              </div>
              {/* Region */}
              <div>
                <label className='block text-sm font-medium text-gray-200 mb-2'>Region</label>
                <div className='relative'>
                  <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                    <FiMapPin className='text-gray-400' />
                  </div>
                  <input
                    type='text'
                    name='region'
                    value={formData.region}
                    onChange={onChange}
                    className='w-full pl-10 px-4 py-3 bg-[#1a1f2b] border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:border-[#ff6b00] focus:ring-2 focus:ring-[#ff6b00] focus:ring-opacity-20 transition-colors'
                    placeholder='Enter region'
                  />
                </div>
              </div>
              {/* Country */}
              <div>
                <label className='block text-sm font-medium text-gray-200 mb-2'>Country</label>
                <div className='relative'>
                  <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                    <FiGlobe className='text-gray-400' />
                  </div>
                  <input
                    type='text'
                    name='country'
                    value={formData.country}
                    onChange={onChange}
                    required
                    className='w-full pl-10 px-4 py-3 bg-[#1a1f2b] border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:border-[#ff6b00] focus:ring-2 focus:ring-[#ff6b00] focus:ring-opacity-20 transition-colors'
                    placeholder='Enter country'
                  />
                </div>
              </div>
              {/* Postcode */}
              <div>
                <label className='block text-sm font-medium text-gray-200 mb-2'>Postcode</label>
                <div className='relative'>
                  <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                    <FiMapPin className='text-gray-400' />
                  </div>
                  <input
                    type='text'
                    name='postcode'
                    value={formData.postcode}
                    onChange={onChange}
                    required
                    className='w-full pl-10 px-4 py-3 bg-[#1a1f2b] border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:border-[#ff6b00] focus:ring-2 focus:ring-[#ff6b00] focus:ring-opacity-20 transition-colors'
                    placeholder='Enter postcode'
                  />
                </div>
              </div>
            </div>
          </motion.div>

          {/* Contact Information */}
          <motion.div variants={itemVariants} className='bg-[#252b38] rounded-2xl shadow-xl p-8 space-y-6 border border-gray-700'>
            <div className='flex items-center gap-3 text-2xl font-semibold text-white border-b border-gray-700 pb-4'>
              <FiPhone className='text-[#ff6b00]' />
              <h2>Contact Information</h2>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
              {/* Phone Number */}
              <div>
                <label className='block text-sm font-medium text-gray-200 mb-2'>Phone Number</label>
                <div className='relative'>
                  <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                    <FiPhone className='text-gray-400' />
                  </div>
                  <input
                    type='text'
                    name='phone'
                    value={formData.phone}
                    onChange={onChange}
                    className='w-full pl-10 px-4 py-3 bg-[#1a1f2b] border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:border-[#ff6b00] focus:ring-2 focus:ring-[#ff6b00] focus:ring-opacity-20 transition-colors'
                    placeholder='Enter phone number'
                  />
                </div>
              </div>
              {/* Email Address */}
              <div>
                <label className='block text-sm font-medium text-gray-200 mb-2'>Email Address</label>
                <div className='relative'>
                  <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                    <FiMail className='text-gray-400' />
                  </div>
                  <input
                    type='email'
                    name='email'
                    value={formData.email}
                    onChange={onChange}
                    className='w-full pl-10 px-4 py-3 bg-[#1a1f2b] border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:border-[#ff6b00] focus:ring-2 focus:ring-[#ff6b00] focus:ring-opacity-20 transition-colors'
                    placeholder='Enter email address'
                  />
                </div>
              </div>
              {/* Website */}
              <div>
                <label className='block text-sm font-medium text-gray-200 mb-2'>Website</label>
                <div className='relative'>
                  <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                    <FiGlobe className='text-gray-400' />
                  </div>
                  <input
                    type='text'
                    name='website'
                    value={formData.website}
                    onChange={onChange}
                    className='w-full pl-10 px-4 py-3 bg-[#1a1f2b] border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:border-[#ff6b00] focus:ring-2 focus:ring-[#ff6b00] focus:ring-opacity-20 transition-colors'
                    placeholder='Enter website URL'
                  />
                </div>
              </div>
            </div>
          </motion.div>

          {/* Contact Person Details */}
          <motion.div variants={itemVariants} className='bg-[#252b38] rounded-2xl shadow-xl p-8 space-y-6 border border-gray-700'>
            <div className='flex items-center gap-3 text-2xl font-semibold text-white border-b border-gray-700 pb-4'>
              <FiUser className='text-[#ff6b00]' />
              <h2>Contact Person</h2>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
              {/* Contact Person Name */}
              <div>
                <label className='block text-sm font-medium text-gray-200 mb-2'>Name</label>
                <div className='relative'>
                  <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                    <FiUser className='text-gray-400' />
                  </div>
                  <input
                    type='text'
                    name='contactPersonName'
                    value={formData.contactPersonName}
                    onChange={onChange}
                    className='w-full pl-10 px-4 py-3 bg-[#1a1f2b] border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:border-[#ff6b00] focus:ring-2 focus:ring-[#ff6b00] focus:ring-opacity-20 transition-colors'
                    placeholder='Enter name'
                  />
                </div>
              </div>
              {/* Contact Person Position */}
              <div>
                <label className='block text-sm font-medium text-gray-200 mb-2'>Position</label>
                <div className='relative'>
                  <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                    <FiUser className='text-gray-400' />
                  </div>
                  <input
                    type='text'
                    name='contactPersonPosition'
                    value={formData.contactPersonPosition}
                    onChange={onChange}
                    className='w-full pl-10 px-4 py-3 bg-[#1a1f2b] border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:border-[#ff6b00] focus:ring-2 focus:ring-[#ff6b00] focus:ring-opacity-20 transition-colors'
                    placeholder='Enter position'
                  />
                </div>
              </div>
              {/* Contact Person Email */}
              <div>
                <label className='block text-sm font-medium text-gray-200 mb-2'>Email</label>
                <div className='relative'>
                  <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                    <FiMail className='text-gray-400' />
                  </div>
                  <input
                    type='email'
                    name='contactPersonEmail'
                    value={formData.contactPersonEmail}
                    onChange={onChange}
                    className='w-full pl-10 px-4 py-3 bg-[#1a1f2b] border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:border-[#ff6b00] focus:ring-2 focus:ring-[#ff6b00] focus:ring-opacity-20 transition-colors'
                    placeholder='Enter email'
                  />
                </div>
              </div>
              {/* Contact Person Phone */}
              <div>
                <label className='block text-sm font-medium text-gray-200 mb-2'>Phone</label>
                <div className='relative'>
                  <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                    <FiPhone className='text-gray-400' />
                  </div>
                  <input
                    type='text'
                    name='contactPersonPhone'
                    value={formData.contactPersonPhone}
                    onChange={onChange}
                    className='w-full pl-10 px-4 py-3 bg-[#1a1f2b] border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:border-[#ff6b00] focus:ring-2 focus:ring-[#ff6b00] focus:ring-opacity-20 transition-colors'
                    placeholder='Enter phone'
                  />
                </div>
              </div>
            </div>
          </motion.div>

          {/* Additional Information */}
          <motion.div variants={itemVariants} className='bg-[#252b38] rounded-2xl shadow-xl p-8 space-y-6 border border-gray-700'>
            <div className='flex items-center gap-3 text-2xl font-semibold text-white border-b border-gray-700 pb-4'>
              <FiInfo className='text-[#ff6b00]' />
              <h2>Additional Information</h2>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
              {/* Charity Type */}
              <div>
                <label className='block text-sm font-medium text-gray-200 mb-2'>Charity Type</label>
                <select
                  name='charityType'
                  value={formData.charityType}
                  onChange={onChange}
                  required
                  className='w-full px-4 py-3 bg-[#1a1f2b] border border-gray-600 rounded-lg text-white focus:border-[#ff6b00] focus:ring-2 focus:ring-[#ff6b00] focus:ring-opacity-20 transition-colors'>
                  <option value=''>Select Charity Type</option>
                  <option value='local'>Local</option>
                  <option value='national'>National</option>
                  <option value='international'>International</option>
                </select>
              </div>
              {/* Charity Category */}
              <div>
                <label className='block text-sm font-medium text-gray-200 mb-2'>Charity Category</label>
                <select
                  name='charityCategory'
                  value={formData.charityCategory}
                  onChange={onChange}
                  required
                  className='w-full px-4 py-3 bg-[#1a1f2b] border border-gray-600 rounded-lg text-white focus:border-[#ff6b00] focus:ring-2 focus:ring-[#ff6b00] focus:ring-opacity-20 transition-colors'>
                  <option value=''>Select Charity Category</option>
                  <option value='animals'>Animals</option>
                  <option value='children'>Children</option>
                  <option value='education'>Education</option>
                  <option value='environment'>Environment</option>
                  <option value='health'>Health</option>
                  <option value='humanitarian'>Humanitarian</option>
                  <option value='poverty'>Poverty</option>
                  <option value='other'>Other</option>
                </select>
              </div>
              {/* Mission Statement */}
              <div className='md:col-span-2'>
                <label className='block text-sm font-medium text-gray-200 mb-2'>Mission Statement</label>
                <textarea
                  name='missionStatement'
                  value={formData.missionStatement}
                  onChange={onChange}
                  rows={4}
                  required
                  className='w-full px-4 py-3 bg-[#1a1f2b] border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:border-[#ff6b00] focus:ring-2 focus:ring-[#ff6b00] focus:ring-opacity-20 transition-colors'
                  placeholder='Enter mission statement'
                />
              </div>
              {/* Donation Methods */}
              <div>
                <label className='block text-sm font-medium text-gray-200 mb-2'>Donation Methods</label>
                <select
                  name='donationMethods'
                  value={formData.donationMethods}
                  onChange={onChange}
                  multiple
                  required
                  className='w-full px-4 py-3 bg-[#1a1f2b] border border-gray-600 rounded-lg text-white focus:border-[#ff6b00] focus:ring-2 focus:ring-[#ff6b00] focus:ring-opacity-20 transition-colors'>
                  <option value='online'>Online</option>
                  <option value='bank_transfer'>Bank Transfer</option>
                  <option value='cheque'>Cheque</option>
                  <option value='cash'>Cash</option>
                </select>
                <p className='text-sm text-gray-500 mt-2'>Hold CTRL/CMD to select multiple</p>
              </div>
              {/* Notes */}
              <div>
                <label className='block text-sm font-medium text-gray-200 mb-2'>Notes</label>
                <textarea
                  name='notes'
                  value={formData.notes}
                  onChange={onChange}
                  rows={4}
                  className='w-full px-4 py-3 bg-[#1a1f2b] border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:border-[#ff6b00] focus:ring-2 focus:ring-[#ff6b00] focus:ring-opacity-20 transition-colors'
                  placeholder='Additional notes'
                />
              </div>
            </div>
          </motion.div>

          {/* Submit Button */}
          <motion.div variants={itemVariants} className='flex justify-end'>
            <button
              type='submit'
              className='px-8 py-4 bg-gradient-to-r from-primary to-primary-dark text-white font-semibold rounded-lg shadow-lg flex items-center gap-2 hover:opacity-90 transition-opacity'>
              <FiSave className='text-xl' />
              Save Charity
            </button>
          </motion.div>
        </motion.form>
      </div>
    </div>
  )
}

export default AddCharity
