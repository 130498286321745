import BackButton from '../components/BackButton'
import { useSelector, useDispatch } from 'react-redux'
import toast from 'react-hot-toast'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getMembers, reset } from '../features/members/membersSlice'
import { transferAgent, reset as agentsReset } from '../features/agents/agentsSlice'
import Pagination from '../components/Pagination'
import { useDebounce } from '../components/useDebounce'
import { motion } from 'framer-motion'
import { FiSearch, FiFilter, FiEye, FiEyeOff } from 'react-icons/fi'
import moment from 'moment'
import { TableSkeleton } from '../components/Skeleton'

const Members = () => {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const [isSearchFocused, setIsSearchFocused] = useState(false)

  const { members, isError, isSuccess, isLoading, message } = useSelector((state) => state.members)
  const {
    isSuccess: agentsIsSuccess,
    isLoading: agentsIsLoading,
    message: agentsMessage,
    isError: agentsIsError,
  } = useSelector((state) => state.agents)

  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  const columnConfig = [
    { id: 'name', label: 'Name', isVisible: true },
    { id: 'createdAt', label: 'Member Since', isVisible: true },
    { id: 'introducedBy', label: 'Introduced By', isVisible: true },
    { id: 'tedsBalance', label: 'TEDS Balance', isVisible: true },
    { id: 'madTedsBalance', label: 'MADS Balance', isVisible: true },
    { id: 'transactionCount', label: 'Transaction Count', isVisible: true },
    { id: 'totalCustomersIntroduced', label: 'Customers Introduced', isVisible: true },
    { id: 'totalPartnersIntroduced', label: 'Partners Introduced', isVisible: true },
  ]

  const [columns, setColumns] = useState(columnConfig)

  const handleColumnVisibilityChange = (columnId) => {
    setColumns(columns.map((col) => (col.id === columnId ? { ...col, isVisible: !col.isVisible } : col)))
  }

  const columnCategories = {
    memberInfo: ['name', 'createdAt'],
    referralInfo: ['introducedBy', 'totalCustomersIntroduced', 'totalPartnersIntroduced'],
    teds: ['tedsBalance', 'madTedsBalance', 'transactionCount'],
  }

  const [categoryVisibility, setCategoryVisibility] = useState({
    memberInfo: true,
    referralInfo: true,
    teds: true,
  })

  const handleCategoryVisibilityChange = (category) => {
    const newVisibility = !categoryVisibility[category]
    setCategoryVisibility({ ...categoryVisibility, [category]: newVisibility })
    setColumns(columns.map((col) => (columnCategories[category].includes(col.id) ? { ...col, isVisible: newVisibility } : col)))
  }

  useEffect(() => {
    if (isError || agentsIsError) {
      console.log(message)
      toast.error(message)
      dispatch(reset())
    } else if (isSuccess) {
      dispatch(reset())
    }
  }, [isSuccess, dispatch, isError, message, agentsIsSuccess, agentsMessage, agentsIsError, currentPage, debouncedSearchTerm])

  useEffect(() => {
    const filterData = {
      page: currentPage,
      limit: 15,
      searchTerm: debouncedSearchTerm,
    }
    dispatch(getMembers(filterData))
  }, [dispatch, currentPage, debouncedSearchTerm])

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
    setCurrentPage(1)
  }

  const tedsToPounds = (teds) => (teds / 10).toFixed(2)

  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3,
      },
    },
  }

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    show: { y: 0, opacity: 1 },
  }

  return (
    <div className='bg-gradient-to-b from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800'>
      {/* Hero Section */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className='relative overflow-hidden bg-gradient-to-r from-primary to-primary-dark py-16 mb-4'>
        <div className='relative z-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
          <div className='text-center'>
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className='text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl'>
              Member Management
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              className='mt-6 text-xl text-gray-100'>
              Manage and monitor all member activities
            </motion.p>
          </div>
        </div>

        {/* Decorative blob */}
        <div className='absolute top-0 right-0 -translate-y-12 translate-x-12 transform opacity-20'>
          <svg width='400' height='400' viewBox='0 0 184 184' xmlns='http://www.w3.org/2000/svg'>
            <path
              fill='white'
              d='M92 0C92 0 92 0 92 0C142.8 0 184 41.2 184 92C184 142.8 142.8 184 92 184C41.2 184 0 142.8 0 92C0 41.2 41.2 0 92 0Z'
            />
          </svg>
        </div>
      </motion.div>

      <div className='mx-auto max-w-8xl px-4 sm:px-6 lg:px-8'>
        <BackButton />

        {/* Column Visibility Controls */}
        <motion.div variants={containerVariants} initial='hidden' animate='show' className='bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6 mb-8'>
          <div className='flex items-center justify-between mb-4'>
            <h2 className='text-2xl font-bold text-gray-900 dark:text-white'>Column Visibility</h2>
            <button
              onClick={() => Object.keys(categoryVisibility).forEach((category) => handleCategoryVisibilityChange(category))}
              className='btn btn-ghost btn-sm'>
              <FiEye className='mr-2' /> Toggle All
            </button>
          </div>

          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'>
            {Object.entries(columnCategories).map(([category, columnIds]) => (
              <motion.div variants={itemVariants} key={category} className='bg-gray-50 dark:bg-gray-700 rounded-xl p-4'>
                <div className='flex items-center justify-between mb-4'>
                  <h3 className='font-semibold text-lg text-gray-900 dark:text-white capitalize'>{category.replace(/([A-Z])/g, ' $1').trim()}</h3>
                  <label className='cursor-pointer'>
                    <input
                      type='checkbox'
                      checked={categoryVisibility[category]}
                      onChange={() => handleCategoryVisibilityChange(category)}
                      className='checkbox checkbox-primary'
                    />
                  </label>
                </div>
                <div className='space-y-2'>
                  {columns
                    .filter((col) => columnIds.includes(col.id))
                    .map((col) => (
                      <label key={col.id} className='flex items-center justify-between p-2 hover:bg-gray-100 dark:hover:bg-gray-600 rounded'>
                        <span className='text-gray-700 dark:text-gray-300'>{col.label}</span>
                        <input
                          type='checkbox'
                          checked={col.isVisible}
                          onChange={() => handleColumnVisibilityChange(col.id)}
                          className='checkbox checkbox-sm checkbox-primary'
                        />
                      </label>
                    ))}
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>

        {/* Search and Controls */}
        <motion.div variants={containerVariants} initial='hidden' animate='show' className='bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6 mb-8'>
          <div className='flex flex-col md:flex-row items-center justify-between gap-4'>
            <div className='relative flex-grow'>
              <div
                className={`absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none 
                transition-all duration-200 ${isSearchFocused ? 'text-primary' : 'text-gray-400'}`}>
                <FiSearch />
              </div>
              <input
                type='text'
                placeholder='Search for a member by name or email'
                value={searchTerm}
                onChange={handleSearchChange}
                onFocus={() => setIsSearchFocused(true)}
                onBlur={() => setIsSearchFocused(false)}
                className='pl-10 w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent transition-all'
              />
            </div>
          </div>
        </motion.div>

        {/* Members Table */}
        <motion.div
          variants={containerVariants}
          initial='hidden'
          animate='show'
          className='bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6 mb-8 overflow-hidden'>
          <h2 className='text-2xl font-bold text-gray-900 dark:text-white mb-6'>Members List</h2>
          <div className='overflow-x-auto'>
            <table className='w-full table-auto'>
              <thead className='bg-gray-50 dark:bg-gray-700'>
                <tr>
                  {columns
                    .filter((col) => col.isVisible)
                    .map((col) => (
                      <th key={col.id} className='px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>
                        {col.label}
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200 dark:divide-gray-600'>
                {isLoading ? (
                  <tr>
                    <td colSpan={columns.filter((col) => col.isVisible).length} className='px-0 py-4'>
                      <div className='w-full px-2'>
                        <TableSkeleton columns={columns.filter((col) => col.isVisible).length} rows={10} />
                      </div>
                    </td>
                  </tr>
                ) : !Array.isArray(members.members) || members.members.length === 0 ? (
                  <tr>
                    <td colSpan={columns.filter((col) => col.isVisible).length} className='text-center py-8 text-gray-500 dark:text-gray-400'>
                      No members found.
                    </td>
                  </tr>
                ) : (
                  members.members.map((member, index) => (
                    <tr key={member._id} className='hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors'>
                      {columns
                        .filter((col) => col.isVisible)
                        .map((col) => {
                          let content
                          switch (col.id) {
                            case 'name':
                              content = (
                                <div className='px-6 py-4'>
                                  <Link
                                    to={`/member-overview/${member._id}/${encodeURIComponent(member.name)}`}
                                    className='hover:text-primary transition-colors'>
                                    <p className='font-bold text-gray-900 dark:text-white'>{member.name}</p>
                                    <p className='text-gray-500 dark:text-gray-400 text-xs'>{member.email}</p>
                                  </Link>
                                </div>
                              )
                              break
                            case 'createdAt':
                              content = (
                                <div className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400'>
                                  {moment(member.createdAt).format('DD/MM/YYYY')}
                                </div>
                              )
                              break
                            case 'introducedBy':
                              content = member.introducedBy ? (
                                <div className='px-6 py-4'>
                                  {member.introducedBy.referralType === 'FriendReferral' ? (
                                    <Link
                                      to={`/member-overview/${member.introducedBy.referralId}/${encodeURIComponent(member.introducedBy.name)}`}
                                      className='hover:text-primary transition-colors'>
                                      <p className='font-bold text-gray-900 dark:text-white'>{member.introducedBy.name}</p>
                                      <p className='text-gray-500 dark:text-gray-400 text-xs'>{member.introducedBy.referralType}</p>
                                    </Link>
                                  ) : member.introducedBy.referralType === 'PartnerIntroduction' ? (
                                    <Link to={`/partner-overview/${member.introducedBy.referralId}`} className='hover:text-primary transition-colors'>
                                      <p className='font-bold text-gray-900 dark:text-white'>{member.introducedBy.name || 'N/A'}</p>
                                      <p className='text-gray-500 dark:text-gray-400 text-xs'>{member.introducedBy.referralType}</p>
                                    </Link>
                                  ) : member.introducedBy.referralType === 'MarketingCampaign' ? (
                                    <div>
                                      <p className='font-bold text-gray-900 dark:text-white'>{member.introducedBy.name || 'Marketing Campaign'}</p>
                                      {member.utmData && (
                                        <div className='text-xs text-gray-500 dark:text-gray-400'>
                                          <p>Source: {member.utmData.source || 'N/A'}</p>
                                          <p>Medium: {member.utmData.medium || 'N/A'}</p>
                                          <p>Campaign: {member.utmData.campaign || 'N/A'}</p>
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    <p className='text-gray-600 dark:text-gray-400'>{member.introducedBy.name || 'N/A'}</p>
                                  )}
                                </div>
                              ) : (
                                <div className='px-6 py-4 text-gray-600 dark:text-gray-400'>N/A</div>
                              )
                              break
                            case 'tedsBalance':
                            case 'madTedsBalance':
                              const balance = col.id === 'tedsBalance' ? member?.wallet?.tedsBalance : member?.wallet?.madTedsBalance
                              content = (
                                <div className='px-6 py-4'>
                                  <div className='text-sm text-gray-900 dark:text-white font-medium'>{balance || 0}</div>
                                  <div className='text-xs text-gray-500 dark:text-gray-400'>£{tedsToPounds(balance || 0)}</div>
                                </div>
                              )
                              break
                            case 'transactionCount':
                              content = (
                                <div className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400'>
                                  {member?.wallet?.transactionCount || 0}
                                </div>
                              )
                              break
                            case 'totalCustomersIntroduced':
                              content = (
                                <div className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400'>
                                  {member.keyMetrics?.totalCustomersIntroduced || 0}
                                </div>
                              )
                              break
                            case 'totalPartnersIntroduced':
                              content = (
                                <div className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400'>
                                  {member.keyMetrics?.totalPartnersIntroduced || 0}
                                </div>
                              )
                              break
                            default:
                              content = <div className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-400'>N/A</div>
                          }
                          return <td key={`${member._id}-${col.id}`}>{content}</td>
                        })}
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </motion.div>

        {/* Pagination */}
        <motion.div variants={containerVariants} initial='hidden' animate='show' className='flex justify-center mb-6'>
          <Pagination totalPages={members.totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
        </motion.div>
      </div>
    </div>
  )
}

export default Members
