import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import BackButton from '../components/BackButton'
import { Link } from 'react-router-dom'
import toast from 'react-hot-toast'
import Spinner from '../components/Spinner'
import { getSoldTEDSTransactions, acceptTEDSTransaction, reset } from '../features/teds/tedsSlice'
import Pagination from '../components/Pagination'
import { useDebounce } from '../components/useDebounce'
import { motion } from 'framer-motion'
import { FiSearch, FiFilter, FiClock, FiUser, FiDollarSign, FiActivity, FiCheck, FiGlobe } from 'react-icons/fi'

const SoldTEDSTransactions = () => {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const [pendingOnly, setPendingOnly] = useState(false)

  const {
    soldTEDSTransactions: { transactions, totalPages },
    isError,
    isSuccess: isSuccessTEDS,
    isLoading,
    message: messageTEDS,
  } = useSelector((state) => state.teds)

  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3,
      },
    },
  }

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    show: { y: 0, opacity: 1 },
  }

  useEffect(() => {
    if (isError) {
      toast.error(messageTEDS)
      dispatch(reset())
    }
    if (isSuccessTEDS) {
      toast.success(messageTEDS)
      dispatch(reset())
    }
  }, [isSuccessTEDS, dispatch, isError, messageTEDS])

  useEffect(() => {
    const filterData = {
      page: currentPage,
      limit: 20,
      searchTerm: debouncedSearchTerm,
      pendingOnly: pendingOnly,
    }
    dispatch(getSoldTEDSTransactions(filterData))
  }, [dispatch, currentPage, debouncedSearchTerm, pendingOnly])

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
    setCurrentPage(1)
  }

  const handleAcceptTransaction = (transactionId) => {
    dispatch(acceptTEDSTransaction(transactionId))
  }

  const handlePendingOnlyChange = (event) => {
    setPendingOnly(event.target.checked)
    setCurrentPage(1)
  }

  const getCreatedAtDaysAgo = (createdAt) => {
    const currentDate = new Date()
    const createdAtDate = new Date(createdAt)
    const timeDiff = Math.abs(currentDate.getTime() - createdAtDate.getTime())
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24))
    return daysDiff
  }

  return (
    <div className='min-h-screen w-full bg-gradient-to-b from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800 pb-20'>
      {/* Hero Section */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className='w-full relative overflow-hidden bg-gradient-to-r from-purple-600 to-indigo-900 py-16 mb-8'>
        <div className='relative z-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
          <div className='text-center'>
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className='text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl'>
              Sold TEDS Transactions
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              className='mt-6 text-xl text-gray-100'>
              Manage and track sold TEDS transactions
            </motion.p>
          </div>
        </div>

        {/* Decorative blob */}
        <div className='absolute top-0 right-0 -translate-y-12 translate-x-12 transform opacity-20'>
          <svg width='400' height='400' viewBox='0 0 184 184' xmlns='http://www.w3.org/2000/svg'>
            <path
              fill='white'
              d='M92 0C92 0 92 0 92 0C142.8 0 184 41.2 184 92C184 142.8 142.8 184 92 184C41.2 184 0 142.8 0 92C0 41.2 41.2 0 92 0Z'
            />
          </svg>
        </div>
      </motion.div>

      <div className='mx-auto max-w-8xl px-4 sm:px-6 lg:px-8'>
        <BackButton className='text-white hover:text-gray-300 mb-6' />

        {/* Search and Filter Controls */}
        <motion.div
          variants={containerVariants}
          initial='hidden'
          animate='show'
          className='bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-6 mb-8 border border-gray-700'>
          <div className='flex flex-col md:flex-row items-center justify-between gap-4'>
            <div className='relative flex-1 w-full'>
              <FiSearch className='absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400' />
              <input
                type='text'
                placeholder='Search transactions'
                value={searchTerm}
                onChange={handleSearchChange}
                className='w-full pl-12 pr-4 py-3 bg-[#1a1f2b] border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:border-purple-500 focus:ring-2 focus:ring-purple-500 focus:ring-opacity-20 transition-colors'
              />
            </div>
            <div className='flex items-center gap-2'>
              <FiFilter className='text-gray-400' />
              <label className='flex items-center gap-3 text-gray-200'>
                <input type='checkbox' checked={pendingOnly} onChange={handlePendingOnlyChange} className='checkbox checkbox-primary' />
                <span>Show pending only</span>
              </label>
            </div>
          </div>
        </motion.div>

        {/* Transactions Table */}
        <motion.div
          variants={containerVariants}
          initial='hidden'
          animate='show'
          className='bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-6 border border-gray-700'>
          {isLoading ? (
            <div className='flex justify-center py-8'>
              <Spinner />
            </div>
          ) : !Array.isArray(transactions) || transactions.length === 0 ? (
            <div className='text-center py-8 text-gray-400 text-lg'>No Sold TEDS Transactions found.</div>
          ) : (
            <div className='overflow-x-auto'>
              <table className='w-full'>
                <thead className='bg-[#1a1f2b]'>
                  <tr>
                    <th className='px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>
                      <div className='flex items-center gap-2'>
                        <FiUser className='text-purple-500' />
                        From User
                      </div>
                    </th>
                    <th className='px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>TEDS #</th>
                    <th className='px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>MADS #</th>
                    <th className='px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>
                      <div className='flex items-center gap-2'>
                        <FiClock className='text-purple-500' />
                        Created At
                      </div>
                    </th>
                    <th className='px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Status</th>
                    <th className='px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>
                      <div className='flex items-center gap-2'>
                        <FiGlobe className='text-purple-500' />
                        Currency
                      </div>
                    </th>
                    <th className='px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>
                      <div className='flex items-center gap-2'>
                        <FiDollarSign className='text-purple-500' />
                        Value
                      </div>
                    </th>
                    <th className='px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Action</th>
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-700'>
                  {transactions.map((transaction) => (
                    <tr key={transaction._id} className='bg-[#252b38] hover:bg-[#2a3040] transition-colors'>
                      <td className='px-6 py-4'>
                        <Link
                          to={`/partner-overview/${transaction.fromUser.userId}`}
                          className='text-purple-500 hover:text-purple-400 transition-colors font-medium'>
                          {transaction.fromUser?.userName || ''}
                        </Link>
                      </td>
                      <td className='px-6 py-4 text-sm text-gray-200'>{transaction.tedsAmount}</td>
                      <td className='px-6 py-4 text-sm text-gray-200'>{transaction.madTedsAmount}</td>
                      <td className='px-6 py-4'>
                        <div className='flex flex-col text-sm'>
                          <span className='text-gray-200'>
                            {new Date(transaction.createdAt).toLocaleString('en-US', {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                              hour: 'numeric',
                              minute: 'numeric',
                              second: 'numeric',
                              hour12: true,
                            })}
                          </span>
                          <span className='text-gray-400'>{getCreatedAtDaysAgo(transaction.createdAt)} days ago</span>
                        </div>
                      </td>
                      <td className='px-6 py-4'>
                        <span
                          className={`px-2 py-1 text-xs font-semibold rounded-full ${
                            transaction.status === 'Completed' ? 'bg-green-500/10 text-green-500' : 'bg-yellow-500/10 text-yellow-500'
                          }`}>
                          {transaction.status}
                        </span>
                      </td>
                      <td className='px-6 py-4 text-sm text-gray-200'>{transaction.currency}</td>
                      <td className='px-6 py-4 text-sm text-green-500'>
                        {transaction.currency === 'GBP' ? <>£{transaction.currencyValue.toFixed(2)}</> : transaction.currencyValue}
                      </td>
                      <td className='px-6 py-4'>
                        {transaction.status !== 'Completed' && (
                          <button
                            onClick={() => handleAcceptTransaction(transaction._id)}
                            className='inline-flex items-center px-3 py-1 rounded-md bg-purple-500 text-white hover:bg-purple-600 transition-colors'>
                            <FiCheck className='mr-2' /> Mark as Paid
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </motion.div>

        {/* Pagination */}
        {totalPages > 1 && (
          <motion.div variants={containerVariants} initial='hidden' animate='show' className='flex justify-center mt-8'>
            <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
          </motion.div>
        )}
      </div>
    </div>
  )
}

export default SoldTEDSTransactions
