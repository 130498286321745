import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import toast from 'react-hot-toast'
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'
import { registerInfluencer, reset } from '../features/agents/agentsSlice'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { motion } from 'framer-motion'
import { FiUser, FiMail, FiHome, FiMapPin, FiPhone, FiGlobe, FiInstagram, FiSave } from 'react-icons/fi'
import BackButton from '../components/BackButton'

const InfluencerRegistration = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    region: '',
    postcode: '',
    country: 'GB',
    addressLine1BusinessAddress: '',
    addressLine2BusinessAddress: '',
    cityBusinessAddress: '',
    regionBusinessAddress: '',
    postcodeBusinessAddress: '',
    countryBusinessAddress: '',
    landline: '',
    mobile: '',
    platform: 'Instagram',
    handle: '',
    followerCount: '',
    audienceDemographics: '',
    contentCategories: '',
  })

  const [formErrors, setFormErrors] = useState({})
  const [emailError, setEmailError] = useState('')
  const [selectedCountry, setSelectedCountry] = useState('GB')
  const [selectedRegion, setSelectedRegion] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)

  const { isLoading, isError, isSuccess, submissionSuccess, message } = useSelector((state) => state.agents)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    if (isError) {
      toast.error(message)
      dispatch(reset())
    }
    if (isSuccess && submissionSuccess) {
      toast.success(message || 'Influencer registered successfully')
      dispatch(reset())
      setFormData({
        name: '',
        email: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        region: '',
        postcode: '',
        country: 'GB',
        addressLine1BusinessAddress: '',
        addressLine2BusinessAddress: '',
        cityBusinessAddress: '',
        regionBusinessAddress: '',
        postcodeBusinessAddress: '',
        countryBusinessAddress: '',
        landline: '',
        mobile: '',
        platform: 'Instagram',
        handle: '',
        followerCount: '',
        audienceDemographics: '',
        contentCategories: '',
      })
      setSelectedCountry('GB')
      setSelectedRegion('')
      navigate('/influencers')
    }
  }, [isSuccess, submissionSuccess, dispatch, isError, message, navigate])

  // Clean up effect
  useEffect(() => {
    return () => {
      dispatch(reset())
      setIsSubmitted(false)
    }
  }, [dispatch])

  const validateForm = () => {
    const requiredFields = ['name', 'email', 'addressLine1', 'city', 'postcode', 'country', 'region', 'platform', 'followerCount']

    const errors = {}
    requiredFields.forEach((field) => {
      if (!formData[field]?.trim()) {
        errors[field] = 'This field is required'
      }
    })

    if (formData.followerCount && isNaN(formData.followerCount)) {
      errors.followerCount = 'Must be a valid number'
    }

    setFormErrors(errors)
    return Object.keys(errors).length === 0
  }

  const handleCountryChange = (val) => {
    setSelectedCountry(val)
    setFormData((prev) => ({ ...prev, country: val }))
  }

  const handleRegionChange = (val) => {
    setSelectedRegion(val)
    setFormData((prev) => ({ ...prev, region: val }))
  }

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  const onChange = (e) => {
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value

    if (e.target.name === 'email') {
      if (!validateEmail(e.target.value)) {
        setEmailError('Invalid email')
      } else {
        setEmailError('')
      }
    }

    if (e.target.name === 'followerCount') {
      value = value.replace(/\D/g, '')
    }

    setFormData((prev) => ({
      ...prev,
      [e.target.name]: value,
    }))
  }

  const onSubmit = (e) => {
    e.preventDefault()
    setIsSubmitted(true)
    if (!validateForm() || emailError) {
      return
    }

    const formattedData = {
      ...formData,
      followerCount: parseInt(formData.followerCount, 10) || 0,
    }

    dispatch(registerInfluencer(formattedData))
  }

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3,
      },
    },
  }

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    show: { y: 0, opacity: 1 },
  }

  return (
    <>
      <Helmet>
        <title>Register Influencer | Loyalty Club PLC</title>
      </Helmet>

      <div className='min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800'>
        {/* Hero Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className='relative overflow-hidden bg-gradient-to-r from-primary to-primary-dark py-16 mb-4'>
          <div className='relative z-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
            <div className='text-center'>
              <motion.h1
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
                className='text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl'>
                Influencer Registration
              </motion.h1>
              <motion.p
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4 }}
                className='mt-6 text-xl text-gray-100'>
                Join our team and become a Loyalty Club PLC Influencer
              </motion.p>
            </div>
          </div>

          {/* Decorative blob */}
          <div className='absolute top-0 right-0 -translate-y-12 translate-x-12 transform opacity-20'>
            <svg width='400' height='400' viewBox='0 0 184 184' xmlns='http://www.w3.org/2000/svg'>
              <path
                fill='white'
                d='M92 0C92 0 92 0 92 0C142.8 0 184 41.2 184 92C184 142.8 142.8 184 92 184C41.2 184 0 142.8 0 92C0 41.2 41.2 0 92 0Z'
              />
            </svg>
          </div>
        </motion.div>

        <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pb-20'>
          <div className='flex justify-start w-full'>
            <BackButton />
          </div>

          <motion.div variants={containerVariants} initial='hidden' animate='show'>
            <motion.div variants={itemVariants} className='bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8 mb-8'>
              <div className='flex items-center gap-3 text-2xl font-semibold text-gray-900 dark:text-white border-b border-gray-200 dark:border-gray-700 pb-4'>
                <FiInstagram className='text-primary' />
                <h2>Influencer Registration</h2>
              </div>

              <div className='mt-6 text-gray-700 dark:text-gray-300'>
                <p className='mb-4'>
                  Welcome to Loyalty Club PLC's Influencer Program! We're excited about the opportunity to partner with talented content creators like
                  you. Our platform connects influencers with premium brands, creating authentic partnerships that benefit everyone involved.
                </p>

                <h3 className='text-xl font-semibold mb-2 text-gray-900 dark:text-white'>Why Join Our Influencer Network?</h3>
                <ul className='list-disc list-inside mb-4 space-y-2'>
                  <li>
                    💰 <strong>Monetize Your Influence</strong>: Earn competitive rates for your collaborations.
                  </li>
                  <li>
                    🔍 <strong>Brand Selection</strong>: Work with brands that align with your personal style and values.
                  </li>
                  <li>
                    📊 <strong>Performance Analytics</strong>: Access detailed metrics about your campaign performance.
                  </li>
                  <li>
                    🤝 <strong>Support Team</strong>: Our dedicated team helps you navigate partnerships with ease.
                  </li>
                </ul>
              </div>
            </motion.div>

            <motion.form variants={containerVariants} onSubmit={onSubmit} className='space-y-8'>
              {/* Personal Information */}
              <motion.div variants={itemVariants} className='bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8 space-y-6'>
                <div className='flex items-center gap-3 text-2xl font-semibold text-gray-900 dark:text-white border-b border-gray-200 dark:border-gray-700 pb-4'>
                  <FiUser className='text-primary' />
                  <h2>Personal Information</h2>
                </div>

                <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
                  <div>
                    <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Full Name</label>
                    <div className='relative'>
                      <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                        <FiUser className='text-gray-400' />
                      </div>
                      <input
                        type='text'
                        name='name'
                        id='name'
                        value={formData.name}
                        onChange={onChange}
                        className='w-full pl-10 px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                        placeholder='Your full name'
                        required
                      />
                    </div>
                    <p className='text-red-500 text-sm mt-1'>{formErrors.name && formErrors.name}</p>
                  </div>

                  <div>
                    <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Email</label>
                    <div className='relative'>
                      <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                        <FiMail className='text-gray-400' />
                      </div>
                      <input
                        type='email'
                        name='email'
                        id='email'
                        value={formData.email}
                        onChange={onChange}
                        className='w-full pl-10 px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                        placeholder='your@email.com'
                        required
                      />
                    </div>
                    <p className='text-red-500 text-sm mt-1'>{emailError || (formErrors.email && formErrors.email)}</p>
                  </div>
                </div>
              </motion.div>

              {/* Home Address */}
              <motion.div variants={itemVariants} className='bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8 space-y-6'>
                <div className='flex items-center gap-3 text-2xl font-semibold text-gray-900 dark:text-white border-b border-gray-200 dark:border-gray-700 pb-4'>
                  <FiHome className='text-primary' />
                  <h2>Home Address</h2>
                </div>

                <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
                  <div>
                    <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Address Line 1</label>
                    <input
                      type='text'
                      name='addressLine1'
                      id='addressLine1'
                      value={formData.addressLine1}
                      onChange={onChange}
                      className='w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                      placeholder='Enter address line 1'
                      required
                    />
                    <p className='text-red-500 text-sm mt-1'>{formErrors.addressLine1 && formErrors.addressLine1}</p>
                  </div>

                  <div>
                    <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Address Line 2</label>
                    <input
                      type='text'
                      name='addressLine2'
                      id='addressLine2'
                      value={formData.addressLine2}
                      onChange={onChange}
                      className='w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                      placeholder='Enter address line 2'
                    />
                  </div>

                  <div>
                    <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>City</label>
                    <input
                      type='text'
                      name='city'
                      id='city'
                      value={formData.city}
                      onChange={onChange}
                      className='w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                      placeholder='Enter city'
                      required
                    />
                    <p className='text-red-500 text-sm mt-1'>{formErrors.city && formErrors.city}</p>
                  </div>

                  <div>
                    <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Country</label>
                    <CountryDropdown
                      value={formData.country}
                      onChange={handleCountryChange}
                      className='w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                      required
                    />
                    <p className='text-red-500 text-sm mt-1'>{formErrors.country && formErrors.country}</p>
                  </div>

                  <div>
                    <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Region/State</label>
                    <RegionDropdown
                      country={formData.country}
                      value={formData.region}
                      onChange={handleRegionChange}
                      className='w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                      required
                    />
                    <p className='text-red-500 text-sm mt-1'>{formErrors.region && formErrors.region}</p>
                  </div>

                  <div>
                    <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Postal Code</label>
                    <input
                      type='text'
                      name='postcode'
                      id='postcode'
                      value={formData.postcode}
                      onChange={onChange}
                      className='w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                      placeholder='Enter postal code'
                      required
                    />
                    <p className='text-red-500 text-sm mt-1'>{formErrors.postcode && formErrors.postcode}</p>
                  </div>
                </div>
              </motion.div>

              {/* Contact Information */}
              <motion.div variants={itemVariants} className='bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8 space-y-6'>
                <div className='flex items-center gap-3 text-2xl font-semibold text-gray-900 dark:text-white border-b border-gray-200 dark:border-gray-700 pb-4'>
                  <FiPhone className='text-primary' />
                  <h2>Contact Information</h2>
                </div>

                <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
                  <div>
                    <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Landline</label>
                    <div className='relative'>
                      <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                        <FiPhone className='text-gray-400' />
                      </div>
                      <input
                        type='text'
                        name='landline'
                        id='landline'
                        value={formData.landline}
                        onChange={onChange}
                        className='w-full pl-10 px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                        placeholder='Enter landline number'
                      />
                    </div>
                  </div>

                  <div>
                    <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Mobile</label>
                    <div className='relative'>
                      <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                        <FiPhone className='text-gray-400' />
                      </div>
                      <input
                        type='text'
                        name='mobile'
                        id='mobile'
                        value={formData.mobile}
                        onChange={onChange}
                        className='w-full pl-10 px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                        placeholder='Enter mobile number'
                      />
                    </div>
                  </div>
                </div>
              </motion.div>

              {/* Social Media Details */}
              <motion.div variants={itemVariants} className='bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8 space-y-6'>
                <div className='flex items-center gap-3 text-2xl font-semibold text-gray-900 dark:text-white border-b border-gray-200 dark:border-gray-700 pb-4'>
                  <FiInstagram className='text-primary' />
                  <h2>Social Media Details</h2>
                </div>

                <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
                  <div>
                    <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Platform</label>
                    <div className='relative'>
                      <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                        <FiGlobe className='text-gray-400' />
                      </div>
                      <select
                        name='platform'
                        id='platform'
                        value={formData.platform}
                        onChange={onChange}
                        className='w-full pl-10 px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                        required>
                        <option value='Instagram'>Instagram</option>
                        <option value='YouTube'>YouTube</option>
                        <option value='TikTok'>TikTok</option>
                        <option value='Twitter'>Twitter</option>
                        <option value='Facebook'>Facebook</option>
                      </select>
                    </div>
                    <p className='text-red-500 text-sm mt-1'>{formErrors.platform && formErrors.platform}</p>
                  </div>

                  <div>
                    <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Handle/Username</label>
                    <div className='relative'>
                      <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                        <FiUser className='text-gray-400' />
                      </div>
                      <input
                        type='text'
                        name='handle'
                        id='handle'
                        value={formData.handle}
                        onChange={onChange}
                        className='w-full pl-10 px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                        placeholder='@username'
                        required
                      />
                    </div>
                    <p className='text-red-500 text-sm mt-1'>{formErrors.handle && formErrors.handle}</p>
                  </div>

                  <div>
                    <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Follower Count</label>
                    <input
                      type='text'
                      name='followerCount'
                      id='followerCount'
                      value={formData.followerCount}
                      onChange={onChange}
                      className='w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                      placeholder='Number of followers'
                      required
                    />
                    <p className='text-red-500 text-sm mt-1'>{formErrors.followerCount && formErrors.followerCount}</p>
                  </div>

                  <div>
                    <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Audience Demographics</label>
                    <textarea
                      name='audienceDemographics'
                      id='audienceDemographics'
                      value={formData.audienceDemographics}
                      onChange={onChange}
                      className='w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors h-32'
                      placeholder='Describe audience age range, interests, etc.'></textarea>
                  </div>

                  <div className='col-span-1 md:col-span-2'>
                    <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Content Categories</label>
                    <textarea
                      name='contentCategories'
                      id='contentCategories'
                      value={formData.contentCategories}
                      onChange={onChange}
                      className='w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors h-32'
                      placeholder='List categories separated by commas (e.g., Fashion, Lifestyle, Travel)'></textarea>
                  </div>
                </div>
              </motion.div>

              <motion.div variants={itemVariants} className='bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8'>
                <div className='flex justify-end'>
                  <button
                    type='submit'
                    className='px-6 py-3 bg-primary hover:bg-primary-dark text-white font-medium rounded-lg transition-colors flex items-center gap-2'
                    disabled={isLoading}>
                    <FiSave className='w-5 h-5' />
                    {isLoading ? 'Submitting...' : 'Register Influencer'}
                  </button>
                </div>

                {formErrors && isSubmitted && Object.keys(formErrors).length > 0 && (
                  <motion.p variants={itemVariants} className='text-red-500 text-center mt-4'>
                    Please fix the errors above before submitting.
                  </motion.p>
                )}

                {isLoading && (
                  <motion.div variants={itemVariants} className='flex justify-center mt-4'>
                    <img src='/images/loadingSpinner.svg' alt='Loading...' className='w-8 h-8 mr-2' />
                    <span className='text-gray-600 dark:text-gray-400'>Saving your application...</span>
                  </motion.div>
                )}
              </motion.div>
            </motion.form>
          </motion.div>
        </div>
      </div>
    </>
  )
}

export default InfluencerRegistration
