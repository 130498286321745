import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    // Use a slight delay to ensure content is rendered before scrolling
    const timeoutId = setTimeout(() => {
      // Force scroll to top with smooth behavior
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'auto', // Use 'auto' instead of 'smooth' for more consistent behavior
      })
    }, 100)

    return () => clearTimeout(timeoutId)
  }, [pathname])

  return null // This component doesn't render anything
}

export default ScrollToTop
