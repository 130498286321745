import React, { useState, useEffect, useRef } from 'react'
import { FiBell, FiCheck, FiX, FiAlertCircle, FiInfo } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'
import { getUnreadNotifications, markNotificationAsRead } from '../features/notifications/notificationSlice'
import { motion, AnimatePresence } from 'framer-motion'
import { format, formatDistance } from 'date-fns'

function NotificationsDropdown() {
  const [isOpen, setIsOpen] = useState(false)
  const dispatch = useDispatch()
  const { notifications, loading } = useSelector((state) => state.notifications)
  const dropdownRef = useRef(null)

  useEffect(() => {
    dispatch(getUnreadNotifications())
  }, [dispatch])

  useEffect(() => {
    // Close dropdown when clicking outside
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [dropdownRef])

  const handleMarkAsRead = (id) => {
    dispatch(markNotificationAsRead(id))
  }

  const getNotificationIcon = (type = 'info') => {
    switch (type) {
      case 'warning':
        return <FiAlertCircle className='text-amber-500 w-5 h-5' />
      case 'error':
        return <FiX className='text-red-500 w-5 h-5' />
      case 'success':
        return <FiCheck className='text-green-500 w-5 h-5' />
      case 'info':
      default:
        return <FiInfo className='text-primary w-5 h-5' />
    }
  }

  // Animation variants
  const dropdownVariants = {
    hidden: { opacity: 0, y: -10, scale: 0.95 },
    visible: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        type: 'spring',
        stiffness: 400,
        damping: 30,
      },
    },
    exit: {
      opacity: 0,
      y: -10,
      scale: 0.95,
      transition: {
        duration: 0.2,
      },
    },
  }

  const bellRingVariants = {
    ringing: {
      rotate: [0, 15, -15, 12, -12, 8, -8, 0],
      transition: {
        duration: 1.5,
        repeat: Infinity,
        repeatDelay: 6,
      },
    },
  }

  return (
    <div className='relative ml-8' ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className='relative flex items-center justify-center w-10 h-10 rounded-full bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50'
        aria-label='Notifications'>
        <motion.div animate={notifications.length > 0 ? 'ringing' : ''} variants={bellRingVariants}>
          <FiBell className='w-5 h-5 text-gray-600 dark:text-gray-300' />
        </motion.div>

        {notifications.length > 0 && (
          <motion.span
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            className='absolute top-0 right-0 w-4 h-4 bg-primary text-white text-xs font-bold flex items-center justify-center rounded-full'>
            {notifications.length > 9 ? '9+' : notifications.length}
          </motion.span>
        )}
      </button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            className='absolute right-0 mt-2 w-80 origin-top-right bg-white dark:bg-gray-800 rounded-lg shadow-xl z-50 border border-gray-200 dark:border-gray-700 overflow-hidden'
            variants={dropdownVariants}
            initial='hidden'
            animate='visible'
            exit='exit'>
            <div className='p-3 border-b border-gray-200 dark:border-gray-700 flex justify-between items-center'>
              <h3 className='font-semibold text-gray-900 dark:text-white text-sm'>Notifications</h3>
              <div className='flex items-center space-x-1'>
                {notifications.length > 0 && (
                  <span className='px-1.5 py-0.5 bg-primary/10 text-primary text-xs font-medium rounded'>{notifications.length} new</span>
                )}
              </div>
            </div>

            <div className='max-h-[calc(100vh-200px)] overflow-y-auto'>
              {loading ? (
                <div className='flex justify-center items-center py-6'>
                  <div className='animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-primary'></div>
                </div>
              ) : notifications.length > 0 ? (
                <div className='divide-y divide-gray-200 dark:divide-gray-700'>
                  {notifications.map((notification) => (
                    <motion.div
                      key={notification._id}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      className='hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-colors'>
                      <button onClick={() => handleMarkAsRead(notification._id)} className='w-full text-left p-3 focus:outline-none'>
                        <div className='flex space-x-3'>
                          <div className='flex-shrink-0'>{getNotificationIcon(notification.type)}</div>
                          <div className='flex-1 min-w-0'>
                            <p className='text-sm text-gray-900 dark:text-white font-medium whitespace-normal break-words'>{notification.message}</p>
                            {notification.createdAt && (
                              <p className='text-xs text-gray-500 dark:text-gray-400 mt-1'>
                                {formatDistance(new Date(notification.createdAt), new Date(), { addSuffix: true })}
                              </p>
                            )}
                          </div>
                          <div className='flex-shrink-0 ml-auto'>
                            <div className='w-2 h-2 bg-primary rounded-full'></div>
                          </div>
                        </div>
                      </button>
                    </motion.div>
                  ))}
                </div>
              ) : (
                <div className='py-6 px-4 text-center'>
                  <div className='inline-flex items-center justify-center w-12 h-12 rounded-full bg-gray-100 dark:bg-gray-700 mb-3'>
                    <FiBell className='w-6 h-6 text-gray-500 dark:text-gray-400' />
                  </div>
                  <p className='text-gray-700 dark:text-gray-300 font-medium'>No New Notifications</p>
                  <p className='text-xs text-gray-500 dark:text-gray-400 mt-1'>You're all caught up!</p>
                </div>
              )}
            </div>

            {notifications.length > 0 && (
              <div className='p-2 border-t border-gray-200 dark:border-gray-700'>
                <button
                  className='w-full py-1.5 px-3 text-xs font-medium text-center text-primary hover:bg-primary/10 rounded-lg transition-colors'
                  onClick={() => notifications.forEach((n) => handleMarkAsRead(n._id))}>
                  Mark all as read
                </button>
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default NotificationsDropdown
