import { useState, useEffect } from 'react'
import toast from 'react-hot-toast'
import { useSelector, useDispatch } from 'react-redux'
import { login, reset } from '../features/auth/authSlice'
import { useNavigate, Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { FiLogIn, FiMail, FiLock, FiAlertCircle } from 'react-icons/fi'

function Login() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  })

  const { email, password } = formData

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { user, isLoading, isError, isSuccess, message } = useSelector((state) => state.auth)

  useEffect(() => {
    if (isError) {
      // Log the entire message object to see its structure
      console.log('Error message:', message)

      // Handle both string and object message formats
      const errorMessage = typeof message === 'string' ? message : message?.message || 'Invalid credentials'
      toast.error(errorMessage)
    }

    // Redirect when logged in
    if (isSuccess || user) {
      navigate('/')
    }

    dispatch(reset())
  }, [isError, isSuccess, user, message, navigate, dispatch])

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...formData,
      [e.target.name]: e.target.value,
    }))
  }

  const onSubmit = (e) => {
    e.preventDefault()

    const userData = {
      email,
      password,
    }

    dispatch(login(userData))
  }

  return (
    <div className='min-h-screen flex items-center justify-center bg-gradient-to-b from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800 px-4 sm:px-6'>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className='w-full max-w-5xl overflow-hidden rounded-2xl shadow-2xl'>
        <div className='flex flex-col md:flex-row'>
          {/* Left Column - Form */}
          <div className='w-full md:w-1/2 bg-white dark:bg-gray-800 p-8 md:p-12'>
            <div className='mb-8'>
              <motion.div initial={{ x: -20 }} animate={{ x: 0 }} transition={{ delay: 0.2, duration: 0.5 }}>
                <img src='/images/logo-dark.svg' alt='Our Loyalty Club' className='h-12 mb-8 mx-auto md:mx-0 dark:hidden' />
                <img src='/images/logo-dark.svg' alt='Our Loyalty Club' className='h-12 mb-8 mx-auto md:mx-0 hidden dark:block' />
              </motion.div>

              <motion.h2
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.3, duration: 0.5 }}
                className='text-3xl font-bold text-gray-900 dark:text-white'>
                Welcome back
              </motion.h2>

              <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.4, duration: 0.5 }}
                className='mt-2 text-gray-600 dark:text-gray-400'>
                Please sign in to your account
              </motion.p>
            </div>

            {isError && (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                className='mb-6 p-4 rounded-lg bg-red-50 dark:bg-red-900/30 text-red-700 dark:text-red-400 flex items-start'>
                <FiAlertCircle className='h-5 w-5 mr-3 flex-shrink-0 mt-0.5' />
                <p className='text-sm'>{typeof message === 'string' ? message : message?.message || 'Invalid credentials'}</p>
              </motion.div>
            )}

            <motion.form initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.5, duration: 0.5 }} onSubmit={onSubmit}>
              <div className='space-y-5'>
                <div>
                  <label htmlFor='email' className='block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1'>
                    Email Address
                  </label>
                  <div className='relative'>
                    <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
                      <FiMail className='h-5 w-5 text-gray-400' />
                    </div>
                    <input
                      type='email'
                      id='email'
                      name='email'
                      value={email}
                      onChange={onChange}
                      required
                      className='block w-full pl-10 pr-3 py-3 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-primary transition-colors'
                      placeholder='you@example.com'
                    />
                  </div>
                </div>

                <div>
                  <div className='flex items-center justify-between mb-1'>
                    <label htmlFor='password' className='block text-sm font-medium text-gray-700 dark:text-gray-300'>
                      Password
                    </label>
                    <Link to='/resetpassword' className='text-sm font-medium text-primary hover:text-primary-dark transition-colors'>
                      Forgot password?
                    </Link>
                  </div>
                  <div className='relative'>
                    <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
                      <FiLock className='h-5 w-5 text-gray-400' />
                    </div>
                    <input
                      type='password'
                      id='password'
                      name='password'
                      value={password}
                      onChange={onChange}
                      required
                      className='block w-full pl-10 pr-3 py-3 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-primary transition-colors'
                      placeholder='••••••••'
                    />
                  </div>
                </div>

                <motion.button
                  type='submit'
                  className='w-full flex justify-center items-center py-3 px-4 border border-transparent rounded-lg shadow-sm text-white bg-gradient-to-r from-primary to-primary-dark hover:from-primary-dark hover:to-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary transition-colors'
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  disabled={isLoading}>
                  {isLoading ? (
                    <svg className='animate-spin h-5 w-5 text-white' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'>
                      <circle className='opacity-25' cx='12' cy='12' r='10' stroke='currentColor' strokeWidth='4'></circle>
                      <path
                        className='opacity-75'
                        fill='currentColor'
                        d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'></path>
                    </svg>
                  ) : (
                    <>
                      <FiLogIn className='h-5 w-5 mr-2' />
                      Sign in
                    </>
                  )}
                </motion.button>
              </div>
            </motion.form>
          </div>

          {/* Right Column - Image */}
          <div className='hidden md:block w-1/2 relative'>
            <div className='absolute inset-0 bg-gradient-to-br from-primary to-primary-dark opacity-90'></div>
            <img src='images/treasure.png' alt='Loyalty rewards' className='h-full w-full object-cover' />
            <div className='absolute inset-0 flex flex-col justify-center p-12'>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.6, duration: 0.5 }}
                className='bg-white/10 backdrop-blur-sm rounded-xl p-6 shadow-lg'>
                <h3 className='text-2xl font-bold text-white mb-2'>Agent Portal</h3>
                <p className='text-white/90 mb-4'>
                  Access your dashboard to manage referrals, track commissions, and grow your business with Our Loyalty Club.
                </p>
                <div className='flex items-center space-x-2'>
                  <div className='h-8 w-8 rounded-full bg-white/20 flex items-center justify-center'>
                    <svg className='h-4 w-4 text-white' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                      <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z' />
                    </svg>
                  </div>
                  <p className='text-white text-sm'>Secure login protected</p>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  )
}

export default Login
