import { Link, useLocation } from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion'
import {
  FiHome,
  FiUsers,
  FiDollarSign,
  FiSettings,
  FiActivity,
  FiMenu,
  FiX,
  FiUserPlus,
  FiBriefcase,
  FiPieChart,
  FiCreditCard,
  FiTarget,
  FiHash,
  FiAward,
} from 'react-icons/fi'
import { FaDog } from 'react-icons/fa6'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

const SideNav = ({ isExpanded, onToggle }) => {
  const location = useLocation()
  const { user } = useSelector((state) => state.auth)
  const [hoveredItem, setHoveredItem] = useState(null)

  const handleToggle = () => {
    if (onToggle) {
      onToggle()
    }
  }

  // Base navigation items for admin users
  const adminNavItems = [
    { path: '/', icon: FiHome, label: 'Home' },
    { path: '/partners', icon: FiUsers, label: 'Partners' },
    { path: '/members', icon: FiUsers, label: 'Members' },
    { path: '/agents', icon: FiBriefcase, label: 'Agents' },
    { path: '/all-sales', icon: FiActivity, label: 'Sales' },
    { path: '/commissions', icon: FiDollarSign, label: 'Commissions' },
    { path: '/teds', icon: FaDog, label: 'TEDS' },
    { path: '/strategic-partners', icon: FiTarget, label: 'Strategic Partners' },
    { path: '/charities', icon: FiAward, label: 'Charities' },
  ]

  // Navigation items for basic agents
  const basicAgentNavItems = [
    { path: '/', icon: FiHome, label: 'Home' },
    { path: '/agent-overview', icon: FiPieChart, label: 'Overview' },
    { path: '/agent-sales', icon: FiActivity, label: 'Sales' },
    { path: '/my-referrals', icon: FiUsers, label: 'My Customers' },
    { path: '/partner-referrals', icon: FiUsers, label: 'Referred Partners' },
    { path: '/teds/wallet', icon: FiCreditCard, label: 'TEDS Wallet' },
    { path: '/commissions', icon: FiDollarSign, label: 'Commissions' },
    { path: '/settings', icon: FiSettings, label: 'Settings' },
  ]

  // Navigation items for country managers
  const countryManagerNavItems = [
    { path: '/', icon: FiHome, label: 'Home' },
    { path: '/agent-overview', icon: FiPieChart, label: 'Overview' },
    { path: '/all-sales', icon: FiActivity, label: 'All Sales' },
    { path: '/agents', icon: FiBriefcase, label: 'Manage Agents' },
    { path: '/partners', icon: FiUsers, label: 'Partners' },
    { path: '/new-agents', icon: FiUserPlus, label: 'New Agents' },
    { path: '/influencers', icon: FiHash, label: 'Influencers' },
    { path: '/teds/wallet', icon: FiCreditCard, label: 'TEDS Wallet' },
    { path: '/commissions', icon: FiDollarSign, label: 'Commissions' },
    { path: '/settings', icon: FiSettings, label: 'Settings' },
  ]

  // Navigation items for sales managers
  const salesManagerNavItems = [
    { path: '/', icon: FiHome, label: 'Home' },
    { path: '/agent-overview', icon: FiPieChart, label: 'Overview' },
    { path: '/agent-sales', icon: FiActivity, label: 'Sales' },
    { path: '/agents', icon: FiBriefcase, label: 'Manage Agents' },
    { path: '/partners', icon: FiUsers, label: 'Partners' },
    { path: '/influencers', icon: FiHash, label: 'Influencers' },
    { path: '/teds/wallet', icon: FiCreditCard, label: 'TEDS Wallet' },
    { path: '/commissions', icon: FiDollarSign, label: 'Commissions' },
    { path: '/settings', icon: FiSettings, label: 'Settings' },
  ]

  // Navigation items for campaign managers
  const campaignManagerNavItems = [
    { path: '/', icon: FiHome, label: 'Home' },
    { path: '/agent-overview', icon: FiPieChart, label: 'Overview' },
    { path: '/agent-sales', icon: FiActivity, label: 'Sales' },
    { path: '/agents', icon: FiBriefcase, label: 'Manage Agents' },
    { path: '/teds/wallet', icon: FiCreditCard, label: 'TEDS Wallet' },
    { path: '/commissions', icon: FiDollarSign, label: 'Commissions' },
    { path: '/settings', icon: FiSettings, label: 'Settings' },
  ]

  // Navigation items for influencers
  const influencerNavItems = [
    { path: '/', icon: FiHome, label: 'Home' },
    { path: '/agent-overview', icon: FiPieChart, label: 'Overview' },
    { path: '/agent-sales', icon: FiActivity, label: 'Sales' },
    { path: '/teds/wallet', icon: FiCreditCard, label: 'TEDS Wallet' },
    { path: '/commissions', icon: FiDollarSign, label: 'Commissions' },
    { path: '/settings', icon: FiSettings, label: 'Settings' },
  ]

  // Determine which navigation items to display based on user role and agent type
  let navItems = basicAgentNavItems // Default

  if (user?.role === 'AdminUser') {
    navItems = adminNavItems
  } else if (user?.role === 'AgentUser') {
    switch (user.agentType) {
      case 'basicAgent':
        navItems = basicAgentNavItems
        break
      case 'countryManager':
        navItems = countryManagerNavItems
        break
      case 'salesManager':
        navItems = salesManagerNavItems
        break
      case 'campaignManager':
        navItems = campaignManagerNavItems
        break
      case 'influencer':
        navItems = influencerNavItems
        break
      default:
        navItems = basicAgentNavItems
    }
  }

  // Animation variants for the tooltip
  const tooltipVariants = {
    hidden: { opacity: 0, x: -10 },
    visible: { opacity: 1, x: 0 },
  }

  return (
    <motion.nav
      initial={false}
      animate={{
        width: isExpanded ? '240px' : '72px',
        transition: { duration: 0.2 },
      }}
      className='sidenav fixed left-0 top-0 h-screen z-[1000] bg-black shadow-2xl overflow-hidden hidden md:block'>
      {/* Header/Logo Section - match header height */}
      <div className='h-[84px] flex items-center px-4 border-b border-gray-800'>
        {isExpanded ? (
          <div className='flex items-center w-full'>
            <button onClick={handleToggle} className='p-2 mr-4 rounded-full hover:bg-gray-800 transition-colors'>
              <FiMenu className='w-6 h-6 text-white' />
            </button>
            <FaDog className='w-8 h-8 text-white mr-2' />
            <span className='text-white font-bold text-xl'>TEDS</span>
          </div>
        ) : (
          <button onClick={handleToggle} className='p-2 rounded-full hover:bg-gray-800 transition-colors mx-auto'>
            <FiMenu className='w-6 h-6 text-white' />
          </button>
        )}
      </div>

      {/* Navigation Items */}
      <div className='py-2 px-1 overflow-y-auto h-[calc(100vh-57px)]'>
        {navItems.map((item, index) => {
          // Check if the current path starts with the item's path
          // This helps with highlighting parent menu items when on sub-pages
          const isActive = location.pathname === item.path || (item.path !== '/' && location.pathname.startsWith(item.path))

          return (
            <div
              key={item.path}
              className='relative'
              onMouseEnter={() => !isExpanded && setHoveredItem(index)}
              onMouseLeave={() => setHoveredItem(null)}>
              <Link to={item.path}>
                <motion.div
                  id={item.path}
                  className={`flex items-center p-3 mb-1 rounded-lg relative ${
                    isActive ? 'bg-gray-800 border-l-4 border-orange-500' : 'hover:bg-gray-800/50'
                  } transition-colors`}>
                  <item.icon className={`w-6 h-6 ${isActive ? 'text-orange-500' : 'text-gray-400'}`} />

                  {isExpanded && <span className={`ml-4 text-sm font-medium ${isActive ? 'text-white' : 'text-gray-300'}`}>{item.label}</span>}
                </motion.div>
              </Link>

              {/* Tooltip - only show when sidebar is collapsed and item is hovered */}
              {!isExpanded && (
                <AnimatePresence>
                  {hoveredItem === index && (
                    <motion.div
                      className='fixed left-[72px] ml-0 top-auto z-[9999]'
                      style={{ top: `${document.getElementById(item.path)?.getBoundingClientRect().top + 12}px` }}
                      variants={tooltipVariants}
                      initial='hidden'
                      animate='visible'
                      exit='hidden'
                      transition={{ duration: 0.2 }}>
                      {/* Tooltip triangle */}
                      <div className='absolute left-0 top-1/2 -translate-x-2 -translate-y-1/2 border-8 border-transparent border-r-gray-800'></div>

                      {/* Tooltip content */}
                      <div className='bg-gray-800 text-white px-4 py-2 rounded-md shadow-xl whitespace-nowrap border border-gray-700'>
                        <div className='flex items-center'>
                          <span className='font-medium'>{item.label}</span>
                        </div>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              )}
            </div>
          )
        })}
      </div>
    </motion.nav>
  )
}

export default SideNav
