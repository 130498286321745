// StrategicPartners.js
import BackButton from '../components/BackButton'
import { useSelector, useDispatch } from 'react-redux'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Spinner from '../components/Spinner'
import { getAllStrategicPartners, reset } from '../features/strategicPartner/strategicPartnerSlice'
import Pagination from '../components/Pagination'
import { useDebounce } from '../components/useDebounce'
import { motion } from 'framer-motion'
import { FiSearch, FiEdit2, FiEye, FiPlus } from 'react-icons/fi'

const StrategicPartners = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const [isSearchFocused, setIsSearchFocused] = useState(false)
  const {
    strategicPartners: { strategicPartners, totalPages },
    isError,
    isSuccess,
    isLoading,
    message,
  } = useSelector((state) => state.strategicPartners)
  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  useEffect(() => {
    if (isError) {
      toast.error(message)
      dispatch(reset())
    }
    if (isSuccess) {
      dispatch(reset())
    }
  }, [isSuccess, dispatch, isError, message])

  useEffect(() => {
    const filterData = {
      page: currentPage,
      limit: 15,
      searchTerm: debouncedSearchTerm,
    }
    dispatch(getAllStrategicPartners(filterData))
    return () => {
      dispatch(reset())
    }
  }, [dispatch, currentPage, debouncedSearchTerm])

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
    setCurrentPage(1)
  }

  const handleEditStrategicPartner = (partnerId) => {
    navigate(`/strategic-partners/${partnerId}/edit`)
  }

  const handleAddStrategicPartner = () => {
    navigate('/strategic-partners/add')
  }

  const handleViewStrategicPartner = (partnerId) => {
    navigate(`/strategic-partner/${partnerId}/view`)
  }

  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3,
      },
    },
  }

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    show: { y: 0, opacity: 1 },
  }

  return (
    <div className='min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800'>
      {/* Hero Section */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className='relative overflow-hidden bg-gradient-to-r from-primary to-primary-dark py-16 mb-4'>
        <div className='relative z-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
          <div className='text-center'>
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className='text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl'>
              Strategic Partners
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              className='mt-6 text-xl text-gray-100'>
              Manage and monitor strategic partnerships
            </motion.p>
          </div>
        </div>

        {/* Decorative blob */}
        <div className='absolute top-0 right-0 -translate-y-12 translate-x-12 transform opacity-20'>
          <svg width='400' height='400' viewBox='0 0 184 184' xmlns='http://www.w3.org/2000/svg'>
            <path
              fill='white'
              d='M92 0C92 0 92 0 92 0C142.8 0 184 41.2 184 92C184 142.8 142.8 184 92 184C41.2 184 0 142.8 0 92C0 41.2 41.2 0 92 0Z'
            />
          </svg>
        </div>
      </motion.div>

      <div className='mx-auto max-w-8xl px-4 sm:px-6 lg:px-8'>
        <BackButton />

        {/* Search and Add Controls */}
        <motion.div variants={containerVariants} initial='hidden' animate='show' className='bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6 mb-8'>
          <div className='flex flex-col md:flex-row items-center justify-between gap-4'>
            <div className='relative flex-grow'>
              <div
                className={`absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none 
                transition-all duration-200 ${isSearchFocused ? 'text-primary' : 'text-gray-400'}`}>
                <FiSearch />
              </div>
              <input
                type='text'
                placeholder='Search by company name, email, or partnership type'
                value={searchTerm}
                onChange={handleSearchChange}
                onFocus={() => setIsSearchFocused(true)}
                onBlur={() => setIsSearchFocused(false)}
                className='pl-10 w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent transition-all'
              />
            </div>
            <button
              onClick={handleAddStrategicPartner}
              className='inline-flex items-center px-4 py-3 rounded-lg bg-primary text-white hover:bg-primary-dark transition-colors shadow-sm'>
              <FiPlus className='mr-2' /> Add Strategic Partner
            </button>
          </div>
        </motion.div>

        {/* Partners Table */}
        <motion.div
          variants={containerVariants}
          initial='hidden'
          animate='show'
          className='bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6 mb-8 overflow-hidden'>
          <h2 className='text-2xl font-bold text-gray-900 dark:text-white mb-6'>Strategic Partners List</h2>
          <div className='overflow-x-auto'>
            <table className='w-full table-auto'>
              <thead className='bg-gray-50 dark:bg-gray-700/50'>
                <tr>
                  <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider rounded-tl-lg'>
                    Company Name
                  </th>
                  <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>Contact Email</th>
                  <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>Contact Phone</th>
                  <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider rounded-tr-lg'>
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200 dark:divide-gray-700'>
                {isLoading ? (
                  <tr>
                    <td colSpan='4' className='text-center py-8'>
                      <Spinner />
                    </td>
                  </tr>
                ) : !Array.isArray(strategicPartners) || strategicPartners.length === 0 ? (
                  <tr>
                    <td colSpan='4' className='text-center py-8 text-gray-500 dark:text-gray-400'>
                      No strategic partners found.
                    </td>
                  </tr>
                ) : (
                  strategicPartners.map((partner, index) => (
                    <tr
                      key={partner._id}
                      className={`${
                        index % 2 === 0 ? 'bg-white dark:bg-gray-800' : 'bg-gray-50 dark:bg-gray-800/50'
                      } hover:bg-gray-100 dark:hover:bg-gray-700/50 transition-colors`}>
                      <td className='px-6 py-4 font-medium text-gray-900 dark:text-white'>{partner.companyName}</td>
                      <td className='px-6 py-4 text-gray-600 dark:text-gray-300'>{partner.contactPerson.email}</td>
                      <td className='px-6 py-4 text-gray-600 dark:text-gray-300'>{partner.contactPerson.phone}</td>
                      <td className='px-6 py-4 space-x-2'>
                        <button
                          onClick={() => handleViewStrategicPartner(partner._id)}
                          className='inline-flex items-center px-3 py-1 rounded-md bg-blue-50 dark:bg-blue-900/20 text-blue-600 dark:text-blue-400 hover:bg-blue-100 dark:hover:bg-blue-900/40 transition-colors'>
                          <FiEye className='mr-2' /> View
                        </button>
                        <button
                          onClick={() => handleEditStrategicPartner(partner._id)}
                          className='inline-flex items-center px-3 py-1 rounded-md bg-amber-50 dark:bg-amber-900/20 text-amber-600 dark:text-amber-400 hover:bg-amber-100 dark:hover:bg-amber-900/40 transition-colors'>
                          <FiEdit2 className='mr-2' /> Edit
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </motion.div>

        {/* Pagination */}
        <motion.div variants={containerVariants} initial='hidden' animate='show' className='flex justify-center mb-20'>
          <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
        </motion.div>
      </div>
    </div>
  )
}

export default StrategicPartners
