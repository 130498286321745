import React, { useState, useEffect } from 'react'
import { FiSearch, FiUser, FiCalendar, FiAlertTriangle, FiClock } from 'react-icons/fi'
import { motion } from 'framer-motion'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { searchAMLRecordsByEmail, resetAmlSearchState } from '../features/auth/authSlice'

function AMLEmailSearch() {
  const [email, setEmail] = useState('')
  const [searched, setSearched] = useState(false)

  const dispatch = useDispatch()
  const { amlRecords, amlSearchLoading, amlSearchError, amlSearchSuccess } = useSelector((state) => state.auth)

  // Effect to handle search results and errors
  useEffect(() => {
    if (amlSearchSuccess) {
      toast.success('AML records retrieved successfully')
    }

    if (amlSearchError) {
      if (amlSearchError.includes('No AML records found')) {
        toast.info('No AML records found for this email')
      } else {
        toast.error(amlSearchError || 'Failed to search AML records')
      }
    }
  }, [amlSearchSuccess, amlSearchError])

  // Clean up on unmount
  useEffect(() => {
    return () => {
      dispatch(resetAmlSearchState())
    }
  }, [dispatch])

  const handleSearch = (e) => {
    e.preventDefault()
    if (!email) {
      toast.error('Please enter an email to search')
      return
    }

    setSearched(true)
    dispatch(searchAMLRecordsByEmail(email))
  }

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A'
    return new Date(dateString).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })
  }

  const itemVariants = {
    hidden: { opacity: 0, y: 10 },
    visible: { opacity: 1, y: 0 },
  }

  return (
    <motion.div initial='hidden' animate='visible' transition={{ staggerChildren: 0.1 }} className='w-full'>
      <motion.form
        variants={itemVariants}
        onSubmit={handleSearch}
        className='p-6 mb-8 bg-white rounded-xl border border-gray-100 shadow-md dark:bg-gray-700 dark:border-gray-600'>
        <div className='relative'>
          <div className='flex items-center mb-4'>
            <FiUser className='mr-2 text-orange-500' size={20} />
            <h3 className='text-lg font-medium text-white'>Find Deleted Account ID</h3>
          </div>
          <div className='flex flex-col gap-4 md:flex-row'>
            <div className='relative flex-1'>
              <div className='flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none'>
                <FiSearch className='text-gray-500' />
              </div>
              <input
                type='email'
                placeholder='Enter customer email address'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className='py-3 pr-4 pl-10 w-full text-gray-900 bg-white rounded-lg border border-gray-300 dark:border-gray-600 dark:bg-gray-800 dark:text-white focus:outline-none focus:ring-2 focus:ring-orange-500'
                required
              />
            </div>
            <button
              type='submit'
              disabled={amlSearchLoading}
              className='flex justify-center items-center px-6 py-3 font-medium text-white bg-orange-500 rounded-lg transition duration-200 ease-in-out hover:bg-orange-600'>
              {amlSearchLoading ? (
                <span className='inline-block mr-2 w-5 h-5 rounded-full border-2 border-white animate-spin border-t-transparent'></span>
              ) : (
                <FiSearch className='mr-2' />
              )}
              {amlSearchLoading ? 'Searching...' : 'Find Account ID'}
            </button>
          </div>
          <p className='mt-3 text-sm text-gray-600 dark:text-gray-300'>
            Enter the customer's email to retrieve their ID for viewing historical TEDS transactions
          </p>
        </div>
      </motion.form>

      {searched && (
        <motion.div
          variants={itemVariants}
          className='overflow-hidden bg-white rounded-xl border border-gray-100 shadow-md dark:bg-gray-700 dark:border-gray-600'>
          <div className='flex items-center p-4 border-b border-gray-100 md:p-6 dark:border-gray-600'>
            <FiClock className='mr-3 text-orange-500' size={20} />
            <h3 className='text-lg font-medium text-white'>Recovered Account Information</h3>
          </div>

          {amlRecords.length > 0 ? (
            <div className='overflow-x-auto'>
              <table className='w-full'>
                <thead className='bg-gray-50 dark:bg-gray-800'>
                  <tr>
                    <th className='px-6 py-4 text-xs font-semibold tracking-wider text-left text-gray-500 uppercase dark:text-gray-300'>
                      <div className='flex items-center'>
                        <FiUser className='mr-2' />
                        Customer ID
                      </div>
                    </th>
                    <th className='px-6 py-4 text-xs font-semibold tracking-wider text-left text-gray-500 uppercase dark:text-gray-300'>
                      <div className='flex items-center'>
                        <FiCalendar className='mr-2' />
                        Created Date
                      </div>
                    </th>
                    <th className='px-6 py-4 text-xs font-semibold tracking-wider text-left text-gray-500 uppercase dark:text-gray-300'>
                      <div className='flex items-center'>
                        <FiCalendar className='mr-2' />
                        Deletion Date
                      </div>
                    </th>
                    <th className='px-6 py-4 text-xs font-semibold tracking-wider text-left text-gray-500 uppercase dark:text-gray-300'>
                      <div className='flex items-center'>
                        <FiCalendar className='mr-2' />
                        Purging Date
                      </div>
                    </th>
                    <th className='px-6 py-4 text-xs font-semibold tracking-wider text-left text-gray-500 uppercase dark:text-gray-300'>
                      <div className='flex items-center'>
                        <FiAlertTriangle className='mr-2' />
                        Status
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody className='bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-700'>
                  {amlRecords.map((record) => (
                    <tr key={record.id} className='transition duration-150 hover:bg-gray-50 dark:hover:bg-gray-600'>
                      <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='flex items-center'>
                          <div className='text-sm font-medium text-orange-500'>{record.originalCustomerId}</div>
                        </div>
                      </td>
                      <td className='px-6 py-4 text-sm text-gray-700 whitespace-nowrap dark:text-gray-300'>{formatDate(record.createdAt)}</td>
                      <td className='px-6 py-4 text-sm text-gray-700 whitespace-nowrap dark:text-gray-300'>{formatDate(record.deletionDate)}</td>
                      <td className='px-6 py-4 text-sm text-gray-700 whitespace-nowrap dark:text-gray-300'>
                        {formatDate(record.scheduledPurgingDate)}
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap'>
                        {record.flaggedForReview ? (
                          <span className='inline-flex items-center px-3 py-1 text-xs font-semibold leading-5 text-amber-800 bg-amber-100 rounded-full dark:bg-amber-900 dark:text-amber-200'>
                            <FiAlertTriangle className='mr-1' /> Flagged for Review
                          </span>
                        ) : (
                          <span className='inline-flex items-center px-3 py-1 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full dark:bg-green-900 dark:text-green-200'>
                            Normal
                          </span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className='p-4 text-sm text-gray-500 bg-gray-50 border-t border-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400'>
                <p className='flex items-center'>
                  <FiUser className='mr-2' />
                  To view TEDS transactions for a deleted account, use the Customer ID from the table above
                </p>
              </div>
            </div>
          ) : (
            <div className='px-4 py-8 text-center'>
              {amlSearchLoading ? (
                <div className='flex flex-col items-center'>
                  <span className='inline-block mb-4 w-8 h-8 rounded-full border-4 border-orange-500 animate-spin border-t-transparent'></span>
                  <p className='text-gray-500 dark:text-gray-400'>Searching for account information...</p>
                </div>
              ) : (
                <div className='text-gray-500 dark:text-gray-400'>
                  <FiUser className='mx-auto mb-4' size={32} />
                  <p>No account records found for this email address.</p>
                </div>
              )}
            </div>
          )}
        </motion.div>
      )}
    </motion.div>
  )
}

export default AMLEmailSearch
