import { FaSignInAlt, FaBell } from 'react-icons/fa'
import { IconContext } from 'react-icons'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { logout, reset } from '../features/auth/authSlice'
import UserDropdown from './UserDropdown'
import NotificationsDropdown from './NotificationsDropdown'
import '../index.css'

function Header({ onMenuClick, isExpanded }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { user } = useSelector((state) => state.auth)

  const onLogout = () => {
    dispatch(logout())
    setTimeout(function () {
      navigate('/login')
    }, 200)
    console.log('successfully navigated away')
  }

  return (
    <div className='header fixed top-0 left-0 right-0 flex justify-between items-center h-[84px] z-[1001] bg-black px-4 shadow-md'>
      {/* Logo - hidden when sidebar is expanded */}
      <div className={`transition-opacity duration-200 ${isExpanded ? 'opacity-0 md:hidden' : 'opacity-100'} md:ml-20`}>
        <Link to='/' className='normal-case text-xl'>
          <img className={`w-56`} src='/images/logo-dark.svg' alt='Logo' />
        </Link>
      </div>

      {/* Right side elements */}
      <div className='flex flex-row mr-4 items-center ml-auto'>
        {/* Notifications Dropdown */}
        {user && user.role === 'AdminUser' && (
          <div className='mr-6'>
            <NotificationsDropdown />
          </div>
        )}
        {/* Login/Logout Buttons */}
        {user ? (
          <UserDropdown user={user} onLogout={onLogout} />
        ) : (
          <Link to='/login' className='btn btn-primary border-none md:px-4 flex-col justify-center md:flex-row flex items-center'>
            <IconContext.Provider value={{ className: 'md:pr-1 text-xl md:text-2xl' }}>
              <FaSignInAlt />
            </IconContext.Provider>
            <p className='text-[8px] md:text-sm'>Login</p>
          </Link>
        )}
      </div>
    </div>
  )
}

export default Header
