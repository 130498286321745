import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { FaRegHandshake, FaBriefcase, FaSearchDollar, FaRegAngry, FaSortAmountUp, FaPenNib, FaPenAlt, FaPen } from 'react-icons/fa'
import { BsPeopleFill } from 'react-icons/bs'
import { FaPuzzlePiece } from 'react-icons/fa'
import { FaHandHoldingHeart } from 'react-icons/fa'
import { authCheck } from '../features/auth/authSlice'
import { FaPenSquare } from 'react-icons/fa'
import { IoIosPeople } from 'react-icons/io'
import { MdOutlineQueryStats } from 'react-icons/md'
import { RiHandCoinLine } from 'react-icons/ri'
// import { GrTransaction } from 'react-icons/gr'
import { BsWallet2 } from 'react-icons/bs'
import { motion } from 'framer-motion'
import { FiArrowRight, FiTrendingUp, FiUsers, FiStar, FiSettings } from 'react-icons/fi'
import { RiMoneyDollarCircleLine } from 'react-icons/ri'
// import AMLEmailSearch from '../components/AMLEmailSearch'

function Home() {
  const navigate = useNavigate()
  const { user } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  // const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    dispatch(authCheck())
    // setIsLoaded(true)
  }, [dispatch])

  // Animation variants for staggered animations
  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3,
      },
    },
  }

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    show: { y: 0, opacity: 1 },
  }

  const itemsForAdmin = [
    { link: '/partners', title: 'Partners', Icon: FaRegHandshake },
    { link: '/members', title: 'Members', Icon: IoIosPeople },
    { link: '/agents', title: 'Agents', Icon: FaBriefcase },
    { link: '/all-sales', title: 'Sales', Icon: FaSearchDollar },
    { link: '/new-agents', title: 'New Agents', Icon: BsPeopleFill },
    { link: '/partner-referrals', title: 'Referred Partners', Icon: BsPeopleFill },
    { link: '/strategic-partners', title: 'Strategic Partners', Icon: FaPuzzlePiece },
    { link: '/charities', title: 'Charities', Icon: FaHandHoldingHeart },
    { link: '/failed-registrations', title: 'Failed Registrations', Icon: FaRegAngry },
    { link: '/other-businesses', title: 'Other Businesses', Icon: FaSortAmountUp },
    { link: '/teds', title: 'TEDS Currency Center', Icon: RiHandCoinLine },
    { link: '/influencers', title: 'Manage Influencers', Icon: FaPenSquare },
  ]

  const adminRegistrations = [
    {
      title: 'Agent Signup',
      description: 'Register a new agent',
      path: '/agent-sign-up',
      icon: FaPen,
    },
    {
      title: 'Country Manager Registration',
      description: 'Register new country manager',
      path: '/country-manager-registration',
      icon: FaPen,
    },
    {
      title: 'Sales Manager Registration',
      description: 'Register new sales manager',
      path: '/sales-manager-registration',
      icon: FaPenAlt,
    },
    {
      title: 'Campaign Manager Registration',
      description: 'Register new campaign managers',
      path: '/campaign-manager-registration',
      icon: FaPenNib,
    },
    {
      title: 'Register Influencer',
      description: 'Register new influencers',
      path: '/influencer-registration',
      icon: FaPenSquare,
    },
    {
      title: 'Register Charity',
      description: 'Register a new charity',
      path: '/charity/add',
      icon: FaHandHoldingHeart,
    },
    {
      title: 'Add Strategic Partner',
      description: 'Register a new strategic partner',
      path: '/strategic-partners/add',
      icon: FaPuzzlePiece,
    },
  ]

  const countryManagerRegistrations = [
    { link: '/agent-sign-up', title: 'Agent Signup', Icon: FaPen },
    { link: '/sales-manager-registration', title: 'Register Sales Manager', Icon: FaPenAlt },
    { link: '/campaign-manager-registration', title: 'Register Campaign Manager', Icon: FaPenNib },
    { link: '/influencer-registration', title: 'Register Influencer', Icon: FaPenSquare },
  ]

  const itemsForAgent = [
    // Common items for all agent types
    { link: '/agent-sales', title: 'Sales', Icon: FaSearchDollar },
    { link: '/agent-overview', title: 'Agent Overview', Icon: MdOutlineQueryStats },
    { link: '/teds/wallet', title: 'Wallet', Icon: BsWallet2 },
    // Remove Transactions item for agents
    // { link: '/teds/transactions', title: 'Transactions', Icon: GrTransaction },

    // Agent type specific items
    ...(user?.agentType === 'basicAgent'
      ? [
          { link: '/my-referrals', title: 'My Customers', Icon: FaRegHandshake },
          { link: '/partner-referrals', title: 'Referred Partners', Icon: BsPeopleFill },
          { link: '/agent-sign-up', title: 'Agent Signup', Icon: FaPenAlt },
        ]
      : []),
    ...(user?.agentType === 'influencer' ? [{ link: '/temporary', title: 'Influencer Dashboard', Icon: FaPenSquare }] : []),
    ...(['countryManager', 'salesManager'].includes(user?.agentType) ? [{ link: '/partners', title: 'Partners', Icon: FaRegHandshake }] : []),
  ]

  // Determine which items to display based on the user's role and agent type
  const items = user?.role === 'AdminUser' ? itemsForAdmin : itemsForAgent

  // Stats data
  const stats = [
    { label: 'Total Earnings', value: '£1,200,450.00', icon: RiMoneyDollarCircleLine, color: 'from-emerald-400 to-teal-500' },
    { label: 'Active Clients', value: '24', icon: FiUsers, color: 'from-blue-400 to-indigo-500' },
    { label: 'Success Rate', value: '94%', icon: FiTrendingUp, color: 'from-purple-400 to-pink-500' },
    { label: 'Client Rating', value: '4.9', icon: FiStar, color: 'from-amber-400 to-orange-500' },
  ]

  // Quick actions for different user types
  const quickActions = [
    {
      title: 'Sales',
      description: user?.agentType === 'countryManager' ? 'View all country sales' : 'View your sales activity',
      path: user?.agentType === 'countryManager' ? '/all-sales' : '/agent-sales',
      icon: FaSearchDollar,
    },
    {
      title: 'Agent Overview',
      description: 'Check your performance metrics',
      path: '/agent-overview',
      icon: MdOutlineQueryStats,
    },
    {
      title: 'Commissions',
      description: 'Track your commission earnings',
      path: '/commissions',
      icon: RiHandCoinLine,
    },
    {
      title: 'Wallet',
      description: 'Manage your TEDS balance',
      path: '/teds/wallet',
      icon: BsWallet2,
    },
    // Add Settings for all agent types
    {
      title: 'Settings',
      description: 'Manage your account settings',
      path: '/settings',
      icon: FiSettings,
    },
    // Only show these for basic agents
    ...(user?.agentType === 'basicAgent'
      ? [
          {
            title: 'My Customers',
            description: 'View and manage your customers',
            path: '/my-referrals',
            icon: FaRegHandshake,
          },
          {
            title: 'Referred Partners',
            description: 'Track your partner referrals',
            path: '/partner-referrals',
            icon: BsPeopleFill,
          },
          {
            title: 'Agent Signup',
            description: 'Register new agents',
            path: '/agent-sign-up',
            icon: FaPenAlt,
          },
        ]
      : []),
  ]

  // Convert itemsForAdmin to Quick Actions format
  const adminQuickActions = [
    {
      title: 'TEDS Currency Center',
      description: 'Manage TEDS currency',
      path: '/teds',
      icon: RiHandCoinLine,
    },
    {
      title: 'Partners',
      description: 'Manage all partners',
      path: '/partners',
      icon: FaRegHandshake,
    },
    {
      title: 'Members',
      description: 'Manage all members',
      path: '/members',
      icon: IoIosPeople,
    },
    {
      title: 'Agents',
      description: 'Manage all agents',
      path: '/agents',
      icon: FaBriefcase,
    },
    {
      title: 'Sales',
      description: 'View all sales activity',
      path: '/all-sales',
      icon: FaSearchDollar,
    },
    {
      title: 'Commissions',
      description: 'View all commission earnings',
      path: '/commissions',
      icon: RiHandCoinLine,
    },
    {
      title: 'New Agents',
      description: 'View new agent applications',
      path: '/new-agents',
      icon: BsPeopleFill,
    },
    {
      title: 'Referred Partners',
      description: 'View partner referrals',
      path: '/partner-referrals',
      icon: BsPeopleFill,
    },
    {
      title: 'Strategic Partners',
      description: 'Manage strategic partners',
      path: '/strategic-partners',
      icon: FaPuzzlePiece,
    },
    {
      title: 'Charities',
      description: 'Manage charity partners',
      path: '/charities',
      icon: FaHandHoldingHeart,
    },
    {
      title: 'Failed Registrations',
      description: 'View failed registrations',
      path: '/failed-registrations',
      icon: FaRegAngry,
    },
    {
      title: 'Other Businesses',
      description: 'Manage other businesses',
      path: '/other-businesses',
      icon: FaSortAmountUp,
    },
    {
      title: 'Manage Influencers',
      description: 'Manage all influencers',
      path: '/influencers',
      icon: FaPenSquare,
    },
    {
      title: 'AML Search',
      description: 'Search for AML records by email',
      path: '/aml-search',
      icon: FaPen,
    },
  ]

  // Use adminQuickActions for admin users, regular quickActions for agents
  const displayActions = user?.role === 'AdminUser' ? adminQuickActions : quickActions

  return (
    <div className='min-h-screen w-full bg-gradient-to-b from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800'>
      {/* Hero Section - Full width */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className='w-full relative overflow-hidden bg-gradient-to-r from-primary to-primary-dark py-16'>
        <div className='relative z-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
          <div className='text-center'>
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className='text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl'>
              Welcome back, {user?.name}
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              className='mt-6 text-xl text-gray-100'>
              Your dashboard is ready for today's success
            </motion.p>
          </div>
        </div>

        {/* Decorative blob */}
        <div className='absolute top-0 right-0 -translate-y-12 translate-x-12 transform opacity-20'>
          <svg width='400' height='400' viewBox='0 0 184 184' xmlns='http://www.w3.org/2000/svg'>
            <path
              fill='white'
              d='M92 0C92 0 92 0 92 0C142.8 0 184 41.2 184 92C184 142.8 142.8 184 92 184C41.2 184 0 142.8 0 92C0 41.2 41.2 0 92 0Z'
            />
          </svg>
        </div>
      </motion.div>

      {/* Content Container */}
      <div className='mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 -mt-12'>
        {/* Stats Grid */}
        <motion.div variants={containerVariants} initial='hidden' animate='show'>
          <div className='grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4'>
            {stats.map((stat, index) => (
              <motion.div
                key={stat.label}
                variants={itemVariants}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                className='relative overflow-hidden rounded-2xl bg-white dark:bg-gray-800 p-6 shadow-lg transition-shadow hover:shadow-xl'>
                <div className={`absolute inset-0 opacity-5 bg-gradient-to-br ${stat.color}`} />
                <div className='relative'>
                  <stat.icon className={`h-8 w-8 bg-gradient-to-r ${stat.color} rounded-lg p-1.5 text-white`} />
                  <p className='mt-4 text-2xl font-semibold text-gray-900 dark:text-white'>{stat.value}</p>
                  <p className='mt-2 text-sm font-medium text-gray-500 dark:text-gray-400'>{stat.label}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>

        {/* Quick Actions */}
        <motion.div variants={containerVariants} initial='hidden' animate='show' className='mt-12 mb-20'>
          <h2 className='text-2xl font-bold text-gray-900 dark:text-white mb-6'>{user?.role === 'AdminUser' ? 'Admin Actions' : 'Quick Actions'}</h2>
          <div className='grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4'>
            {displayActions.map((action, index) => (
              <motion.button
                key={action.title}
                variants={itemVariants}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={() => navigate(action.path)}
                className='group relative overflow-hidden rounded-2xl bg-white dark:bg-gray-800 p-6 shadow-lg transition-all hover:shadow-xl text-left'>
                <div className='flex items-start space-x-4'>
                  <action.icon className='h-8 w-8 text-primary' />
                  <div>
                    <h3 className='text-lg font-semibold text-gray-900 dark:text-white group-hover:text-primary transition-colors'>{action.title}</h3>
                    <p className='mt-2 text-sm text-gray-500 dark:text-gray-400'>{action.description}</p>
                  </div>
                  <FiArrowRight className='h-6 w-6 text-gray-400 group-hover:text-primary transition-colors ml-auto' />
                </div>
                <div className='absolute inset-0 bg-gradient-to-r from-primary/5 to-primary-dark/5 opacity-0 group-hover:opacity-100 transition-opacity' />
              </motion.button>
            ))}
          </div>
        </motion.div>

        {/* Admin Sections */}
        {user?.role === 'AdminUser' && (
          <div className='space-y-8 mb-8'>
            <div>
              <h2 className='text-2xl font-bold mb-6 text-gray-900 dark:text-white'>Registration Management</h2>
              <div className='grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4'>
                {adminRegistrations.map((action, index) => (
                  <motion.button
                    key={action.title}
                    variants={itemVariants}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={() => navigate(action.path)}
                    className='group relative overflow-hidden rounded-2xl bg-white dark:bg-gray-800 p-6 shadow-lg transition-all hover:shadow-xl text-left'>
                    <div className='flex items-start space-x-4'>
                      <action.icon className='h-8 w-8 text-primary' />
                      <div>
                        <h3 className='text-lg font-semibold text-gray-900 dark:text-white group-hover:text-primary transition-colors'>
                          {action.title}
                        </h3>
                        <p className='mt-2 text-sm text-gray-500 dark:text-gray-400'>{action.description}</p>
                      </div>
                      <FiArrowRight className='h-6 w-6 text-gray-400 group-hover:text-primary transition-colors ml-auto' />
                    </div>
                    <div className='absolute inset-0 bg-gradient-to-r from-primary/5 to-primary-dark/5 opacity-0 group-hover:opacity-100 transition-opacity' />
                  </motion.button>
                ))}
              </div>
            </div>
          </div>
        )}

        {/* Country Manager Sections */}
        {user?.role === 'AgentUser' && ['countryManager'].includes(user?.agentType) && (
          <div className='space-y-8 mb-8'>
            <div>
              <h2 className='text-2xl font-bold mb-6 text-gray-900 dark:text-white'>My Agents</h2>
              <div className='grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3'>
                {[
                  { link: '/agents', title: 'Manage Agents', Icon: FaBriefcase, description: 'View and manage your agents' },
                  { link: '/new-agents', title: 'New Agents', Icon: BsPeopleFill, description: 'Register new agents to your network' },
                  { link: '/influencers', title: 'Manage Influencers', Icon: FaPenSquare, description: 'View and manage your influencers' },
                ].map((item) => (
                  <motion.button
                    key={item.title}
                    variants={itemVariants}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={() => navigate(item.link)}
                    className='group relative overflow-hidden rounded-2xl bg-white dark:bg-gray-800 p-6 shadow-lg transition-all hover:shadow-xl text-left'>
                    <div className='flex items-start space-x-4'>
                      <item.Icon className='h-8 w-8 text-primary' />
                      <div>
                        <h3 className='text-lg font-semibold text-gray-900 dark:text-white group-hover:text-primary transition-colors'>
                          {item.title}
                        </h3>
                        <p className='mt-2 text-sm text-gray-500 dark:text-gray-400'>{item.description}</p>
                      </div>
                      <FiArrowRight className='h-6 w-6 text-gray-400 group-hover:text-primary transition-colors ml-auto' />
                    </div>
                    <div className='absolute inset-0 bg-gradient-to-r from-primary/5 to-primary-dark/5 opacity-0 group-hover:opacity-100 transition-opacity' />
                  </motion.button>
                ))}
              </div>
            </div>
          </div>
        )}

        {/* Manager Registration Section */}
        {user?.agentType === 'countryManager' && (
          <div className='space-y-8 mb-8'>
            <div>
              <h2 className='text-2xl font-bold mb-6 text-gray-900 dark:text-white'>Manager Registrations</h2>
              <div className='grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4'>
                {countryManagerRegistrations.map((item) => (
                  <motion.button
                    key={item.title}
                    variants={itemVariants}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={() => navigate(item.link)}
                    className='group relative overflow-hidden rounded-2xl bg-white dark:bg-gray-800 p-6 shadow-lg transition-all hover:shadow-xl text-left'>
                    <div className='flex items-start space-x-4'>
                      <item.Icon className='h-8 w-8 text-primary' />
                      <div>
                        <h3 className='text-lg font-semibold text-gray-900 dark:text-white group-hover:text-primary transition-colors'>
                          {item.title}
                        </h3>
                        <p className='mt-2 text-sm text-gray-500 dark:text-gray-400'>{item.description || 'Register new users to the system'}</p>
                      </div>
                      <FiArrowRight className='h-6 w-6 text-gray-400 group-hover:text-primary transition-colors ml-auto' />
                    </div>
                    <div className='absolute inset-0 bg-gradient-to-r from-primary/5 to-primary-dark/5 opacity-0 group-hover:opacity-100 transition-opacity' />
                  </motion.button>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Home
