import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import toast from 'react-hot-toast'
import { format, subDays, subMonths, parseISO, startOfMonth, startOfYear } from 'date-fns'
import { FaCoins, FaChartLine, FaCalendarAlt } from 'react-icons/fa'
import { RiHandCoinLine } from 'react-icons/ri'
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import { fetchCommissions } from '../features/agents/agentsSlice'
import { motion } from 'framer-motion'
import { FiDollarSign, FiArrowUp, FiArrowDown, FiCalendar, FiPieChart, FiBarChart2, FiTrendingUp } from 'react-icons/fi'

function CommissionEarnings() {
  const { user } = useSelector((state) => state.auth)
  const { commissionsData, isLoading, isError, message } = useSelector((state) => state.agents)
  const [timeframe, setTimeframe] = useState('monthly')
  const [loadingTimeframe, setLoadingTimeframe] = useState(null)
  const dispatch = useDispatch()

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.2,
      },
    },
  }

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    show: { y: 0, opacity: 1 },
  }

  const [dateRange, setDateRange] = useState({
    start: format(subMonths(new Date(), 1), 'yyyy-MM-dd'),
    end: format(new Date(), 'yyyy-MM-dd'),
  })

  useEffect(() => {
    // Fetch commission data when timeframe changes
    setLoadingTimeframe(timeframe)
    dispatch(fetchCommissions(timeframe))
      .unwrap()
      .then(() => {
        setLoadingTimeframe(null)
      })
      .catch((error) => {
        setLoadingTimeframe(null)
        toast.error(`Failed to load commission data: ${error}`)
      })
  }, [timeframe, dispatch])

  const handleTimeframeChange = (newTimeframe) => {
    if (newTimeframe === timeframe) return // Prevent re-fetching if timeframe hasn't changed

    setTimeframe(newTimeframe)

    // Update date range based on selected timeframe
    const today = new Date()
    let start

    switch (newTimeframe) {
      case 'daily':
        start = subDays(today, 1)
        break
      case 'weekly':
        start = subDays(today, 7)
        break
      case 'monthly':
        start = subMonths(today, 1)
        break
      case 'yearly':
        start = subMonths(today, 12)
        break
      default:
        start = subMonths(today, 1)
    }

    setDateRange({
      start: format(start, 'yyyy-MM-dd'),
      end: format(today, 'yyyy-MM-dd'),
    })
  }

  const formatCurrency = (value) => {
    // Format TEDS value
    const tedsValue = value || 0
    const formattedTeds = tedsValue.toFixed(2)

    // Format GBP value (1 TED = 1 pence)
    const poundsValue = tedsValue / 100
    const formattedPounds = new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(poundsValue)

    return `${formattedTeds} TEDS (${formattedPounds})`
  }

  // Get percentage change for display
  const getPercentageChange = () => {
    if (!commissionsData) return { value: 0, isPositive: true }

    const current = commissionsData.currentPeriod || 0
    const previous = commissionsData.previousPeriod || 0

    if (previous === 0) return { value: current > 0 ? 100 : 0, isPositive: current >= 0 }

    const change = ((current - previous) / previous) * 100
    return {
      value: Math.abs(change).toFixed(1),
      isPositive: change >= 0,
    }
  }

  // Initial loading state - when no data is available yet
  if (isLoading && !commissionsData) {
    return (
      <div className='flex justify-center items-center h-64'>
        <div className='flex flex-col items-center'>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className='w-16 h-16 border-4 border-primary border-t-transparent rounded-full animate-spin'
          />
          <motion.p initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.3 } }} className='mt-4 text-gray-500 dark:text-gray-400'>
            Loading commission data...
          </motion.p>
        </div>
      </div>
    )
  }

  // If there's no data yet, show a message
  if (!commissionsData) {
    return (
      <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} className='flex flex-col items-center justify-center h-64'>
        <FiDollarSign className='w-16 h-16 text-gray-300 dark:text-gray-600 mb-4' />
        <p className='text-xl font-semibold text-gray-700 dark:text-gray-300 mb-2'>No Commission Data Available</p>
        <p className='text-sm text-gray-500 dark:text-gray-400'>Commission data will appear here once you start earning</p>
      </motion.div>
    )
  }

  const { value: percentChange, isPositive } = getPercentageChange()

  return (
    <div className='bg-gradient-to-b from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800'>
      {/* Hero Header */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className='relative overflow-hidden bg-gradient-to-r from-primary to-primary-dark py-16 mb-4'>
        <div className='relative z-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
          <div className='text-center'>
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className='text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl'>
              Commission Earnings
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              className='mt-6 text-xl text-white/80'>
              Track and analyze your commission earnings over time
            </motion.p>

            <div className='flex flex-wrap items-center justify-center mt-8 gap-4'>
              <div className='bg-white/10 backdrop-blur-sm px-4 py-2'>
                <p className='text-sm text-white/70'>Total Earnings</p>
                <p className='text-2xl font-bold'>{(commissionsData.totalToDate || 0).toFixed(2)} TEDS</p>
              </div>

              <div className='bg-white/10 backdrop-blur-sm px-4 py-2'>
                <p className='text-sm text-white/70'>Period Change</p>
                <div className='flex items-center'>
                  {isPositive ? <FiArrowUp className='mr-1 text-green-300' /> : <FiArrowDown className='mr-1 text-red-300' />}
                  <p className={`text-xl font-medium ${isPositive ? 'text-green-300' : 'text-red-300'}`}>{percentChange}%</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Decorative blob */}
        <div className='absolute top-0 right-0 -translate-y-12 translate-x-12 transform opacity-20'>
          <svg width='400' height='400' viewBox='0 0 184 184' xmlns='http://www.w3.org/2000/svg'>
            <path
              fill='white'
              d='M92 0C92 0 92 0 92 0C142.8 0 184 41.2 184 92C184 142.8 142.8 184 92 184C41.2 184 0 142.8 0 92C0 41.2 41.2 0 92 0Z'
            />
          </svg>
        </div>
      </motion.div>

      {/* Main Content */}
      <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pb-20'>
        {/* Time Period Selector */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1 }}
          className='bg-white dark:bg-gray-800 rounded-xl shadow-md mb-8 p-1 overflow-hidden'>
          <div className='flex'>
            <button
              className={`flex-1 py-3 rounded-lg transition-all duration-200 ${
                timeframe === 'daily'
                  ? 'bg-primary text-white shadow-md'
                  : 'hover:bg-gray-100 dark:hover:bg-gray-700 text-gray-700 dark:text-gray-200'
              }`}
              onClick={() => handleTimeframeChange('daily')}
              disabled={loadingTimeframe === 'daily'}>
              <div className='flex items-center justify-center space-x-1'>
                {loadingTimeframe === 'daily' ? (
                  <div className='w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin'></div>
                ) : (
                  <FiCalendar className='w-4 h-4' />
                )}
                <span className='ml-1'>Daily</span>
              </div>
            </button>
            <button
              className={`flex-1 py-3 rounded-lg transition-all duration-200 ${
                timeframe === 'weekly'
                  ? 'bg-primary text-white shadow-md'
                  : 'hover:bg-gray-100 dark:hover:bg-gray-700 text-gray-700 dark:text-gray-200'
              }`}
              onClick={() => handleTimeframeChange('weekly')}
              disabled={loadingTimeframe === 'weekly'}>
              <div className='flex items-center justify-center space-x-1'>
                {loadingTimeframe === 'weekly' ? (
                  <div className='w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin'></div>
                ) : (
                  <FiCalendar className='w-4 h-4' />
                )}
                <span className='ml-1'>Weekly</span>
              </div>
            </button>
            <button
              className={`flex-1 py-3 rounded-lg transition-all duration-200 ${
                timeframe === 'monthly'
                  ? 'bg-primary text-white shadow-md'
                  : 'hover:bg-gray-100 dark:hover:bg-gray-700 text-gray-700 dark:text-gray-200'
              }`}
              onClick={() => handleTimeframeChange('monthly')}
              disabled={loadingTimeframe === 'monthly'}>
              <div className='flex items-center justify-center space-x-1'>
                {loadingTimeframe === 'monthly' ? (
                  <div className='w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin'></div>
                ) : (
                  <FiCalendar className='w-4 h-4' />
                )}
                <span className='ml-1'>Monthly</span>
              </div>
            </button>
            <button
              className={`flex-1 py-3 rounded-lg transition-all duration-200 ${
                timeframe === 'yearly'
                  ? 'bg-primary text-white shadow-md'
                  : 'hover:bg-gray-100 dark:hover:bg-gray-700 text-gray-700 dark:text-gray-200'
              }`}
              onClick={() => handleTimeframeChange('yearly')}
              disabled={loadingTimeframe === 'yearly'}>
              <div className='flex items-center justify-center space-x-1'>
                {loadingTimeframe === 'yearly' ? (
                  <div className='w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin'></div>
                ) : (
                  <FiCalendar className='w-4 h-4' />
                )}
                <span className='ml-1'>Yearly</span>
              </div>
            </button>
          </div>
        </motion.div>

        {/* Summary Cards */}
        <motion.div variants={containerVariants} initial='hidden' animate='show' className='grid grid-cols-1 md:grid-cols-3 gap-6 mb-8'>
          <motion.div
            variants={itemVariants}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            className='relative overflow-hidden rounded-2xl bg-white dark:bg-gray-800 p-6 shadow-lg transition-shadow hover:shadow-xl'>
            <div className='absolute inset-0 opacity-5 bg-gradient-to-br from-blue-500 to-primary-dark'></div>
            <div className='relative flex justify-between items-start'>
              <div>
                <h2 className='text-lg font-semibold text-gray-700 dark:text-gray-200'>Current Period</h2>
                {loadingTimeframe ? (
                  <div className='h-10 flex items-center mt-2'>
                    <div className='w-5 h-5 border-2 border-primary border-t-transparent rounded-full animate-spin'></div>
                  </div>
                ) : (
                  <div className='mt-2'>
                    <p className='text-3xl font-bold text-gray-900 dark:text-white'>
                      {(commissionsData.currentPeriod || 0).toFixed(2)} <span className='text-lg'>TEDS</span>
                    </p>
                    <p className='text-sm text-gray-500 dark:text-gray-400'>
                      {new Intl.NumberFormat('en-GB', {
                        style: 'currency',
                        currency: 'GBP',
                      }).format((commissionsData.currentPeriod || 0) / 100)}
                    </p>
                  </div>
                )}
                <p className='text-sm text-gray-500 dark:text-gray-400 mt-2'>
                  {timeframe === 'daily' ? 'Today' : timeframe === 'weekly' ? 'This Week' : timeframe === 'monthly' ? 'This Month' : 'This Year'}
                </p>
              </div>
              <FiDollarSign className='h-8 w-8 bg-gradient-to-r from-blue-500 to-primary rounded-lg p-1.5 text-white' />
            </div>
          </motion.div>

          <motion.div
            variants={itemVariants}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            className='relative overflow-hidden rounded-2xl bg-white dark:bg-gray-800 p-6 shadow-lg transition-shadow hover:shadow-xl'>
            <div className='absolute inset-0 opacity-5 bg-gradient-to-br from-indigo-500 to-primary-dark'></div>
            <div className='relative flex justify-between items-start'>
              <div>
                <h2 className='text-lg font-semibold text-gray-700 dark:text-gray-200'>Previous Period</h2>
                {loadingTimeframe ? (
                  <div className='h-10 flex items-center mt-2'>
                    <div className='w-5 h-5 border-2 border-primary border-t-transparent rounded-full animate-spin'></div>
                  </div>
                ) : (
                  <div className='mt-2'>
                    <p className='text-3xl font-bold text-gray-900 dark:text-white'>
                      {(commissionsData.previousPeriod || 0).toFixed(2)} <span className='text-lg'>TEDS</span>
                    </p>
                    <p className='text-sm text-gray-500 dark:text-gray-400'>
                      {new Intl.NumberFormat('en-GB', {
                        style: 'currency',
                        currency: 'GBP',
                      }).format((commissionsData.previousPeriod || 0) / 100)}
                    </p>
                  </div>
                )}
                <p className='text-sm text-gray-500 dark:text-gray-400 mt-2'>
                  {timeframe === 'daily' ? 'Yesterday' : timeframe === 'weekly' ? 'Last Week' : timeframe === 'monthly' ? 'Last Month' : 'Last Year'}
                </p>
              </div>
              <FiCalendar className='h-8 w-8 bg-gradient-to-r from-indigo-500 to-primary rounded-lg p-1.5 text-white' />
            </div>
          </motion.div>

          <motion.div
            variants={itemVariants}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            className='relative overflow-hidden rounded-2xl bg-white dark:bg-gray-800 p-6 shadow-lg transition-shadow hover:shadow-xl'>
            <div className='absolute inset-0 opacity-5 bg-gradient-to-br from-purple-500 to-primary-dark'></div>
            <div className='relative flex justify-between items-start'>
              <div>
                <h2 className='text-lg font-semibold text-gray-700 dark:text-gray-200'>Total To Date</h2>
                {loadingTimeframe ? (
                  <div className='h-10 flex items-center mt-2'>
                    <div className='w-5 h-5 border-2 border-primary border-t-transparent rounded-full animate-spin'></div>
                  </div>
                ) : (
                  <div className='mt-2'>
                    <p className='text-3xl font-bold text-gray-900 dark:text-white'>
                      {(commissionsData.totalToDate || 0).toFixed(2)} <span className='text-lg'>TEDS</span>
                    </p>
                    <p className='text-sm text-gray-500 dark:text-gray-400'>
                      {new Intl.NumberFormat('en-GB', {
                        style: 'currency',
                        currency: 'GBP',
                      }).format((commissionsData.totalToDate || 0) / 100)}
                    </p>
                  </div>
                )}
                <p className='text-sm text-gray-500 dark:text-gray-400 mt-2'>All Time</p>
              </div>
              <FiTrendingUp className='h-8 w-8 bg-gradient-to-r from-purple-500 to-primary rounded-lg p-1.5 text-white' />
            </div>
          </motion.div>
        </motion.div>

        {/* Trend Chart */}
        <motion.div
          variants={itemVariants}
          initial='hidden'
          animate='show'
          transition={{ delay: 0.3 }}
          className='relative overflow-hidden rounded-2xl bg-white dark:bg-gray-800 p-6 shadow-lg mb-8'>
          <div className='flex justify-between items-center mb-6'>
            <div>
              <h2 className='text-xl font-semibold text-gray-900 dark:text-white'>Commission Trend</h2>
              <p className='text-sm text-gray-500 dark:text-gray-400'>View your earnings pattern over time</p>
            </div>
            <FiBarChart2 className='h-6 w-6 text-primary' />
          </div>

          <div className='h-72'>
            {loadingTimeframe ? (
              <div className='h-full flex justify-center items-center'>
                <div className='w-8 h-8 border-3 border-primary border-t-transparent rounded-full animate-spin'></div>
              </div>
            ) : commissionsData.trendData && commissionsData.trendData.length > 0 ? (
              <ResponsiveContainer width='100%' height='100%'>
                <LineChart data={commissionsData.trendData}>
                  <CartesianGrid strokeDasharray='3 3' stroke='#374151' opacity={0.1} />
                  <XAxis dataKey='period' stroke='#6B7280' tick={{ fill: '#6B7280' }} />
                  <YAxis stroke='#6B7280' tick={{ fill: '#6B7280' }} />
                  <Tooltip
                    formatter={(value) => [formatCurrency(value), 'Commission']}
                    contentStyle={{
                      backgroundColor: 'rgba(31, 41, 55, 0.8)',
                      borderRadius: '0.5rem',
                      color: 'white',
                      border: 'none',
                      boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)',
                    }}
                    itemStyle={{ color: 'white' }}
                    labelStyle={{ color: 'white', fontWeight: 'bold' }}
                  />
                  <Legend />
                  <Line
                    type='monotone'
                    dataKey='amount'
                    stroke='#4f46e5'
                    strokeWidth={3}
                    dot={{ stroke: '#4f46e5', strokeWidth: 2, r: 4, fill: 'white' }}
                    activeDot={{ r: 8, stroke: '#4f46e5', strokeWidth: 2, fill: '#4f46e5' }}
                    name='Commission'
                  />
                </LineChart>
              </ResponsiveContainer>
            ) : (
              <div className='h-full flex flex-col justify-center items-center'>
                <FiPieChart className='w-12 h-12 text-gray-300 dark:text-gray-600 mb-4' />
                <p className='text-gray-500 dark:text-gray-400'>No trend data available for this period</p>
              </div>
            )}
          </div>
        </motion.div>

        {/* Breakdown By Source */}
        <motion.div
          variants={itemVariants}
          initial='hidden'
          animate='show'
          transition={{ delay: 0.4 }}
          className='relative overflow-hidden rounded-2xl bg-white dark:bg-gray-800 p-6 shadow-lg mb-8'>
          <div className='flex justify-between items-center mb-6'>
            <div>
              <h2 className='text-xl font-semibold text-gray-900 dark:text-white'>Commission By Source</h2>
              <p className='text-sm text-gray-500 dark:text-gray-400'>Earnings breakdown by source type</p>
            </div>
            <FiPieChart className='h-6 w-6 text-primary' />
          </div>

          <div className='h-72'>
            {loadingTimeframe ? (
              <div className='h-full flex justify-center items-center'>
                <div className='w-8 h-8 border-3 border-primary border-t-transparent rounded-full animate-spin'></div>
              </div>
            ) : commissionsData.sourceData && commissionsData.sourceData.length > 0 ? (
              <ResponsiveContainer width='100%' height='100%'>
                <BarChart data={commissionsData.sourceData}>
                  <CartesianGrid strokeDasharray='3 3' stroke='#374151' opacity={0.1} />
                  <XAxis dataKey='source' stroke='#6B7280' tick={{ fill: '#6B7280' }} />
                  <YAxis stroke='#6B7280' tick={{ fill: '#6B7280' }} />
                  <Tooltip
                    formatter={(value) => [formatCurrency(value), 'Commission']}
                    contentStyle={{
                      backgroundColor: 'rgba(31, 41, 55, 0.8)',
                      borderRadius: '0.5rem',
                      color: 'white',
                      border: 'none',
                      boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)',
                    }}
                    itemStyle={{ color: 'white' }}
                    labelStyle={{ color: 'white', fontWeight: 'bold' }}
                  />
                  <Legend />
                  <Bar dataKey='amount' fill='#4f46e5' radius={[4, 4, 0, 0]} name='Commission' />
                </BarChart>
              </ResponsiveContainer>
            ) : (
              <div className='h-full flex flex-col justify-center items-center'>
                <FiPieChart className='w-12 h-12 text-gray-300 dark:text-gray-600 mb-4' />
                <p className='text-gray-500 dark:text-gray-400'>No source data available for this period</p>
              </div>
            )}
          </div>
        </motion.div>

        {/* Monthly Summary Table */}
        <motion.div
          variants={itemVariants}
          initial='hidden'
          animate='show'
          transition={{ delay: 0.5 }}
          className='relative overflow-hidden rounded-2xl bg-white dark:bg-gray-800 p-6 shadow-lg mb-6'>
          <div className='flex justify-between items-center mb-6'>
            <div>
              <h2 className='text-xl font-semibold text-gray-900 dark:text-white'>Monthly Summary</h2>
              <p className='text-sm text-gray-500 dark:text-gray-400'>Historical monthly commission data</p>
            </div>
            <FiCalendar className='h-6 w-6 text-primary' />
          </div>

          {loadingTimeframe ? (
            <div className='h-32 flex justify-center items-center'>
              <div className='w-8 h-8 border-3 border-primary border-t-transparent rounded-full animate-spin'></div>
            </div>
          ) : commissionsData.monthlySummary && commissionsData.monthlySummary.length > 0 ? (
            <div className='overflow-x-auto'>
              <table className='w-full'>
                <thead>
                  <tr className='border-b border-gray-200 dark:border-gray-700'>
                    <th className='py-3 px-4 text-left text-sm font-medium text-gray-500 dark:text-gray-400'>Month</th>
                    <th className='py-3 px-4 text-left text-sm font-medium text-gray-500 dark:text-gray-400'>Commission Earned</th>
                    <th className='py-3 px-4 text-left text-sm font-medium text-gray-500 dark:text-gray-400'>Payment Status</th>
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-200 dark:divide-gray-700'>
                  {commissionsData.monthlySummary.map((month, index) => (
                    <tr key={index} className='hover:bg-gray-50 dark:hover:bg-gray-750 transition-colors'>
                      <td className='py-4 px-4 text-sm text-gray-900 dark:text-gray-200'>{month.period}</td>
                      <td className='py-4 px-4 text-sm font-medium text-gray-900 dark:text-gray-200'>{formatCurrency(month.amount)}</td>
                      <td className='py-4 px-4 text-sm'>
                        <span
                          className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                            month.paid
                              ? 'bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100'
                              : 'bg-amber-100 text-amber-800 dark:bg-amber-800 dark:text-amber-100'
                          }`}>
                          {month.paid ? 'Paid' : 'Pending'}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className='h-32 flex flex-col justify-center items-center'>
              <FiCalendar className='w-12 h-12 text-gray-300 dark:text-gray-600 mb-4' />
              <p className='text-gray-500 dark:text-gray-400'>No monthly summary data available yet</p>
            </div>
          )}
        </motion.div>
      </div>
    </div>
  )
}

export default CommissionEarnings
