import { useState, useEffect } from 'react'
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'
import { registerSalesManager, reset } from '../features/agents/agentsSlice'
import { useSelector, useDispatch } from 'react-redux'
import toast from 'react-hot-toast'
import { motion } from 'framer-motion'
import { FiUser, FiMail, FiHome, FiMapPin, FiPhone, FiGlobe, FiBriefcase, FiSave } from 'react-icons/fi'
import BackButton from '../components/BackButton'

const SalesManagerRegistration = () => {
  const { message, isLoading, isSuccess, isError, submissionSuccess } = useSelector((state) => state.agents)
  const dispatch = useDispatch()

  const [selectedCountry, setSelectedCountry] = useState('GB')
  const [selectedRegion, setSelectedRegion] = useState('')
  const [selectedCountryBusinessAddress, setSelectedCountryBusinessAddress] = useState('GB')
  const [selectedRegionBusinessAddress, setSelectedRegionBusinessAddress] = useState('')
  const [emailError, setEmailError] = useState('')
  const [formErrors, setFormErrors] = useState({})
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isValidPostCode, setIsValidPostCode] = useState(true)

  const [formData, setFormData] = useState({
    email: '',
    name: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    postcode: '',
    country: selectedCountry,
    region: selectedRegion,
    addressLine1BusinessAddress: '',
    addressLine2BusinessAddress: '',
    cityBusinessAddress: '',
    postcodeBusinessAddress: '',
    countryBusinessAddress: selectedCountryBusinessAddress,
    regionBusinessAddress: selectedRegionBusinessAddress,
    website: '',
    landline: '',
    mobile: '',
    vatRegistered: '',
    vatRegNumber: '',
    tradingStyle: '',
    tradingName: '',
    currentTradingActivity: '',
    companyRegNum: '',
    agentType: 'salesManager',
  })

  // Define animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3,
      },
    },
  }

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    show: { y: 0, opacity: 1 },
  }

  useEffect(() => {
    if (isError) {
      toast.error(message)
    }

    if (isSuccess) {
      dispatch(reset())
    }
  }, [dispatch, isError, isSuccess, message])

  useEffect(() => {
    if (isSubmitted && Object.keys(formErrors).length === 0 && !emailError) {
      setFormData({
        email: '',
        name: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        postcode: '',
        country: 'GB',
        region: '',
        addressLine1BusinessAddress: '',
        addressLine2BusinessAddress: '',
        cityBusinessAddress: '',
        postcodeBusinessAddress: '',
        countryBusinessAddress: 'GB',
        regionBusinessAddress: '',
        website: '',
        landline: '',
        mobile: '',
        vatRegistered: '',
        vatRegNumber: '',
        tradingStyle: '',
        tradingName: '',
        currentTradingActivity: '',
        companyRegNum: '',
        agentType: 'salesManager',
      })
      // Reset dropdown selections
      setSelectedCountry('GB')
      setSelectedRegion('')
      setSelectedCountryBusinessAddress('GB')
      setSelectedRegionBusinessAddress('')
      setIsSubmitted(false)
    }
  }, [isSubmitted, formErrors, emailError])

  const validateForm = () => {
    const requiredFields = [
      'name',
      'email',
      'addressLine1',
      'city',
      'postcode',
      'country',
      'region',
      'vatRegistered',
      'tradingStyle',
      'tradingName',
      'currentTradingActivity',
      'addressLine1BusinessAddress',
      'cityBusinessAddress',
      'postcodeBusinessAddress',
      'countryBusinessAddress',
      'regionBusinessAddress',
    ]

    if (formData.vatRegistered === 'true') requiredFields.push('vatRegNumber')
    if (formData.tradingStyle === 'Ltd company') requiredFields.push('companyRegNum')

    const errors = {}
    requiredFields.forEach((field) => {
      if (!formData[field] || formData[field].trim() === '') {
        errors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`
      }
    })

    setFormErrors(errors)
    return Object.keys(errors).length === 0
  }

  const handleCountryChange = (val) => {
    setSelectedCountry(val)
    setFormData({ ...formData, country: val })
  }

  const handleRegionChange = (val) => {
    setSelectedRegion(val)
    setFormData({ ...formData, region: val })
  }

  const handleCountryChangeBusinessAddress = (val) => {
    setSelectedCountryBusinessAddress(val)
    setFormData({ ...formData, countryBusinessAddress: val })
  }

  const handleRegionChangeBusinessAddress = (val) => {
    setSelectedRegionBusinessAddress(val)
    setFormData({ ...formData, regionBusinessAddress: val })
  }

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  const onChange = (e) => {
    const { name, value } = e.target
    if (name === 'email' && !validateEmail(value)) {
      setEmailError('Invalid email format')
    } else if (name === 'email') {
      setEmailError('')
    }

    setFormData((prev) => ({ ...prev, [name]: value }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setIsSubmitted(true)
    if (validateForm() && !emailError) {
      dispatch(registerSalesManager(formData))
    }
  }

  return (
    <div className='min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800'>
      {/* Hero Section */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className='relative overflow-hidden bg-gradient-to-r from-primary to-primary-dark py-16 mb-4'>
        <div className='relative z-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
          <div className='text-center'>
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className='text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl'>
              Sales Manager Registration
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              className='mt-6 text-xl text-gray-100'>
              Register a new Sales Manager for the Loyalty Club network
            </motion.p>
          </div>
        </div>

        {/* Decorative blob */}
        <div className='absolute top-0 right-0 -translate-y-12 translate-x-12 transform opacity-20'>
          <svg width='400' height='400' viewBox='0 0 184 184' xmlns='http://www.w3.org/2000/svg'>
            <path
              fill='white'
              d='M92 0C92 0 92 0 92 0C142.8 0 184 41.2 184 92C184 142.8 142.8 184 92 184C41.2 184 0 142.8 0 92C0 41.2 41.2 0 92 0Z'
            />
          </svg>
        </div>
      </motion.div>

      <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pb-20'>
        <div className='flex justify-start w-full mb-6'>
          <BackButton />
        </div>

        <motion.div variants={containerVariants} initial='hidden' animate='show'>
          <motion.div variants={itemVariants} className='bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8 mb-8'>
            <div className='flex items-center gap-3 text-2xl font-semibold text-gray-900 dark:text-white border-b border-gray-200 dark:border-gray-700 pb-4'>
              <FiUser className='text-primary' />
              <h2>Sales Manager Registration</h2>
            </div>

            <div className='mt-6 text-gray-700 dark:text-gray-300'>
              <p className='mb-4'>Register a new Sales Manager. Ensure all information is verified through official documentation.</p>
            </div>
          </motion.div>

          <motion.form variants={containerVariants} onSubmit={handleSubmit} className='space-y-8'>
            {/* Personal Information */}
            <motion.div variants={itemVariants} className='bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8 space-y-6'>
              <div className='flex items-center gap-3 text-2xl font-semibold text-gray-900 dark:text-white border-b border-gray-200 dark:border-gray-700 pb-4'>
                <FiUser className='text-primary' />
                <h2>Personal Information</h2>
              </div>

              <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
                <div>
                  <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Full Name</label>
                  <div className='relative'>
                    <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                      <FiUser className='text-gray-400' />
                    </div>
                    <input
                      type='text'
                      name='name'
                      value={formData.name}
                      onChange={onChange}
                      className='w-full pl-10 px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                      placeholder='Full name of Sales Manager'
                    />
                  </div>
                  <p className='text-red-500 text-sm mt-1'>{formErrors.name && formErrors.name}</p>
                </div>

                <div>
                  <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Email Address</label>
                  <div className='relative'>
                    <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                      <FiMail className='text-gray-400' />
                    </div>
                    <input
                      type='email'
                      name='email'
                      value={formData.email}
                      onChange={onChange}
                      className='w-full pl-10 px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                      placeholder='Email address'
                    />
                  </div>
                  <p className='text-red-500 text-sm mt-1'>{emailError || (formErrors.email && formErrors.email)}</p>
                </div>
              </div>
            </motion.div>

            {/* Personal Address */}
            <motion.div variants={itemVariants} className='bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8 space-y-6'>
              <div className='flex items-center gap-3 text-2xl font-semibold text-gray-900 dark:text-white border-b border-gray-200 dark:border-gray-700 pb-4'>
                <FiHome className='text-primary' />
                <h2>Personal Address</h2>
              </div>

              <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
                <div>
                  <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Address Line 1</label>
                  <input
                    type='text'
                    name='addressLine1'
                    value={formData.addressLine1}
                    onChange={onChange}
                    className='w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                    placeholder='Street address'
                  />
                  <p className='text-red-500 text-sm mt-1'>{formErrors.addressLine1 && formErrors.addressLine1}</p>
                </div>

                <div>
                  <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Address Line 2</label>
                  <input
                    type='text'
                    name='addressLine2'
                    value={formData.addressLine2}
                    onChange={onChange}
                    className='w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                    placeholder='Apartment, suite, etc. (optional)'
                  />
                </div>

                <div>
                  <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>City</label>
                  <input
                    type='text'
                    name='city'
                    value={formData.city}
                    onChange={onChange}
                    className='w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                    placeholder='City'
                  />
                  <p className='text-red-500 text-sm mt-1'>{formErrors.city && formErrors.city}</p>
                </div>

                <div>
                  <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Postal Code</label>
                  <input
                    type='text'
                    name='postcode'
                    value={formData.postcode}
                    onChange={onChange}
                    className='w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                    placeholder='Postal code'
                  />
                  <p className='text-red-500 text-sm mt-1'>{formErrors.postcode && formErrors.postcode}</p>
                </div>

                <div>
                  <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Country</label>
                  <CountryDropdown
                    value={selectedCountry}
                    onChange={handleCountryChange}
                    classes='w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                    valueType='short'
                  />
                  <p className='text-red-500 text-sm mt-1'>{formErrors.country && formErrors.country}</p>
                </div>

                <div>
                  <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Region/State</label>
                  <RegionDropdown
                    country={selectedCountry}
                    value={selectedRegion}
                    onChange={handleRegionChange}
                    classes='w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                    countryValueType='short'
                  />
                  <p className='text-red-500 text-sm mt-1'>{formErrors.region && formErrors.region}</p>
                </div>
              </div>
            </motion.div>

            {/* Business Address */}
            <motion.div variants={itemVariants} className='bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8 space-y-6'>
              <div className='flex items-center gap-3 text-2xl font-semibold text-gray-900 dark:text-white border-b border-gray-200 dark:border-gray-700 pb-4'>
                <FiBriefcase className='text-primary' />
                <h2>Business Address</h2>
              </div>

              <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
                <div>
                  <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Address Line 1</label>
                  <input
                    type='text'
                    name='addressLine1BusinessAddress'
                    value={formData.addressLine1BusinessAddress}
                    onChange={onChange}
                    className='w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                    placeholder='Business street address'
                  />
                  <p className='text-red-500 text-sm mt-1'>{formErrors.addressLine1BusinessAddress && formErrors.addressLine1BusinessAddress}</p>
                </div>

                <div>
                  <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Address Line 2</label>
                  <input
                    type='text'
                    name='addressLine2BusinessAddress'
                    value={formData.addressLine2BusinessAddress}
                    onChange={onChange}
                    className='w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                    placeholder='Suite, unit, etc. (optional)'
                  />
                </div>

                <div>
                  <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>City</label>
                  <input
                    type='text'
                    name='cityBusinessAddress'
                    value={formData.cityBusinessAddress}
                    onChange={onChange}
                    className='w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                    placeholder='Business city'
                  />
                  <p className='text-red-500 text-sm mt-1'>{formErrors.cityBusinessAddress && formErrors.cityBusinessAddress}</p>
                </div>

                <div>
                  <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Postal Code</label>
                  <input
                    type='text'
                    name='postcodeBusinessAddress'
                    value={formData.postcodeBusinessAddress}
                    onChange={onChange}
                    className='w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                    placeholder='Business postal code'
                  />
                  <p className='text-red-500 text-sm mt-1'>{formErrors.postcodeBusinessAddress && formErrors.postcodeBusinessAddress}</p>
                </div>

                <div>
                  <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Country</label>
                  <CountryDropdown
                    value={selectedCountryBusinessAddress}
                    onChange={handleCountryChangeBusinessAddress}
                    classes='w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                    valueType='short'
                  />
                  <p className='text-red-500 text-sm mt-1'>{formErrors.countryBusinessAddress && formErrors.countryBusinessAddress}</p>
                </div>

                <div>
                  <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Region/State</label>
                  <RegionDropdown
                    country={selectedCountryBusinessAddress}
                    value={selectedRegionBusinessAddress}
                    onChange={handleRegionChangeBusinessAddress}
                    classes='w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                    countryValueType='short'
                  />
                  <p className='text-red-500 text-sm mt-1'>{formErrors.regionBusinessAddress && formErrors.regionBusinessAddress}</p>
                </div>
              </div>
            </motion.div>

            {/* Contact Information */}
            <motion.div variants={itemVariants} className='bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8 space-y-6'>
              <div className='flex items-center gap-3 text-2xl font-semibold text-gray-900 dark:text-white border-b border-gray-200 dark:border-gray-700 pb-4'>
                <FiPhone className='text-primary' />
                <h2>Contact Information</h2>
              </div>

              <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
                <div>
                  <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Landline</label>
                  <div className='relative'>
                    <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                      <FiPhone className='text-gray-400' />
                    </div>
                    <input
                      type='tel'
                      name='landline'
                      value={formData.landline}
                      onChange={onChange}
                      className='w-full pl-10 px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                      placeholder='Landline number'
                    />
                  </div>
                </div>

                <div>
                  <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Mobile</label>
                  <div className='relative'>
                    <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                      <FiPhone className='text-gray-400' />
                    </div>
                    <input
                      type='tel'
                      name='mobile'
                      value={formData.mobile}
                      onChange={onChange}
                      className='w-full pl-10 px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                      placeholder='Mobile number'
                    />
                  </div>
                </div>

                <div>
                  <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Website</label>
                  <div className='relative'>
                    <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                      <FiGlobe className='text-gray-400' />
                    </div>
                    <input
                      type='url'
                      name='website'
                      value={formData.website}
                      onChange={onChange}
                      className='w-full pl-10 px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                      placeholder='Company website'
                    />
                  </div>
                </div>
              </div>
            </motion.div>

            {/* Business Information */}
            <motion.div variants={itemVariants} className='bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8 space-y-6'>
              <div className='flex items-center gap-3 text-2xl font-semibold text-gray-900 dark:text-white border-b border-gray-200 dark:border-gray-700 pb-4'>
                <FiBriefcase className='text-primary' />
                <h2>Business Information</h2>
              </div>

              <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
                <div>
                  <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Trading Style</label>
                  <select
                    name='tradingStyle'
                    value={formData.tradingStyle}
                    onChange={onChange}
                    className='w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'>
                    <option value=''>Select trading style</option>
                    <option value='Trader'>Sole trader</option>
                    <option value='Partnership'>Partnership</option>
                    <option value='Ltd company'>Limited company</option>
                    <option value='Other'>Other</option>
                  </select>
                  <p className='text-red-500 text-sm mt-1'>{formErrors.tradingStyle && formErrors.tradingStyle}</p>
                </div>

                <div>
                  <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Trading Name</label>
                  <input
                    type='text'
                    name='tradingName'
                    value={formData.tradingName}
                    onChange={onChange}
                    className='w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                    placeholder='Official trading name'
                  />
                  <p className='text-red-500 text-sm mt-1'>{formErrors.tradingName && formErrors.tradingName}</p>
                </div>

                <div className='md:col-span-2'>
                  <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Trading Activity</label>
                  <textarea
                    name='currentTradingActivity'
                    value={formData.currentTradingActivity}
                    onChange={onChange}
                    className='w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                    placeholder='Describe current trading activities'
                    rows='4'></textarea>
                  <p className='text-red-500 text-sm mt-1'>{formErrors.currentTradingActivity && formErrors.currentTradingActivity}</p>
                </div>

                <div>
                  <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>VAT Registered</label>
                  <div className='flex space-x-4'>
                    <label className='flex items-center space-x-2'>
                      <input
                        type='radio'
                        name='vatRegistered'
                        value='true'
                        checked={formData.vatRegistered === 'true'}
                        onChange={onChange}
                        className='w-4 h-4 text-primary focus:ring-primary'
                      />
                      <span className='text-gray-700 dark:text-gray-300'>Yes</span>
                    </label>
                    <label className='flex items-center space-x-2'>
                      <input
                        type='radio'
                        name='vatRegistered'
                        value='false'
                        checked={formData.vatRegistered === 'false'}
                        onChange={onChange}
                        className='w-4 h-4 text-primary focus:ring-primary'
                      />
                      <span className='text-gray-700 dark:text-gray-300'>No</span>
                    </label>
                  </div>
                  <p className='text-red-500 text-sm mt-1'>{formErrors.vatRegistered && formErrors.vatRegistered}</p>
                </div>

                {formData.vatRegistered === 'true' && (
                  <div>
                    <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>VAT Registration Number</label>
                    <input
                      type='text'
                      name='vatRegNumber'
                      value={formData.vatRegNumber}
                      onChange={onChange}
                      className='w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                      placeholder='VAT registration number'
                    />
                    <p className='text-red-500 text-sm mt-1'>{formErrors.vatRegNumber && formErrors.vatRegNumber}</p>
                  </div>
                )}

                {formData.tradingStyle === 'Ltd company' && (
                  <div>
                    <label className='block font-medium mb-2 text-gray-700 dark:text-gray-300'>Company Registration Number</label>
                    <input
                      type='text'
                      name='companyRegNum'
                      value={formData.companyRegNum}
                      onChange={onChange}
                      className='w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                      placeholder='Company registration number'
                    />
                    <p className='text-red-500 text-sm mt-1'>{formErrors.companyRegNum && formErrors.companyRegNum}</p>
                  </div>
                )}
              </div>
            </motion.div>

            <motion.div variants={itemVariants} className='flex justify-center mt-8'>
              <button
                type='submit'
                className='px-8 py-4 bg-primary hover:bg-primary-dark text-white font-medium rounded-lg transition-colors flex items-center gap-2 text-lg'
                disabled={isLoading}>
                {isLoading ? (
                  <>
                    <span className='inline-block animate-spin mr-2'>⟳</span>
                    Submitting...
                  </>
                ) : (
                  <>
                    <FiSave className='text-xl' />
                    Register Sales Manager
                  </>
                )}
              </button>
            </motion.div>

            {isSubmitted && Object.keys(formErrors).length > 0 && (
              <motion.p variants={itemVariants} className='text-red-500 text-center text-lg font-bold mt-4'>
                Please fill in all required fields
              </motion.p>
            )}
          </motion.form>
        </motion.div>
      </div>
    </div>
  )
}

export default SalesManagerRegistration
