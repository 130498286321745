import { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import { useSelector, useDispatch } from 'react-redux'
import { getCharityDetails, reset } from '../features/charity/charitySlice'
import Spinner from '../components/Spinner'
import BackButton from '../components/BackButton'

function ViewCharity() {
  const { charityId } = useParams()
  const { isLoading, isSuccess, isError, message, charity } = useSelector((state) => state.charities)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (isError) {
      toast.error(message)
    }
    dispatch(getCharityDetails(charityId))
    return () => {
      dispatch(reset())
    }
  }, [isError, message, charityId, dispatch])

  if (isLoading) {
    return <Spinner />
  }

  if (isError) {
    return (
      <div className='alert alert-error shadow-lg'>
        <div>
          <svg xmlns='http://www.w3.org/2000/svg' className='stroke-current flex-shrink-0 h-6 w-6' fill='none' viewBox='0 0 24 24'>
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z'
            />
          </svg>
          <span>Error: {message}</span>
        </div>
      </div>
    )
  }

  const handleEdit = () => {
    navigate(`/charities/${charityId}/edit`)
  }

  return (
    <>
      <div className='flex mb-4'>
        <BackButton />
      </div>
      <div className='max-w-4xl mx-auto'>
        <h1 className='text-3xl font-bold mb-6'>Charity Details</h1>
        {charity && charity.charity && (
          <div className='bg-white shadow-md rounded-lg p-6 mb-20'>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
              <div>
                <div className='mb-4'>
                  <h2 className='text-xl font-bold'>Charity Name</h2>
                  <p>{charity.charity.charityName}</p>
                </div>
                <div className='mb-4'>
                  <h2 className='text-xl font-bold'>Charity Category</h2>
                  <p>{charity.charity.charityCategory}</p>
                </div>
                <div className='mb-4'>
                  <h2 className='text-xl font-bold'>Registration Number</h2>
                  <p>{charity.charity.registrationNumber}</p>
                </div>
                <div className='mb-4'>
                  <h2 className='text-xl font-bold'>Address</h2>
                  <p>{charity.charity.address.addressLine1}</p>
                  {charity.charity.address.addressLine2 && <p>{charity.charity.address.addressLine2}</p>}
                  <p>{charity.charity.address.city}</p>
                  <p>{charity.charity.address.region}</p>
                  <p>{charity.charity.address.country}</p>
                  <p>{charity.charity.address.postcode}</p>
                </div>
                <div className='mb-4'>
                  <h2 className='text-xl font-bold'>Phone</h2>
                  <p>{charity.charity.phone}</p>
                </div>
                <div className='mb-4'>
                  <h2 className='text-xl font-bold'>Email</h2>
                  <p>{charity.charity.email}</p>
                </div>
              </div>
              <div>
                <div className='mb-4'>
                  <h2 className='text-xl font-bold'>Website</h2>
                  <p>{charity.charity.website}</p>
                </div>
                <div className='mb-4'>
                  <h2 className='text-xl font-bold'>Contact Person</h2>
                  <p>{charity.charity.contactPerson.name}</p>
                  <p>{charity.charity.contactPerson.position}</p>
                  <p>{charity.charity.contactPerson.email}</p>
                  <p>{charity.charity.contactPerson.phone}</p>
                </div>
                <div className='mb-4'>
                  <h2 className='text-xl font-bold'>Charity Type</h2>
                  <p>{charity.charity.charityType}</p>
                </div>
                <div className='mb-4'>
                  <h2 className='text-xl font-bold'>Mission Statement</h2>
                  <p>{charity.charity.missionStatement}</p>
                </div>
                <div className='mb-4'>
                  <h2 className='text-xl font-bold'>Donation Methods</h2>
                  <ul>
                    {charity.charity.donationMethods.map((method, index) => (
                      <li key={index}>{method}</li>
                    ))}
                  </ul>
                </div>
                <div className='mb-4'>
                  <h2 className='text-xl font-bold'>Notes</h2>
                  <p>{charity.charity.notes}</p>
                </div>
              </div>
            </div>
            <div className='mt-8'>
              <button className='btn btn-primary' onClick={handleEdit}>
                Edit Charity
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default ViewCharity
