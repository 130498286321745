import { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import toast from 'react-hot-toast'
import { useSelector, useDispatch } from 'react-redux'
import { registerStrategicPartner, reset } from '../features/strategicPartner/strategicPartnerSlice'
import Spinner from '../components/Spinner'
import BackButton from '../components/BackButton'
import { motion } from 'framer-motion'
import { FiSave, FiBriefcase, FiMapPin, FiPhone, FiMail, FiGlobe, FiUser } from 'react-icons/fi'

function AddStrategicPartner() {
  const { isLoading, isSuccess, isError, message } = useSelector((state) => state.strategicPartners)

  const [formData, setFormData] = useState({
    companyName: '',
    businessCategory: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    region: '',
    country: '',
    postcode: '',
    phone: '',
    email: '',
    website: '',
    contactPersonName: '',
    contactPersonPosition: '',
    contactPersonEmail: '',
    contactPersonPhone: '',
    notes: '',
  })

  const {
    companyName,
    businessCategory,
    addressLine1,
    addressLine2,
    city,
    region,
    country,
    postcode,
    phone,
    email,
    website,
    contactPersonName,
    contactPersonPosition,
    contactPersonEmail,
    contactPersonPhone,
    notes,
  } = formData

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (isError) {
      toast.error(message)
    }
    if (isSuccess) {
      toast.success('Strategic Partner Added Successfully')
      navigate('/strategic-partners')
    }
    dispatch(reset())
  }, [isError, isSuccess, message, navigate, dispatch])

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const onSubmit = (e) => {
    e.preventDefault()

    const strategicPartnerData = {
      companyName,
      businessCategory,
      address: {
        addressLine1,
        addressLine2,
        city,
        region,
        country,
        postcode,
      },
      phone,
      email,
      website,
      contactPerson: {
        name: contactPersonName,
        position: contactPersonPosition,
        email: contactPersonEmail,
        phone: contactPersonPhone,
      },
      notes,
    }
    dispatch(registerStrategicPartner(strategicPartnerData))
  }

  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3,
      },
    },
  }

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    show: { y: 0, opacity: 1 },
  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <div className='min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800'>
      {/* Hero Section */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className='relative overflow-hidden bg-gradient-to-r from-primary to-primary-dark py-16 mb-8'>
        <div className='relative z-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
          <div className='text-center'>
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className='text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl'>
              Add Strategic Partner
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              className='mt-6 text-xl text-gray-100'>
              Register a new strategic partnership
            </motion.p>
          </div>
        </div>
        {/* Decorative blob */}
        <div className='absolute top-0 right-0 -translate-y-12 translate-x-12 transform opacity-20'>
          <svg width='400' height='400' viewBox='0 0 184 184' xmlns='http://www.w3.org/2000/svg'>
            <path
              fill='white'
              d='M92 0C92 0 92 0 92 0C142.8 0 184 41.2 184 92C184 142.8 142.8 184 92 184C41.2 184 0 142.8 0 92C0 41.2 41.2 0 92 0Z'
            />
          </svg>
        </div>
      </motion.div>

      <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
        {/* Back and Home Navigation */}
        <div className='flex justify-between items-center mb-4'>
          <BackButton />
          <Link to='/' className='text-primary underline'>
            Home
          </Link>
        </div>

        <motion.div variants={containerVariants} initial='hidden' animate='show' className='bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6 mb-8'>
          <form onSubmit={onSubmit} className='space-y-8'>
            {/* Company Information */}
            <motion.div variants={itemVariants} className='space-y-6'>
              <h2 className='text-xl font-semibold text-gray-900 dark:text-white flex items-center'>
                <FiBriefcase className='mr-2' /> Company Information
              </h2>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
                {/* Company Name Field */}
                <div>
                  <label className='block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2'>Company Name</label>
                  <div className='relative'>
                    <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                      <FiBriefcase className='text-gray-400' />
                    </div>
                    <input
                      type='text'
                      name='companyName'
                      value={companyName}
                      onChange={onChange}
                      required
                      className='w-full pl-10 px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                      placeholder='Enter company name'
                    />
                  </div>
                </div>
                {/* Business Category Field */}
                <div>
                  <label className='block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2'>Business Category</label>
                  <div className='relative'>
                    <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                      <FiBriefcase className='text-gray-400' />
                    </div>
                    <input
                      type='text'
                      name='businessCategory'
                      value={businessCategory}
                      onChange={onChange}
                      required
                      className='w-full pl-10 px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                      placeholder='e.g., Wine, Retail, Services'
                    />
                  </div>
                </div>
              </div>
            </motion.div>

            {/* Address Information */}
            <motion.div variants={itemVariants} className='space-y-6'>
              <h2 className='text-xl font-semibold text-gray-900 dark:text-white flex items-center'>
                <FiMapPin className='mr-2' /> Address Details
              </h2>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
                {/* Address Line 1 */}
                <div>
                  <label className='block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2'>Address Line 1</label>
                  <div className='relative'>
                    <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                      <FiMapPin className='text-gray-400' />
                    </div>
                    <input
                      type='text'
                      name='addressLine1'
                      value={addressLine1}
                      onChange={onChange}
                      required
                      className='w-full pl-10 px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                      placeholder='Enter address line 1'
                    />
                  </div>
                </div>
                {/* Address Line 2 */}
                <div>
                  <label className='block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2'>Address Line 2</label>
                  <div className='relative'>
                    <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                      <FiMapPin className='text-gray-400' />
                    </div>
                    <input
                      type='text'
                      name='addressLine2'
                      value={addressLine2}
                      onChange={onChange}
                      className='w-full pl-10 px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                      placeholder='Enter address line 2'
                    />
                  </div>
                </div>
                {/* City */}
                <div>
                  <label className='block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2'>City</label>
                  <div className='relative'>
                    <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                      <FiGlobe className='text-gray-400' />
                    </div>
                    <input
                      type='text'
                      name='city'
                      value={city}
                      onChange={onChange}
                      required
                      className='w-full pl-10 px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                      placeholder='Enter city'
                    />
                  </div>
                </div>
                {/* Region */}
                <div>
                  <label className='block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2'>Region</label>
                  <div className='relative'>
                    <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                      <FiMapPin className='text-gray-400' />
                    </div>
                    <input
                      type='text'
                      name='region'
                      value={region}
                      onChange={onChange}
                      className='w-full pl-10 px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                      placeholder='Enter region'
                    />
                  </div>
                </div>
                {/* Country */}
                <div>
                  <label className='block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2'>Country</label>
                  <div className='relative'>
                    <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                      <FiGlobe className='text-gray-400' />
                    </div>
                    <input
                      type='text'
                      name='country'
                      value={country}
                      onChange={onChange}
                      required
                      className='w-full pl-10 px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                      placeholder='Enter country'
                    />
                  </div>
                </div>
                {/* Postcode */}
                <div>
                  <label className='block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2'>Postcode</label>
                  <div className='relative'>
                    <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                      <FiMapPin className='text-gray-400' />
                    </div>
                    <input
                      type='text'
                      name='postcode'
                      value={postcode}
                      onChange={onChange}
                      required
                      className='w-full pl-10 px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                      placeholder='Enter postcode'
                    />
                  </div>
                </div>
              </div>
            </motion.div>

            {/* Contact Information */}
            <motion.div variants={itemVariants} className='space-y-6'>
              <h2 className='text-xl font-semibold text-gray-900 dark:text-white flex items-center'>
                <FiPhone className='mr-2' /> Contact Information
              </h2>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
                {/* Phone Number */}
                <div>
                  <label className='block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2'>Phone Number</label>
                  <div className='relative'>
                    <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                      <FiPhone className='text-gray-400' />
                    </div>
                    <input
                      type='text'
                      name='phone'
                      value={phone}
                      onChange={onChange}
                      className='w-full pl-10 px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                      placeholder='Enter phone number'
                    />
                  </div>
                </div>
                {/* Email Address */}
                <div>
                  <label className='block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2'>Email Address</label>
                  <div className='relative'>
                    <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                      <FiMail className='text-gray-400' />
                    </div>
                    <input
                      type='email'
                      name='email'
                      value={email}
                      onChange={onChange}
                      className='w-full pl-10 px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                      placeholder='Enter email address'
                    />
                  </div>
                </div>
                {/* Website */}
                <div>
                  <label className='block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2'>Website</label>
                  <div className='relative'>
                    <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                      <FiGlobe className='text-gray-400' />
                    </div>
                    <input
                      type='text'
                      name='website'
                      value={website}
                      onChange={onChange}
                      className='w-full pl-10 px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                      placeholder='Enter website URL'
                    />
                  </div>
                </div>
              </div>
            </motion.div>

            {/* Contact Person Details */}
            <motion.div variants={itemVariants} className='space-y-6'>
              <h2 className='text-xl font-semibold text-gray-900 dark:text-white flex items-center'>
                <FiUser className='mr-2' /> Contact Person Details
              </h2>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
                {/* Contact Person Name */}
                <div>
                  <label className='block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2'>Name</label>
                  <div className='relative'>
                    <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                      <FiUser className='text-gray-400' />
                    </div>
                    <input
                      type='text'
                      name='contactPersonName'
                      value={contactPersonName}
                      onChange={onChange}
                      className='w-full pl-10 px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                      placeholder='Enter contact person name'
                    />
                  </div>
                </div>
                {/* Contact Person Position */}
                <div>
                  <label className='block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2'>Position</label>
                  <div className='relative'>
                    <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                      <FiUser className='text-gray-400' />
                    </div>
                    <input
                      type='text'
                      name='contactPersonPosition'
                      value={contactPersonPosition}
                      onChange={onChange}
                      className='w-full pl-10 px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                      placeholder='Enter position'
                    />
                  </div>
                </div>
                {/* Contact Person Email */}
                <div>
                  <label className='block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2'>Email</label>
                  <div className='relative'>
                    <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                      <FiMail className='text-gray-400' />
                    </div>
                    <input
                      type='email'
                      name='contactPersonEmail'
                      value={contactPersonEmail}
                      onChange={onChange}
                      className='w-full pl-10 px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                      placeholder='Enter contact person email'
                    />
                  </div>
                </div>
                {/* Contact Person Phone */}
                <div>
                  <label className='block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2'>Phone</label>
                  <div className='relative'>
                    <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                      <FiPhone className='text-gray-400' />
                    </div>
                    <input
                      type='text'
                      name='contactPersonPhone'
                      value={contactPersonPhone}
                      onChange={onChange}
                      className='w-full pl-10 px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                      placeholder='Enter contact person phone'
                    />
                  </div>
                </div>
              </div>
            </motion.div>

            {/* Additional Notes */}
            <motion.div variants={itemVariants} className='space-y-6'>
              <h2 className='text-xl font-semibold text-gray-900 dark:text-white flex items-center'>Additional Notes</h2>
              <div>
                <textarea
                  name='notes'
                  value={notes}
                  onChange={onChange}
                  rows={4}
                  className='w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                  placeholder='Enter any additional notes'
                />
              </div>
            </motion.div>

            {/* Submit Button */}
            <motion.div variants={itemVariants} className='flex justify-end'>
              <button
                type='submit'
                className='inline-flex items-center px-6 py-3 rounded-md bg-primary text-white hover:bg-primary-dark transition-colors mb-5'>
                <FiSave className='mr-2' /> Add Strategic Partner
              </button>
            </motion.div>
          </form>
        </motion.div>
      </div>
    </div>
  )
}

export default AddStrategicPartner
