import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import { useSelector, useDispatch } from 'react-redux'
import { getCharityDetails, updateCharity, reset } from '../features/charity/charitySlice'
import Spinner from '../components/Spinner'
import BackButton from '../components/BackButton'
import { motion } from 'framer-motion'
import { FiHeart, FiInfo, FiMapPin, FiMail, FiPhone, FiGlobe, FiUser, FiSave } from 'react-icons/fi'

function EditCharity() {
  const { charityId } = useParams()
  const { isLoading, isSuccess, isError, message, charity } = useSelector((state) => state.charities)

  const [formData, setFormData] = useState({
    charityName: '',
    registrationNumber: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    region: '',
    country: '',
    postcode: '',
    phone: '',
    email: '',
    website: '',
    contactPersonName: '',
    contactPersonPosition: '',
    contactPersonEmail: '',
    contactPersonPhone: '',
    charityType: '',
    charityCategory: '',
    missionStatement: '',
    donationMethods: [],
    notes: '',
  })

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (isError) {
      toast.error(message)
    } else if (charity && charity.charity) {
      const {
        charity: {
          charityName,
          registrationNumber,
          address,
          phone,
          email,
          website,
          contactPerson,
          charityType,
          charityCategory,
          missionStatement,
          donationMethods,
          notes,
        },
      } = charity

      setFormData({
        charityName,
        registrationNumber,
        addressLine1: address?.addressLine1,
        addressLine2: address?.addressLine2,
        city: address?.city,
        region: address?.region,
        country: address?.country,
        postcode: address?.postcode,
        phone,
        email,
        website,
        contactPersonName: contactPerson?.name,
        contactPersonPosition: contactPerson?.position,
        contactPersonEmail: contactPerson?.email,
        contactPersonPhone: contactPerson?.phone,
        charityType,
        charityCategory,
        missionStatement,
        donationMethods,
        notes,
      })
    }

    if (isSuccess) {
      toast.success('Charity Updated Successfully')
      navigate('/charities')
    }

    return () => {
      dispatch(reset())
    }
  }, [isError, isSuccess, message, navigate, dispatch, charityId, charity])

  useEffect(() => {
    dispatch(getCharityDetails(charityId))
  }, [dispatch, charityId])

  const onChange = (e) => {
    if (e.target.name === 'donationMethods') {
      const selectedMethods = Array.from(e.target.selectedOptions, (option) => option.value)
      setFormData((prevState) => ({
        ...prevState,
        donationMethods: selectedMethods,
      }))
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }))
    }
  }

  const onSubmit = (e) => {
    e.preventDefault()

    const charityData = {
      charityId,
      charityName: formData.charityName,
      registrationNumber: formData.registrationNumber,
      address: {
        addressLine1: formData.addressLine1,
        addressLine2: formData.addressLine2,
        city: formData.city,
        region: formData.region,
        country: formData.country,
        postcode: formData.postcode,
      },
      phone: formData.phone,
      email: formData.email,
      website: formData.website,
      contactPerson: {
        name: formData.contactPersonName,
        position: formData.contactPersonPosition,
        email: formData.contactPersonEmail,
        phone: formData.contactPersonPhone,
      },
      charityType: formData.charityType,
      charityCategory: formData.charityCategory,
      missionStatement: formData.missionStatement,
      donationMethods: formData.donationMethods,
      notes: formData.notes,
    }

    dispatch(updateCharity(charityData))
  }

  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3,
      },
    },
  }

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    show: { y: 0, opacity: 1 },
  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <div className='min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800'>
      {/* Hero Section */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className='relative overflow-hidden bg-gradient-to-r from-primary to-primary-dark py-16 mb-8'>
        <div className='relative z-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
          <div className='text-center'>
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className='text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl'>
              Edit Charity
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              className='mt-6 text-xl text-gray-100'>
              Update charity information
            </motion.p>
          </div>
        </div>

        {/* Decorative blob */}
        <div className='absolute top-0 right-0 -translate-y-12 translate-x-12 transform opacity-20'>
          <svg width='400' height='400' viewBox='0 0 184 184' xmlns='http://www.w3.org/2000/svg'>
            <path
              fill='white'
              d='M92 0C92 0 92 0 92 0C142.8 0 184 41.2 184 92C184 142.8 142.8 184 92 184C41.2 184 0 142.8 0 92C0 41.2 41.2 0 92 0Z'
            />
          </svg>
        </div>
      </motion.div>

      <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pb-20'>
        <BackButton />

        <motion.form variants={containerVariants} initial='hidden' animate='show' onSubmit={onSubmit} className='space-y-8'>
          {/* Charity Information */}
          <motion.div variants={itemVariants} className='bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6 space-y-6'>
            <div className='flex items-center gap-2 text-xl font-semibold text-gray-900 dark:text-white'>
              <FiHeart className='text-primary' />
              <h2>Charity Information</h2>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
              <div>
                <label className='block font-medium mb-1'>Charity Name</label>
                <input
                  type='text'
                  name='charityName'
                  value={formData.charityName}
                  onChange={onChange}
                  className='input input-bordered w-full'
                  required
                />
              </div>
              <div>
                <label className='block font-medium mb-1'>Registration Number</label>
                <input
                  type='text'
                  name='registrationNumber'
                  value={formData.registrationNumber}
                  onChange={onChange}
                  className='input input-bordered w-full'
                  required
                />
              </div>
            </div>
          </motion.div>

          {/* Address Information */}
          <motion.div variants={itemVariants} className='bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6 space-y-6'>
            <div className='flex items-center gap-2 text-xl font-semibold text-gray-900 dark:text-white'>
              <FiMapPin className='text-primary' />
              <h2>Address Information</h2>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
              <div>
                <label className='block font-medium mb-1'>Address Line 1</label>
                <input
                  type='text'
                  name='addressLine1'
                  value={formData.addressLine1}
                  onChange={onChange}
                  className='input input-bordered w-full'
                  required
                />
              </div>
              <div>
                <label className='block font-medium mb-1'>Address Line 2</label>
                <input type='text' name='addressLine2' value={formData.addressLine2} onChange={onChange} className='input input-bordered w-full' />
              </div>
              <div>
                <label className='block font-medium mb-1'>City</label>
                <input type='text' name='city' value={formData.city} onChange={onChange} className='input input-bordered w-full' required />
              </div>
              <div>
                <label className='block font-medium mb-1'>Region</label>
                <input type='text' name='region' value={formData.region} onChange={onChange} className='input input-bordered w-full' />
              </div>
              <div>
                <label className='block font-medium mb-1'>Country</label>
                <input type='text' name='country' value={formData.country} onChange={onChange} className='input input-bordered w-full' required />
              </div>
              <div>
                <label className='block font-medium mb-1'>Postcode</label>
                <input type='text' name='postcode' value={formData.postcode} onChange={onChange} className='input input-bordered w-full' required />
              </div>
            </div>
          </motion.div>

          {/* Contact Information */}
          <motion.div variants={itemVariants} className='bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6 space-y-6'>
            <div className='flex items-center gap-2 text-xl font-semibold text-gray-900 dark:text-white'>
              <FiPhone className='text-primary' />
              <h2>Contact Information</h2>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
              <div>
                <label className='block font-medium mb-1'>Phone Number</label>
                <input type='text' name='phone' value={formData.phone} onChange={onChange} className='input input-bordered w-full' />
              </div>
              <div>
                <label className='block font-medium mb-1'>Email Address</label>
                <input type='email' name='email' value={formData.email} onChange={onChange} className='input input-bordered w-full' />
              </div>
              <div>
                <label className='block font-medium mb-1'>Website</label>
                <input type='text' name='website' value={formData.website} onChange={onChange} className='input input-bordered w-full' />
              </div>
            </div>
          </motion.div>

          {/* Contact Person */}
          <motion.div variants={itemVariants} className='bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6 space-y-6'>
            <div className='flex items-center gap-2 text-xl font-semibold text-gray-900 dark:text-white'>
              <FiUser className='text-primary' />
              <h2>Contact Person</h2>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
              <div>
                <label className='block font-medium mb-1'>Name</label>
                <input
                  type='text'
                  name='contactPersonName'
                  value={formData.contactPersonName}
                  onChange={onChange}
                  className='input input-bordered w-full'
                />
              </div>
              <div>
                <label className='block font-medium mb-1'>Position</label>
                <input
                  type='text'
                  name='contactPersonPosition'
                  value={formData.contactPersonPosition}
                  onChange={onChange}
                  className='input input-bordered w-full'
                />
              </div>
              <div>
                <label className='block font-medium mb-1'>Email</label>
                <input
                  type='email'
                  name='contactPersonEmail'
                  value={formData.contactPersonEmail}
                  onChange={onChange}
                  className='input input-bordered w-full'
                />
              </div>
              <div>
                <label className='block font-medium mb-1'>Phone</label>
                <input
                  type='text'
                  name='contactPersonPhone'
                  value={formData.contactPersonPhone}
                  onChange={onChange}
                  className='input input-bordered w-full'
                />
              </div>
            </div>
          </motion.div>

          {/* Additional Information */}
          <motion.div variants={itemVariants} className='bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6 space-y-6'>
            <div className='flex items-center gap-2 text-xl font-semibold text-gray-900 dark:text-white'>
              <FiInfo className='text-primary' />
              <h2>Additional Information</h2>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
              <div>
                <label className='block font-medium mb-1'>Charity Type</label>
                <select name='charityType' value={formData.charityType} onChange={onChange} className='select select-bordered w-full' required>
                  <option value=''>Select Charity Type</option>
                  <option value='local'>Local</option>
                  <option value='national'>National</option>
                  <option value='international'>International</option>
                </select>
              </div>
              <div>
                <label className='block font-medium mb-1'>Charity Category</label>
                <select
                  name='charityCategory'
                  value={formData.charityCategory}
                  onChange={onChange}
                  className='select select-bordered w-full'
                  required>
                  <option value=''>Select Charity Category</option>
                  <option value='animals'>Animals</option>
                  <option value='children'>Children</option>
                  <option value='education'>Education</option>
                  <option value='environment'>Environment</option>
                  <option value='health'>Health</option>
                  <option value='humanitarian'>Humanitarian</option>
                  <option value='poverty'>Poverty</option>
                  <option value='other'>Other</option>
                </select>
              </div>
              <div className='md:col-span-2'>
                <label className='block font-medium mb-1'>Mission Statement</label>
                <textarea
                  name='missionStatement'
                  value={formData.missionStatement}
                  onChange={onChange}
                  className='textarea textarea-bordered w-full'
                  rows={4}
                />
              </div>
              <div>
                <label className='block font-medium mb-1'>Donation Methods</label>
                <select
                  name='donationMethods'
                  value={formData.donationMethods}
                  onChange={onChange}
                  className='select select-bordered w-full'
                  multiple
                  required>
                  <option value='online'>Online</option>
                  <option value='bank_transfer'>Bank Transfer</option>
                  <option value='cheque'>Cheque</option>
                  <option value='cash'>Cash</option>
                </select>
                <p className='text-sm text-gray-500 mt-1'>Hold CTRL/CMD to select multiple</p>
              </div>
              <div>
                <label className='block font-medium mb-1'>Notes</label>
                <textarea name='notes' value={formData.notes} onChange={onChange} className='textarea textarea-bordered w-full' rows={4} />
              </div>
            </div>
          </motion.div>

          {/* Submit Button */}
          <motion.div variants={itemVariants} className='flex justify-end'>
            <button type='submit' className='btn btn-primary gap-2'>
              <FiSave />
              Update Charity
            </button>
          </motion.div>
        </motion.form>
      </div>
    </div>
  )
}

export default EditCharity
