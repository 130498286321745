import BackButton from '../components/BackButton'
import { useSelector, useDispatch } from 'react-redux'
import toast from 'react-hot-toast'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { getDailyWalletActivity, reset, resetTedsState } from '../features/teds/tedsSlice'
import Pagination from '../components/Pagination'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { motion } from 'framer-motion'
import { FiCalendar, FiArrowDown, FiArrowUp, FiUser, FiUsers, FiSearch } from 'react-icons/fi'

const DailyWalletActivity = () => {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedDate, setSelectedDate] = useState(() => {
    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)
    return yesterday
  })

  const {
    walletActivity: { walletActivities, totalPages },
    isError,
    isSuccess,
    isLoading,
    message,
  } = useSelector((state) => state.teds)

  useEffect(() => {
    if (isError) {
      toast.error(message)
      dispatch(reset())
    }
    if (isSuccess) {
      dispatch(reset())
    }
  }, [isSuccess, dispatch, isError, message])

  useEffect(() => {
    const filterData = {
      date: selectedDate,
      page: currentPage,
      limit: 15,
    }
    dispatch(getDailyWalletActivity(filterData))

    return () => {
      dispatch(reset())
    }
  }, [dispatch, currentPage, selectedDate])

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const handleDateChange = (date) => {
    setSelectedDate(date)
    setCurrentPage(1)
    dispatch(resetTedsState())
  }

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3,
      },
    },
  }

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    show: { y: 0, opacity: 1 },
  }

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })
  }

  // Function to get display name for user types
  const getUserTypeDisplay = (userType) => {
    if (userType === 'ClientUser') {
      return 'Partner'
    }
    return userType
  }

  // Add this function to determine badge colors based on user type
  const getBadgeColorsByUserType = (userType) => {
    switch (userType) {
      case 'Customer':
        return 'bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200'
      case 'Partner':
      case 'ClientUser':
        return 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200'
      case 'Agent':
        return 'bg-orange-100 text-orange-800 dark:bg-orange-900 dark:text-orange-200'
      case 'AdminUser':
        return 'bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-200'
      default:
        return 'bg-gray-100 text-gray-800 dark:bg-gray-900 dark:text-gray-200'
    }
  }

  return (
    <div className='min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800'>
      {/* Hero Section */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className='relative overflow-hidden bg-gradient-to-r from-primary to-primary-dark py-16 mb-4'>
        <div className='relative z-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
          <div className='text-center'>
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className='text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl'>
              Daily Wallet Activity
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              className='mt-6 text-xl text-gray-100'>
              Track and analyze daily TEDS transactions
            </motion.p>
          </div>
        </div>

        {/* Decorative blob */}
        <div className='absolute top-0 right-0 -translate-y-12 translate-x-12 transform opacity-20'>
          <svg width='400' height='400' viewBox='0 0 184 184' xmlns='http://www.w3.org/2000/svg'>
            <path
              fill='white'
              d='M92 0C92 0 92 0 92 0C142.8 0 184 41.2 184 92C184 142.8 142.8 184 92 184C41.2 184 0 142.8 0 92C0 41.2 41.2 0 92 0Z'
            />
          </svg>
        </div>
      </motion.div>

      <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pb-20'>
        <div className='flex justify-start w-full mb-4'>
          <BackButton />
        </div>

        <motion.div variants={containerVariants} initial='hidden' animate='show' className='w-full'>
          <motion.div variants={itemVariants} className='bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-6 mb-8'>
            <div className='flex items-center gap-3 text-xl font-semibold text-gray-900 dark:text-white border-b border-gray-200 dark:border-gray-700 pb-4 mb-4'>
              <FiCalendar className='text-primary' />
              <h2>Select Date</h2>
            </div>

            <div className='flex flex-col sm:flex-row gap-4 items-center'>
              <div className='w-full sm:w-auto relative'>
                <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                  <FiCalendar className='text-gray-400' />
                </div>
                <DatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  dateFormat='dd/MM/yyyy'
                  className='w-full pl-10 px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                />
              </div>
              <p className='text-gray-600 dark:text-gray-400 font-medium'>
                Viewing activity for: <span className='text-primary font-semibold'>{formatDate(selectedDate)}</span>
              </p>
            </div>
          </motion.div>

          <motion.div variants={itemVariants} className='bg-white dark:bg-gray-800 rounded-2xl shadow-xl overflow-hidden'>
            {isLoading ? (
              <div className='flex justify-center items-center p-12'>
                <div className='animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary'></div>
              </div>
            ) : (
              <>
                {/* Desktop view - Table */}
                <div className='hidden md:block overflow-x-auto'>
                  <table className='w-full min-w-full'>
                    <thead className='bg-gray-50 dark:bg-gray-700 border-b border-gray-200 dark:border-gray-600'>
                      <tr>
                        <th className='px-6 py-4 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>
                          User Name
                        </th>
                        <th className='px-6 py-4 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>
                          User Type
                        </th>
                        <th className='px-6 py-4 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>
                          TEDS Incoming
                        </th>
                        <th className='px-6 py-4 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>
                          TEDS Outgoing
                        </th>
                      </tr>
                    </thead>
                    <tbody className='bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700'>
                      {!Array.isArray(walletActivities) || walletActivities.length === 0 ? (
                        <tr>
                          <td colSpan='4' className='px-6 py-4 text-center text-gray-500 dark:text-gray-400'>
                            <div className='flex flex-col items-center justify-center py-8'>
                              <FiSearch className='w-12 h-12 text-gray-300 dark:text-gray-600 mb-3' />
                              <p className='text-lg'>No wallet activity found for the selected date.</p>
                              <p className='text-sm text-gray-500 dark:text-gray-400'>Try selecting a different date</p>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        walletActivities.map((activity, index) => (
                          <tr
                            key={activity.walletId._id}
                            className={`hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors ${
                              index % 2 === 0 ? 'bg-gray-50/50 dark:bg-gray-800/50' : ''
                            }`}>
                            <td className='px-6 py-4 whitespace-nowrap'>
                              <Link
                                to={`/member-overview/${activity.walletId.userAccount.userId}/${encodeURIComponent(
                                  activity.walletId.userAccount.userName
                                )}`}
                                className='text-primary hover:text-primary-dark transition-colors font-medium'>
                                {activity.walletId.userAccount.userName}
                              </Link>
                            </td>
                            <td className='px-6 py-4 whitespace-nowrap'>
                              <span
                                className={`inline-flex items-center gap-1.5 px-2.5 py-0.5 rounded-full text-xs font-medium ${getBadgeColorsByUserType(
                                  activity.userAccount.userType
                                )}`}>
                                {activity.userAccount.userType === 'Customer' ? (
                                  <>
                                    <FiUser className='w-3 h-3' /> {getUserTypeDisplay(activity.userAccount.userType)}
                                  </>
                                ) : (
                                  <>
                                    <FiUsers className='w-3 h-3' /> {getUserTypeDisplay(activity.userAccount.userType)}
                                  </>
                                )}
                              </span>
                            </td>
                            <td className='px-6 py-4 whitespace-nowrap'>
                              <div className='flex items-center text-green-500 font-medium'>
                                <FiArrowDown className='mr-1.5 w-4 h-4' /> {activity.totalInflow.toFixed(2)} TEDS
                              </div>
                            </td>
                            <td className='px-6 py-4 whitespace-nowrap'>
                              <div className='flex items-center text-red-500 font-medium'>
                                <FiArrowUp className='mr-1.5 w-4 h-4' /> {activity.totalOutflow.toFixed(2)} TEDS
                              </div>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>

                {/* Mobile view - Cards */}
                <div className='md:hidden'>
                  {!Array.isArray(walletActivities) || walletActivities.length === 0 ? (
                    <div className='px-4 py-8 text-center text-gray-500 dark:text-gray-400'>
                      <div className='flex flex-col items-center justify-center'>
                        <FiSearch className='w-12 h-12 text-gray-300 dark:text-gray-600 mb-3' />
                        <p className='text-lg'>No wallet activity found</p>
                        <p className='text-sm'>Try selecting a different date</p>
                      </div>
                    </div>
                  ) : (
                    <div className='divide-y divide-gray-200 dark:divide-gray-700'>
                      {walletActivities.map((activity) => (
                        <div key={activity.walletId._id} className='p-4 space-y-3'>
                          <div className='flex justify-between items-start'>
                            <div>
                              <Link
                                to={`/member-overview/${activity.walletId.userAccount.userId}/${encodeURIComponent(
                                  activity.walletId.userAccount.userName
                                )}`}
                                className='text-primary font-medium'>
                                {activity.walletId.userAccount.userName}
                              </Link>
                              <div className='mt-1'>
                                <span
                                  className={`inline-flex items-center gap-1.5 px-2.5 py-0.5 rounded-full text-xs font-medium ${getBadgeColorsByUserType(
                                    activity.userAccount.userType
                                  )}`}>
                                  {activity.userAccount.userType === 'Customer' ? (
                                    <>
                                      <FiUser className='w-3 h-3' /> {getUserTypeDisplay(activity.userAccount.userType)}
                                    </>
                                  ) : (
                                    <>
                                      <FiUsers className='w-3 h-3' /> {getUserTypeDisplay(activity.userAccount.userType)}
                                    </>
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className='flex space-x-3'>
                              <div className='flex flex-col items-end'>
                                <div className='text-xs text-gray-500 dark:text-gray-400'>Incoming</div>
                                <div className='flex items-center text-green-500 font-medium'>
                                  <FiArrowDown className='mr-1 w-3 h-3' /> {activity.totalInflow.toFixed(2)}
                                </div>
                              </div>
                              <div className='flex flex-col items-end'>
                                <div className='text-xs text-gray-500 dark:text-gray-400'>Outgoing</div>
                                <div className='flex items-center text-red-500 font-medium'>
                                  <FiArrowUp className='mr-1 w-3 h-3' /> {activity.totalOutflow.toFixed(2)}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </>
            )}

            {/* Pagination */}
            <div className='px-6 py-4 bg-gray-50 dark:bg-gray-700 border-t border-gray-200 dark:border-gray-600'>
              <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
            </div>
          </motion.div>
        </motion.div>
      </div>
    </div>
  )
}

export default DailyWalletActivity
