import { Navigate, Outlet } from 'react-router-dom'
import { useAuthStatus } from '../hooks/useAuthStatus'
import { useSelector } from 'react-redux'
import Spinner from './Spinner'

const PrivateRoute = ({ roles, allowedAgentTypes = [] }) => {
  const { isAuthenticated, checkingStatus } = useAuthStatus()

  const { user } = useSelector((state) => state.auth)

  if (checkingStatus) {
    return <Spinner />
  }

  if (!user || !user.role || (roles.length && !roles.includes(user.role))) {
    return <Navigate to='/login' />
  }

  if (isAuthenticated) {
    // Check agent type if required
    if (user.role === 'AgentUser' && allowedAgentTypes.length > 0) {
      if (!allowedAgentTypes.includes(user.agentType)) {
        return <Navigate to='/login' />
      }
    }

    return <Outlet />
  }

  return <Navigate to='/login' />
}

export default PrivateRoute
