import { Link, useLocation } from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion'
import { FiHome, FiUsers, FiDollarSign, FiSettings, FiActivity } from 'react-icons/fi'
import { useSelector } from 'react-redux'

const BottomNav = () => {
  const location = useLocation()
  const { user } = useSelector((state) => state.auth)

  // Define navigation items based on user role and type
  let navItems = []

  if (user?.role === 'AdminUser') {
    // Admin navigation
    navItems = [
      { path: '/', icon: FiHome, label: 'Home' },
      { path: '/agents', icon: FiUsers, label: 'Agents' },
      { path: '/teds', icon: FiDollarSign, label: 'TEDS' },
      { path: '/all-sales', icon: FiActivity, label: 'Sales' },
      { path: '/commissions', icon: FiDollarSign, label: 'Commissions' },
    ]
  } else if (user?.role === 'AgentUser') {
    // Base items for all agent types
    if (['basicAgent', 'salesManager', 'campaignManager', 'influencer'].includes(user.agentType)) {
      navItems = [
        { path: '/', icon: FiHome, label: 'Home' },
        { path: '/agent-sales', icon: FiActivity, label: 'Sales' },
        { path: '/commissions', icon: FiDollarSign, label: 'Commissions' },
        { path: '/settings', icon: FiSettings, label: 'Settings' },
      ]
    } else if (user.agentType === 'countryManager') {
      navItems = [
        { path: '/', icon: FiHome, label: 'Home' },
        { path: '/all-sales', icon: FiActivity, label: 'All Sales' },
        { path: '/commissions', icon: FiDollarSign, label: 'Commissions' },
        { path: '/settings', icon: FiSettings, label: 'Settings' },
      ]
    }
  }

  return (
    <div className='fixed bottom-0 left-0 right-0 z-50 block md:hidden'>
      {/* Floating Navigation Bar */}
      <motion.nav
        initial={{ y: 100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ type: 'spring', stiffness: 200, damping: 20 }}
        className='bg-white/10 dark:bg-gray-900/50 backdrop-blur-xl border-t border-white/20 dark:border-white/10'>
        <div className='relative px-2 py-4'>
          {/* Background Glow Effect */}
          <div className='absolute inset-0'>
            <div className='absolute inset-0 bg-gradient-to-r from-orange-500/10 via-amber-500/10 to-yellow-500/10 animate-gradient-shift' />
          </div>

          {/* Navigation Items */}
          <div className='relative flex justify-around items-center'>
            {navItems.map((item) => {
              const isActive = location.pathname === item.path

              return (
                <Link key={item.path} to={item.path} className='relative group'>
                  <motion.div className='relative flex flex-col items-center' whileTap={{ scale: 0.9 }}>
                    {/* Active Background */}
                    <AnimatePresence>
                      {isActive && (
                        <motion.div
                          layoutId='navBackground'
                          className='absolute -inset-3 bg-orange-500/10 dark:bg-orange-500/20 rounded-xl'
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 0.2 }}
                        />
                      )}
                    </AnimatePresence>

                    {/* Icon Container */}
                    <motion.div
                      className='relative z-10 p-2'
                      animate={{
                        y: isActive ? -4 : 0,
                      }}
                      transition={{ type: 'spring', stiffness: 300, damping: 20 }}>
                      {/* Icon Glow Effect */}
                      {isActive && (
                        <motion.div
                          className='absolute inset-0 bg-orange-500/20 rounded-full blur-md'
                          initial={{ scale: 0 }}
                          animate={{ scale: 1 }}
                          exit={{ scale: 0 }}
                        />
                      )}

                      {/* Icon */}
                      <item.icon
                        className={`w-6 h-6 relative z-10 transition-colors duration-200 ${
                          isActive
                            ? 'text-orange-500 drop-shadow-[0_0_8px_rgba(249,115,22,0.5)]'
                            : 'text-gray-500 dark:text-gray-400 group-hover:text-orange-400'
                        }`}
                      />
                    </motion.div>

                    {/* Label */}
                    <motion.span
                      className={`text-xs font-medium transition-all duration-200 ${
                        isActive ? 'text-orange-500' : 'text-gray-500 dark:text-gray-400 group-hover:text-orange-400'
                      }`}
                      animate={{
                        y: isActive ? 0 : 2,
                        scale: isActive ? 1.1 : 1,
                      }}
                      transition={{ type: 'spring', stiffness: 300, damping: 20 }}>
                      {item.label}
                    </motion.span>

                    {/* Hover Indicator */}
                    <motion.div
                      className='absolute -bottom-1 left-1/2 w-1 h-1 bg-orange-500 rounded-full opacity-0 transform -translate-x-1/2'
                      animate={{
                        opacity: isActive ? 1 : 0,
                        scale: isActive ? 1 : 0,
                      }}
                      transition={{ duration: 0.2 }}
                    />
                  </motion.div>
                </Link>
              )
            })}
          </div>
        </div>
      </motion.nav>

      {/* Add custom styles for the gradient animation */}
      <style jsx global>{`
        @keyframes gradient-shift {
          0% {
            background-position: 0% 50%;
          }
          50% {
            background-position: 100% 50%;
          }
          100% {
            background-position: 0% 50%;
          }
        }
        .animate-gradient-shift {
          animation: gradient-shift 8s ease infinite;
          background-size: 200% 200%;
        }
      `}</style>
    </div>
  )
}

export default BottomNav
