import { useSelector, useDispatch } from 'react-redux'
import toast from 'react-hot-toast'
import { useEffect, useState } from 'react'
import Spinner from '../components/Spinner'
import { getAgentsPartners, reset } from '../features/partners/partnersSlice'
import Pagination from '../components/Pagination'
import { useDebounce } from '../components/useDebounce'
import { motion } from 'framer-motion'
import { FiSearch, FiUsers, FiDatabase, FiCopy } from 'react-icons/fi'
import { Link } from 'react-router-dom'

const MyReferrals = () => {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const [copiedCode, setCopiedCode] = useState(false)

  const { partners, isError, isSuccess, isLoading, message } = useSelector((state) => state.partners)
  const { user } = useSelector((state) => state.auth)

  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3,
      },
    },
  }

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    show: { y: 0, opacity: 1 },
  }

  useEffect(() => {
    if (isError) {
      toast.error(message)
    }
    if (isSuccess) {
      dispatch(reset())
    }
  }, [isSuccess, dispatch, isError, message])

  useEffect(() => {
    const filterData = {
      page: currentPage,
      limit: 20,
      searchTerm: debouncedSearchTerm,
      agentReferralCode: user.agentReferralCode,
    }

    dispatch(getAgentsPartners(filterData))

    return () => {
      dispatch(reset())
    }
  }, [dispatch, currentPage, debouncedSearchTerm, user])

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
    setCurrentPage(1)
  }

  const copyReferralCode = () => {
    navigator.clipboard.writeText(user.agentReferralCode)
    setCopiedCode(true)
    setTimeout(() => setCopiedCode(false), 2000)
    toast.success('Referral code copied to clipboard!')
  }

  return (
    <div className='bg-gradient-to-b from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800'>
      {/* Hero Section */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className='relative overflow-hidden bg-gradient-to-r from-primary to-primary-dark py-16 mb-4'>
        <div className='relative z-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
          <div className='text-center'>
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className='text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl'>
              My Referrals
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              className='mt-6 text-xl text-gray-100'>
              Track your partner referrals and commission earnings
            </motion.p>
          </div>
        </div>

        {/* Decorative blob */}
        <div className='absolute top-0 right-0 -translate-y-12 translate-x-12 transform opacity-20'>
          <svg width='400' height='400' viewBox='0 0 184 184' xmlns='http://www.w3.org/2000/svg'>
            <path
              fill='white'
              d='M92 0C92 0 92 0 92 0C142.8 0 184 41.2 184 92C184 142.8 142.8 184 92 184C41.2 184 0 142.8 0 92C0 41.2 41.2 0 92 0Z'
            />
          </svg>
        </div>
      </motion.div>

      <div className='mx-auto max-w-8xl px-4 sm:px-6 lg:px-8'>
        {/* Referral Code Card */}
        <motion.div variants={containerVariants} initial='hidden' animate='show' className='bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6 mb-8'>
          <div className='flex flex-col md:flex-row items-center justify-between gap-4'>
            <div className='flex items-center'>
              <FiUsers className='text-primary mr-3 h-8 w-8' />
              <div>
                <h2 className='text-xl font-bold text-gray-900 dark:text-white'>Your Referral Code</h2>
                <p className='text-sm text-gray-500 dark:text-gray-400'>Share this code with potential partners</p>
              </div>
            </div>
            <div className='flex items-center bg-gray-100 dark:bg-gray-700 rounded-lg px-4 py-2'>
              <span className='text-xl font-mono font-semibold text-gray-800 dark:text-gray-200 mr-3'>{user.agentReferralCode}</span>
              <button onClick={copyReferralCode} className='text-primary hover:text-primary-dark transition-colors' aria-label='Copy referral code'>
                <FiCopy className={`h-5 w-5 ${copiedCode ? 'text-green-500' : ''}`} />
              </button>
            </div>
          </div>
        </motion.div>

        {/* Search Controls */}
        <motion.div variants={containerVariants} initial='hidden' animate='show' className='bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6 mb-8'>
          <div className='flex flex-col md:flex-row items-center justify-between gap-4'>
            <div className='relative flex-1 w-full'>
              <FiSearch className='absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400' />
              <input
                type='text'
                placeholder='Search for a partner by name or email'
                value={searchTerm}
                onChange={handleSearchChange}
                className='w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-primary'
              />
            </div>
          </div>
        </motion.div>

        {/* Partners Table */}
        <motion.div
          variants={containerVariants}
          initial='hidden'
          animate='show'
          className='bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6 mb-8 overflow-hidden'>
          <div className='flex items-center justify-between mb-6'>
            <h2 className='text-2xl font-bold text-gray-900 dark:text-white'>Referred Partners</h2>
            <div className='text-sm text-gray-500 dark:text-gray-400 flex items-center'>
              <FiDatabase className='mr-2' />
              <span>Total: {partners.partners && Array.isArray(partners.partners) ? partners.partners.length : 0} partners</span>
            </div>
          </div>

          <div className='overflow-x-auto'>
            <table className='w-full table-auto bg-[#1a1f2b]'>
              <thead className='bg-[#1a1f2b]'>
                <tr>
                  <th className='px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Business Name</th>
                  <th className='px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Client Name</th>
                  <th className='px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Industry</th>
                  <th className='px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Date Joined</th>
                  <th className='px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Subscription</th>
                  <th className='px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Plan</th>
                  <th className='px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Monthly Payments</th>
                  <th className='px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Yearly Payments</th>
                  <th className='px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Last Payment</th>
                  <th className='px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Next Payment</th>
                  <th className='px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Total Commission</th>
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-700'>
                {isLoading ? (
                  <tr>
                    <td colSpan='11' className='text-center py-8'>
                      <Spinner />
                    </td>
                  </tr>
                ) : !Array.isArray(partners.partners) || partners.partners.length === 0 ? (
                  <tr>
                    <td colSpan='11' className='text-center py-8 text-gray-400'>
                      No partners found.
                    </td>
                  </tr>
                ) : (
                  partners.partners.map((partner) => (
                    <tr key={partner._id} className='group bg-[#1a1f2b] hover:bg-[#252b38]'>
                      <td className='px-6 py-4 text-sm font-medium text-white'>{partner.businessName}</td>
                      <td className='px-6 py-4 text-sm text-gray-300'>{partner.name}</td>
                      <td className='px-6 py-4 text-sm text-gray-300'>{partner.businessType}</td>
                      <td className='px-6 py-4 text-sm text-gray-300'>{new Date(partner.createdAt).toLocaleDateString()}</td>
                      <td className='px-6 py-4'>
                        <span
                          className={`px-2 py-1 text-xs font-medium rounded-full ${
                            partner.subscription?.status === 'active'
                              ? 'bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100'
                              : partner.subscription?.status === 'trialing'
                              ? 'bg-blue-100 text-blue-800 dark:bg-blue-800 dark:text-blue-100'
                              : 'bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-gray-100'
                          }`}>
                          {partner.subscription?.status || 'N/A'}
                        </span>
                      </td>
                      <td className='px-6 py-4 text-sm text-gray-300'>{partner.subscription?.plan === 'monthly' ? 'Monthly' : 'Yearly'}</td>
                      <td className='px-6 py-4 text-sm text-gray-300'>{partner.subscription?.monthlyPaymentsMade || 0}</td>
                      <td className='px-6 py-4 text-sm text-gray-300'>{partner.subscription?.yearlyPaymentsMade || 0}</td>
                      <td className='px-6 py-4 text-sm text-gray-300'>
                        {partner.subscription?.lastPaymentDate && !isNaN(new Date(partner.subscription?.lastPaymentDate))
                          ? new Date(partner.subscription?.lastPaymentDate).toLocaleDateString('en-GB', {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                            })
                          : 'N/A'}
                      </td>
                      <td className='px-6 py-4 text-sm text-gray-300'>
                        {partner.subscription?.endDate
                          ? new Date(partner.subscription?.endDate).toLocaleDateString('en-GB', {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                            })
                          : 'N/A'}
                      </td>
                      <td className='px-6 py-4 text-sm font-medium text-green-400'>
                        {(partner.subscription?.totalAgentCommission / 100).toLocaleString('en-GB', { style: 'currency', currency: 'GBP' })}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </motion.div>

        {/* Pagination */}
        {partners.totalPages > 1 && (
          <motion.div variants={containerVariants} initial='hidden' animate='show' className='flex justify-center mb-8'>
            <Pagination totalPages={partners.totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
          </motion.div>
        )}
      </div>
    </div>
  )
}

export default MyReferrals
