import api from '../../api'
import axios from 'axios'
const { REACT_APP_SERVER_URL } = process.env

const API_URL = `${REACT_APP_SERVER_URL}/api/agents`

console.log('🚀 ~ API_URL:', API_URL)
// Register a new user
// Register a new user
const register = async (userData) => {
  try {
    const response = await axios.post(`${API_URL}/register-admin`, userData)
    if (response.data) {
      localStorage.setItem('user', JSON.stringify(response.data))
    }
    return response.data
  } catch (error) {
    throw error
  }
}

// Login a new user
const login = async (userData) => {
  try {
    const response = await axios.post(`${API_URL}/login`, userData)
    if (response.data) {
      localStorage.setItem('user', JSON.stringify(response.data))
    }
    return response.data
  } catch (error) {
    throw error
  }
}

// Initiate password reset
const resetPassword = async (emailAddress) => {
  try {
    console.log('🚀 ~ file: authService.js:129 ~ resetPassword ~ emailAddress:', emailAddress)
    const response = await api.post(`${API_URL}/forgot-password`, { email: emailAddress })
    return response.data
  } catch (error) {
    throw error
  }
}

const verifyToken = async (verificationToken) => {
  try {
    const response = await api.get(`${API_URL}/verify-email/${verificationToken}`)

    return response.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 500) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

const resendVerificationEmail = async (token) => {
  console.log(token)
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

    const response = await api.post(`${API_URL}/resend-verification`, {}, config)

    return response.data
  } catch (error) {
    let errorMessage = 'An unexpected error occurred.'

    // check for error response
    if (error.response) {
      // status code related error
      switch (error.response.status) {
        case 400:
          errorMessage = 'Bad Request. The server could not understand the request due to invalid syntax.'
          break
        case 401:
          errorMessage = 'Unauthorized. The client must authenticate itself to get the requested response.'
          break
        case 403:
          errorMessage = 'Forbidden. The client does not have access rights to the content.'
          break
        case 404:
          errorMessage = 'Not Found. The server could not find the requested resource.'
          break
        case 500:
          errorMessage = 'Internal Server Error. The server has encountered problem'
          break
        default:
          errorMessage = error.response.data.error || errorMessage
          break
      }
    } else if (error.request) {
      // request made but no response
      errorMessage = 'The request was made but no response was received.'
    } else {
      // other errors
      errorMessage = error.message || errorMessage
    }

    throw new Error(errorMessage)
  }
}

// *update a agents user details
const updateAgentDetails = async (agentData, token) => {
  console.log('🚀 ~ file: authService.js:105 ~ updateAgentDetails ~ agentData:', agentData)

  console.log(agentData)
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await api.post(API_URL + '/update-agent', agentData, config)

  return response.data
}

// *get agent details
const getAgentDetails = async (token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await api.get(`${API_URL}/get-agent-details`, config)

    return response.data
  } catch (error) {
    throw error
  }
}

// Auth check
const authCheck = async (token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await api.get(`${API_URL}/auth-check`, config)
    return response.data
  } catch (error) {
    throw error
  }
}

// Search AML records by email
const searchAMLRecordsByEmail = async (email, token) => {
  console.log(email, 'email')
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/admin/aml-records/search`, { email }, config)
    return response.data
  } catch (error) {
    throw error
  }
}

// logout a new user
const logout = () => localStorage.removeItem('user')

const authService = {
  register,
  login,
  logout,
  resetPassword,
  verifyToken,
  resendVerificationEmail,
  updateAgentDetails,
  getAgentDetails,
  authCheck,
  searchAMLRecordsByEmail,
}

export default authService
