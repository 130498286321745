import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import BackButton from '../components/BackButton'
import toast from 'react-hot-toast'
import Spinner from '../components/Spinner'
import { getAgents, reset } from '../features/agents/agentsSlice'
import Pagination from '../components/Pagination'
import SalesDataDisplay from '../components/SalesDataDisplay'
import { Link } from 'react-router-dom'
import { useDebounce } from '../components/useDebounce'
import { motion } from 'framer-motion'
import { FiSearch, FiUser, FiUsers, FiBarChart2, FiUserPlus } from 'react-icons/fi'

const Agents = () => {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const [uniqueWeekLabels, setUniqueWeekLabels] = useState([])
  const [uniqueMonthLabels, setUniqueMonthLabels] = useState([])
  const [uniqueDayLabels, setUniqueDayLabels] = useState([])
  const [uniqueYearLabels, setUniqueYearLabels] = useState([])
  const [isSearchFocused, setIsSearchFocused] = useState(false)

  const { agents, isError, isSuccess, isLoading, message } = useSelector(
    (state) =>
      state.agents || {
        agents: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: '',
      }
  )

  const { user } = useSelector((state) => state.auth)

  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  // Add safe array fallback for agents.agents
  const agentList = React.useMemo(() => agents?.agents || [], [agents?.agents])

  useEffect(() => {
    if (isError) {
      toast.error(message)
      dispatch(reset())
    }
    if (isSuccess) {
      dispatch(reset())
    }
  }, [isSuccess, dispatch, isError, message])

  useEffect(() => {
    const filterData = {
      page: currentPage,
      limit: 20,
      searchTerm: debouncedSearchTerm,
      isActive: true,
    }
    dispatch(getAgents(filterData))
  }, [dispatch, currentPage, debouncedSearchTerm])

  useEffect(() => {
    const allWeekLabels = agentList.reduce((labels, agent) => {
      const sales = agent.weeklySales || []
      const newLabels = sales.map((week) => week.weekLabel)
      return [...labels, ...newLabels]
    }, [])
    const masterWeekLabels = [...new Set(allWeekLabels)] // Removes duplicates
    const sortedMasterWeekLabels = masterWeekLabels.sort((a, b) => new Date(b) - new Date(a))
    const lastFiveUniqueWeekLabels = sortedMasterWeekLabels.slice(-5)
    const reversedUniqueWeekLabels = [...lastFiveUniqueWeekLabels].reverse()
    setUniqueWeekLabels(reversedUniqueWeekLabels)
  }, [agentList])

  useEffect(() => {
    const allMonthLabels = agentList.reduce((labels, agent) => {
      const sales = agent.monthlySales || []
      const newLabels = sales.map((month) => month.monthLabel)
      return [...labels, ...newLabels]
    }, [])

    const masterMonthLabels = [...new Set(allMonthLabels)] // Removes duplicates
    const sortedMasterMonthLabels = masterMonthLabels.sort((a, b) => new Date(`${a}-01`) - new Date(`${b}-01`))
    const lastTwelveUniqueMonthLabels = sortedMasterMonthLabels.slice(-12)
    const reversedUniqueMonthLabels = [...lastTwelveUniqueMonthLabels].reverse()

    setUniqueMonthLabels(reversedUniqueMonthLabels)
  }, [agentList])

  useEffect(() => {
    const allDayLabels = agentList.reduce((labels, agent) => {
      const sales = agent.dailySales || []
      const newLabels = sales.map((day) => day.dayLabel)
      return [...labels, ...newLabels]
    }, [])

    const masterDayLabels = [...new Set(allDayLabels)] // Removes duplicates
    const sortedMasterDayLabels = masterDayLabels.sort((a, b) => new Date(b) - new Date(a))
    const lastSevenUniqueDayLabels = sortedMasterDayLabels.slice(-7)

    setUniqueDayLabels(lastSevenUniqueDayLabels)
  }, [agentList])

  useEffect(() => {
    const allYearLabels = agentList.reduce((labels, agent) => {
      const sales = agent.yearlySales || []
      const newLabels = sales.map((year) => year.yearLabel)
      return [...labels, ...newLabels]
    }, [])

    const masterYearLabels = [...new Set(allYearLabels)] // Removes duplicates
    const sortedMasterYearLabels = masterYearLabels.sort((a, b) => new Date(`${a}-01-01`) - new Date(`${b}-01-01`))
    const lastFiveUniqueYearLabels = sortedMasterYearLabels.slice(-5)

    setUniqueYearLabels(lastFiveUniqueYearLabels)
  }, [agentList])

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
    setCurrentPage(1)
  }

  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3,
      },
    },
  }

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    show: { y: 0, opacity: 1 },
  }

  return (
    <div className='min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800'>
      {/* Hero Section */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className='relative overflow-hidden bg-gradient-to-r from-primary to-primary-dark py-16 mb-4'>
        <div className='relative z-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
          <div className='text-center'>
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className='text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl'>
              {user?.role === 'AgentUser' && ['countryManager', 'salesManager'].includes(user?.agentType) ? 'My Agents' : 'Agent Management'}
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              className='mt-6 text-xl text-gray-100'>
              Monitor and manage agent performance and activities
            </motion.p>
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: 0.5 }}
              className='mt-4 flex justify-center'>
              <Link
                to='/agent-sign-up'
                className='flex items-center px-4 py-2 rounded-lg bg-white text-primary font-medium shadow-md hover:bg-primary-dark transition-colors'>
                <FiUserPlus className='mr-2' />
                Register New Agent
              </Link>
            </motion.div>
          </div>
        </div>

        {/* Decorative blob */}
        <div className='absolute top-0 right-0 -translate-y-12 translate-x-12 transform opacity-20'>
          <svg width='400' height='400' viewBox='0 0 184 184' xmlns='http://www.w3.org/2000/svg'>
            <path
              fill='white'
              d='M92 0C92 0 92 0 92 0C142.8 0 184 41.2 184 92C184 142.8 142.8 184 92 184C41.2 184 0 142.8 0 92C0 41.2 41.2 0 92 0Z'
            />
          </svg>
        </div>
      </motion.div>

      <div className='mx-auto max-w-8xl px-4 sm:px-6 lg:px-8'>
        <BackButton />

        {/* Search Controls */}
        <motion.div variants={containerVariants} initial='hidden' animate='show' className='bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6 mb-8'>
          <div className='flex flex-col md:flex-row items-center justify-between gap-4'>
            <div className='relative flex-grow'>
              <div
                className={`absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none 
                transition-all duration-200 ${isSearchFocused ? 'text-primary' : 'text-gray-400'}`}>
                <FiSearch />
              </div>
              <input
                type='text'
                placeholder='Search by name, email or city'
                value={searchTerm}
                onChange={handleSearchChange}
                onFocus={() => setIsSearchFocused(true)}
                onBlur={() => setIsSearchFocused(false)}
                className='pl-10 w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent transition-all'
              />
            </div>
          </div>
        </motion.div>

        {/* Agents Table */}
        <motion.div
          variants={containerVariants}
          initial='hidden'
          animate='show'
          className='bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6 mb-8 overflow-hidden'>
          <h2 className='text-2xl font-bold text-gray-900 dark:text-white mb-6'>Agents List</h2>
          <div className='overflow-x-auto'>
            <table className='w-full table-auto bg-[#1a1f2b]'>
              <thead className='bg-[#1a1f2b]'>
                <tr>
                  <th className='sticky left-0 bg-[#1a1f2b] px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider z-10'>
                    Name
                  </th>
                  <th className='px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Email</th>
                  <th className='px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>City</th>
                  <th className='px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Region</th>
                  <th className='px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Country</th>
                  <th className='px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Agent since</th>
                  <th className='px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Agent code</th>
                  <th className='px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Parent DNA</th>
                  {uniqueDayLabels.map((label, index) => (
                    <th key={index} className='px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>
                      Day - {label}
                    </th>
                  ))}
                  {uniqueWeekLabels.map((label, index) => (
                    <th key={index} className='px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>
                      {label}
                    </th>
                  ))}
                  {uniqueMonthLabels.map((label, index) => (
                    <th key={index} className='px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>
                      Month - {label}
                    </th>
                  ))}
                  {uniqueYearLabels.map((label, index) => (
                    <th key={index} className='px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>
                      Year - {label}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200 dark:divide-gray-600'>
                {isLoading ? (
                  <tr>
                    <td colSpan='21' className='text-center py-8'>
                      <Spinner />
                    </td>
                  </tr>
                ) : agentList.length === 0 ? (
                  <tr>
                    <td colSpan='21' className='text-center py-8 text-gray-500 dark:text-gray-400'>
                      No agents found.
                    </td>
                  </tr>
                ) : (
                  agentList.map((agent, index) => (
                    <tr key={agent.id || index} className='group bg-[#1a1f2b] hover:bg-[#252b38]'>
                      <td className='sticky left-0 bg-[#1a1f2b] group-hover:bg-[#252b38] px-6 py-4 z-10'>
                        <div className='flex flex-col space-y-2 mb-5'>
                          <p className='font-bold text-white'>{agent.name}</p>
                          <Link
                            to={`/agent-info/${agent.id}/${agent.agentReferralCode}`}
                            className='inline-flex items-center px-3 py-1 rounded-md bg-primary text-white hover:bg-primary-dark transition-colors'>
                            <FiUsers className='mr-2' /> View Partners
                          </Link>
                          <Link
                            to={`/agent-overview/${agent.id}`}
                            className='inline-flex items-center px-3 py-1 rounded-md bg-secondary text-white hover:bg-secondary-dark transition-colors'>
                            <FiBarChart2 className='mr-2' /> View Stats
                          </Link>
                        </div>
                      </td>
                      <td className='px-6 py-4 text-sm text-gray-300'>{agent.email}</td>
                      <td className='px-6 py-4 text-sm text-gray-300'>{agent.city}</td>
                      <td className='px-6 py-4 text-sm text-gray-300'>{agent.region}</td>
                      <td className='px-6 py-4 text-sm text-gray-300'>{agent.country}</td>
                      <td className='px-6 py-4 text-sm text-gray-300'>{new Date(agent.createdAt).toLocaleDateString()}</td>
                      <td className='px-6 py-4 font-medium text-white'>{agent.agentReferralCode}</td>
                      <td className='px-6 py-4'>
                        {agent.parentDna?.user ? (
                          <Link to={`/agent-overview/${agent.parentDna.user}`} className='text-primary hover:text-primary-dark transition-colors'>
                            {agent.parentDna.name}
                          </Link>
                        ) : (
                          <span className='text-gray-400'>N/A</span>
                        )}
                      </td>
                      {uniqueDayLabels.map((label, labelIndex) => {
                        const dailySales = agent.dailySales || []
                        const dayData = dailySales.find((day) => day.dayLabel === label)
                        return (
                          <td key={labelIndex} className='px-6 py-4 bg-[#1a1f2b] group-hover:bg-[#252b38]'>
                            {dayData ? (
                              <div className='text-white'>
                                <SalesDataDisplay data={dayData} />
                              </div>
                            ) : (
                              <p className='text-sm font-medium text-red-400'>No Sales Made</p>
                            )}
                          </td>
                        )
                      })}
                      {uniqueWeekLabels.map((label, labelIndex) => {
                        const weeklySales = agent.weeklySales || []
                        const weekData = weeklySales.find((week) => week.weekLabel === label)
                        return (
                          <td key={labelIndex} className='px-6 py-4 bg-[#1a1f2b] group-hover:bg-[#252b38]'>
                            {weekData ? (
                              <div className='text-white'>
                                <SalesDataDisplay data={weekData} />
                              </div>
                            ) : (
                              <p className='text-sm font-medium text-red-400'>No Sales Made</p>
                            )}
                          </td>
                        )
                      })}
                      {uniqueMonthLabels.map((label, labelIndex) => {
                        const monthlySales = agent.monthlySales || []
                        const monthData = monthlySales.find((month) => month.monthLabel === label)
                        return (
                          <td key={labelIndex} className='px-6 py-4 bg-[#1a1f2b] group-hover:bg-[#252b38]'>
                            {monthData ? (
                              <div className='text-white'>
                                <SalesDataDisplay data={monthData} />
                              </div>
                            ) : (
                              <p className='text-sm font-medium text-red-400'>No Sales Made</p>
                            )}
                          </td>
                        )
                      })}
                      {uniqueYearLabels.map((label, labelIndex) => {
                        const yearlySales = agent.yearlySales || []
                        const yearData = yearlySales.find((year) => year.yearLabel === label)
                        return (
                          <td key={labelIndex} className='px-6 py-4 bg-[#1a1f2b] group-hover:bg-[#252b38]'>
                            {yearData ? (
                              <div className='text-white'>
                                <SalesDataDisplay data={yearData} />
                              </div>
                            ) : (
                              <p className='text-sm font-medium text-red-400'>No Sales Made</p>
                            )}
                          </td>
                        )
                      })}
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </motion.div>

        {/* Pagination */}
        <motion.div variants={containerVariants} initial='hidden' animate='show' className='flex justify-center mb-20'>
          <Pagination totalPages={agents?.totalPages || 1} currentPage={currentPage} onPageChange={handlePageChange} />
        </motion.div>
      </div>
    </div>
  )
}

export default Agents
