import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import agentsService from './agentsService'

const initialState = {
  agents: [],
  agent: {},
  sales: [],
  agentDetails: {},
  submissionSuccess: false,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
  commissionsData: null,
}

// *Fetch Agents
export const getAgents = createAsyncThunk('agents/getAgents', async (filterData, thunkAPI) => {
  console.log('filter data', filterData)
  try {
    const token = thunkAPI.getState().auth.user.token
    return await agentsService.getAgents(token, filterData)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Fetch Agent
export const getAgent = createAsyncThunk('partners/getAgent', async (agentId, thunkAPI) => {
  console.log('agentId', agentId)
  try {
    const token = thunkAPI.getState().auth.user.token
    return await agentsService.getAgent(token, agentId)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Agent Signup
export const agentSignup = createAsyncThunk('agents/agent-signup', async (formData, thunkAPI) => {
  console.log('filter data', formData)
  try {
    return await agentsService.agentSignup(formData)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Accept agent via ID

export const acceptAgent = createAsyncThunk('agents/acceptAgent', async (agentID, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    const response = await agentsService.acceptAgent(agentID, token)
    return response
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Transfer agent to a partner

export const transferAgent = createAsyncThunk('agents/transferAgent', async (agentData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    const response = await agentsService.transferAgent(agentData, token)
    return response
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Country Manager Registration
export const registerCountryManager = createAsyncThunk('agents/register-country-manager', async (formData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await agentsService.registerCountryManager(formData, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Sales Manager Registration
export const registerSalesManager = createAsyncThunk('agents/register-sales-manager', async (formData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await agentsService.registerSalesManager(formData, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Campaign Manager Registration
export const registerCampaignManager = createAsyncThunk('agents/register-campaign-manager', async (formData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await agentsService.registerCampaignManager(formData, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Fetch Manager's Agents
export const getManagerAgents = createAsyncThunk('partners/getManagerAgents', async (filterData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await agentsService.getManagerAgents(token, filterData)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Register Influencer
export const registerInfluencer = createAsyncThunk('agents/register-influencer', async (formData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await agentsService.registerInfluencer(formData, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Fetch Agent Commissions
export const fetchCommissions = createAsyncThunk('agents/fetchCommissions', async (timeframe, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await agentsService.fetchCommissions(token, timeframe)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// The Slice
export const agentsSlice = createSlice({
  name: 'agents',
  initialState,
  reducers: {
    // Put your synchronous reducers here
    reset: (state) => {
      state.isLoading = false
      state.isError = false
      state.isSuccess = false
      state.message = ''
    },
    logout: (state) => {
      state.agents = []
      state.agent = {}
      state.sales = []
      state.agentDetails = {}
      state.submissionSuccess = false
      state.isError = false
      state.isSuccess = false
      state.isLoading = false
      state.message = ''
      state.commissionsData = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(agentSignup.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(agentSignup.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.submissionSuccess = true
      state.message = action.payload
    })
    builder.addCase(agentSignup.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(getAgents.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getAgents.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.agents = action.payload
    })
    builder.addCase(getAgents.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(acceptAgent.pending, (state) => {})
    builder.addCase(acceptAgent.fulfilled, (state, action) => {
      state.isSuccess = true
      state.message = action.payload

      const agentIdToRemove = action.payload.agentId

      // Removing the agent with matching id from state.agents
      state.agents.agents = state.agents.agents.filter((agent) => agent.id !== agentIdToRemove)
    })
    builder.addCase(acceptAgent.rejected, (state, action) => {
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(getAgent.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getAgent.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.agent = action.payload
    })
    builder.addCase(getAgent.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(transferAgent.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(transferAgent.fulfilled, (state, action) => {
      state.isSuccess = true
      state.isLoading = false
      state.message = action.payload
    })
    builder.addCase(transferAgent.rejected, (state, action) => {
      state.isError = true
      state.isLoading = false
      state.message = action.payload
    })
    builder.addCase(registerCountryManager.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(registerCountryManager.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.submissionSuccess = true
      state.message = action.payload?.message || 'Country manager registered successfully'
    })
    builder.addCase(registerCountryManager.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = typeof action.payload === 'string' ? action.payload : 'Registration failed'
    })

    // Sales Manager Registration cases
    builder.addCase(registerSalesManager.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(registerSalesManager.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.submissionSuccess = true
      state.message = action.payload.message || 'Sales manager registered successfully'
    })
    builder.addCase(registerSalesManager.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })

    builder.addCase(getManagerAgents.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getManagerAgents.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.agents = action.payload
    })
    builder.addCase(getManagerAgents.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })

    builder.addCase(registerCampaignManager.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(registerCampaignManager.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.submissionSuccess = true
      state.message = action.payload.message || 'Campaign manager registered successfully'
    })
    builder.addCase(registerCampaignManager.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })

    builder.addCase(registerInfluencer.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(registerInfluencer.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.submissionSuccess = true
      state.message = action.payload.message || 'Influencer registered successfully'
    })
    builder.addCase(registerInfluencer.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })

    // Commissions fetching
    builder.addCase(fetchCommissions.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchCommissions.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.commissionsData = action.payload
    })
    builder.addCase(fetchCommissions.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
  },
})

export const { reset, logout } = agentsSlice.actions

export default agentsSlice.reducer
