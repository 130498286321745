import { useSelector, useDispatch } from 'react-redux'
import toast from 'react-hot-toast'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getAgentsPartners, reset } from '../features/partners/partnersSlice'
import Pagination from '../components/Pagination'
import { useDebounce } from '../components/useDebounce'
import BackButton from '../components/BackButton'
import { motion } from 'framer-motion'
import { FiSearch, FiCalendar, FiDollarSign, FiCreditCard, FiTrendingUp, FiUser, FiBriefcase, FiPackage } from 'react-icons/fi'

const AgentInfo = () => {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')

  const { agentId, agentReferralCode } = useParams()
  const { partners, isError, isSuccess, isLoading, message } = useSelector((state) => state.partners)
  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  useEffect(() => {
    if (isError) {
      toast.error(message)
    }
    if (isSuccess) {
      dispatch(reset())
    }
  }, [isSuccess, dispatch, isError, message])

  useEffect(() => {
    const filterData = {
      page: currentPage,
      limit: 20,
      searchTerm: debouncedSearchTerm,
      agentReferralCode: agentReferralCode,
      agentId,
    }
    dispatch(getAgentsPartners(filterData))

    return () => {
      dispatch(reset())
    }
  }, [dispatch, currentPage, debouncedSearchTerm, agentReferralCode, agentId])

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
    setCurrentPage(1)
  }

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3,
      },
    },
  }

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    show: { y: 0, opacity: 1 },
  }

  const formatCurrency = (amount) => {
    return (amount / 100).toLocaleString('en-GB', { style: 'currency', currency: 'GBP' })
  }

  const formatDate = (date) => {
    if (!date || isNaN(new Date(date))) return 'N/A'
    return new Date(date).toLocaleDateString('en-GB', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }

  return (
    <div className='w-full min-h-screen bg-gray-900 text-white'>
      {/* Hero Section */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className='relative overflow-hidden bg-gradient-to-r from-primary to-primary-dark py-16 mb-8'>
        <div className='relative z-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
          <div className='text-center'>
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className='text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl'>
              Agent Partner Information
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              className='mt-6 text-xl text-gray-100'>
              View partners registered by this agent
            </motion.p>
          </div>
        </div>

        {/* Decorative blob */}
        <div className='absolute top-0 right-0 -translate-y-12 translate-x-12 transform opacity-20'>
          <svg width='400' height='400' viewBox='0 0 184 184' xmlns='http://www.w3.org/2000/svg'>
            <path
              fill='white'
              d='M92 0C92 0 92 0 92 0C142.8 0 184 41.2 184 92C184 142.8 142.8 184 92 184C41.2 184 0 142.8 0 92C0 41.2 41.2 0 92 0Z'
            />
          </svg>
        </div>
      </motion.div>

      <div className='w-full flex justify-start mb-4 px-6'>
        <BackButton />
      </div>

      <motion.div variants={containerVariants} initial='hidden' animate='show' className='w-full px-6'>
        <motion.div variants={itemVariants} className='bg-gray-800 rounded-xl shadow-xl p-6 mb-8'>
          <div className='flex items-center gap-3 text-xl font-bold text-white border-b border-gray-700 pb-4 mb-4'>
            <FiSearch className='text-primary' />
            <h2>Search Partners</h2>
          </div>

          <div className='relative'>
            <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
              <FiSearch className='text-gray-400' />
            </div>
            <input
              type='text'
              placeholder='Search for a partner by name or email'
              value={searchTerm}
              onChange={handleSearchChange}
              className='w-full pl-10 py-3 bg-gray-700 border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
            />
          </div>
        </motion.div>

        <motion.div variants={itemVariants} className='bg-gray-800 rounded-xl shadow-xl overflow-hidden w-full'>
          {isLoading ? (
            <div className='flex justify-center items-center p-12'>
              <div className='animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary'></div>
            </div>
          ) : !Array.isArray(partners.partners) || partners.partners.length === 0 ? (
            <div className='flex flex-col items-center justify-center py-12 px-4 text-center'>
              <FiBriefcase className='w-16 h-16 text-gray-600 mb-4' />
              <h3 className='text-xl font-medium text-white mb-2'>No Partners Found</h3>
              <p className='text-base text-gray-400'>This agent has no signed up partners yet.</p>
            </div>
          ) : (
            <>
              {/* Desktop view - Table */}
              <div className='w-full overflow-x-auto'>
                <table className='w-full min-w-full table-auto'>
                  <thead className='bg-gray-700 border-b border-gray-600'>
                    <tr>
                      <th className='px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Business Name</th>
                      <th className='px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Client Name</th>
                      <th className='px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Industry</th>
                      <th className='px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Date Joined</th>
                      <th className='px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Status</th>
                      <th className='px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Plan</th>
                      <th className='px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Next Payment</th>
                      <th className='px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Total Spend</th>
                      <th className='px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Commission</th>
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-700'>
                    {partners.partners.map((partner, index) => (
                      <tr key={partner._id} className={`transition-colors ${index % 2 === 0 ? 'bg-gray-800' : 'bg-gray-750'} hover:bg-gray-700`}>
                        <td className='px-6 py-4 whitespace-nowrap'>
                          <span className='font-medium text-primary text-base'>{partner.businessName}</span>
                        </td>
                        <td className='px-6 py-4 whitespace-nowrap text-gray-200'>{partner.name}</td>
                        <td className='px-6 py-4 whitespace-nowrap text-gray-200'>{partner.businessType}</td>
                        <td className='px-6 py-4 whitespace-nowrap'>
                          <div className='flex items-center text-gray-200'>
                            <FiCalendar className='mr-2 text-gray-400' />
                            {formatDate(partner.createdAt)}
                          </div>
                        </td>
                        <td className='px-6 py-4 whitespace-nowrap'>
                          <span
                            className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                              partner.subscription?.status === 'active'
                                ? 'bg-green-900 text-green-200'
                                : partner.subscription?.status === 'past_due'
                                ? 'bg-yellow-900 text-yellow-200'
                                : 'bg-red-900 text-red-200'
                            }`}>
                            {partner.subscription?.status
                              ? partner.subscription.status.charAt(0).toUpperCase() + partner.subscription.status.slice(1)
                              : 'N/A'}
                          </span>
                        </td>
                        <td className='px-6 py-4 whitespace-nowrap'>
                          <div className='flex items-center text-gray-200'>
                            <FiPackage className='mr-2 text-gray-400' />
                            {partner.subscription?.plan === 'monthly' ? 'Monthly' : 'Yearly'}
                          </div>
                        </td>
                        <td className='px-6 py-4 whitespace-nowrap text-gray-200'>{formatDate(partner.subscription?.endDate)}</td>
                        <td className='px-6 py-4 whitespace-nowrap'>
                          <div className='flex items-center text-gray-100 font-medium'>
                            <FiDollarSign className='mr-1 text-green-500' />
                            {formatCurrency(partner.subscription?.totalSpent || 0)}
                          </div>
                        </td>
                        <td className='px-6 py-4 whitespace-nowrap'>
                          <div className='flex items-center text-gray-100 font-medium'>
                            <FiTrendingUp className='mr-1 text-primary' />
                            {formatCurrency(partner.subscription?.totalAgentCommission || 0)}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* Mobile view - Cards */}
              <div className='md:hidden'>
                <div className='divide-y divide-gray-700'>
                  {partners.partners.map((partner) => (
                    <div key={partner._id} className='p-4 space-y-3'>
                      <div className='flex justify-between'>
                        <div>
                          <h3 className='text-lg font-semibold text-primary'>{partner.businessName}</h3>
                          <p className='text-sm text-gray-300'>{partner.name}</p>
                        </div>
                        <span
                          className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium h-fit ${
                            partner.subscription?.status === 'active'
                              ? 'bg-green-900 text-green-200'
                              : partner.subscription?.status === 'past_due'
                              ? 'bg-yellow-900 text-yellow-200'
                              : 'bg-red-900 text-red-200'
                          }`}>
                          {partner.subscription?.status
                            ? partner.subscription.status.charAt(0).toUpperCase() + partner.subscription.status.slice(1)
                            : 'N/A'}
                        </span>
                      </div>

                      <div className='grid grid-cols-2 gap-2 text-sm'>
                        <div className='flex items-center'>
                          <FiUser className='mr-2 text-gray-400' />
                          <span className='text-gray-300'>{partner.businessType}</span>
                        </div>
                        <div className='flex items-center'>
                          <FiCalendar className='mr-2 text-gray-400' />
                          <span className='text-gray-300'>{formatDate(partner.createdAt)}</span>
                        </div>
                        <div className='flex items-center'>
                          <FiPackage className='mr-2 text-gray-400' />
                          <span className='text-gray-300'>{partner.subscription?.plan === 'monthly' ? 'Monthly' : 'Yearly'}</span>
                        </div>
                        <div className='flex items-center'>
                          <FiCalendar className='mr-2 text-gray-400' />
                          <span className='text-gray-300'>Next: {formatDate(partner.subscription?.endDate)}</span>
                        </div>
                      </div>

                      <div className='flex justify-between pt-2 border-t border-gray-700'>
                        <div>
                          <p className='text-xs text-gray-400'>Total Spend</p>
                          <div className='flex items-center text-white font-medium'>
                            <FiDollarSign className='mr-1 text-green-500' />
                            {formatCurrency(partner.subscription?.totalSpent || 0)}
                          </div>
                        </div>
                        <div>
                          <p className='text-xs text-gray-400'>Commission</p>
                          <div className='flex items-center text-white font-medium'>
                            <FiTrendingUp className='mr-1 text-primary' />
                            {formatCurrency(partner.subscription?.totalAgentCommission || 0)}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}

          {/* Pagination */}
          {!isLoading && Array.isArray(partners.partners) && partners.partners.length > 0 && (
            <div className='px-6 py-4 bg-gray-700 border-t border-gray-600'>
              <Pagination totalPages={partners.totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
            </div>
          )}
        </motion.div>
      </motion.div>
    </div>
  )
}

export default AgentInfo
