import { useSelector, useDispatch } from 'react-redux'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import {
  FiSearch,
  FiArrowDown,
  FiArrowUp,
  FiUser,
  FiCalendar,
  FiDollarSign,
  FiExternalLink,
  FiInfo,
  FiPhone,
  FiMapPin,
  FiUsers,
  FiCreditCard,
  FiUserCheck,
  FiGlobe,
  FiDatabase,
} from 'react-icons/fi'
import BackButton from '../components/BackButton'
import Spinner from '../components/Spinner'
import { getPartnerTEDSTransactions, getSinglePartner, reset, resetPartnersState, unblockPartnerAccount } from '../features/partners/partnersSlice'
import Pagination from '../components/Pagination'
import { useDebounce } from '../components/useDebounce'

const PartnerOverview = () => {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const { partnerId } = useParams()

  const {
    partnerTEDSTransactions: { transactions, totalPages },
    isError,
    isSuccess,
    isLoading,
    message,
    partner,
  } = useSelector((state) => state.partners)

  const { user } = useSelector((state) => state.auth)

  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3,
      },
    },
  }

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    show: { y: 0, opacity: 1 },
  }

  useEffect(() => {
    if (isError) {
      toast.error(message)
      dispatch(reset())
    }
    if (isSuccess) {
      dispatch(reset())
    }
  }, [isSuccess, dispatch, isError, message])

  useEffect(() => {
    const filterData = {
      page: currentPage,
      limit: 15,
      searchTerm: debouncedSearchTerm,
      partnerId,
    }
    dispatch(getSinglePartner(partnerId))
    dispatch(getPartnerTEDSTransactions(filterData))

    return () => {
      dispatch(resetPartnersState())
    }
  }, [dispatch, currentPage, debouncedSearchTerm, partnerId])

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
    setCurrentPage(1)
  }

  const formatDate = (isoString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }
    const date = new Date(isoString)
    return date.toLocaleDateString('en-GB', options)
  }

  function formatTransactionType(transactionType) {
    const words = transactionType.split('-')
    return words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
  }

  const tedsToPounds = (teds) => (teds / 10).toFixed(2)

  return (
    <div className='min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800'>
      {/* Hero Section */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className='relative overflow-hidden bg-gradient-to-r from-primary to-primary-dark py-16 mb-4'>
        <div className='relative z-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
          <div className='text-center'>
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className='text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl'>
              {partner?.name || 'Partner'} Overview
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              className='mt-6 text-xl text-gray-100'>
              {partner?.businessName || 'Business'} Details and Transaction History
            </motion.p>
          </div>
        </div>

        {/* Decorative blob */}
        <div className='absolute top-0 right-0 -translate-y-12 translate-x-12 transform opacity-20'>
          <svg width='400' height='400' viewBox='0 0 184 184' xmlns='http://www.w3.org/2000/svg'>
            <path
              fill='white'
              d='M92 0C92 0 92 0 92 0C142.8 0 184 41.2 184 92C184 142.8 142.8 184 92 184C41.2 184 0 142.8 0 92C0 41.2 41.2 0 92 0Z'
            />
          </svg>
        </div>
      </motion.div>

      <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pb-20'>
        <div className='flex justify-start w-full mb-6'>
          <BackButton />
        </div>

        {isLoading && !partner ? (
          <div className='flex justify-center items-center p-12'>
            <div className='animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary'></div>
          </div>
        ) : partner ? (
          <motion.div variants={containerVariants} initial='hidden' animate='show'>
            {/* Partner Information Cards */}
            <motion.div variants={itemVariants} className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8'>
              {/* Basic Info Card */}
              <div className='bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden'>
                <div className='bg-gradient-to-r from-blue-500 to-blue-600 px-6 py-4'>
                  <div className='flex items-center text-white'>
                    <FiInfo className='mr-2 h-5 w-5' />
                    <h3 className='text-lg font-semibold'>Basic Information</h3>
                  </div>
                </div>
                <div className='p-6 space-y-3'>
                  <p className='flex justify-between'>
                    <span className='text-gray-500 dark:text-gray-400'>Email:</span>
                    <span className='font-medium text-gray-900 dark:text-white'>{partner.email}</span>
                  </p>
                  <p className='flex justify-between'>
                    <span className='text-gray-500 dark:text-gray-400'>Business Category:</span>
                    <span className='font-medium text-gray-900 dark:text-white'>{partner.businessCategory}</span>
                  </p>
                  <p className='flex justify-between'>
                    <span className='text-gray-500 dark:text-gray-400'>Business Type:</span>
                    <span className='font-medium text-gray-900 dark:text-white'>{partner.businessType}</span>
                  </p>
                  <p className='flex justify-between'>
                    <span className='text-gray-500 dark:text-gray-400'>Date Joined:</span>
                    <span className='font-medium text-gray-900 dark:text-white'>{new Date(partner.createdAt).toLocaleDateString()}</span>
                  </p>
                  <p className='flex justify-between'>
                    <span className='text-gray-500 dark:text-gray-400'>Status:</span>
                    <span className={`font-medium ${partner.subscription?.isActive ? 'text-green-600' : 'text-red-600'}`}>
                      {partner.subscription?.isActive ? 'Active' : 'Inactive'}
                    </span>
                  </p>
                </div>
              </div>

              {/* Contact Information Card */}
              <div className='bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden'>
                <div className='bg-gradient-to-r from-teal-500 to-teal-600 px-6 py-4'>
                  <div className='flex items-center text-white'>
                    <FiPhone className='mr-2 h-5 w-5' />
                    <h3 className='text-lg font-semibold'>Contact Information</h3>
                  </div>
                </div>
                <div className='p-6 space-y-3'>
                  {partner.phone && partner.phone.landline && (
                    <p className='flex justify-between'>
                      <span className='text-gray-500 dark:text-gray-400'>Landline:</span>
                      <span className='font-medium text-gray-900 dark:text-white'>{partner.phone.landline}</span>
                    </p>
                  )}
                  {partner.phone && partner.phone.mobile && (
                    <p className='flex justify-between'>
                      <span className='text-gray-500 dark:text-gray-400'>Mobile:</span>
                      <span className='font-medium text-gray-900 dark:text-white'>{partner.phone.mobile}</span>
                    </p>
                  )}
                  <div className='pt-3 border-t border-gray-200 dark:border-gray-700'>
                    <div className='flex items-center text-gray-900 dark:text-white mb-2'>
                      <FiMapPin className='mr-2 h-4 w-4 text-teal-500' />
                      <span className='font-semibold'>Address</span>
                    </div>
                    <p className='text-gray-700 dark:text-gray-300'>{partner.address.addressLine1}</p>
                    {partner.address.addressLine2 && <p className='text-gray-700 dark:text-gray-300'>{partner.address.addressLine2}</p>}
                    <p className='text-gray-700 dark:text-gray-300'>
                      {partner.address.city}, {partner.address.region} {partner.address.postcode}
                    </p>
                    <p className='text-gray-700 dark:text-gray-300'>{partner.address.country}</p>
                  </div>
                </div>
              </div>

              {/* Customer Metrics Card */}
              <div className='bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden'>
                <div className='bg-gradient-to-r from-purple-500 to-purple-600 px-6 py-4'>
                  <div className='flex items-center text-white'>
                    <FiUsers className='mr-2 h-5 w-5' />
                    <h3 className='text-lg font-semibold'>Customer Metrics</h3>
                  </div>
                </div>
                <div className='p-6 space-y-3'>
                  <p className='flex justify-between'>
                    <span className='text-gray-500 dark:text-gray-400'>Total Customers:</span>
                    <span className='font-medium text-gray-900 dark:text-white'>{partner.keyMetrics?.totalCustomers || 0}</span>
                  </p>
                  <p className='flex justify-between'>
                    <span className='text-gray-500 dark:text-gray-400'>Stamps Administered:</span>
                    <span className='font-medium text-gray-900 dark:text-white'>{partner.keyMetrics?.numberOfStampsAdministered || 0}</span>
                  </p>
                  <p className='flex justify-between'>
                    <span className='text-gray-500 dark:text-gray-400'>Campaigns:</span>
                    <span className='font-medium text-gray-900 dark:text-white'>{partner.keyMetrics?.numberOfCampaigns || 0}</span>
                  </p>
                  <p className='flex justify-between'>
                    <span className='text-gray-500 dark:text-gray-400'>Rewards Administered:</span>
                    <span className='font-medium text-gray-900 dark:text-white'>{partner.keyMetrics?.numberOfRewardsAdministered || 0}</span>
                  </p>
                  <p className='flex justify-between'>
                    <span className='text-gray-500 dark:text-gray-400'>Servers:</span>
                    <span className='font-medium text-gray-900 dark:text-white'>{partner.keyMetrics?.numberOfServers || 0}</span>
                  </p>
                  <p className='flex justify-between'>
                    <span className='text-gray-500 dark:text-gray-400'>Messages Sent:</span>
                    <span className='font-medium text-gray-900 dark:text-white'>{partner.keyMetrics?.numberOfMessagesSent || 0}</span>
                  </p>
                </div>
              </div>

              {/* Financial Information Card */}
              <div className='bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden'>
                <div className='bg-gradient-to-r from-green-500 to-green-600 px-6 py-4'>
                  <div className='flex items-center text-white'>
                    <FiCreditCard className='mr-2 h-5 w-5' />
                    <h3 className='text-lg font-semibold'>Financial Information</h3>
                  </div>
                </div>
                <div className='p-6 space-y-3'>
                  <p className='flex justify-between'>
                    <span className='text-gray-500 dark:text-gray-400'>Monthly Payments:</span>
                    <span className='font-medium text-gray-900 dark:text-white'>{partner.subscription?.monthlyPaymentsMade || 0}</span>
                  </p>
                  <p className='flex justify-between'>
                    <span className='text-gray-500 dark:text-gray-400'>Yearly Payments:</span>
                    <span className='font-medium text-gray-900 dark:text-white'>{partner.subscription?.yearlyPaymentsMade || 0}</span>
                  </p>
                  <p className='flex justify-between'>
                    <span className='text-gray-500 dark:text-gray-400'>Total Spent:</span>
                    <span className='font-medium text-gray-900 dark:text-white'>£{((partner.subscription?.totalSpent || 0) / 100).toFixed(2)}</span>
                  </p>
                  <p className='flex justify-between'>
                    <span className='text-gray-500 dark:text-gray-400'>Last Payment:</span>
                    <span className='font-medium text-gray-900 dark:text-white'>
                      {partner.subscription?.lastPaymentDate ? new Date(partner.subscription.lastPaymentDate).toLocaleDateString() : 'N/A'}
                    </span>
                  </p>
                  <p className='flex justify-between'>
                    <span className='text-gray-500 dark:text-gray-400'>Plan:</span>
                    <span className='font-medium text-gray-900 dark:text-white'>{partner.subscription?.plan || 'No Plan'}</span>
                  </p>
                </div>
              </div>

              {/* TEDS Information Card */}
              <div className='bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden'>
                <div className='bg-gradient-to-r from-amber-500 to-amber-600 px-6 py-4'>
                  <div className='flex items-center text-white'>
                    <FiDatabase className='mr-2 h-5 w-5' />
                    <h3 className='text-lg font-semibold'>TEDS Information</h3>
                  </div>
                </div>
                <div className='p-6 space-y-3'>
                  <p className='flex justify-between'>
                    <span className='text-gray-500 dark:text-gray-400'>TEDS Balance:</span>
                    <span className='font-medium text-gray-900 dark:text-white'>
                      {partner.wallet?.tedsBalance || 0} (£{tedsToPounds(partner.wallet?.tedsBalance || 0)})
                    </span>
                  </p>
                  <p className='flex justify-between'>
                    <span className='text-gray-500 dark:text-gray-400'>Mad TEDS Balance:</span>
                    <span className='font-medium text-gray-900 dark:text-white'>
                      {partner.wallet?.madTedsBalance || 0} (£{tedsToPounds(partner.wallet?.madTedsBalance || 0)})
                    </span>
                  </p>
                  <p className='flex justify-between'>
                    <span className='text-gray-500 dark:text-gray-400'>Transaction Count:</span>
                    <span className='font-medium text-gray-900 dark:text-white'>{partner.wallet?.transactionCount || 0}</span>
                  </p>
                  <p className='flex justify-between'>
                    <span className='text-gray-500 dark:text-gray-400'>Stamps Sold For TEDS:</span>
                    <span className='font-medium text-gray-900 dark:text-white'>{partner.keyMetrics?.numberOfStampsSoldForTeds || 0}</span>
                  </p>
                  <p className='flex justify-between'>
                    <span className='text-gray-500 dark:text-gray-400'>Stamps Bought For TEDS:</span>
                    <span className='font-medium text-gray-900 dark:text-white'>{partner.keyMetrics?.numberOfStampsBoughtForTeds || 0}</span>
                  </p>
                  <p className='flex justify-between'>
                    <span className='text-gray-500 dark:text-gray-400'>Rewards Bought For TEDS:</span>
                    <span className='font-medium text-gray-900 dark:text-white'>{partner.keyMetrics?.numberOfRewardsBoughtForTeds || 0}</span>
                  </p>
                </div>
              </div>

              {/* Agent Information Card */}
              <div className='bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden'>
                <div className='bg-gradient-to-r from-indigo-500 to-indigo-600 px-6 py-4'>
                  <div className='flex items-center text-white'>
                    <FiUserCheck className='mr-2 h-5 w-5' />
                    <h3 className='text-lg font-semibold'>Agent Information</h3>
                  </div>
                </div>
                <div className='p-6 space-y-3'>
                  {partner.agentReferralCode ? (
                    <>
                      <p className='flex justify-between'>
                        <span className='text-gray-500 dark:text-gray-400'>Agent Name:</span>
                        <span className='font-medium text-gray-900 dark:text-white'>{partner.subscription?.agentName || 'Unnamed Agent'}</span>
                      </p>
                      <p className='flex justify-between'>
                        <span className='text-gray-500 dark:text-gray-400'>Agent Referral Code:</span>
                        <span className='font-medium text-gray-900 dark:text-white'>{partner.agentReferralCode}</span>
                      </p>
                    </>
                  ) : (
                    <p className='text-gray-500 dark:text-gray-400 text-center py-4'>No agent assigned</p>
                  )}

                  <div className='pt-3 border-t border-gray-200 dark:border-gray-700'>
                    <div className='flex items-center text-gray-900 dark:text-white mb-2'>
                      <FiGlobe className='mr-2 h-4 w-4 text-indigo-500' />
                      <span className='font-semibold'>Point of Origin</span>
                    </div>
                    {partner.introducedBy ? (
                      <>
                        <p className='flex justify-between'>
                          <span className='text-gray-500 dark:text-gray-400'>Introduced By:</span>
                          <span className='font-medium text-gray-900 dark:text-white'>{partner.introducedBy.businessName}</span>
                        </p>
                        <p className='flex justify-between'>
                          <span className='text-gray-500 dark:text-gray-400'>Referral Type:</span>
                          <span className='font-medium text-gray-900 dark:text-white'>{partner.introducedBy.referralType}</span>
                        </p>
                      </>
                    ) : (
                      <p className='text-gray-500 dark:text-gray-400'>No introduction data available</p>
                    )}
                  </div>
                </div>
              </div>

              {/* Marketing Campaign Data Card */}
              <div className='bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden'>
                <div className='bg-gradient-to-r from-pink-500 to-pink-600 px-6 py-4'>
                  <div className='flex items-center text-white'>
                    <FiGlobe className='mr-2 h-5 w-5' />
                    <h3 className='text-lg font-semibold'>Marketing Campaign Data</h3>
                  </div>
                </div>
                <div className='p-6 space-y-3'>
                  {partner.utmData ? (
                    <>
                      <p className='flex justify-between'>
                        <span className='text-gray-500 dark:text-gray-400'>Source:</span>
                        <span className='font-medium text-gray-900 dark:text-white'>{partner.utmData.utm_source || 'N/A'}</span>
                      </p>
                      <p className='flex justify-between'>
                        <span className='text-gray-500 dark:text-gray-400'>Medium:</span>
                        <span className='font-medium text-gray-900 dark:text-white'>{partner.utmData.utm_medium || 'N/A'}</span>
                      </p>
                      <p className='flex justify-between'>
                        <span className='text-gray-500 dark:text-gray-400'>Campaign:</span>
                        <span className='font-medium text-gray-900 dark:text-white'>{partner.utmData.utm_campaign || 'N/A'}</span>
                      </p>
                      <p className='flex justify-between'>
                        <span className='text-gray-500 dark:text-gray-400'>Term:</span>
                        <span className='font-medium text-gray-900 dark:text-white'>{partner.utmData.utm_term || 'N/A'}</span>
                      </p>
                      <p className='flex justify-between'>
                        <span className='text-gray-500 dark:text-gray-400'>Content:</span>
                        <span className='font-medium text-gray-900 dark:text-white'>{partner.utmData.utm_content || 'N/A'}</span>
                      </p>
                    </>
                  ) : (
                    <p className='text-gray-500 dark:text-gray-400 text-center py-4'>No UTM data available</p>
                  )}
                </div>
              </div>

              {/* Bank Details Card */}
              <div className='bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden'>
                <div className='bg-gradient-to-r from-gray-500 to-gray-600 px-6 py-4'>
                  <div className='flex items-center text-white'>
                    <FiCreditCard className='mr-2 h-5 w-5' />
                    <h3 className='text-lg font-semibold'>Bank Details for Sale of TEDS</h3>
                  </div>
                </div>
                <div className='p-6 space-y-3'>
                  {partner.bankDetails ? (
                    <>
                      <p className='flex justify-between'>
                        <span className='text-gray-500 dark:text-gray-400'>Account Holder:</span>
                        <span className='font-medium text-gray-900 dark:text-white'>{partner.bankDetails.accountHolderName}</span>
                      </p>
                      <p className='flex justify-between'>
                        <span className='text-gray-500 dark:text-gray-400'>Account Number:</span>
                        <span className='font-medium text-gray-900 dark:text-white'>{partner.bankDetails.accountNumber}</span>
                      </p>
                      <p className='flex justify-between'>
                        <span className='text-gray-500 dark:text-gray-400'>Sort Code:</span>
                        <span className='font-medium text-gray-900 dark:text-white'>{partner.bankDetails.sortCode}</span>
                      </p>
                      <p className='flex justify-between'>
                        <span className='text-gray-500 dark:text-gray-400'>Bank Name:</span>
                        <span className='font-medium text-gray-900 dark:text-white'>{partner.bankDetails.bankName}</span>
                      </p>
                      <p className='flex justify-between'>
                        <span className='text-gray-500 dark:text-gray-400'>Status:</span>
                        <span className={`font-medium ${partner.bankDetails.verified ? 'text-green-600' : 'text-amber-600'}`}>
                          {partner.bankDetails.verified ? 'Verified' : 'Unverified'}
                        </span>
                      </p>
                    </>
                  ) : (
                    <p className='text-gray-500 dark:text-gray-400 text-center py-4'>No bank account linked</p>
                  )}
                </div>
              </div>

              {/* Account Status Card */}
              <div className='bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden'>
                <div className='bg-gradient-to-r from-red-500 to-red-600 px-6 py-4'>
                  <div className='flex items-center text-white'>
                    <FiUser className='mr-2 h-5 w-5' />
                    <h3 className='text-lg font-semibold'>Account Status</h3>
                  </div>
                </div>
                <div className='p-6 space-y-3'>
                  <p className='flex justify-between'>
                    <span className='text-gray-500 dark:text-gray-400'>Account Blocked:</span>
                    <span className={`font-medium ${partner.securitySettings?.accountIsBlocked ? 'text-red-600' : 'text-green-600'}`}>
                      {partner.securitySettings?.accountIsBlocked ? 'Yes' : 'No'}
                    </span>
                  </p>
                  {partner.securitySettings?.accountIsBlocked && (
                    <div className='mt-4 text-center'>
                      <button
                        onClick={() => dispatch(unblockPartnerAccount(partnerId))}
                        className='px-4 py-2 bg-red-600 hover:bg-red-700 text-white rounded-md transition-colors focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50'>
                        Unblock Partner Account
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </motion.div>

            {/* Search Section */}
            <motion.div variants={itemVariants} className='bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-6 mb-8'>
              <div className='flex items-center gap-3 text-xl font-semibold text-gray-900 dark:text-white border-b border-gray-200 dark:border-gray-700 pb-4 mb-4'>
                <FiSearch className='text-primary' />
                <h2>Search Transactions</h2>
              </div>

              <div className='relative'>
                <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                  <FiSearch className='text-gray-400' />
                </div>
                <input
                  type='text'
                  placeholder='Search for a transaction by name...'
                  value={searchTerm}
                  onChange={handleSearchChange}
                  className='w-full pl-10 px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
                />
              </div>
            </motion.div>

            {/* Transactions Table */}
            <motion.div variants={itemVariants} className='bg-white dark:bg-gray-800 rounded-2xl shadow-xl overflow-hidden'>
              <div className='flex items-center gap-3 text-xl font-semibold text-gray-900 dark:text-white border-b border-gray-200 dark:border-gray-700 p-6'>
                <FiExternalLink className='text-primary' />
                <h2>Transaction History</h2>
              </div>

              {isLoading ? (
                <div className='flex justify-center items-center p-12'>
                  <div className='animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary'></div>
                </div>
              ) : (
                <div className='overflow-x-auto'>
                  <table className='w-full min-w-full'>
                    <thead className='bg-gray-50 dark:bg-gray-700 border-b border-gray-200 dark:border-gray-600'>
                      <tr>
                        <th className='px-6 py-4 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>
                          <div className='flex items-center'>
                            <FiCalendar className='mr-2' />
                            Date
                          </div>
                        </th>
                        <th className='px-6 py-4 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>
                          <div className='flex items-center'>
                            <FiArrowUp className='mr-2' />
                            From
                          </div>
                        </th>
                        <th className='px-6 py-4 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>
                          <div className='flex items-center'>
                            <FiArrowDown className='mr-2' />
                            To
                          </div>
                        </th>
                        <th className='px-6 py-4 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>
                          <div className='flex items-center'>
                            <FiDollarSign className='mr-2' />
                            Cost
                          </div>
                        </th>
                        <th className='px-6 py-4 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>
                          <div className='flex items-center'>
                            <FiExternalLink className='mr-2' />
                            TEDS Amount
                          </div>
                        </th>
                        <th className='px-6 py-4 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>
                          <div className='flex items-center'>
                            <FiUser className='mr-2' />
                            Type
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className='bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700'>
                      {!Array.isArray(transactions) || transactions.length === 0 ? (
                        <tr>
                          <td colSpan='6' className='px-6 py-10 text-center text-gray-500 dark:text-gray-400'>
                            <div className='flex flex-col items-center justify-center'>
                              <FiSearch className='w-12 h-12 text-gray-300 dark:text-gray-600 mb-3' />
                              <p className='text-lg'>No transactions found</p>
                              <p className='text-sm'>Try adjusting your search criteria</p>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        transactions.map((transaction, index) => (
                          <tr
                            key={transaction.id || index}
                            className={`hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors ${
                              index % 2 === 0 ? 'bg-gray-50/50 dark:bg-gray-800/50' : ''
                            }`}>
                            <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white'>{formatDate(transaction.createdAt)}</td>
                            <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white'>{transaction.fromUser.userName}</td>
                            <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white'>{transaction.toUser.userName}</td>
                            <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white'>
                              {isNaN(transaction.cost) || transaction.cost == null ? (
                                'N/A'
                              ) : (
                                <span className='font-medium'>{`${user.currency}${transaction.cost.toFixed(2)}`}</span>
                              )}
                            </td>
                            <td className='px-6 py-4 whitespace-nowrap text-sm'>
                              {transaction.transactionType === 'stamp-exchange' || transaction.transactionType === 'reward-exchange' ? (
                                <div className='flex items-center text-red-500 font-medium'>
                                  <FiArrowUp className='mr-1.5 w-4 h-4' />-{transaction.tedsAmount}
                                </div>
                              ) : transaction.transactionType === 'teds-purchase' || transaction.transactionType === 'stamp-purchase' ? (
                                <div className='flex items-center text-green-500 font-medium'>
                                  <FiArrowDown className='mr-1.5 w-4 h-4' />+{transaction.tedsAmount}
                                </div>
                              ) : (
                                <span className='font-medium'>{transaction.tedsAmount}</span>
                              )}
                            </td>
                            <td className='px-6 py-4 whitespace-nowrap text-sm'>
                              <span className='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800 dark:bg-indigo-900 dark:text-indigo-200'>
                                {formatTransactionType(transaction.transactionType)}
                              </span>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              )}

              {/* Pagination */}
              <div className='px-6 py-4 bg-gray-50 dark:bg-gray-700 border-t border-gray-200 dark:border-gray-600'>
                <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
              </div>
            </motion.div>
          </motion.div>
        ) : null}
      </div>
    </div>
  )
}

export default PartnerOverview
