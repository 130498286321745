import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import BackButton from '../components/BackButton'
import { Link } from 'react-router-dom'
import toast from 'react-hot-toast'
import Spinner from '../components/Spinner'
import { getAgents, getManagerAgents, reset, acceptAgent as acceptNewAgent } from '../features/agents/agentsSlice'
import Pagination from '../components/Pagination'
import { useDebounce } from '../components/useDebounce'
import { motion } from 'framer-motion'
import { FiSearch, FiCheck, FiUser, FiMapPin, FiDollarSign } from 'react-icons/fi'

const NewAgents = () => {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const [isSearchFocused, setIsSearchFocused] = useState(false)

  const { agents, isError, isSuccess, isLoading, message } = useSelector((state) => state.agents)
  const { user } = useSelector((state) => state.auth)
  const userRole = user?.role

  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  useEffect(() => {
    if (isError) {
      toast.error(message)
      dispatch(reset())
    }
    if (isSuccess) {
      dispatch(reset())
    }
  }, [isSuccess, dispatch, isError, message])

  useEffect(() => {
    const filterData = {
      page: currentPage,
      limit: 20,
      searchTerm: debouncedSearchTerm,
      isActive: false,
    }

    if (userRole === 'AdminUser') {
      dispatch(getAgents(filterData))
    } else {
      dispatch(getManagerAgents(filterData))
    }
  }, [dispatch, currentPage, debouncedSearchTerm, userRole])

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
    setCurrentPage(1)
  }

  const acceptAgent = (agentID) => {
    dispatch(acceptNewAgent(agentID))
  }

  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3,
      },
    },
  }

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    show: { y: 0, opacity: 1 },
  }

  return (
    <div className='min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800'>
      {/* Hero Section */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className='relative overflow-hidden bg-gradient-to-r from-primary to-primary-dark py-16 mb-4'>
        <div className='relative z-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
          <div className='text-center'>
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className='text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl'>
              New Agent Applications
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              className='mt-6 text-xl text-gray-100'>
              Review and approve new agent registrations
            </motion.p>
          </div>
        </div>

        {/* Decorative blob */}
        <div className='absolute top-0 right-0 -translate-y-12 translate-x-12 transform opacity-20'>
          <svg width='400' height='400' viewBox='0 0 184 184' xmlns='http://www.w3.org/2000/svg'>
            <path
              fill='white'
              d='M92 0C92 0 92 0 92 0C142.8 0 184 41.2 184 92C184 142.8 142.8 184 92 184C41.2 184 0 142.8 0 92C0 41.2 41.2 0 92 0Z'
            />
          </svg>
        </div>
      </motion.div>

      <div className='mx-auto max-w-8xl px-4 sm:px-6 lg:px-8'>
        <BackButton />

        {/* Search Controls */}
        <motion.div variants={containerVariants} initial='hidden' animate='show' className='bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6 mb-8'>
          <div className='flex flex-col md:flex-row items-center justify-between gap-4'>
            <div className='relative flex-grow'>
              <div
                className={`absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none 
                transition-all duration-200 ${isSearchFocused ? 'text-primary' : 'text-gray-400'}`}>
                <FiSearch />
              </div>
              <input
                type='text'
                placeholder='Search by name or email'
                value={searchTerm}
                onChange={handleSearchChange}
                onFocus={() => setIsSearchFocused(true)}
                onBlur={() => setIsSearchFocused(false)}
                className='pl-10 w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent transition-all'
              />
            </div>
          </div>
        </motion.div>

        {/* Agents Table */}
        <motion.div
          variants={containerVariants}
          initial='hidden'
          animate='show'
          className='bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6 mb-8 overflow-hidden'>
          <h2 className='text-2xl font-bold text-gray-900 dark:text-white mb-6'>New Agent Applications</h2>
          {isLoading ? (
            <div className='text-center py-8'>
              <Spinner />
            </div>
          ) : !Array.isArray(agents.agents) || agents.agents.length === 0 ? (
            <div className='text-center py-8 text-gray-500 dark:text-gray-400 text-lg'>No new agent applications found.</div>
          ) : (
            <div className='overflow-x-auto'>
              <table className='w-full table-auto bg-[#1a1f2b]'>
                <thead className='bg-[#1a1f2b]'>
                  <tr>
                    <th className='px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Date</th>
                    <th className='px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Agent Details</th>
                    <th className='px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Business Details</th>
                    <th className='px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Address</th>
                    <th className='px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Trading Info</th>
                    <th className='px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Monthly Sales</th>
                    <th className='px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider'>Action</th>
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-700'>
                  {agents.agents.map((agent) => (
                    <tr key={agent.id} className='group bg-[#1a1f2b] hover:bg-[#252b38]'>
                      <td className='px-6 py-4 text-sm text-gray-300'>{new Date(agent.createdAt).toLocaleDateString()}</td>
                      <td className='px-6 py-4'>
                        <div className='flex flex-col space-y-1'>
                          <p className='font-medium text-white'>{agent.name}</p>
                          <p className='text-sm text-gray-300'>{agent.email}</p>
                        </div>
                      </td>
                      <td className='px-6 py-4'>
                        <div className='flex flex-col space-y-1'>
                          <p className='font-medium text-white'>{agent.tradingName}</p>
                          <p className='text-sm text-gray-300'>{agent.website || 'No website'}</p>
                        </div>
                      </td>
                      <td className='px-6 py-4'>
                        <div className='flex flex-col space-y-1 text-sm text-gray-300'>
                          <p>{agent.address?.addressLine1}</p>
                          {agent.address?.addressLine2 && <p>{agent.address.addressLine2}</p>}
                          <p>{agent.address?.city}</p>
                          <p>{agent.address?.region}</p>
                          <p>{agent.address?.country}</p>
                          <p>{agent.address?.postcode}</p>
                        </div>
                      </td>
                      <td className='px-6 py-4'>
                        <div className='flex flex-col space-y-1 text-sm text-gray-300'>
                          <p>
                            <span className='font-medium text-white'>Style:</span> {agent.tradingStyle}
                          </p>
                          <p>
                            <span className='font-medium text-white'>Activity:</span> {agent.currentTradingActivity}
                          </p>
                          <p>
                            <span className='font-medium text-white'>Company Reg:</span> {agent.companyRegNumber || 'N/A'}
                          </p>
                          <p>
                            <span className='font-medium text-white'>VAT Registered:</span> {agent.vatRegistered ? 'Yes' : 'No'}
                          </p>
                          <p>
                            <span className='font-medium text-white'>VAT Number:</span> {agent.vatRegNumber || 'N/A'}
                          </p>
                        </div>
                      </td>
                      <td className='px-6 py-4'>
                        <div className='grid grid-cols-3 gap-2 text-sm'>
                          {[
                            'january',
                            'february',
                            'march',
                            'april',
                            'may',
                            'june',
                            'july',
                            'august',
                            'september',
                            'october',
                            'november',
                            'december',
                          ].map((month) => (
                            <div key={month} className='text-gray-300'>
                              <span className='font-medium text-white capitalize'>{month.slice(0, 3)}:</span> {agent[month] || '0'}
                            </div>
                          ))}
                        </div>
                      </td>
                      <td className='px-6 py-4'>
                        <button
                          onClick={() => acceptAgent(agent.id)}
                          className='inline-flex items-center px-3 py-1 rounded-md bg-primary text-white hover:bg-primary-dark transition-colors'>
                          <FiCheck className='mr-2' /> Accept
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </motion.div>

        {/* Pagination */}
        {agents.totalPages > 1 && (
          <motion.div variants={containerVariants} initial='hidden' animate='show' className='flex justify-center mb-20'>
            <Pagination totalPages={agents.totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
          </motion.div>
        )}
      </div>
    </div>
  )
}

export default NewAgents
