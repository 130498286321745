import api from '../../api'

const { REACT_APP_SERVER_URL } = process.env

const API_URL = `${REACT_APP_SERVER_URL}/api/agents`

const ADMIN_URL = `${REACT_APP_SERVER_URL}/api/admin`

// *login a new user
const agentSignup = async (userData) => {
  console.log(userData)
  const response = await api.post(API_URL + '/agent-signup', userData)

  return response.data
}

const getAgents = async (token, filterData) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

    const { page = 1, limit = 10, searchTerm = '', isActive } = filterData
    const url = `${ADMIN_URL}/get-all-agents?page=${page}&limit=${limit}&searchTerm=${searchTerm}&isActive=${isActive}`
    const agents = await api.get(url, config)

    return agents.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

// In agentsService.jsx
const getManagerAgents = async (token, { page = 1, limit = 10, searchTerm = '', isActive, agentType }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const url = `${API_URL}/get-manager-agents?page=${page}&limit=${limit}&searchTerm=${searchTerm}&isActive=${isActive}${
      agentType ? `&agentType=${agentType}` : ''
    }`
    const response = await api.get(url, config)
    return response.data
  } catch (error) {
    const message = error.response?.data?.message || error.message || error.toString()
    throw new Error(message)
  }
}

const getAgent = async (token, agentId) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    let url
    if (agentId) {
      url = `${API_URL}/get-agent/${agentId}`
    } else {
      url = `${API_URL}/get-agent`
    }

    const agents = await api.get(url, config)

    return agents.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

// *Accept agent via ID
// Accept agent via ID
const acceptAgent = async (agentID, token) => {
  console.log('agentID', agentID)
  console.log('token', token)
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await api.put(`${ADMIN_URL}/accept-agent/${agentID}`, null, config)
    return response.data
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    console.error(`An error occurred while accepting the agent: ${message}`)
    throw new Error(message)
  }
}

// Transfer agent using agentData
// Transfer agent using agentData
const transferAgent = async (agentData, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }
    const response = await api.put(`${ADMIN_URL}/transfer-agent`, agentData, config)
    return response.data
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    console.error(`An error occurred while transferring the agent: ${message}`)
    throw new Error(message)
  }
}

// Country Manager Registration
const registerCountryManager = async (formData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await api.post(`${ADMIN_URL}/register-country-manager`, formData, config)
  return response.data
}

// Sales Manager Registration
const registerSalesManager = async (formData, token) => {
  console.log(formData, 'form data')
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await api.post(`${API_URL}/register-sales-manager`, formData, config)
  return response.data
}

// Campaign Manager Registration
const registerCampaignManager = async (formData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await api.post(`${API_URL}/register-campaign-manager`, formData, config)
  return response.data
}

// Add new service function
const registerInfluencer = async (formData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await api.post(`${API_URL}/register-influencer`, formData, config)
  return response.data
}

// Fetch commission data
const fetchCommissions = async (token, timeframe = 'monthly') => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

    const url = `${API_URL}/commissions?timeframe=${timeframe}`
    const response = await api.get(url, config)
    return response.data
  } catch (error) {
    const message = error.response?.data?.message || error.message || error.toString()
    throw new Error(message)
  }
}

const agentService = {
  getAgents,
  getManagerAgents,
  getAgent,
  agentSignup,
  acceptAgent,
  transferAgent,
  registerCountryManager,
  registerSalesManager,
  registerCampaignManager,
  registerInfluencer,
  fetchCommissions,
}

export default agentService
