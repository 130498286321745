import { useSelector, useDispatch } from 'react-redux'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { FiSearch, FiArrowDown, FiArrowUp, FiUser, FiCalendar, FiDollarSign, FiExternalLink } from 'react-icons/fi'
import BackButton from '../components/BackButton'
import { getMemberTEDSTransactions, reset, resetMembersState } from '../features/members/membersSlice'
import Pagination from '../components/Pagination'
import { useDebounce } from '../components/useDebounce'

const MemberOverview = () => {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const { memberId, name } = useParams()
  const decodedName = name ? decodeURIComponent(name) : 'Member'

  const {
    memberTEDSTransactions: { transactions, totalPages },
    isError,
    isSuccess,
    isLoading,
    message,
  } = useSelector((state) => state.members)

  const { user } = useSelector((state) => state.auth)
  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3,
      },
    },
  }

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    show: { y: 0, opacity: 1 },
  }

  useEffect(() => {
    if (isError) {
      toast.error(message)
      dispatch(reset())
    }
    if (isSuccess) {
      dispatch(reset())
    }
  }, [isSuccess, dispatch, isError, message])

  useEffect(() => {
    const filterData = {
      page: currentPage,
      limit: 15,
      searchTerm: debouncedSearchTerm,
      memberId,
    }
    dispatch(getMemberTEDSTransactions(filterData))

    return () => {
      dispatch(resetMembersState())
    }
  }, [dispatch, currentPage, debouncedSearchTerm, memberId])

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
    setCurrentPage(1)
  }

  const formatDate = (isoString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' }
    return new Date(isoString).toLocaleDateString('en-GB', options)
  }

  function formatTransactionType(transactionType) {
    const words = transactionType.split('-')
    return words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
  }

  return (
    <div className='min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800'>
      {/* Hero Section */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className='relative overflow-hidden bg-gradient-to-r from-primary to-primary-dark py-16 mb-4'>
        <div className='relative z-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
          <div className='text-center'>
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className='text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl'>
              {decodedName}'s Transactions
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              className='mt-6 text-xl text-gray-100'>
              Complete history of TEDS transactions and activities
            </motion.p>
          </div>
        </div>

        {/* Decorative blob */}
        <div className='absolute top-0 right-0 -translate-y-12 translate-x-12 transform opacity-20'>
          <svg width='400' height='400' viewBox='0 0 184 184' xmlns='http://www.w3.org/2000/svg'>
            <path
              fill='white'
              d='M92 0C92 0 92 0 92 0C142.8 0 184 41.2 184 92C184 142.8 142.8 184 92 184C41.2 184 0 142.8 0 92C0 41.2 41.2 0 92 0Z'
            />
          </svg>
        </div>
      </motion.div>

      <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pb-20'>
        <div className='flex justify-start w-full mb-6'>
          <BackButton />
        </div>

        <motion.div variants={containerVariants} initial='hidden' animate='show'>
          {/* Search Section */}
          <motion.div variants={itemVariants} className='bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-6 mb-8'>
            <div className='flex items-center gap-3 text-xl font-semibold text-gray-900 dark:text-white border-b border-gray-200 dark:border-gray-700 pb-4 mb-4'>
              <FiSearch className='text-primary' />
              <h2>Search Transactions</h2>
            </div>

            <div className='relative'>
              <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                <FiSearch className='text-gray-400' />
              </div>
              <input
                type='text'
                placeholder='Search for a transaction by name...'
                value={searchTerm}
                onChange={handleSearchChange}
                className='w-full pl-10 px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white placeholder-gray-400 focus:border-primary focus:ring-2 focus:ring-primary focus:ring-opacity-20 transition-colors'
              />
            </div>
          </motion.div>

          {/* Transactions Table */}
          <motion.div variants={itemVariants} className='bg-white dark:bg-gray-800 rounded-2xl shadow-xl overflow-hidden'>
            <div className='flex items-center gap-3 text-xl font-semibold text-gray-900 dark:text-white border-b border-gray-200 dark:border-gray-700 p-6'>
              <FiExternalLink className='text-primary' />
              <h2>Transaction History</h2>
            </div>

            {isLoading ? (
              <div className='flex justify-center items-center p-12'>
                <div className='animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary'></div>
              </div>
            ) : (
              <div className='overflow-x-auto'>
                <table className='w-full min-w-full'>
                  <thead className='bg-gray-50 dark:bg-gray-700 border-b border-gray-200 dark:border-gray-600'>
                    <tr>
                      <th className='px-6 py-4 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>
                        <div className='flex items-center'>
                          <FiCalendar className='mr-2' />
                          Date
                        </div>
                      </th>
                      <th className='px-6 py-4 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>
                        <div className='flex items-center'>
                          <FiArrowUp className='mr-2' />
                          From
                        </div>
                      </th>
                      <th className='px-6 py-4 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>
                        <div className='flex items-center'>
                          <FiArrowDown className='mr-2' />
                          To
                        </div>
                      </th>
                      <th className='px-6 py-4 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>
                        <div className='flex items-center'>
                          <FiDollarSign className='mr-2' />
                          Cost
                        </div>
                      </th>
                      <th className='px-6 py-4 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>
                        <div className='flex items-center'>
                          <FiExternalLink className='mr-2' />
                          TEDS Amount
                        </div>
                      </th>
                      <th className='px-6 py-4 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>
                        <div className='flex items-center'>
                          <FiUser className='mr-2' />
                          Type
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className='bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700'>
                    {!Array.isArray(transactions) || transactions.length === 0 ? (
                      <tr>
                        <td colSpan='6' className='px-6 py-10 text-center text-gray-500 dark:text-gray-400'>
                          <div className='flex flex-col items-center justify-center'>
                            <FiSearch className='w-12 h-12 text-gray-300 dark:text-gray-600 mb-3' />
                            <p className='text-lg'>No transactions found</p>
                            <p className='text-sm'>Try adjusting your search criteria</p>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      transactions.map((transaction, index) => (
                        <tr
                          key={transaction._id || index}
                          className={`hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors ${
                            index % 2 === 0 ? 'bg-gray-50/50 dark:bg-gray-800/50' : ''
                          }`}>
                          <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white'>{formatDate(transaction.createdAt)}</td>
                          <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white'>{transaction.fromUser.userName}</td>
                          <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white'>{transaction.toUser.userName}</td>
                          <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white'>
                            {isNaN(transaction.cost) || transaction.cost == null ? (
                              'N/A'
                            ) : (
                              <span className='font-medium'>{`${user.currency}${transaction.cost.toFixed(2)}`}</span>
                            )}
                          </td>
                          <td className='px-6 py-4 whitespace-nowrap text-sm'>
                            {transaction.transactionType === 'stamp-exchange' ? (
                              <div className='flex items-center text-green-500 font-medium'>
                                <FiArrowDown className='mr-1.5 w-4 h-4' />+{transaction.tedsAmount}
                              </div>
                            ) : transaction.transactionType === 'stamp-purchase' || transaction.transactionType === 'reward-exchange' ? (
                              <div className='flex items-center text-red-500 font-medium'>
                                <FiArrowUp className='mr-1.5 w-4 h-4' />-{transaction.tedsAmount}
                              </div>
                            ) : (
                              <span className='font-medium'>{transaction.tedsAmount}</span>
                            )}
                          </td>
                          <td className='px-6 py-4 whitespace-nowrap text-sm'>
                            <span className='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800 dark:bg-indigo-900 dark:text-indigo-200'>
                              {formatTransactionType(transaction.transactionType)}
                            </span>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            )}

            {/* Pagination */}
            <div className='px-6 py-4 bg-gray-50 dark:bg-gray-700 border-t border-gray-200 dark:border-gray-600'>
              <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
            </div>
          </motion.div>
        </motion.div>
      </div>
    </div>
  )
}

export default MemberOverview
