import { useState, useEffect } from 'react'
import BackButton from '../components/BackButton'
import { updateAgentDetails, getAgentDetails, reset } from '../features/auth/authSlice'
import { useSelector, useDispatch } from 'react-redux'
import toast from 'react-hot-toast'
import { motion } from 'framer-motion'
import { FiMail, FiLock, FiEdit2, FiX, FiSave, FiCheckCircle, FiUserCheck } from 'react-icons/fi'

const AgentSettings = () => {
  const { user, agentDetails, isLoading, isSuccess, isError, message } = useSelector((state) => state.auth)

  const [emailError, setEmailError] = useState('')
  const [formErrors, setFormErrors] = useState({})

  const [isSubmitted, setIsSubmitted] = useState(false)

  const [isEditing, setIsEditing] = useState(false)

  const dispatch = useDispatch()

  const [formData, setFormData] = useState({
    email: user.email,
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  })

  useEffect(() => {
    if (isSuccess) {
      toast.success(agentDetails.message)
    }

    if (isError) {
      toast.error(message)
    }
    dispatch(reset())
  }, [agentDetails, isError, message, isSuccess, dispatch])

  const onSubmit = (e) => {
    e.preventDefault()
    setIsSubmitted(true)

    const userData = {
      email: formData.email,
    }

    // Add password data only if provided.
    if (formData.currentPassword && formData.newPassword && formData.confirmNewPassword) {
      userData.currentPassword = formData.currentPassword
      userData.newPassword = formData.newPassword
      userData.confirmNewPassword = formData.confirmNewPassword
    }

    dispatch(updateAgentDetails(userData)).then((res) => {
      if (res.type === updateAgentDetails.fulfilled.type) {
        setIsEditing(false)

        // Reset the password fields
        setFormData((prevState) => ({
          ...prevState,
          currentPassword: '',
          newPassword: '',
          confirmNewPassword: '',
        }))
      }
    })
  }

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  const onChange = (e) => {
    if (e.target.name === 'email') {
      if (!validateEmail(e.target.value)) {
        setEmailError('Invalid email')
      } else {
        setEmailError('')
      }
    }

    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const onEditButtonClick = () => {
    setIsEditing(true)
  }

  const onCancelButtonClick = () => {
    setIsEditing(false)
    setFormData((prevState) => ({
      ...prevState,
      email: user.email,
    }))
  }

  useEffect(() => {
    dispatch(getAgentDetails())
  }, [dispatch])

  useEffect(() => {
    if (agentDetails) {
      setFormData((prevState) => ({
        ...prevState,
        email: agentDetails.email || prevState.email,
      }))
    }
  }, [agentDetails, isSuccess])

  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3,
      },
    },
  }

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    show: { y: 0, opacity: 1 },
  }

  return (
    <div className='bg-[#171c26] text-gray-200'>
      {/* Hero Section */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className='relative overflow-hidden bg-gradient-to-r from-primary to-primary-dark py-16 mb-4'>
        <div className='relative z-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
          <div className='text-center'>
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className='text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl'>
              Account Settings
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              className='mt-6 text-xl text-gray-100'>
              Manage your profile and security preferences
            </motion.p>
          </div>
        </div>

        {/* Decorative blob */}
        <div className='absolute top-0 right-0 -translate-y-12 translate-x-12 transform opacity-20'>
          <svg width='400' height='400' viewBox='0 0 184 184' xmlns='http://www.w3.org/2000/svg'>
            <path
              fill='white'
              d='M92 0C92 0 92 0 92 0C142.8 0 184 41.2 184 92C184 142.8 142.8 184 92 184C41.2 184 0 142.8 0 92C0 41.2 41.2 0 92 0Z'
            />
          </svg>
        </div>
      </motion.div>

      <div className='mx-auto max-w-4xl px-4 sm:px-6 lg:px-8 pb-12'>
        <BackButton />

        <motion.div variants={containerVariants} initial='hidden' animate='show' className='mt-6'>
          <motion.div variants={itemVariants} className='bg-[#1a1f2b] rounded-lg shadow-lg p-8 mb-8'>
            <div className='flex items-center mb-6'>
              <FiUserCheck className='text-[#f2994a] text-2xl mr-3' />
              <h2 className='text-2xl font-bold text-white'>Profile Information</h2>
            </div>

            <form onSubmit={onSubmit} className='space-y-8'>
              {/* Email Section */}
              <motion.div variants={itemVariants} className='space-y-6'>
                <div className='flex items-center pb-2 border-b border-gray-700'>
                  <FiMail className='text-[#f2994a] mr-2' />
                  <h3 className='text-lg font-semibold text-white'>Email Address</h3>
                </div>

                <div className='flex flex-col md:flex-row md:items-center'>
                  <div className='w-full md:w-2/3 relative'>
                    {isEditing ? (
                      <div className='relative'>
                        <input
                          type='text'
                          className='w-full p-3 pl-10 bg-[#252b38] border border-gray-700 rounded-md focus:ring-2 focus:ring-[#f2994a] focus:border-transparent text-white'
                          placeholder='Enter Email Address'
                          id='email'
                          name='email'
                          value={formData.email}
                          onChange={onChange}
                        />
                        <FiMail className='absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400' />
                        {emailError && <p className='text-red-400 text-sm mt-1'>{emailError}</p>}
                      </div>
                    ) : (
                      <div className='p-3 bg-[#252b38] rounded-md flex items-center'>
                        <FiMail className='text-gray-400 mr-2' />
                        <span className='text-gray-200'>{formData.email}</span>
                      </div>
                    )}
                  </div>
                  <div className='mt-3 md:mt-0 md:ml-4 flex'>
                    {!isEditing ? (
                      <button
                        type='button'
                        className='btn btn-sm bg-[#2a3142] hover:bg-[#323a4d] text-white border-0 flex items-center'
                        onClick={onEditButtonClick}>
                        <FiEdit2 className='mr-1' /> EDIT
                      </button>
                    ) : (
                      <button
                        type='button'
                        className='btn btn-sm bg-[#2a3142] hover:bg-[#323a4d] text-white border-0 flex items-center ml-2'
                        onClick={onCancelButtonClick}>
                        <FiX className='mr-1' /> Cancel
                      </button>
                    )}
                  </div>
                </div>
              </motion.div>

              {/* Password Section */}
              <motion.div variants={itemVariants} className='space-y-6'>
                <div className='flex items-center pb-2 border-b border-gray-700'>
                  <FiLock className='text-[#f2994a] mr-2' />
                  <h3 className='text-lg font-semibold text-white'>Change Password</h3>
                </div>
                <p className='text-sm text-gray-400'>
                  To update your password, enter your current password followed by your new password. Leave these fields empty if you don't want to
                  change your password.
                </p>

                {/* Current Password */}
                <div className='space-y-4'>
                  <div className='relative'>
                    <label className='text-sm font-medium text-gray-300 block mb-2'>Current Password</label>
                    <div className='relative'>
                      <input
                        type='password'
                        className='w-full p-3 pl-10 bg-[#252b38] border border-gray-700 rounded-md focus:ring-2 focus:ring-[#f2994a] focus:border-transparent text-white'
                        id='currentPassword'
                        name='currentPassword'
                        value={formData.currentPassword}
                        onChange={onChange}
                        placeholder='Enter Current Password'
                      />
                      <FiLock className='absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400' />
                    </div>
                  </div>

                  {/* New Password */}
                  <div className='relative'>
                    <label className='text-sm font-medium text-gray-300 block mb-2'>New Password</label>
                    <div className='relative'>
                      <input
                        type='password'
                        className='w-full p-3 pl-10 bg-[#252b38] border border-gray-700 rounded-md focus:ring-2 focus:ring-[#f2994a] focus:border-transparent text-white'
                        id='newPassword'
                        name='newPassword'
                        value={formData.newPassword}
                        onChange={onChange}
                        placeholder='Enter New Password'
                      />
                      <FiLock className='absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400' />
                    </div>
                  </div>

                  {/* Confirm New Password */}
                  <div className='relative'>
                    <label className='text-sm font-medium text-gray-300 block mb-2'>Confirm New Password</label>
                    <div className='relative'>
                      <input
                        type='password'
                        className='w-full p-3 pl-10 bg-[#252b38] border border-gray-700 rounded-md focus:ring-2 focus:ring-[#f2994a] focus:border-transparent text-white'
                        id='confirmNewPassword'
                        name='confirmNewPassword'
                        value={formData.confirmNewPassword}
                        onChange={onChange}
                        placeholder='Confirm New Password'
                      />
                      <FiLock className='absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400' />
                    </div>
                  </div>
                </div>
              </motion.div>

              {/* Submit Button */}
              <motion.div variants={itemVariants} className='pt-4'>
                <button
                  className='btn bg-[#f2994a] hover:bg-[#e67f27] border-0 text-white flex items-center justify-center'
                  disabled={isLoading || emailError}>
                  {isLoading ? (
                    <>
                      <img className='w-5 h-5 mr-2' src='/images/loadingSpinner.svg' alt='Loading...' />
                      <span>SAVING CHANGES...</span>
                    </>
                  ) : (
                    <>
                      <FiSave className='mr-2' />
                      <span>SAVE CHANGES</span>
                    </>
                  )}
                </button>

                {isSubmitted && Object.keys(formErrors).length > 0 && (
                  <div className='mt-4 p-3 bg-red-900/30 border border-red-700 rounded-md'>
                    <p className='text-red-400 text-sm'>Please fill in all required fields</p>
                  </div>
                )}

                {isSuccess && (
                  <div className='mt-4 p-3 bg-green-900/30 border border-green-700 rounded-md flex items-center'>
                    <FiCheckCircle className='text-green-400 mr-2' />
                    <p className='text-green-300 text-sm'>Your changes have been saved successfully</p>
                  </div>
                )}
              </motion.div>
            </form>
          </motion.div>
        </motion.div>
      </div>
    </div>
  )
}

export default AgentSettings
