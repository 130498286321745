// Charities.js
import BackButton from '../components/BackButton'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import { useEffect, useState } from 'react'
import Spinner from '../components/Spinner'
import { getAllCharities, reset } from '../features/charity/charitySlice'
import Pagination from '../components/Pagination'
import { useDebounce } from '../components/useDebounce'
import { motion } from 'framer-motion'
import { FiSearch, FiEdit, FiEye, FiPlus, FiHeart, FiMail, FiPhone, FiGlobe } from 'react-icons/fi'

const Charities = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const {
    charities: { charities, totalPages },
    isError,
    isSuccess,
    isLoading,
    message,
  } = useSelector((state) => state.charities)
  const debouncedSearchTerm = useDebounce(searchTerm, 500)
  const [isSearchFocused, setIsSearchFocused] = useState(false)

  useEffect(() => {
    if (isError) {
      toast.error(message)
      dispatch(reset())
    }
    if (isSuccess) {
      dispatch(reset())
    }
  }, [isSuccess, dispatch, isError, message])

  useEffect(() => {
    const filterData = {
      page: currentPage,
      limit: 15,
      searchTerm: debouncedSearchTerm,
    }
    dispatch(getAllCharities(filterData))
    return () => {
      dispatch(reset())
    }
  }, [dispatch, currentPage, debouncedSearchTerm])

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
    setCurrentPage(1)
  }

  const handleEditCharity = (charityId) => {
    navigate(`/charities/${charityId}/edit`)
  }

  const handleAddCharity = () => {
    navigate('/charity/add')
  }

  const handleViewCharity = (charityId) => {
    navigate(`/charity/${charityId}/view`)
  }

  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3,
      },
    },
  }

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    show: { y: 0, opacity: 1 },
  }

  return (
    <div className='min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800'>
      {/* Hero Section */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className='relative overflow-hidden bg-gradient-to-r from-primary to-primary-dark py-16 mb-4'>
        <div className='relative z-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
          <div className='text-center'>
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className='text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl'>
              Charity Management
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              className='mt-6 text-xl text-gray-100'>
              Manage and oversee registered charities
            </motion.p>
          </div>
        </div>

        {/* Decorative blob */}
        <div className='absolute top-0 right-0 -translate-y-12 translate-x-12 transform opacity-20'>
          <svg width='400' height='400' viewBox='0 0 184 184' xmlns='http://www.w3.org/2000/svg'>
            <path
              fill='white'
              d='M92 0C92 0 92 0 92 0C142.8 0 184 41.2 184 92C184 142.8 142.8 184 92 184C41.2 184 0 142.8 0 92C0 41.2 41.2 0 92 0Z'
            />
          </svg>
        </div>
      </motion.div>

      <div className='mx-auto max-w-8xl px-4 sm:px-6 lg:px-8'>
        <BackButton />

        {/* Search and Add Controls */}
        <motion.div variants={containerVariants} initial='hidden' animate='show' className='bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6 mb-8'>
          <div className='flex flex-col md:flex-row items-center justify-between gap-4'>
            <div className='relative flex-grow'>
              <div
                className={`absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none 
                transition-all duration-200 ${isSearchFocused ? 'text-primary' : 'text-gray-400'}`}>
                <FiSearch />
              </div>
              <input
                type='text'
                placeholder='Search by charity name, email, or type'
                value={searchTerm}
                onChange={handleSearchChange}
                onFocus={() => setIsSearchFocused(true)}
                onBlur={() => setIsSearchFocused(false)}
                className='pl-10 w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent transition-all'
              />
            </div>
            <button
              onClick={handleAddCharity}
              className='inline-flex items-center px-4 py-3 rounded-lg bg-primary text-white hover:bg-primary-dark transition-colors shadow-sm'>
              <FiPlus className='mr-2' /> Add Charity
            </button>
          </div>
        </motion.div>

        {/* Charities Table */}
        <motion.div
          variants={containerVariants}
          initial='hidden'
          animate='show'
          className='bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6 mb-8 overflow-hidden'>
          <h2 className='text-2xl font-bold text-gray-900 dark:text-white mb-6'>Registered Charities</h2>
          {isLoading ? (
            <div className='text-center py-8'>
              <Spinner />
            </div>
          ) : !Array.isArray(charities) || charities.length === 0 ? (
            <div className='text-center py-12 bg-gray-50 dark:bg-gray-800/50 rounded-lg'>
              <FiHeart className='mx-auto h-12 w-12 text-gray-400 dark:text-gray-500' />
              <h3 className='mt-2 text-lg font-medium text-gray-900 dark:text-white'>No charities found</h3>
              <p className='mt-1 text-gray-500 dark:text-gray-400'>Try adjusting your search or add a new charity.</p>
              <div className='mt-6'>
                <button
                  onClick={handleAddCharity}
                  className='inline-flex items-center px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary-dark transition-colors'>
                  <FiPlus className='mr-2' /> Add Charity
                </button>
              </div>
            </div>
          ) : (
            <div className='overflow-x-auto'>
              <table className='w-full table-auto'>
                <thead className='bg-gray-50 dark:bg-gray-700/50'>
                  <tr>
                    <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider rounded-tl-lg'>
                      Charity Details
                    </th>
                    <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>
                      Contact Information
                    </th>
                    <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>
                      Category & Type
                    </th>
                    <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider rounded-tr-lg'>
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-200 dark:divide-gray-700'>
                  {charities.map((charity, index) => (
                    <tr
                      key={charity._id}
                      className={`${
                        index % 2 === 0 ? 'bg-white dark:bg-gray-800' : 'bg-gray-50 dark:bg-gray-800/50'
                      } hover:bg-gray-100 dark:hover:bg-gray-700/50 transition-colors`}>
                      <td className='px-6 py-4'>
                        <div className='flex items-center'>
                          <div className='flex-shrink-0 h-10 w-10 bg-primary/10 rounded-full flex items-center justify-center'>
                            <FiHeart className='text-primary' />
                          </div>
                          <div className='ml-4'>
                            <div className='font-medium text-gray-900 dark:text-white'>{charity.charityName}</div>
                            <div className='text-sm text-gray-500 dark:text-gray-400'>Reg: {charity.registrationNumber}</div>
                          </div>
                        </div>
                      </td>
                      <td className='px-6 py-4'>
                        <div className='text-sm'>
                          <div className='flex items-center gap-2 text-gray-600 dark:text-gray-300'>
                            <FiMail className='flex-shrink-0 text-gray-400 dark:text-gray-500' />
                            {charity.email}
                          </div>
                          <div className='flex items-center gap-2 text-gray-600 dark:text-gray-300'>
                            <FiPhone className='flex-shrink-0 text-gray-400 dark:text-gray-500' />
                            {charity.phone}
                          </div>
                          {charity.website && (
                            <div className='flex items-center gap-2 text-gray-600 dark:text-gray-300'>
                              <FiGlobe className='flex-shrink-0 text-gray-400 dark:text-gray-500' />
                              {charity.website}
                            </div>
                          )}
                        </div>
                      </td>
                      <td className='px-6 py-4'>
                        <span className='px-3 py-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-primary/10 text-primary'>
                          {charity.charityCategory}
                        </span>
                        <div className='text-sm text-gray-600 dark:text-gray-300 mt-2'>{charity.charityType}</div>
                      </td>
                      <td className='px-6 py-4 text-sm font-medium space-x-2'>
                        <button
                          onClick={() => handleViewCharity(charity._id)}
                          className='inline-flex items-center px-3 py-1 rounded-md bg-blue-50 dark:bg-blue-900/20 text-blue-600 dark:text-blue-400 hover:bg-blue-100 dark:hover:bg-blue-900/40 transition-colors'>
                          <FiEye className='mr-2' /> View
                        </button>
                        <button
                          onClick={() => handleEditCharity(charity._id)}
                          className='inline-flex items-center px-3 py-1 rounded-md bg-amber-50 dark:bg-amber-900/20 text-amber-600 dark:text-amber-400 hover:bg-amber-100 dark:hover:bg-amber-900/40 transition-colors'>
                          <FiEdit className='mr-2' /> Edit
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </motion.div>

        {/* Pagination */}
        {totalPages > 1 && (
          <motion.div variants={containerVariants} initial='hidden' animate='show' className='flex justify-center mb-20'>
            <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
          </motion.div>
        )}
      </div>
    </div>
  )
}

export default Charities
