import React from 'react'
import { PageSkeleton } from './Skeleton'

const Spinner = () => (
  <div className='w-full h-full'>
    <PageSkeleton />
  </div>
)

export default Spinner
