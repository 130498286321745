// UserDropdown.js
import React, { useState, useRef, useEffect } from 'react'
import { FaSignOutAlt, FaCoins, FaCog, FaUser } from 'react-icons/fa'
import { FaDog } from 'react-icons/fa6'

import { FiLogOut, FiUser, FiDollarSign, FiSettings, FiCreditCard } from 'react-icons/fi'
import { Link, useNavigate } from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion'

function UserDropdown({ user, onLogout }) {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef(null)
  const navigate = useNavigate()

  // Ensure user.wallet is defined before accessing its properties
  const totalTedsBalance = user.wallet ? user.wallet.madTedsBalance + user.wallet.tedsBalance : 0

  // Format user role with emojis for admin
  const formatUserRole = () => {
    if (user.role === 'AdminUser') {
      return '👑 Administrator 🛠️'
    } else if (user.agentType) {
      return user.agentType.charAt(0).toUpperCase() + user.agentType.slice(1).replace(/([A-Z])/g, ' $1')
    } else {
      return 'User'
    }
  }

  // Handle clicking outside to close dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  // Animation variants
  const dropdownVariants = {
    hidden: { opacity: 0, y: -10, scale: 0.95 },
    visible: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        type: 'spring',
        stiffness: 300,
        damping: 30,
      },
    },
    exit: {
      opacity: 0,
      y: -10,
      scale: 0.95,
      transition: { duration: 0.2 },
    },
  }

  const itemVariants = {
    hidden: { opacity: 0, x: -10 },
    visible: (i) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: i * 0.05,
        duration: 0.2,
      },
    }),
  }

  return (
    <div className='relative' ref={dropdownRef}>
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={() => setIsOpen(!isOpen)}
        className='flex items-center space-x-2 bg-gradient-to-r from-orange-500 to-orange-600 text-white px-3 py-2 rounded-xl shadow-md hover:shadow-lg transition-all duration-200'>
        <div className='h-8 w-8 rounded-full bg-white/20 flex items-center justify-center'>
          <FiUser className='text-white' />
        </div>
        <span className='font-medium hidden md:block'>{user.name ? user.name.split(' ')[0] : 'User'}</span>
      </motion.button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            variants={dropdownVariants}
            initial='hidden'
            animate='visible'
            exit='exit'
            className='absolute right-0 mt-3 py-2 w-72 bg-white dark:bg-gray-800 rounded-xl shadow-xl border border-gray-100 dark:border-gray-700 z-50 overflow-hidden'>
            {/* Header with user info */}
            <div className='px-4 py-3 border-b border-gray-100 dark:border-gray-700 bg-gradient-to-r from-orange-500/10 to-orange-600/10'>
              <p className='text-sm font-medium text-gray-900 dark:text-white'>{user.name}</p>
              <p className='text-xs text-gray-500 dark:text-gray-400'>{user.email}</p>
              <p className='text-xs font-medium text-gray-500 dark:text-gray-400 mt-1'>{formatUserRole()}</p>
            </div>

            {/* Menu items */}
            <div className='py-1'>
              {user.role !== 'ServerUser' && (
                <motion.div custom={0} variants={itemVariants} initial='hidden' animate='visible'>
                  <Link
                    to='/teds/wallet'
                    className='flex items-center px-4 py-3 text-sm text-gray-700 dark:text-gray-200 hover:bg-orange-500/10 transition-colors'>
                    <FaDog className='mr-3 h-5 w-5 text-orange-500' />
                    <div>
                      <span className='font-medium'>TEDS Balance</span>
                      <p className='text-xs text-gray-500 dark:text-gray-400 mt-0.5'>{totalTedsBalance} TEDS available</p>
                    </div>
                  </Link>
                </motion.div>
              )}

              {/* Settings option - for agent users */}
              {user.role === 'AgentUser' && (
                <motion.div custom={1} variants={itemVariants} initial='hidden' animate='visible'>
                  <Link
                    to='/settings'
                    className='flex items-center px-4 py-3 text-sm text-gray-700 dark:text-gray-200 hover:bg-orange-500/10 transition-colors'>
                    <FiSettings className='mr-3 h-5 w-5 text-orange-500' />
                    <span className='font-medium'>Account Settings</span>
                  </Link>
                </motion.div>
              )}

              {/* Divider */}
              <div className='border-t border-gray-100 dark:border-gray-700 my-1'></div>

              {/* Logout Option */}
              <motion.div custom={2} variants={itemVariants} initial='hidden' animate='visible'>
                <button
                  onClick={() => {
                    setIsOpen(false)
                    onLogout()
                  }}
                  className='flex w-full items-center px-4 py-3 text-sm text-red-600 dark:text-red-400 hover:bg-red-50 dark:hover:bg-red-900/20 transition-colors'>
                  <FiLogOut className='mr-3 h-5 w-5' />
                  <span className='font-medium'>Sign Out</span>
                </button>
              </motion.div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default UserDropdown
