import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import { useSelector, useDispatch } from 'react-redux'
import { register, reset } from '../features/auth/authSlice'
import Spinner from '../components/Spinner'

function Register() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    password2: '',
  })

  const { name, email, password, password2 } = formData

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { user, isLoading, isSuccess, isError, message } = useSelector((state) => state.auth)

  useEffect(() => {
    if (isError) {
      toast.error(message.message)
      console.log(message)
    }
    // Redirect when logged in

    if (isSuccess || user) {
      navigate('/')
    }

    dispatch(reset())
  }, [isError, isSuccess, user, message, navigate, dispatch])

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...formData,
      [e.target.name]: e.target.value,
    }))
  }

  const onSubmit = (e) => {
    e.preventDefault()

    if (password !== password2) {
      toast.error('Passwords do not match')
    } else {
      const userData = {
        name,
        email,
        password,
      }
      dispatch(register(userData))
    }
  }

  if (isLoading) {
    return <Spinner />
  }
  return (
    <>
      {/* Global Container */}
      <div className='flex items-center justify-center'>
        {/* Card Container */}
        <div className='relative flex flex-col m-6 space-y-10 bg-white shadow-2xl rounded-2xl md:flex-row md:space-y-0 md:m-0'>
          {/* Left Side */}
          <div className='p-6 md:p-20'>
            {/* Top Content */}

            <h2 className='font-mono mb-5 text-4xl font-bold text-left ml-1'>Register</h2>

            <p className='max-w-sm mb-12 font-sans font-light text-gray-600 text-left'>Register now to start earning rewards</p>
            <form onSubmit={onSubmit}>
              <input
                type='text'
                className='w-full p-6 border border-gray-300 rounded-md placeholder:font-sans.placeholder:font-light mb-3'
                placeholder='Enter your name'
                id='name'
                name='name'
                value={name}
                onChange={onChange}
                required
              />
              <input
                type='email'
                className='w-full p-6 border border-gray-300 rounded-md placeholder:font-sans.placeholder:font-light mb-3'
                placeholder='Enter your email address'
                id='email'
                name='email'
                value={email}
                onChange={onChange}
                required
              />
              <input
                type='password'
                className='w-full p-6 border border-gray-300 rounded-md placeholder:font-sans.placeholder:font-light mb-3'
                placeholder='Enter your password'
                id='password'
                name='password'
                value={password}
                onChange={onChange}
                required
              />
              <input
                type='password'
                className='w-full p-6 border border-gray-300 rounded-md placeholder:font-sans.placeholder:font-light'
                placeholder='Confirm your password'
                id='password2'
                name='password2'
                value={password2}
                onChange={onChange}
                required
              />

              {/* Middle Content */}
              <div className='flex flex-col space-y-6 md:flex-row justify-between items-center py-6 md:space-y-0 md:space-x-6'>
                <button className='flex p-6 bg-blue-500 rounded-md text-white w-full   justify-center items-center space-x-4 font-sans font-bold text-white  shadow-lg px-9 bg-cyan-700 shadow-cyan-100 hover:bg-opacity-90 shadow-sm hover:shadow-lg border transition hover:-translate-y-0.5 duration-150'>
                  <span>Submit</span>
                  <img src='images/next-arrow.svg' alt='' />
                </button>
              </div>
            </form>
            {/* Border */}
            <div className='border-b border-b-gray-300 my-4' />
            {/* Bottom Content */}
            <p className='py-6 text-sm text-center font-thin text-gray-700'>Or Sign Up with</p>
            {/* Social Buttons */}
            <div className='flex flex-col space-x-0 space-y-6 md:flex-row md:space-x-6 md:space-y-0'>
              <button className='flex justify-center items-center p-6 space-x-3 py-2 border border-gray-300 rounded shadow-sm hover:bg -opacity-30 hover:shadow-lg hover:-translate-y-0.5 transition duration-150 md:w-1/2'>
                <img src='images/facebook.png' className='w-9' alt='' />
                <span className='font-thin'>Facebook</span>
              </button>
              <button className='flex justify-center items-center p-6 space-x-3 py-2 border border-gray-300 rounded shadow-sm hover:bg-opacity-30 hover:shadow-lg hover:-translate-y-0.5 transition duration-150 md:w-1/2'>
                <img src='images/google.png' className='w-9' alt='' />
                <span className='font-thin'>Google</span>
              </button>
            </div>
          </div>
          {/* Right Side */}
          <img src='images/image.jpg' alt='' className='w-[430px] hidden md:block' />
          {/* Close button */}
          <div className='group absolute -top-5 right-4 flex items-center justify-center w-10 h-10 bg-gray-200 rounded-full md:bg-white md:top-4 hover:cursor-pointer hover:-translate-y-0.5 transition duration-150'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='w-6 h-6 text-black group-hover:text-gray-600'
              viewBox='0 0 24 24'
              strokeWidth='1.5'
              stroke='currentColor'
              fill='none'
              strokeLinecap='round'
              strokeLinejoin='round'>
              <path stroke='none' d='M0 0h24v24H0z' fill='none' />
              <line x1='18' y1='6' x2='6' y2='18' />
              <line x1='6' y1='6' x2='18' y2='18' />
            </svg>
          </div>
        </div>
      </div>
    </>
  )
}

export default Register
