import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { fetchTedsWallet } from '../features/teds/tedsSlice'
import { useDispatch, useSelector } from 'react-redux'
import BackButton from '../components/BackButton'
import { Helmet } from 'react-helmet-async'
import { motion } from 'framer-motion'
import { FiArrowRight } from 'react-icons/fi'
import { GrTransaction } from 'react-icons/gr'
import { RiHandCoinLine, RiCoinsFill } from 'react-icons/ri'
import { BsWallet2 } from 'react-icons/bs'
import { MdOutlineCurrencyExchange } from 'react-icons/md'
import { GiWallet } from 'react-icons/gi'

function TEDSHome() {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)

  const tedsActions = [
    {
      title: 'TEDS Wallet',
      description: 'View and manage your TEDS balance',
      path: '/teds/wallet',
      icon: BsWallet2,
    },
    {
      title: 'Transactions',
      description: 'View your transaction history',
      path: '/teds/transactions',
      icon: GrTransaction,
    },
    {
      title: 'Daily Update',
      description: 'Check daily TEDS statistics',
      path: '/teds/daily-update',
      icon: RiCoinsFill,
    },
    {
      title: 'Wallet Activity',
      description: 'Monitor daily wallet movements',
      path: '/teds/daily-wallet-activity',
      icon: GiWallet,
    },
    {
      title: 'Sold TEDS',
      description: 'View sold TEDS transactions',
      path: '/teds/sold-teds-transactions',
      icon: MdOutlineCurrencyExchange,
    },
  ]

  // Animation variants for staggered animations
  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3,
      },
    },
  }

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    show: { y: 0, opacity: 1 },
  }

  useEffect(() => {
    dispatch(fetchTedsWallet())
  }, [dispatch])

  // Stats data
  const stats = [
    { label: 'Total TEDS', value: '125,450', icon: RiCoinsFill, color: 'from-purple-400 to-indigo-500' },
    { label: 'Daily Volume', value: '2,845', icon: GrTransaction, color: 'from-blue-400 to-cyan-500' },
    { label: 'Active Wallets', value: '1,294', icon: GiWallet, color: 'from-emerald-400 to-teal-500' },
    { label: 'TEDS Value', value: '£0.0124', icon: RiHandCoinLine, color: 'from-amber-400 to-orange-500' },
  ]

  return (
    <div className='min-h-screen w-full bg-gradient-to-b from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800'>
      <Helmet>
        <title>TEDS Currency Center | Loyalty Club PLC</title>
      </Helmet>

      {/* Hero Section - Full width */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className='w-full relative overflow-hidden bg-gradient-to-r from-purple-600 to-indigo-900 py-16'>
        <div className='relative z-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
          <div className='text-center'>
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className='text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl'>
              TEDS Currency Center
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              className='mt-6 text-xl text-gray-100'>
              Manage and monitor your TEDS currency operations
            </motion.p>
          </div>
        </div>

        {/* Decorative blob */}
        <div className='absolute top-0 right-0 -translate-y-12 translate-x-12 transform opacity-20'>
          <svg width='400' height='400' viewBox='0 0 184 184' xmlns='http://www.w3.org/2000/svg'>
            <path
              fill='white'
              d='M92 0C92 0 92 0 92 0C142.8 0 184 41.2 184 92C184 142.8 142.8 184 92 184C41.2 184 0 142.8 0 92C0 41.2 41.2 0 92 0Z'
            />
          </svg>
        </div>
      </motion.div>

      {/* Content Container */}
      <div className='mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 '>
        <BackButton className='text-white hover:text-gray-300 mb-6' />

        {/* Stats Grid */}
        <motion.div variants={containerVariants} initial='hidden' animate='show'>
          <div className='grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4'>
            {stats.map((stat) => (
              <motion.div
                key={stat.label}
                variants={itemVariants}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                className='relative overflow-hidden rounded-2xl bg-white dark:bg-gray-800 p-6 shadow-lg transition-shadow hover:shadow-xl'>
                <div className={`absolute inset-0 opacity-5 bg-gradient-to-br ${stat.color}`} />
                <div className='relative'>
                  <stat.icon className={`h-8 w-8 bg-gradient-to-r ${stat.color} rounded-lg p-1.5 text-white`} />
                  <p className='mt-4 text-2xl font-semibold text-gray-900 dark:text-white'>{stat.value}</p>
                  <p className='mt-2 text-sm font-medium text-gray-500 dark:text-gray-400'>{stat.label}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>

        {/* TEDS Actions */}
        <motion.div variants={containerVariants} initial='hidden' animate='show' className='mt-12 mb-20'>
          <h2 className='text-2xl font-bold text-gray-900 dark:text-white mb-6'>TEDS Management</h2>
          <div className='grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3'>
            {tedsActions.map((action) => (
              <Link key={action.title} to={action.path} className='block'>
                <motion.div
                  variants={itemVariants}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  className='group relative overflow-hidden rounded-2xl bg-white dark:bg-gray-800 p-6 shadow-lg transition-all hover:shadow-xl h-full'>
                  <div className='flex items-start space-x-4'>
                    <action.icon className='h-8 w-8 text-purple-500' />
                    <div className='flex-1'>
                      <h3 className='text-lg font-semibold text-gray-900 dark:text-white group-hover:text-purple-500 transition-colors'>
                        {action.title}
                      </h3>
                      <p className='mt-2 text-sm text-gray-500 dark:text-gray-400'>{action.description}</p>
                    </div>
                    <FiArrowRight className='h-6 w-6 text-gray-400 group-hover:text-purple-500 transition-colors' />
                  </div>
                  <div className='absolute inset-0 bg-gradient-to-r from-purple-500/5 to-indigo-500/5 opacity-0 group-hover:opacity-100 transition-opacity' />
                </motion.div>
              </Link>
            ))}
          </div>
        </motion.div>
      </div>
    </div>
  )
}

export default TEDSHome
