import React, { useState, useEffect } from 'react'
import toast from 'react-hot-toast'
import { resetPassword, reset, resetEmailSent } from '../features/auth/authSlice'
import { useSelector, useDispatch } from 'react-redux'
import { motion } from 'framer-motion'
import { FiMail, FiArrowLeft, FiSend, FiClock } from 'react-icons/fi'
import { Link } from 'react-router-dom'

const ResetPassword = () => {
  const dispatch = useDispatch()
  const [emailAddress, setEmailAddress] = useState('')
  const [cooldown, setCooldown] = useState(0)

  const { isSuccess, isError, passwordResetEmail, message } = useSelector((state) => state.auth)

  const handleEmailChange = (e) => {
    setEmailAddress(e.target.value)
  }

  useEffect(() => {
    if (isSuccess) {
      setCooldown(60)
      dispatch(reset())
    }

    if (isError) {
      toast.error(message)
      dispatch(reset())
    }
  }, [isSuccess, isError, message, dispatch])

  const sendResetLink = () => {
    if (cooldown === 0) {
      dispatch(resetPassword(emailAddress))
      return
    }
  }

  useEffect(() => {
    if (cooldown > 0) {
      const timer = setInterval(() => {
        setCooldown(cooldown - 1)
      }, 1000)
      return () => clearInterval(timer)
    }
    dispatch(resetEmailSent())
  }, [cooldown, dispatch])

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  }

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: { y: 0, opacity: 1 },
  }

  return (
    <div className='min-h-screen w-full bg-gradient-to-b from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800'>
      {/* Hero Section - Full width */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className='w-full relative overflow-hidden bg-gradient-to-r from-primary to-primary-dark py-12'>
        <div className='relative z-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
          <Link to='/login' className='inline-flex items-center text-white mb-6 hover:text-gray-200 transition-colors'>
            <FiArrowLeft className='mr-2' /> Back to Login
          </Link>
          <div className='text-center'>
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className='text-3xl font-bold tracking-tight text-white sm:text-4xl'>
              Reset Your Password
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              className='mt-4 text-xl text-gray-100'>
              We'll help you get back into your account
            </motion.p>
          </div>
        </div>

        {/* Decorative blob */}
        <div className='absolute top-0 right-0 -translate-y-12 translate-x-12 transform opacity-20'>
          <svg width='400' height='400' viewBox='0 0 184 184' xmlns='http://www.w3.org/2000/svg'>
            <path
              fill='white'
              d='M92 0C92 0 92 0 92 0C142.8 0 184 41.2 184 92C184 142.8 142.8 184 92 184C41.2 184 0 142.8 0 92C0 41.2 41.2 0 92 0Z'
            />
          </svg>
        </div>
      </motion.div>

      {/* Content Container */}
      <div className='mx-auto max-w-xl px-4 sm:px-6 lg:px-8 -mt-8'>
        <motion.div
          variants={containerVariants}
          initial='hidden'
          animate='visible'
          className='bg-white dark:bg-gray-800 rounded-xl shadow-xl p-8 overflow-hidden relative'>
          <motion.div variants={itemVariants} className='mb-6'>
            <div className='bg-primary/10 dark:bg-primary/20 rounded-full w-16 h-16 flex items-center justify-center mb-6'>
              <FiMail className='h-8 w-8 text-primary' />
            </div>
            <h2 className='text-2xl font-bold text-gray-900 dark:text-white'>Forgot your password?</h2>
            <p className='mt-3 text-gray-600 dark:text-gray-300'>
              Enter the email address associated with your account and we'll send you a link to reset your password.
            </p>
          </motion.div>

          <motion.div variants={itemVariants} className='space-y-4'>
            <div className='relative'>
              <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
                <FiMail className='h-5 w-5 text-gray-400' />
              </div>
              <input
                type='email'
                className='pl-10 w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-primary dark:focus:ring-primary-dark'
                placeholder='Enter your email address'
                value={emailAddress}
                onChange={handleEmailChange}
              />
            </div>

            {cooldown > 0 && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                className='bg-green-50 dark:bg-green-900/30 rounded-lg p-4 flex items-start'>
                <div className='flex-shrink-0'>
                  <FiSend className='h-5 w-5 text-green-500 dark:text-green-400' />
                </div>
                <div className='ml-3'>
                  <p className='text-sm font-medium text-green-800 dark:text-green-300'>
                    Password reset email has been dispatched. The digital pigeons are on the way to your inbox.
                  </p>
                </div>
              </motion.div>
            )}

            <motion.button
              variants={itemVariants}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={sendResetLink}
              disabled={cooldown > 0}
              className={`w-full flex items-center justify-center px-4 py-3 rounded-lg text-white font-medium transition-all
                ${cooldown > 0 ? 'bg-gray-400 dark:bg-gray-600 cursor-not-allowed' : 'bg-primary hover:bg-primary-dark'}`}>
              {cooldown > 0 ? (
                <>
                  <FiClock className='mr-2' /> Resend in {cooldown} seconds
                </>
              ) : (
                <>
                  <FiSend className='mr-2' /> Request Password Reset
                </>
              )}
            </motion.button>
          </motion.div>
        </motion.div>
      </div>
    </div>
  )
}

export default ResetPassword
